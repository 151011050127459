import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CONSTANTS } from '../../utils/constants/constants';
import { useNavigate } from 'react-router-dom';
import ISwitchProject from '../../utils/entities/header/ISwitchProject';

const SwitchProject = ({ projects, selectedProject, setSelectedProject }: ISwitchProject) => {
  const navigate = useNavigate();

  const onChangeProject = (e: SelectChangeEvent<string>) => {
    setSelectedProject(e.target.value);
    navigate(CONSTANTS.PAGES_URL.INBOX);
  };

  return (
    <Select
      data-testid="project-select-dropdown"
      value={selectedProject}
      onChange={onChangeProject}
      className="navbar-title"
      disableUnderline={true}
      variant="standard"
      classes={{
        iconStandard: 'select-icon',
      }}>
      {projects.map((project) => (
        <MenuItem
          key={project}
          value={project}
          className="project-dropdown"
          data-testid="project-menu-item">
          {project}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SwitchProject;
