import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DotType } from '../../../../types/dotType';
import { CONSTANTS } from '../../../../utils/constants/constants';
import dateUtils from '../../../../utils/functions/dateUtils';
import { IColorThreshold } from '../../../../utils/entities/ag-grid/IDataModelColumn';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import Dot from '../../chip/dot/Dot';
import TooltipComp from '../../tooltip/Tooltip';
import './DateCountdown.scss';

interface IProps {
  timer: number;
  colorTresholds: IColorThreshold[];
}

const DateCountdown = (props: IProps) => {
  const { timer, colorTresholds } = { ...props };
  const { t } = useTranslation();
  const timezone = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);
  const format = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT);

  const [type, setType] = useState<DotType | undefined>();
  const [message, setMessage] = useState<string | undefined>();

  useEffect(() => {
    calcMessageByTimer();
  }, []);

  const isInTreshold = (value: number, min?: number, max?: number) => {
    return value >= (min ?? -Infinity) && value <= (max ?? Infinity);
  };

  const calcType = (hours: number, days: number, months: number, years: number) => {
    for (const confs of colorTresholds) {
      switch (confs.boundType) {
        case 'HOUR':
          if (isInTreshold(hours, confs.lowerBound, confs.upperBound)) {
            setType(confs.colourCode);
            return;
          }
          break;
        case 'DAY':
          if (isInTreshold(days, confs.lowerBound, confs.upperBound)) {
            setType(confs.colourCode);
            return;
          }
          break;
        case 'MONTH':
          if (isInTreshold(months, confs.lowerBound, confs.upperBound)) {
            setType(confs.colourCode);
            return;
          }
          break;
        case 'YEAR':
          if (isInTreshold(years, confs.lowerBound, confs.upperBound)) {
            setType(confs.colourCode);
            return;
          }
          break;
        default:
          setType('OFF');
          break;
      }
    }
  };

  const calcLabel = (hours: number, days: number, months: number, years: number) => {
    if (years >= 1) {
      setMessage(
        `${years} ${t('DATE.YEAR', {
          count: years,
        })}`
      );
    } else if (months >= 1) {
      setMessage(
        `${months} ${t('DATE.MONTH', {
          count: months,
        })}`
      );
    } else if (days >= 1) {
      setMessage(
        `${days} ${t('DATE.DAY', {
          count: days,
        })}`
      );
    } else if (hours >= 1 || hours < 0) {
      setMessage(
        `${hours} ${t('DATE.HOUR', {
          count: hours,
        })}`
      );
    } else if (hours >= 0) {
      setMessage(
        `<${hours} ${t('DATE.HOUR', {
          count: hours,
        })}`
      );
    }
  };

  const calcMessageByTimer = () => {
    const date = new Date();
    const epochNow = Math.floor(date.getTime() / 1000);

    const diffHours = Math.round((timer - epochNow) / 3600);
    const diffDays = diffHours / 24;

    const years = Math.floor(diffDays / 365);
    const months = Math.floor(diffDays / 30);
    const days = Math.floor(diffDays);
    const hours = Math.floor(diffHours);

    calcType(hours, days, months, years);
    calcLabel(hours, days, months, years);
  };

  return (
    <>
      {type && (
        <div className="date-countdown">
          <TooltipComp label={dateUtils.epochToDateFormated(timer, format, timezone)}>
            <Dot type={type} message={message} />
          </TooltipComp>
        </div>
      )}
    </>
  );
};

export default DateCountdown;
