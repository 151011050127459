import React from 'react';
import ILinkButton from '../../../../utils/entities/genericComponents/button/link/ILinkButton';
import TooltipComp from '../../tooltip/Tooltip';
import './LinkButton.scss';

const LinkButton = ({ url, text }: ILinkButton) => {
  return (
    <a
      href={url}
      rel="noopener noreferrer"
      className="hyper-link"
      data-testid="link-button-component">
      <TooltipComp label={text} />
    </a>
  );
};

export default LinkButton;
