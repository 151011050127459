import { Box, IconButton, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IDAndVProperties from './IDAndVProperties';
import SourceContainer from './Source/SourceContainer';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IDrawerBoxProps from '../../../../utils/entities/id-and-v/IDrawerBox';
import PlainTextButton from '../../../generic/button/PlainTextButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useIScreen } from '../../../../pages/task/Task';
import ConfirmationModal from '../../../generic/modal/ConfirmationModal';
import { documentActionType } from '../../../../types/document/documentAction';
import IReceivedItem from '../../../../utils/entities/case/IReceivedItem';
import IEvidenceDocument from '../../../../utils/entities/case/IEvidenceDocument';
import { IConfirmationContent } from '../../../../utils/entities/genericComponents/IConfirmationContent';
import { IDocumentConfiguration } from '../../../../utils/entities/document-center';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { updateDocument } from '../../../../service/data-block-builder.service';
import { PropaneSharp } from '@mui/icons-material';
import { updateDocumentType } from '../../../../types/document/updateDocumentType';
import { addAlert } from '../../../../store/actions/alerts.actions';
import { getCase } from '../../../../service/case-manager.service';
import { getDocumentMetadataList } from '../../../../service/document-manager.service';
import ICase from '../../../../utils/entities/case/ICase';
import IDocumentMetadata from '../../../../utils/entities/case/IDocumentMetadata';
import { getDocumentCenterConfigurations } from '../../../../service/config-manager.service';
import { sendDraft } from '../../../../service/task-manager.service';
import FileUploadDialog from '../document/FileUploadDialog';

const DrawerBox = ({ isIdentification, openDrawer, question, value, entity }: IDrawerBoxProps) => {
  const {
    data,
    activeTabId,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
    requestBody,
    fileType,
    maxFileSize,
    setIndex,
    selectedEntity,
  } = useIScreen();

  const { t } = useTranslation();

  const [guidItemAttr, setGuidItemAttr] = useState<Array<IReceivedItem> | undefined>();
  const [documentList, setDocumentList] = useState<Array<IEvidenceDocument>>([]);
  const [documentListIdxFileToEdit, setDocumentListIdxFileToEdit] = useState<number | undefined>(
    undefined
  );
  const [isFileDialogOpen, setIsFileDialogOpen] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [confirmModalContent, setConfirmModalContent] = useState<IConfirmationContent>({
    title: '',
    text: '',
  });
  const [selectedActionType, setSelectedActionType] = useState<documentActionType | undefined>(
    undefined
  );
  const [documentConfigurations, setDocumentConfigurations] = useState<
    Array<IDocumentConfiguration>
  >([]);
  const virusScanProjectConfig = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED
  );

  useEffect(() => {
    getDocuments(data.caseId);
    getDocumentConfigs();
    sendDraft(requestBody);
    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED,
        virusScanProjectConfig
      );
    };
  }, []);

  const checkDisabled = () => {
    return isCompletedTask || !hasAssignee || assignee != loggedUser;
  };

  const addFileTrigger = () => {
    setIsFileDialogOpen(true);
  };

  const getDocumentConfigs = () => {
    getDocumentCenterConfigurations(data.tenant)
      .then((docConfigs) => {
        setDocumentConfigurations(docConfigs);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('DOC_CENTER.ERROR_CONFIG') }));
  };

  const setDocListWithMetadata = async (docs: IEvidenceDocument[]) => {
    const documentGuidList: Array<string> = [];

    docs.map((doc: IEvidenceDocument) => {
      documentGuidList.push(doc.guid);
    });

    getDocumentMetadataList(data.tenant, documentGuidList)
      .then((metaDataRes: Array<IDocumentMetadata>) => {
        const documetsTemp: IEvidenceDocument[] = [];

        for (const [idx, metadata] of metaDataRes.entries()) {
          const doc = docs.find((docs) => docs.guid === metadata.documentGUID);
          if (doc) {
            documetsTemp[idx] = {
              guid: doc.guid,
              issuedDate: doc?.issuedDate,
              documentDescription: doc?.documentDescription,
              checked: doc?.checked,
              metadata: metadata,
              type: doc?.type,
            };
          } else {
            addAlert({ type: 'error', primaryText: t('ERROR') });
          }
        }
        setDocumentList([...documetsTemp]);
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ERROR') });
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getDocuments = (caseId: string) => {
    getCase(caseId)
      .then((caseFile: ICase) => {
        setDocListWithMetadata(caseFile.evidenceDocuments || []);
        setGuidItemAttr(caseFile.items);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('ERROR') }));
  };

  const getSelectedDocuments = () => {
    return documentList.filter((doc) => doc.checked);
  };

  const getFilesToBeDeleted = () => {
    return getSelectedDocuments().filter((files) => !files.metadata?.delete);
  };

  const getFilesToBeRestored = () => {
    return getSelectedDocuments().filter((files) => files.metadata?.delete);
  };

  const handleUpdateDocuments = () => {
    let docs: IEvidenceDocument[] = [];
    let isDelete: updateDocumentType = null;
    let successMsg = '';
    switch (selectedActionType) {
      case 'delete':
        docs = getFilesToBeDeleted();
        isDelete = 'SOFT_DELETE';
        successMsg = t('DOC_CENTER.FILE_DELETED');
        break;
      case 'restore':
        docs = getFilesToBeRestored();
        isDelete = null;
        successMsg = t('DOC_CENTER.FILE_RESTORED');
        break;
      case 'edit':
        docs = [documentList[documentListIdxFileToEdit as number]];
        isDelete = documentList[documentListIdxFileToEdit as number].metadata?.delete
          ? 'SOFT_DELETE'
          : null;
        setDocumentListIdxFileToEdit(undefined);
        successMsg = t('DOC_CENTER.FILE_UPDATED');
        break;
    }

    if (docs.length === 0) {
      return;
    }

    setShowLoader(true);
    for (const file of docs) {
      updateDocument(
        {
          documentGUID: file.guid,
          caseId: data.caseId,
          documentOriginalName: file.documentDescription,
          documentType: file.metadata?.documentType,
          documentSubType: file.metadata?.documentSubType,
          expirationDate: file.metadata?.expirationDate,
          ingestionDate: file.metadata?.ingestionDate,
          active: file.metadata?.active,
          delete: isDelete, // TODO -> This should be a string 'delete' | 'restore' | 'edit'
          documentName: file.metadata?.documentName,
        },
        data.tenant,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME)
      )
        .then(() => {
          addAlert({ type: 'success', primaryText: successMsg });
          getDocuments(data.caseId);
          LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
        })
        .catch((error) => {
          setShowLoader(false);
          addAlert({ type: 'error', primaryText: error });
        });
    }
  };

  return (
    <Box className="drawerPanelView" sx={{ overflow: 'hidden' }}>
      <div className="header-section input-side">
        <div className="left-side">
          <b>{isIdentification ? t('ID.IDENTIFICATION') : t('V.VERIFICATION')}</b>
        </div>
        <div className="right-side">
          {!isIdentification && (
            <PlainTextButton
              handleClick={() => {
                addFileTrigger();
                setSelectedActionType('upload');
              }}
              isDisabled={checkDisabled()}
              classes={`add-document`}
              icon={<AddCircleOutlineIcon />}
              label={t('ADD_DOCUMENT')}
            />
          )}
          <IconButton onClick={() => openDrawer(false)}>
            <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
          </IconButton>
        </div>
      </div>

      <Divider className="divider" />

      <div className="content-section" style={{ height: '100%' }}>
        <IDAndVProperties
          isIdentification={isIdentification}
          label={question.label}
          idAndVStatus={
            isIdentification
              ? question.identificationStatus[entity]
              : question.verificationStatus[entity]
          }
        />

        {isIdentification &&
          question.identificationStatus[entity].identificationStatus ===
            CONSTANTS.IDENTIFICATIONSTATUS.IDENTIFIED && (
            <>
              <Divider className="divider" />
              <SourceContainer
                isIdentification={isIdentification}
                dataBlocks={[question.identificationStatus[entity].identificationDataBlock]}
              />
            </>
          )}

        {!isIdentification &&
          (question?.verificationStatus[entity]?.verificationStatus ===
            CONSTANTS.VERIFICATIONSTATUS.VERIFIED ||
            question?.verificationStatus[entity]?.verificationStatus ===
              CONSTANTS.VERIFICATIONSTATUS.PARTIALLY_VERIFIED) && (
            <>
              <Divider className="divider" />
              <SourceContainer
                isIdentification={isIdentification}
                dataBlocks={question?.verificationStatus[entity]?.verificationDataBlocks}
              />
            </>
          )}

        {selectedActionType && (
          <FileUploadDialog
            guidItemAttr={guidItemAttr}
            caseId={data.caseId}
            caseDisplayId={data.caseDisplayId}
            hasAssignee={!checkDisabled()}
            tenant={data.tenant}
            fileType={fileType}
            maxFileSize={maxFileSize}
            addFileTrigger={addFileTrigger}
            setShowLoader={setShowLoader}
            setDocumentList={setDocumentList}
            isFileDialogOpen={isFileDialogOpen}
            setIsFileDialogOpen={setIsFileDialogOpen}
            documentList={documentList}
            documentListIdxFileToEdit={documentListIdxFileToEdit}
            handleUpdateDocuments={handleUpdateDocuments}
            editMode={selectedActionType}
            setEditMode={setSelectedActionType}
            documentConfigurations={documentConfigurations}
            getDocuments={getDocuments}
            taskId={requestBody.id}
            enableSaveSync={requestBody.enableSaveSync}
            isVerificationProcess={true}
            questionToVerify={question}
            currentSet={requestBody.dynamicQuestionnaire[activeTabId].sets[setIndex]}
            selectedEntity={selectedEntity}
          />
        )}

        <ConfirmationModal
          showConfirmButton
          openModal={confirmModalOpen}
          setOpenModal={setConfirmModalOpen}
          action={handleUpdateDocuments}
          content={confirmModalContent}
        />
      </div>
    </Box>
  );
};

export default DrawerBox;
