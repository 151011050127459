import axios, { AxiosError, AxiosResponse } from 'axios';
import { IDraftDetail, IDraftTemplate } from '../utils/entities/draft';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/functions/serviceUtils';

export interface IEmailTemaplateDetail {
  tenant: string;
  templateType: string;
  templateName: string;
  templateSubject: string;
  templateBody: string;
  toAddress: string;
}

export const getRFIValue = (tenant: string, templateType: string): Promise<string[]> =>
  axios
    .get(
      `/getMessageTemplates/${encodeURIComponent(tenant)}/${encodeURIComponent(templateType)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<string[]>) => {
      return serviceUtils.processSuccess<string[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getRFITemplateDetail = (
  caseId: string,
  templateType: string,
  templateName: string
): Promise<IEmailTemaplateDetail> =>
  axios
    .post(
      `/populateMessageTemplates/${encodeURIComponent(caseId)}/${encodeURIComponent(
        templateType
      )}/${encodeURIComponent(templateName)}`,
      {},
      axiosConfig
    )
    .then((res: AxiosResponse<IEmailTemaplateDetail>) => {
      return serviceUtils.processSuccess<IEmailTemaplateDetail>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//Get list of drafts' names for given caseId
export const getAllDrafts = (caseId: string): Promise<string[]> =>
  axios
    .get(`/getDraftsNamesList/${encodeURIComponent(caseId)}`, axiosConfig)
    .then((res: AxiosResponse<string[]>) => {
      return serviceUtils.processSuccess<string[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//Get a draft by it's caseId and name
export const getDraftData = (caseId: string, draftName: string): Promise<IDraftDetail> =>
  axios
    .get(
      `/getDraftByCaseIdAndName/${encodeURIComponent(caseId)}/${encodeURIComponent(draftName)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<IDraftDetail>) => {
      return serviceUtils.processSuccess<IDraftDetail>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//Save a draft from a template
export const saveDraftFromTemplate = (
  caseId: string,
  draftName: string,
  reqBody: IDraftTemplate
): Promise<IDraftDetail> =>
  axios
    .post(
      `/copyDraftByCaseIdAndName/${encodeURIComponent(caseId)}/${encodeURIComponent(draftName)}`,
      reqBody,
      axiosConfig
    )
    .then((res: AxiosResponse<IDraftDetail>) => {
      return serviceUtils.processSuccess<IDraftDetail>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//Update a draft for given draftId
export const updateDraft = (draftId: string, reqBody: IDraftDetail) =>
  axios
    .put(`/updateDraftById/${encodeURIComponent(draftId)}`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return serviceUtils.processSuccess(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//Delete a draft for given draftId
export const deleteDraft = (draftId: string) =>
  axios
    .delete(`/deleteDraftById/${encodeURIComponent(draftId)}`, axiosConfig)
    .then((res: AxiosResponse) => {
      return serviceUtils.processSuccess(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
