import React from 'react';
import { Collapse, List } from '@mui/material';
import { IPageListItemProps } from '../../utils/entities/screen/IAboutPage';
import { sortPagesAsc } from './AboutPage';
import PageListItem from './PageListItem';

const ChildPages = (props: IPageListItemProps) => {
  const { page, level, editMode, selectedPageId, expandList, loadPage, setNewPageName } = props;

  return (
    <div>
      {sortPagesAsc(page.children).map((p) => (
        <Collapse in={page.open} timeout="auto" unmountOnExit key={p.pageId}>
          <List component="div" disablePadding>
            <PageListItem
              page={p}
              level={level + 1}
              editMode={editMode}
              selectedPageId={selectedPageId}
              expandList={expandList}
              loadPage={loadPage}
              setNewPageName={setNewPageName}
            />
          </List>
        </Collapse>
      ))}
    </div>
  );
};
export default ChildPages;
