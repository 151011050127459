import { ApolloClient, InMemoryCache } from '@apollo/client';
import { API_CONSTANTS } from '../utils/constants/api_constants';

export const client = new ApolloClient({
  //uri: `http://localhost:8083/graphql`,
  uri: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${API_CONSTANTS.DATAGATEWAY_SERVICE}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  cache: new InMemoryCache(),
});

export const apolloClientTaskList = new ApolloClient({
  ...client,
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
