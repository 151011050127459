import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { IGrpahGlobalSearch, ISource } from '../../utils/entities/global-search/IGlobalSearch';

const QUERY_GLOBAL_SEARCH = gql`
  query Query($tenant: String, $term: String) {
    search(tenant: $tenant, term: $term) {
      source
      values {
        id
        displayLabel
        attributes {
          name
          value
        }
      }
    }
  }
`;

export const GQL_GLOBAL_SEARCH = (
  handleSearchData: (sources: ISource[]) => void,
  handleSearchError: (error: ApolloError) => void
) => {
  const [fetch] = useLazyQuery<IGrpahGlobalSearch>(QUERY_GLOBAL_SEARCH, {
    fetchPolicy: 'no-cache',
    initialFetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    refetchWritePolicy: 'overwrite',
    onCompleted: (data) => handleSearchData(data?.search),
    onError: handleSearchError,
  });
  return {
    fetch,
  };
};
