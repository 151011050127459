import React from 'react';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { ICaseHeaderAttributes } from 'fso-fincrime-sdk-ui';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import TooltipComp from '../../../generic/tooltip/Tooltip';
import { IPermission } from '../../../../utils/entities/role';
import LinkButton from '../../../generic/button/link/LinkButton';
import { PermissionTypes } from '../../../../utils/constants/enums';

interface IProps {
  attribute: ICaseHeaderAttributes;
}

const FocalEntity = (props: IProps) => {
  const { attribute } = { ...props };
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const canAccessAggregateView = GeneralUtils.checkUserPermissions(
    PermissionTypes.TASK_AGGREGATE_VIEW,
    casePermissions
  );
  const canAccess360 = GeneralUtils.checkUserPermissions(
    PermissionTypes.CASE_DETAIL_CASE_360,
    casePermissions
  );

  return (
    <div style={{ display: 'block', width: '100%' }}>
      {canAccessAggregateView || canAccess360 ? (
        <LinkButton
          url={`${CONSTANTS.PAGES_URL.CASE_DETAILS}/${attribute.attributeValue}`}
          text={attribute.attributeValue}
        />
      ) : (
        <TooltipComp label={attribute.attributeValue} />
      )}
    </div>
  );
};

export default FocalEntity;
