import axios, { AxiosError, AxiosResponse } from 'axios';
import parameter from '../../types/service/parameter';
import queryParameter from '../../types/service/queryParameter';
import IServiceUtils from '../entities/functions/IServiceUtils';
import IQueryParameter from '../entities/service/IQueryParameter';

const serviceUtils: IServiceUtils = {
  handleAxiosError: (error: AxiosError) => {
    const errorMsg = (error.response?.data as string) ?? error.message;
    return errorMsg;
  },
  processError: (error: AxiosError | string, returnStatusCode?: boolean) => {
    if (axios.isAxiosError(error)) {
      if (axios.isCancel(error) === true) {
        throw null;
      }

      if (returnStatusCode) {
        throw error.response?.status;
      }
      throw serviceUtils.handleAxiosError(error);
    } else {
      throw error;
    }
  },
  processSuccess: <T>(res: AxiosResponse): T => {
    return res.data as T;
  },
  ArrayParamSerializer: (
    property: string,
    paramsList: Array<parameter> | undefined
  ): string | undefined => {
    if (!paramsList) {
      return undefined;
    }

    return paramsList
      .map((param: parameter) => property + '=' + encodeURIComponent(String(param)))
      .join('&');
  },
  QueryParamsSerializer: (params: IQueryParameter | undefined): string | undefined => {
    if (!params) {
      return undefined;
    }

    const paramsResult = Object.entries(params)
      .map((param: [string, queryParameter]) => {
        if (!param) return;
        switch (typeof param[1]) {
          case 'string':
          case 'number':
          case 'boolean':
            return param[0] + '=' + encodeURIComponent(String(param[1]));
          default:
            return serviceUtils.ArrayParamSerializer(param[0], param[1]);
        }
      })
      .filter(Boolean)
      .join('&');
    return paramsResult && '?' + paramsResult;
  },
};

export default serviceUtils;
