import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../utils/constants/constants';
import ICaseAudit from '../../utils/entities/ICaseAudit';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  ColGroupDef,
  ColumnState,
  ColumnVO,
  FilterModel,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import Pagination from '../generic/AgGrid/pagination/Pagination';
import { getAuditRowsWithServerSideFilterSort } from '../../service/audit-data.service';
import utils from '../../utils/functions/generalUtils';
import SearchIcon from '@mui/icons-material/Search';
import TextFieldComponent from '../generic/inputs/text-field/TextFieldComponent';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EpochToDateText from '../generic/timer/EpochToDateText';
import { IAccessToken } from '../../utils/entities/authentication';
import dateUtils from '../../utils/functions/dateUtils';
import { addAlert } from '../../store/actions/alerts.actions';
import agGridUtils from '../../utils/functions/agGridUtils';
import IDataModelColumn from '../../utils/entities/ag-grid/IDataModelColumn';
import ICaseAuditProps from '../../utils/entities/case/ICaseAudit';
import { IGenericObject } from '../../utils/entities/IGenericObject';
import TooltipComp from '../generic/tooltip/Tooltip';
import IGroupkeyPagination from '../../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import IconButtonComponent from '../generic/button/IconButtonComponent';
import IAgGridTblPayload from '../../utils/entities/ag-grid/IAgGridTblPayload';
import IGroupKeyPageRequest from '../../utils/entities/ag-grid/pagination/IGroupKeyPageRequest';
import IAgGridRowsTaskRes from '../../utils/entities/screen/IAgGridRowsTaskRes';
import Reset from '../generic/AgGrid/reset/Reset';

const CaseAudit = ({ openAudit, caseId }: ICaseAuditProps) => {
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const tenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );
  const columnsData: Array<IDataModelColumn> = [
    {
      attributeLabel: '',
      attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
      attributeType: '',
      columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
    },
    {
      attributeLabel: t('AUDIT.DATE'),
      attributeName: 'timestamp',
      attributeType: '',
      columnType: CONSTANTS.COLUMNTYPES.DATE,
    },
    {
      attributeLabel: t('AUDIT.PERFOMED_BY'),
      attributeName: 'author',
      attributeType: '',
      columnType: CONSTANTS.COLUMNTYPES.TEXT,
    },
    {
      attributeLabel: t('AUDIT.ACTION_MESSAGE'),
      attributeName: 'message',
      attributeType: '',
      columnType: CONSTANTS.COLUMNTYPES.TEXT,
    },
  ];
  const gridRef = useRef<AgGridReact>(null);
  const gridOptions: GridOptions = {
    autoGroupColumnDef: {
      headerName: t('AG_GRID.GROUP_HEADER'),
      field: 'group',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      enableRowGroup: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    getRowId: (params: GetRowIdParams) => {
      if (params.data.id) {
        return params.data.id;
      } else {
        const col = params.data.auditListColumnEntityList[0];
        const parentKeys = (params.parentKeys ?? []).join('-');
        return `${parentKeys}-${col.attributeName}-${col.attributeValue}`;
      }
    },
  };

  const [columns, setColumns] = useState<Array<ColDef | ColGroupDef>>();
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageNo, setPageNo] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [searchField, setSearchField] = useState<string>('');
  const [gridApi, setGridApi] = useState<GridReadyEvent>();
  const [sortModal, setSortModal] = useState<
    {
      sort: string;
      colId: string;
    }[]
  >();
  const [groupByParams, setGroupByParams] = useState<Array<ColumnVO>>([]);
  const [isRes, setIsRes] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    configureColumns(columnsData);

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE);
    };
  }, []);

  useEffect(() => {
    reloadGrid();
  }, [pageNo, pageSize, searchField, tenant]);

  useEffect(() => {
    if (!isRes) {
      reloadGrid();
    }
  }, [isRes]);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE, pageSize);
    setPageNo(0);
  }, [pageSize]);

  const configureColumns = (colConfigs: Array<IDataModelColumn>) => {
    const columns: Array<ColDef | ColGroupDef> = [];
    colConfigs.forEach((c: IDataModelColumn) => {
      const column: ColDef | ColGroupDef = {
        field: c.attributeName,
        headerName: c.attributeLabel,
        headerTooltip: c.attributeLabel,
        filter: agGridUtils.isColDateType(c.columnType)
          ? CONSTANTS.AG_GRID.FILTER.DATE
          : CONSTANTS.AG_GRID.FILTER.TEXT,
        filterParams: agGridUtils.isColDateType(c.columnType)
          ? {
              filterOptions: CONSTANTS.AG_GRID.FILTER.DATE_FILTER_OPTIONS,
              suppressAndOrCondition: true,
            }
          : { suppressAndOrCondition: true },
      };
      if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE) {
        column.cellStyle = { alignContent: 'center' };
        column.resizable = false;
        column.enableRowGroup = false;
        column.suppressMenu = true;
        column.sortable = false;
        column.suppressMovable = true;
        column.maxWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.minWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
        column.suppressColumnsToolPanel = true;
      }
      switch (c.columnType) {
        case CONSTANTS.COLUMNTYPES.DATE:
          column.cellRenderer = (params: { value: number }) => {
            return <EpochToDateText epoch={params.value} />;
          };
          break;
        case CONSTANTS.COLUMNTYPES.TEXT:
          column.cellRenderer = (params: { value: string }) => {
            return (
              <Tooltip title={<span>{params.value}</span>}>
                <span>{params.value}</span>
              </Tooltip>
            );
          };
          break;
        case CONSTANTS.COLUMNTYPES.SHOW_MORE:
          column.cellRenderer = (params: ICellRendererParams) => {
            const key = params.node.key;
            if (params.node.group && params.node.expanded && key) {
              const savedGroupPagination: IGroupkeyPagination[] | undefined =
                LocalStorageUtils.getSavedItem(
                  CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
                );
              const totalElements =
                savedGroupPagination?.find((gr) => gr.keyName === params.node.key)?.totalElements ??
                0;
              const childsNumber = getChildsCount(params.node);
              const canLoadMore = childsNumber < totalElements;

              return (
                <>
                  {canLoadMore && (
                    <TooltipComp
                      label={t('AG_GRID.SHOW_MORE', {
                        num: childsNumber,
                        total: totalElements,
                      })}>
                      <IconButtonComponent
                        icon={'add'}
                        handleClick={() => handleShowMoreClick(key, childsNumber, params)}
                        size={'small'}
                        disableRipple
                        disableFocusRipple
                      />
                    </TooltipComp>
                  )}
                </>
              );
            }
          };
          break;
      }
      columns.push(column);
    });
    setColumns([...columns]);
  };

  const reloadGrid = () => {
    gridApi?.api?.updateGridOptions({ serverSideDatasource: datasource });
  };

  const onFilterTextBoxChanged = (word: string) => {
    setSearchField(word);
  };

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params);
    params.api.setGridOption('domLayout', 'autoHeight');
    params.api.setGridOption('serverSideDatasource', datasource);
  };

  const datasource: IServerSideDatasource = {
    getRows(params: IServerSideGetRowsParams) {
      const { request, success, fail } = params;
      const { filterModel, sortModel, rowGroupCols, groupKeys } = request;
      setSortModal(sortModel);
      setGroupByParams(rowGroupCols);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS,
        rowGroupCols
      );

      const filter = filterModel as FilterModel;
      if (filter) {
        for (const filterKey in filter) {
          if (filter[filterKey].filterType === 'date') {
            filter[filterKey] = {
              filter: dateUtils.dateFormatToEpoch(filter[filterKey]?.dateFrom as string),
              type: 'ranges',
              filterType: filter[filterKey].filterType,
            };
          }
        }
      }

      const auditPayload = {
        caseId: caseId,
        filterModel: filter,
        sortModel: sortModel,
        pageNumber: pageNo,
        pageSize: pageSize,
        rowGroupCols: rowGroupCols,
        groupKeys: groupKeys,
        assignee: userInfo.user,
      };

      if (searchField) {
        filter.id = {
          filterType: typeof searchField,
          type: 'searchAllFields',
          filter: searchField,
        };
      } else if (!searchField && filter.id) {
        delete filter.id;
      }
      saveNewPayload(auditPayload);

      setDataLoading(true);
      getAuditRowsWithServerSideFilterSort(auditPayload)
        .then((response: IAgGridRowsTaskRes) => {
          setIsRes(!!response.content.length);

          if (groupKeys.length === 0) {
            setPageNo(response.pageable.pageNumber);
            setTotalPages(response.totalPages - 1);
            setTotalElements(response.totalElements);
          }

          success({
            rowData: prepareTableData(response.content as ICaseAudit[]),
            rowCount: response.content.length,
          });
          if (!utils.isEmpty(filter) && !response.content.length) {
            setPageNo(0);
          }
          if (!pageNo && !response.content.length) {
            gridApi?.api.showNoRowsOverlay();
          } else {
            gridApi?.api.hideOverlay();
          }

          keepGroupByTotalElements(response.groupKeyPagination);
        })
        .catch((error: string) => {
          addAlert({
            type: 'error',
            primaryText: error ?? '',
          });
          fail();
        })
        .finally(() => setDataLoading(false));
    },
  };

  const prepareTableData = (content: ICaseAudit[]): Array<IGenericObject> => {
    const data: Array<IGenericObject> = [...content];
    content.forEach((row) => {
      row.auditListColumnEntityList.forEach((col) => {
        (row as IGenericObject)[col.attributeName] = col.attributeValue;
      });
    });
    return data;
  };

  /***************************** Needed for the Clear button *****************************/
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const filterModel = gridApi?.api.getFilterModel();
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [columnFiltering, setColumnFiltering] = useState<boolean>(true);
  const [columnSorting, setColumnSorting] = useState<boolean>(true);
  const [columnGroup, setColumnGroup] = useState<boolean>(true);
  const [resetColumns, setResetColumns] = useState<boolean>(true);
  const displayedColumns = gridNode?.columnApi?.api?.columnModel?.displayedColumns;

  useEffect(() => {
    const checkIfGroup = gridNode?.columnApi?.api?.columnModel?.gridColumns?.find((column: any) => {
      return !!column.rowGroupActive;
    });
    setColumnGroup(!checkIfGroup);

    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
    });

    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
  }, [columnState, filterModel, sortModal, groupByParams]);

  const columnsMoved = () => {
    setResetColumns(false);
  };
  /***************************** End of Needed for the Clear button *****************************/

  const keepGroupByTotalElements = (groupKeyPagination: IGroupkeyPagination | undefined) => {
    if (!groupKeyPagination) {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      return;
    }

    const savedGroupPagination: IGroupkeyPagination[] | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
    );

    if (savedGroupPagination) {
      const indexOf = savedGroupPagination
        .map((gr) => gr.keyName)
        .indexOf(groupKeyPagination.keyName);

      if (indexOf < 0) {
        savedGroupPagination.push(groupKeyPagination);
      } else {
        savedGroupPagination[indexOf] = groupKeyPagination;
      }

      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS,
        savedGroupPagination
      );
    } else {
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS, [
        groupKeyPagination,
      ]);
    }
  };

  const saveNewPayload = (auditPayload: IAgGridTblPayload) => {
    LocalStorageUtils.setSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.AUDIT.PAYLOAD,
      structuredClone(auditPayload)
    );
  };

  const findNode = (nodes: IRowNode[], nodeId: string): IRowNode | undefined => {
    return nodes.find((node) => node.id === nodeId);
  };

  const getExpandedGroupKeysRecursive = (
    nodes: IRowNode[],
    expandedGroupKeys: string[],
    nodeId: string
  ) => {
    const node = findNode(nodes, nodeId);
    if (node?.key) {
      expandedGroupKeys.push(node.key);
    }

    const parentKey = node?.parent?.id;
    if (parentKey) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, parentKey);
    } else {
      return expandedGroupKeys;
    }
  };

  const getExpandedGroupKeys = (params: ICellRendererParams) => {
    const expandedGroupKeys: string[] = [];
    const nodes = params?.api?.getRenderedNodes();
    if (nodes) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, params.node.id as string);
    }

    return expandedGroupKeys.reverse();
  };

  const getChildsCount = (node: any) => {
    return node.childStore.displayIndexEnd - node.childStore.displayIndexStart;
  };

  const increaseGroupKeyPagination = (key: string, childsCount: number): IGroupKeyPageRequest => {
    const pageNumber = Math.round(
      childsCount / LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE)
    );

    return {
      keyName: key,
      loadMoreRequest: pageNumber,
    };
  };

  const handleShowMoreClick = (key: string, childsCount: number, params: ICellRendererParams) => {
    if (params.node.id) {
      const groupKeys: string[] = getExpandedGroupKeys(params);
      const groupByParams: ColumnVO[] = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS
      );
      const payload: IAgGridTblPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.AUDIT.PAYLOAD
      );

      if (payload && key) {
        const newPayload = structuredClone(payload);
        newPayload.groupKeys = groupKeys;
        newPayload.rowGroupCols = groupByParams;
        newPayload.groupKeyPageRequest = increaseGroupKeyPagination(key, childsCount);
        saveNewPayload(newPayload);
        setDataLoading(true);
        getAuditRowsWithServerSideFilterSort(newPayload)
          .then((res) => {
            params?.api?.applyServerSideTransaction({
              route: groupKeys,
              add: prepareTableData(res.content as ICaseAudit[]),
            });
          })
          .catch(() => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => setDataLoading(false));
      }
    }
  };

  const getRowClass = (params: any) => {
    if (params?.data?.status != null && (params?.data?.status as string).toUpperCase() == 'NEW') {
      return 'bold-font';
    }
  };

  return (
    <Box className="drawerPanelView">
      <div className="header-section input-side">
        <div className="left-side">
          <b>{t('AUDIT_HISTORY')}</b>
        </div>
        <div className="right-side">
          <IconButton onClick={() => openAudit(false)}>
            <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
          </IconButton>
        </div>
      </div>
      <Divider className="divider" />
      <div className="content-section">
        <Paper variant="outlined" className="transaction-paper">
          <div className="right-aligned-items padding-left-right-10">
            <Stack spacing={2} direction="row" className="center-aligned">
              <Box className="label bold-font search-box margin-bottom-10">
                <TextFieldComponent
                  placeholder={t('SEARCH_3_DOTS')}
                  type="text"
                  value={searchField ?? ''}
                  setData={(value: string) => onFilterTextBoxChanged(value)}
                  icon={<SearchIcon />}
                />
              </Box>

              <Reset
                searchField={searchField}
                setSearchField={setSearchField}
                setColumnSorting={setColumnSorting}
                setColumnGroup={setColumnGroup}
                setColumnFiltering={setColumnFiltering}
                setSelectAllChecked={setSelectAllChecked}
                setResetColumns={setResetColumns}
                columnFiltering={columnFiltering}
                columnSorting={columnSorting}
                columnGroup={columnGroup}
                selectAllChecked={selectAllChecked}
                resetColumns={resetColumns}
                grid={gridRef}
                api={gridApi}
                columnsConfigs={columnsData}
                displayedColumns={displayedColumns ?? []}
              />
            </Stack>
          </div>
        </Paper>
        <div className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            defaultColDef={CONSTANTS.AG_GRID.DEFAULT_COL_DEF}
            rowGroupPanelShow="always"
            rowSelection="multiple"
            domLayout="autoHeight"
            rowModelType="serverSide"
            suppressRowClickSelection={true}
            suppressServerSideInfiniteScroll={false}
            animateRows={true}
            columnDefs={columns}
            groupSelectsChildren={!(searchField && !groupByParams.length)}
            onGridReady={onGridReady}
            getRowClass={getRowClass}
            onColumnMoved={columnsMoved}
            getContextMenuItems={() => CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS}
            onColumnRowGroupChanged={() => {
              setPageNo(0);
            }}
            onRowGroupOpened={() => {
              gridApi?.api.refreshCells({ force: true });
            }}
            paginationPageSize={pageSize}
            paginationAutoPageSize={false}
            cacheBlockSize={0}
          />
          <Pagination
            pageSize={pageSize}
            pageNo={pageNo}
            totalPages={totalPages}
            totalElements={totalElements}
            setPageSize={setPageSize}
            setPageNo={setPageNo}
            dataLoading={dataLoading}
          />
        </div>
      </div>
    </Box>
  );
};

export default CaseAudit;
