import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, FormGroup, Tooltip } from '@mui/material';
import { ColumnState, GridReadyEvent } from 'ag-grid-community';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import './Reset.scss';
import DarkButton from '../../buttons/DarkButton';
import CheckboxComponent from '../../inputs/checkbox/CheckboxComponent';
import { CONSTANTS } from '../../../../utils/constants/constants';
import IAgGridResetConfig from '../../../../utils/entities/ag-grid/reset/IReset';
import PlainTextButton from '../../button/PlainTextButton';

const Reset = ({
  searchField,
  setSearchField,
  setDefaultView,
  setColumnSorting,
  setColumnGroup,
  setColumnFiltering,
  setSelectAllChecked,
  setResetColumns,
  defaultView,
  columnFiltering,
  columnSorting,
  columnGroup,
  selectAllChecked,
  resetColumns,
  grid,
  api,
  columnsConfigs,
  displayedColumns,
}: IAgGridResetConfig) => {
  const gridRef = useRef(null);
  const { t } = useTranslation();
  const resetFilterRef = useRef<HTMLDivElement>(null);
  const [gridApi] = useState<GridReadyEvent>();
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const filterModel = api?.api.getFilterModel();

  const [showClearFilters, setShowClearFilters] = useState<boolean>(false);
  const [resetList] = useState(CONSTANTS.RESET_FILTERS);
  const [resetListStates, setResetListStates] = useState<Array<boolean>>(
    resetList.map(() => false)
  );
  const [toBeCleared, setToBeCleared] = useState<Array<string>>([]);

  useEffect(() => {
    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
      if (state.rowGroup) {
        setColumnGroup(false);
      }
    });
    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
    if (columnsConfigs && displayedColumns && columnsConfigs.length !== displayedColumns.length) {
      setResetColumns(false);
    }
  }, [showClearFilters]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (resetFilterRef.current && !resetFilterRef.current.contains(event.target)) {
        setShowClearFilters(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resetFilterRef]);

  const clearFilters = () => {
    const gridNode = grid.current as any;
    if (toBeCleared.length) {
      if (toBeCleared.includes('Filtering')) {
        api?.api.setFilterModel(null);
      }
      if (
        toBeCleared.includes('Sorting') ||
        toBeCleared.includes('Grouping') ||
        toBeCleared.includes('Columns')
      ) {
        gridNode.columnApi.api?.resetColumnState();
      }
      if (toBeCleared.includes('Searching') && setSearchField) {
        setSearchField('');
      }
      api?.api.onFilterChanged();
      setDefaultView && setDefaultView(true);
      setTimeout(() => {
        setDefaultView && setDefaultView(false);
      }, 1000);
      setResetListStates(resetListStates.map(() => false));
      setToBeCleared([]);
      setShowClearFilters(false);
    }
  };

  useEffect(() => {
    setColumnFiltering(columnFiltering);
    setColumnSorting(columnSorting);
    setColumnGroup(columnGroup);
    setResetColumns(resetColumns);
    setSearchField(searchField ?? '');
  }, [columnFiltering, columnSorting, columnGroup, resetColumns, searchField]);

  return (
    <div className="reset-filter-wrapper">
      <Tooltip title={t('RESET_FILTERS_TOOLTIP')}>
        <>
          <PlainTextButton
            handleClick={() => {
              setShowClearFilters(!showClearFilters);
            }}
            classes="text-transform-capitalize bold-font"
            icon={<FilterListOffIcon />}
            label={t('RESET_FILTERS')}
          />
        </>
      </Tooltip>

      <div
        className="reset-filter-dropdown"
        ref={resetFilterRef}
        style={{ display: showClearFilters ? 'flex' : 'none' }}>
        <FormGroup>
          <ul className="reset-checks">
            <li>
              <FormControlLabel
                control={
                  <CheckboxComponent
                    value={t('SELECT_ALL')}
                    checked={selectAllChecked ?? false}
                    isDisabled={
                      columnSorting &&
                      columnGroup &&
                      columnFiltering &&
                      resetColumns &&
                      !searchField
                    }
                    onClick={() => {
                      const checked = !selectAllChecked;
                      setSelectAllChecked && setSelectAllChecked(checked);
                      setResetListStates(resetListStates.map(() => checked));
                      if (checked) {
                        setToBeCleared(
                          resetList.filter((choice) => {
                            return (
                              (choice !== 'Sorting' || !columnSorting) &&
                              (choice !== 'Grouping' || !columnGroup) &&
                              (choice !== 'Filtering' || !columnFiltering) &&
                              (choice !== 'Searching' || searchField) &&
                              (choice !== 'Columns' || !resetColumns)
                            );
                          })
                        );
                      } else {
                        setToBeCleared([]);
                      }
                    }}
                  />
                }
                label={t('SELECT_ALL')}
              />
            </li>
            {resetList.map((choice, index) => (
              <li key={choice}>
                <FormControlLabel
                  control={
                    <CheckboxComponent
                      value={choice}
                      checked={resetListStates[index]}
                      onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const checked = !resetListStates[index];
                        const value = e.target.value;
                        if (checked && !toBeCleared.includes(value)) {
                          setToBeCleared([...toBeCleared, value]);
                        } else if (!checked && toBeCleared.includes(value)) {
                          setToBeCleared(toBeCleared.filter((item) => item !== value));
                        }
                        const newStates = [...resetListStates];
                        newStates[index] = checked;
                        setResetListStates(newStates);
                      }}
                      isDisabled={
                        choice === 'Sorting'
                          ? columnSorting
                          : choice === 'Grouping'
                            ? columnGroup
                            : choice === 'Filtering'
                              ? columnFiltering
                              : choice === 'Columns'
                                ? resetColumns
                                : !searchField
                      }
                    />
                  }
                  label={choice}
                />
              </li>
            ))}
          </ul>
        </FormGroup>
        <div className="reset-buttons">
          <DarkButton
            text={t('CONFIRM')}
            onClick={() => {
              clearFilters();
              setColumnSorting(true);
              setColumnGroup(true);
              setColumnFiltering(true);
              setTimeout(() => {
                setResetColumns(true);
              }, 50);
              setSelectAllChecked && setSelectAllChecked(false);
            }}
            isDisabled={!toBeCleared.length}
          />
          <DarkButton
            text={t('CANCEL')}
            onClick={() => {
              setResetListStates(resetListStates.map(() => false));
              setToBeCleared([]);
              setSelectAllChecked && setSelectAllChecked(false);
              setShowClearFilters(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Reset;
