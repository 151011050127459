export const EVENTS_CONSTANTS = {
  HEADER_EXPAND_STATUS: 'HEADER_EXPAND_STATUS',
  IS_DIRTY_EVT: 'is_dirty_evt',
  SET_CASE_HEADER_DATA_EVT: 'set_case_header_data_evt',
  NAVIGATE_EVT: 'navigate_event',
  DATE_FORMAT_CHANGE: 'DATE_FORMAT_CHANGE',
  NOTIFICATIONS_REFRESH_EVT: 'notifications_refresh',
  TASK_LOADING_EVT: 'Task_Loading',
  NARRATIVE_UPDATE: 'narrative_update',
  DOCUMENT_ADD_UPDATE: 'document_add_update',
  CASE_TAG_UPDATE: 'case_tags_update',
  TASK_VIEW_CHANGE: 'TASK_VIEW_CHANGE',
  ACTIVE_ROLE_UPDATED: 'ACTIVE_ROLE_UPDATED',
  TASK_LIST_PAYLOAD_UPDATED: 'TASK_LIST_PAYLOAD_UPDATED',
  HEADER_CASE_TAG_UPDATE: 'header_case_tags_update',
  ROUTING_CHANGE: 'routing_change',
  IR_RESULTS_LOADING_EVT: 'ir_search',
  CASE_ROLES_UPDATE: 'case_roles_update',
  AV_RESULTS_LOADING_EVT: 'av_search',
  TR_RESULTS_LOADING_EVT: 'tr_search',
  TR_QA_RESULTS_LOADING_EVT: 'tr_quickanalysis',
} as const;
