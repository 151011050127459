import ICaseRoles from './entities/case/ICaseRoles';

export const validateAssignRoles = (
  caseRoles: ICaseRoles[],
  assignedCaseRoles: ICaseRoles[]
): boolean => {
  return caseRoles?.every((role: ICaseRoles) => {
    const matchingRole = assignedCaseRoles.find((e) => e.name === role.name);

    if (!matchingRole) {
      return role.min === 0;
    }

    const usersArrayLength = matchingRole.users.length;
    return usersArrayLength >= role.min && usersArrayLength <= role.max;
  });
};
