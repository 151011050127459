import {
  Button,
  Tooltip,
  IconButton,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  AutocompleteRenderInputParams,
  Autocomplete,
  TextFieldProps,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSearchTerms } from '../../../service/config-manager.service';
import {
  IFilterOption,
  ILocation,
  ISearchTerms,
  IFilter,
  ICaseParty,
} from '../../../utils/entities/dueDiligence';
import AddIcon from '@mui/icons-material/Add';
import { ICity, ISearchSections } from '../../../utils/entities/screen/IDueDiligence';
import CancelIcon from '@mui/icons-material/Cancel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import DefaultModal from '../../generic/modal/Modal';
import TextFieldComponent from '../../generic/inputs/text-field/TextFieldComponent';
import dateUtils from '../../../utils/functions/dateUtils';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckboxComponent from '../../generic/inputs/checkbox/CheckboxComponent';
import IRefData from '../../../utils/entities/screen/IRefData';
import ErrorMessage from '../../generic/error-message/ErrorMessage';
import IReceivedItem from '../../../utils/entities/case/IReceivedItem';
import { CONSTANTS } from '../../../utils/constants/constants';

const FilterCriteriaPopup = (props: {
  filter: IFilter;
  handleClose: () => void;
  tenant: string;
  newSelectedParty: ICaseParty | undefined;
  setNewSelectedParty: React.Dispatch<React.SetStateAction<ICaseParty | undefined>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setCountries: React.Dispatch<React.SetStateAction<Array<IRefData> | undefined>>;
  countries: Array<IRefData> | undefined;
  setCities: React.Dispatch<React.SetStateAction<Array<ICity> | undefined>>;
  cities: Array<ICity> | undefined;
  selectedCity: ICity | undefined;
  setSelectedCity: React.Dispatch<React.SetStateAction<ICity | undefined>>;
  selectedCountry: IRefData | undefined;
  setSelectedCountry: React.Dispatch<React.SetStateAction<IRefData | undefined>>;
  dateFormat: string | undefined;
  searchSections: ISearchSections[];
  handleSearch: () => void;
  addressAttributes: Array<IReceivedItem>;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    filter,
    handleClose,
    tenant,
    newSelectedParty,
    setNewSelectedParty,
    setShowModal,
    showModal,
    setCountries,
    countries,
    setCities,
    cities,
    selectedCity,
    setSelectedCity,
    selectedCountry,
    setSelectedCountry,
    dateFormat,
    searchSections,
    handleSearch,
  } = props;
  const [selectedSearchTermsGroup, setSelectedSearchTermsGroup] = useState<string>('');
  const [filterOption, setFilterOption] = useState<IFilterOption>(props.filter.filterOption);
  const [searchTermsGroups, setSearchTermsGroups] = useState<Array<ISearchTerms>>([]);
  const [defaultCountries, setDefaultCountries] = useState<Array<IRefData>>();
  const [defaultCities, setDefaultCities] = useState<Array<ICity>>();
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    getSearchTerms(props.tenant).then((data) => {
      setSearchTermsGroups(data);
    });
    if (newSelectedParty) {
      if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN) {
        filterOption.partyNameNN = newSelectedParty.searchAttribute.value;
      } else if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
        filterOption.partyNameIR = newSelectedParty.searchAttribute.value;
      } else {
        filterOption.partyName = newSelectedParty.searchAttribute.value;
      }
    }
  }, [newSelectedParty]);

  useEffect(() => {
    searchSections.map((section) => {
      section.searchOptions.map((searchOption) => {
        setDefaultCountries(searchOption.countryListRefData);
        setDefaultCities(searchOption.cityListRefData);
      });
    });
  }, [showModal]);

  const onChangeSearchGroup = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedSearchGroup = searchTermsGroups.find(
      (st) => st.searchTermGroup === selectedValue
    );
    if (selectedSearchGroup) {
      filterOption.searchTermsNN = selectedSearchGroup.searchTerms;
    }
    setSelectedSearchTermsGroup(selectedValue);
    updateFilterOption();
  };

  const onChangeSearchTerms = (value: string) => {
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN) {
      filterOption.searchTermsNN = value;
    }
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
      filterOption.searchTermsIR = value;
    }
    updateFilterOption();
  };

  const updateFilterOption = () => {
    const filterOptionTemp = { ...filterOption };
    setFilterOption(filterOptionTemp);
  };

  const addAlias = () => {
    filterOption.alias?.push('');
    updateFilterOption();
  };

  const deleteAlias = (index: number) => {
    if (filterOption.alias) {
      delete filterOption.alias[index];
    }
    updateFilterOption();
  };

  const addAddress = () => {
    filterOption.addressValidation?.push({
      Street: '',
      City: '',
      State: '',
      County: '',
      ZipCode: '',
      Province: '',
      Country: '',
    });
    updateFilterOption();
  };

  const deleteAddress = (index: number) => {
    filterOption.addressValidation?.splice(index, 1);
    updateFilterOption();
  };

  const addLocation = () => {
    filterOption.location?.push({
      country: '',
      state: '',
      city: '',
    });
    updateFilterOption();
  };

  const deleteLocation = (index: number) => {
    if (filterOption.location) {
      delete filterOption.location[index];
    }
    updateFilterOption();
  };

  const save = () => {
    if (
      filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN &&
      filterOption.partyNameNN?.length != 0 &&
      filterOption.searchTermsNN?.length != 0 &&
      filterOption.searchTermsNN?.[0] !== ''
    ) {
      props.filter.filterOption = { ...filterOption };
      props.filter.active = true;
      handleClose();
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_TR) {
      props.filter.filterOption = { ...filterOption };
      props.filter.active = true;
      handleClose();
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
      props.filter.filterOption = { ...filterOption };
      props.filter.active = true;
      handleClose();
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_AV) {
      props.filter.filterOption = { ...filterOption };
      props.filter.active = true;
      handleClose();
    }
  };

  const clearFilter = () => {
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN) {
      filterOption.searchTermsNN = '';
      filterOption.partyNameNN = '';
      filterOption.articleDate = { after: '', before: '' };
      filterOption.location = [{ country: '', state: '', city: '' }];
      filterOption.alias = [''];
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_AV) {
      filterOption.addressValidation = [
        {
          Street: '',
          City: '',
          State: '',
          County: '',
          ZipCode: '',
          Province: '',
          Country: '',
        },
      ];
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_TR) {
      filterOption.searchType = '';
      filterOption.lastName = '';
      filterOption.firstName = '';
      filterOption.middleInitial = '';
      filterOption.emailAddress = '';
      filterOption.phoneNumber = '';
      filterOption.ssn = '';
      filterOption.personBirthDate = '';
      filterOption.personAgeFrom = '';
      filterOption.personAgeTo = '';
      filterOption.driverLicenseNumber = '';
      filterOption.secondaryLastName = '';
      filterOption.street = '';
      filterOption.streetNamesSoundSimilarOption = 'false';
      filterOption.city = '';
      filterOption.county = '';
      filterOption.state = '';
      filterOption.province = '';
      filterOption.zipCode = '';
      filterOption.country = '';
      filterOption.businessName = '';
      filterOption.corporationId = '';
      filterOption.filingNumber = '';
      filterOption.filingDate = '';
      filterOption.fein = '';
      filterOption.dunsNumber = '';
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
      filterOption.searchTermsIR = '';
      filterOption.partyNameIR = '';
    }

    props.filter.filterOption = { ...filterOption };
    updateFilterOption();
    setSelectedSearchTermsGroup('');
    setErrorMessage('');
  };

  const handleModalClose = (event: any) => {
    if (event?.target && !event.target.type) {
      handleClose();
    }
    if (event?.target.type == 'button') {
      clearFilter();
    }
  };

  const handleRunSearch = () => {
    if (!checkErrors()) {
      props.filter.filterOption = { ...filterOption }; // update filter option
      props.handleSearch();
      clearFilter();
      handleClose();
    }
  };

  useEffect(() => {
    updateFilterOption();
  }, [newSelectedParty]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  };

  const checkErrors = () => {
    let error = false;
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN) {
      if (filterOption.searchTermsNN?.length == 0) {
        setErrorMessage(t('DD.NN_EMPTY_SEARCH_TERMS_ERROR'));
        error = true;
        return error;
      }
      if (!filterOption.articleDate?.after || !filterOption.articleDate?.before) {
        setErrorMessage(t('DD.NN_EMPTY_ARTICLE_DATE_ERROR'));
        error = true;
        return error;
      }
      if (!filterOption.partyNameNN || filterOption.partyNameNN == '') {
        setErrorMessage(t('DD.NN_EMPTY_PARTY_NAME_ERROR'));
        error = true;
        return error;
      }
    }
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
      if (filterOption.searchTermsIR?.length == 0) {
        setErrorMessage(t('DD.IR_EMPTY_SEARCH_ERROR'));
        error = true;
      }
      return error;
    }
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_AV) {
      if (
        filterOption.addressValidation?.length == 1 &&
        filterOption.addressValidation[0].Street == ''
      ) {
        setErrorMessage(t('DD.AV_EMPTY_SEARCH_ERROR'));
        error = true;
      }

      if (filterOption.addressValidation && filterOption.addressValidation?.length > 1) {
        if (filterOption.addressValidation?.filter((address) => address.Street == '').length > 0) {
          setErrorMessage(t('DD.AV_EMPTY_ADDRESS_SEARCH_ERROR'));
          error = true;
        }
      }

      return error;
    }
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_TR) {
      if (filterOption.searchType == '') {
        setErrorMessage(t('DD.TR_EMPTY_SEARCH_ERROR'));
        error = true;
      }
      if (
        filterOption.searchType == 'Person' &&
        filterOption.lastName == '' &&
        filterOption.firstName == '' &&
        filterOption.ssn == ''
      ) {
        setErrorMessage(t('DD.TR_PERSON_EMPTY_SEARCH_ERROR'));
        error = true;
      }
      if (
        filterOption.searchType == 'Business' &&
        filterOption.businessName == '' &&
        filterOption.fein == ''
      ) {
        setErrorMessage(t('DD.TR_BUSINESS_EMPTY_SEARCH_ERROR'));
        error = true;
      }
      return error;
    }
  };

  const getFilterBody = () => {
    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_NN) {
      return (
        <>
          {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
          {/* Search Terms */}
          <Grid className="search-terms">
            <Grid className="search-terms-label">
              <span className="popup-label-font">{t('DD.SEARCH_TERMS')}</span>
            </Grid>
            <Grid className="search-terms-fields">
              <Grid className="search-dropdown-wrapper">
                {searchTermsGroups.length == 0 && (
                  <>
                    <FormControl>
                      <InputLabel id="search-terms-label">{t('DD.SEARCH_TERMS')}</InputLabel>
                      <Select
                        labelId="search-terms-label"
                        id="search-terms-dropdown"
                        value={selectedSearchTermsGroup}
                        onChange={onChangeSearchGroup}
                        label={t('DD.SEARCH_TERMS')}
                        variant="filled">
                        {searchTermsGroups?.map((st, index) => (
                          <MenuItem value={st.searchTermGroup} key={index}>
                            {st.searchTermGroup}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {searchTermsGroups.length != 0 && (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="search-terms-label">{t('DD.SEARCH_TERMS')}</InputLabel>
                      <Select
                        labelId="search-terms-label"
                        id="search-terms-dropdown"
                        value={selectedSearchTermsGroup}
                        onChange={onChangeSearchGroup}
                        label={t('DD.SEARCH_TERMS')}
                        variant="filled">
                        {searchTermsGroups?.map((st, index) => (
                          <MenuItem value={st.searchTermGroup} key={index}>
                            {st.searchTermGroup}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid className="search-terms-textfield-wrapper">
                <Tooltip title={filterOption.searchTermsNN} sx={{ boxShadow: 3 }}>
                  <Grid className="search-terms-textfield">
                    <TextFieldComponent
                      type="text"
                      placeholder={t('DD.SEARCH_TERMS')}
                      value={filterOption.searchTermsNN}
                      setData={onChangeSearchTerms}
                    />
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          {/* Party Name */}
          <Grid className="party-name">
            <Grid className="party-name-label">
              <span className="popup-label-font">{t('DD.PARTY_NAME')}</span>
            </Grid>
            <Grid className="party-name-wrapper">
              <TextFieldComponent
                type="text"
                placeholder={t('DD.PARTY_NAME_PLACEHOLDER')}
                value={filterOption.partyNameNN}
                setData={(value: string) => {
                  value !== newSelectedParty?.searchAttribute.value
                    ? setNewSelectedParty(undefined)
                    : '';
                  filterOption.partyNameNN = value;
                  updateFilterOption();
                }}
              />
              {filterOption.partyNameNN && (
                <IconButton
                  className="remove-party"
                  onClick={() => {
                    filterOption.partyNameNN = '';
                    setNewSelectedParty(undefined);
                    updateFilterOption();
                  }}>
                  <CancelIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>

          {/* Articles Date */}
          <Grid ref={ref} className="articles-date">
            <Grid className="articles-date-label">
              <span className="popup-label-font">{t('DD.ARTICLE_DATE')}</span>
            </Grid>
            <Grid className="articles-date-datepickers-container">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid className="articles-date-datepicker">
                  <DatePicker
                    label={t('DD.ARTICLE_AFTER')}
                    value={
                      filterOption.articleDate?.after ? dayjs(filterOption.articleDate.after) : null
                    }
                    format={dateFormat}
                    slotProps={{ textField: { variant: 'filled' } }}
                    onChange={(value: string | null | Dayjs) => {
                      const date = formatDate(
                        String(
                          dayjs(dateUtils.getDateFormatedDateForDateTimePicker(value as string))
                        )
                      );
                      value = date;
                      if (filterOption.articleDate) {
                        filterOption.articleDate.after = value;
                      }
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid className="articles-date-datepicker">
                  <DatePicker
                    label={t('DD.ARTICLE_BEFORE')}
                    value={
                      filterOption.articleDate?.before
                        ? dayjs(filterOption.articleDate.before)
                        : null
                    }
                    format={dateFormat}
                    slotProps={{ textField: { variant: 'filled' } }}
                    onChange={(value: string | null | Dayjs) => {
                      const date = formatDate(
                        String(
                          dayjs(dateUtils.getDateFormatedDateForDateTimePicker(value as string))
                        )
                      );
                      value = date;
                      if (filterOption.articleDate) {
                        filterOption.articleDate.before = value;
                      }
                      updateFilterOption();
                    }}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </Grid>

          {/* Location */}
          <Grid className="location">
            <Grid className="location-label">
              <span className="popup-label-font">{t('DD.LOCATIONS')}</span>
            </Grid>
            {filterOption.location?.map((location: ILocation, index: number) => {
              return (
                <Grid className="location-country-container" key={index}>
                  <Grid className="location-wrapper">
                    <Grid className="location-country">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="location-country-label">{t('COUNTRY')}</InputLabel>
                        <Select
                          labelId="location-country-label"
                          id="location-country-dropdown"
                          value={location.country}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const value = event.target.value;
                            location.country = value;
                            updateFilterOption();
                            const updatedCities: ICity[] = [];
                            defaultCities?.forEach((city) => {
                              if (city.parent === value) {
                                updatedCities.push(city);
                              }
                              setCities(updatedCities);
                            });
                          }}
                          label={t('DD.COUNTRY')}
                          variant="filled">
                          {countries?.map((country, index) => {
                            return (
                              <MenuItem value={country.label} key={index}>
                                {country.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid className="location-state">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="location-state-label">{t('DD.STATE')}</InputLabel>
                        <Select
                          labelId="location-state-label"
                          id="location-state-dropdown"
                          value={location.state}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const value = event.target.value;
                            location.state = value;
                            updateFilterOption();
                          }}
                          label={t('DD.STATE')}
                          variant="filled">
                          {cities?.map((city, index) => {
                            return (
                              <MenuItem value={city.label} key={index}>
                                {city.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid className="location-city">
                      <TextFieldComponent
                        type="text"
                        placeholder={t('DD.CITY')}
                        value={location.city}
                        setData={(value: string) => {
                          location.city = value;
                          updateFilterOption();
                        }}
                      />
                    </Grid>
                    <IconButton onClick={() => deleteLocation(index)}>
                      <DeleteOutlineIcon className="dark-icon-default" />
                    </IconButton>
                  </Grid>
                </Grid>
              );
            })}
            <Grid className="add-location">
              <Button className="link-cursor add-document" onClick={addLocation}>
                {t('DD.ADD_LOCATION')}
              </Button>
            </Grid>
          </Grid>

          {/* Alias */}
          {filterOption.alias?.map((alias: string, index: number) => {
            return (
              <Grid className="aliases" key={index}>
                <Grid className="aliases-label">
                  {!index ? <span className="popup-label-font">{t('DD.ALIASES')}</span> : null}
                </Grid>
                <Grid className="aliases-wrapper">
                  <Grid className="aliases-container">
                    <TextFieldComponent
                      type="text"
                      placeholder={t('DD.ALIASES')}
                      value={alias}
                      setData={(value: string) => {
                        filterOption.alias ??= [];
                        filterOption.alias[index] = value;
                        updateFilterOption();
                      }}
                    />
                  </Grid>
                  <Grid className="aliases-icons">
                    {index == 0 && (
                      <span className="plus-icon-font" onClick={addAlias}>
                        <AddIcon />
                      </span>
                    )}
                    {index != 0 && (
                      <IconButton onClick={() => deleteAlias(index)} className="plus-icon-font">
                        <DeleteOutlineIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </>
      );
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_IR) {
      return (
        <>
          {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
          {/* Party Name */}
          <Grid className="party-name align-items-center">
            <Grid className="party-name-label">
              <span className="popup-label-font">{t('DD.PARTY_NAME')}</span>
            </Grid>
            <Grid className="party-name-wrapper">
              <TextFieldComponent
                type="text"
                placeholder={t('DD.PARTY_NAME_PLACEHOLDER')}
                value={filterOption.partyNameIR}
                setData={(value: string) => {
                  value !== newSelectedParty?.searchAttribute.value
                    ? setNewSelectedParty(undefined)
                    : '';
                  filterOption.partyNameIR = value;
                  updateFilterOption();
                }}
              />
              {filterOption.partyNameIR && (
                <IconButton
                  className="remove-party"
                  onClick={() => {
                    filterOption.partyNameIR = '';
                    setNewSelectedParty(undefined);
                    updateFilterOption();
                  }}>
                  <CancelIcon />
                </IconButton>
              )}
            </Grid>
            <Grid>
              <div className="padding-left-right-10">AND</div>
            </Grid>
          </Grid>

          {/* Search Terms */}
          <Grid className="search-terms align-items-center">
            <Grid className="search-terms-label">
              <span className="popup-label-font">{t('DD.SEARCH_TERMS')}</span>
            </Grid>
            <Grid className="search-terms-fields">
              <Grid className="search-terms-textfield">
                <TextFieldComponent
                  type={'text'}
                  label={''}
                  placeholder={t('DD.SEARCH_TERMS')}
                  value={filterOption.searchTermsIR}
                  setData={(value: string) => {
                    filterOption.searchTermsIR = value;
                    updateFilterOption();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      );
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_AV) {
      return (
        <>
          {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
          {/* Address */}
          <Grid className="address">
            {filterOption.addressValidation?.map((address, index) => {
              return (
                <Grid className="address-list-container" key={index}>
                  <Grid className="address-wrapper">
                    <Grid className="address-label">
                      <span className="popup-label-font">
                        {t('DD.ADDRESS') + ' ' + (index + 1)}
                      </span>
                    </Grid>
                    <Grid className="address-list address-dropdown-wrapper">
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="address-label"></InputLabel>
                        <Autocomplete
                          value={address.Street}
                          onChange={(event: any, newValue: any) => {
                            if (newValue && newValue.value) {
                              address.Street = newValue.value;
                              updateFilterOption();
                            }
                          }}
                          onInputChange={(event, newInputValue) => {
                            address.Street = newInputValue;
                            updateFilterOption();
                          }}
                          id="address-free-solo-with-text"
                          options={props.addressAttributes.map((option: IReceivedItem) => {
                            return {
                              value:
                                option?.attributes?.Address_Street_Address?.value +
                                ' ' +
                                option?.attributes?.Address_City?.value +
                                ' ' +
                                option?.attributes?.Address_State?.value +
                                ' ' +
                                option?.attributes?.Address_Country?.value +
                                ' ' +
                                option?.attributes?.Address_Zipcode?.value,
                              title:
                                option?.attributes?.Address_Street_Address?.value +
                                ' ' +
                                option?.attributes?.Address_City?.value +
                                ' ' +
                                option?.attributes?.Address_State?.value +
                                ' ' +
                                option?.attributes?.Address_Country?.value +
                                ' ' +
                                option?.attributes?.Address_Zipcode?.value,
                            };
                          })}
                          getOptionLabel={(option: any) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            // Regular option
                            return option.title;
                          }}
                          renderOption={(props, option) => <li {...props}>{option.title}</li>}
                          freeSolo
                          renderInput={(params: AutocompleteRenderInputParams) => (
                            <TextField {...(params as TextFieldProps)} variant="standard" />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid className="delete-address">
                      <FormControl sx={{ m: 1, minWidth: 30 }}>
                        {index != 0 && (
                          <IconButton onClick={() => deleteAddress(index)}>
                            <DeleteOutlineIcon className="dark-icon-default" />
                          </IconButton>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid className="add-address">
              <Button className="link-cursor add-document" onClick={addAddress}>
                {t('DD.ADD_ADDRESS')}
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }

    if (filter.apiName == CONSTANTS.DD.DUE_DILIGENCE_TR) {
      return (
        <>
          {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
          {/* Search Type */}
          <Grid className="search-type">
            <Grid className="search-type-label">
              <span className="popup-label-font">{'Type of Search'}</span>
            </Grid>
            <Grid className="search-type-fields">
              <Grid className="search-dropdown-wrapper">
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="search-type"></InputLabel>
                  <Select
                    labelId="search-type"
                    id="search-type-dropdown"
                    variant="filled"
                    value={filterOption.searchType}
                    onChange={(event: SelectChangeEvent<string>) => {
                      const value = event.target.value;
                      if (filterOption.searchType !== value && filterOption.searchType !== '') {
                        filterOption.lastName = '';
                        filterOption.firstName = '';
                        filterOption.middleInitial = '';
                        filterOption.emailAddress = '';
                        filterOption.phoneNumber = '';
                        filterOption.ssn = '';
                        filterOption.personBirthDate = '';
                        filterOption.personAgeFrom = '';
                        filterOption.personAgeTo = '';
                        filterOption.driverLicenseNumber = '';
                        filterOption.secondaryLastName = '';
                        filterOption.street = '';
                        filterOption.streetNamesSoundSimilarOption = 'false';
                        filterOption.city = '';
                        filterOption.county = '';
                        filterOption.state = '';
                        filterOption.province = '';
                        filterOption.zipCode = '';
                        filterOption.country = '';
                        filterOption.businessName = '';
                        filterOption.corporationId = '';
                        filterOption.filingNumber = '';
                        filterOption.filingDate = '';
                        filterOption.fein = '';
                        filterOption.dunsNumber = '';
                      }
                      filterOption.searchType = value;
                      updateFilterOption();
                      setErrorMessage('');
                    }}>
                    <MenuItem value={'Person'} key={'person'}>
                      Person
                    </MenuItem>
                    <MenuItem value={'Business'} key={'business'}>
                      Business
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* Person Search */}
          {filterOption.searchType == 'Person' && (
            <>
              <Grid container rowSpacing={0.5} columnSpacing={3}>
                <Grid item className="tr-clear last-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Last name'}
                    value={filterOption.lastName}
                    setData={(value: string) => {
                      filterOption.lastName = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="secondary-last-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Secondary Last Name'}
                    value={filterOption.secondaryLastName}
                    setData={(value: string) => {
                      filterOption.secondaryLastName = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="first-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'First name'}
                    value={filterOption.firstName}
                    setData={(value: string) => {
                      filterOption.firstName = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="person-address" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Address'}
                    value={filterOption.street}
                    setData={(value: string) => {
                      filterOption.street = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="middle-initial" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Middle Initial'}
                    value={filterOption.middleInitial}
                    setData={(value: string) => {
                      filterOption.middleInitial = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="person-similar-address" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <FormControlLabel
                    className="person-similar-address-label"
                    control={
                      <CheckboxComponent
                        checked={filterOption.streetNamesSoundSimilarOption == 'true'}
                        onClick={(e) => {
                          filterOption.streetNamesSoundSimilarOption = String(e.target.checked);
                          updateFilterOption();
                        }}
                      />
                    }
                    label={'Include street names that sound similar'}
                  />
                </Grid>
                <Grid item className="email-address" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Email Address'}
                    value={filterOption.emailAddress}
                    setData={(value: string) => {
                      filterOption.emailAddress = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                  <Grid container className="display-flex flex-justify-space-between" spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="person-city  padding-left-0">
                        <TextFieldComponent
                          type={'text'}
                          label={'City'}
                          value={filterOption.city}
                          setData={(value: string) => {
                            filterOption.city = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="person-city">
                        <TextFieldComponent
                          type={'text'}
                          label={'County'}
                          value={filterOption.county}
                          setData={(value: string) => {
                            filterOption.county = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="phone-number" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Phone'}
                    value={filterOption.phoneNumber}
                    setData={(value: string) => {
                      filterOption.phoneNumber = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="person-state" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'State/Territory'}
                    value={filterOption.state}
                    setData={(value: string) => {
                      filterOption.state = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="ssn-itin" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'SSN/ITIN'}
                    value={filterOption.ssn}
                    setData={(value: string) => {
                      filterOption.ssn = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="person-province" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Canadian Province/Territory'}
                    value={filterOption.province}
                    setData={(value: string) => {
                      filterOption.province = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="dob" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <div className="display-flex flex-justify-space-between">
                    <span>{t('DD.DATE_OF_BIRTH')}</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={
                        filterOption.personBirthDate ? dayjs(filterOption.personBirthDate) : null
                      }
                      format={dateFormat}
                      slotProps={{ textField: { variant: 'filled' } }}
                      onChange={(value: string | null | Dayjs) => {
                        const date = formatDate(
                          String(
                            dayjs(dateUtils.getDateFormatedDateForDateTimePicker(value as string))
                          )
                        );
                        value = date;
                        filterOption.personBirthDate = value;
                        updateFilterOption();
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item className="person-zipcode" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Zip Code'}
                    value={filterOption.zipCode}
                    setData={(value: string) => {
                      filterOption.zipCode = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                  <div className="display-flex flex-justify-space-between">
                    <span>Age Range</span>
                    <span>(1-2 digits)</span>
                  </div>
                  <Grid container className="display-flex flex-justify-space-between" spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="age-range-from padding-left-0">
                        <TextFieldComponent
                          type={'number'}
                          value={filterOption.personAgeFrom}
                          setData={(value: string) => {
                            filterOption.personAgeFrom = value;
                            updateFilterOption();
                          }}
                          min={'0'}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="age-range-to">
                        <TextFieldComponent
                          type={'number'}
                          value={filterOption.personAgeTo}
                          setData={(value: string) => {
                            filterOption.personAgeTo = value;
                            updateFilterOption();
                          }}
                          min={'0'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="person-country" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Country'}
                    value={filterOption.country}
                    setData={(value: string) => {
                      filterOption.country = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
                  <Grid container rowSpacing={0.5} columnSpacing={3}>
                    <Grid
                      className="drivers-license-number"
                      item
                      xs={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sm={6}>
                      <TextFieldComponent
                        type={'text'}
                        label={'Drivers License Number'}
                        value={filterOption.driverLicenseNumber}
                        setData={(value: string) => {
                          filterOption.driverLicenseNumber = value;
                          updateFilterOption();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* Business Search */}
          {filterOption.searchType == 'Business' && (
            <>
              <Grid container rowSpacing={0.5} columnSpacing={3}>
                <Grid item className="business-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Business Name'}
                    value={filterOption.businessName}
                    setData={(value: string) => {
                      filterOption.businessName = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="business-address" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Address'}
                    value={filterOption.street}
                    setData={(value: string) => {
                      filterOption.street = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="corporation-id" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Corporation ID'}
                    value={filterOption.corporationId}
                    setData={(value: string) => {
                      filterOption.corporationId = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="business-similar-address" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <FormControlLabel
                    className="person-similar-address-label"
                    control={
                      <CheckboxComponent
                        checked={filterOption.streetNamesSoundSimilarOption == 'true'}
                        onClick={(e) => {
                          filterOption.streetNamesSoundSimilarOption = String(e.target.checked);
                          updateFilterOption();
                        }}
                      />
                    }
                    label={'Include street names that sound similar'}
                  />
                </Grid>
                <Grid item className="filing-number" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Filing Number'}
                    value={filterOption.filingNumber}
                    setData={(value: string) => {
                      filterOption.filingNumber = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                  <Grid container className="display-flex flex-justify-space-between" spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="business-city full-width  padding-left-0">
                        <TextFieldComponent
                          type={'text'}
                          label={'City'}
                          value={filterOption.city}
                          setData={(value: string) => {
                            filterOption.city = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="business-city full-width">
                        <TextFieldComponent
                          type={'text'}
                          label={'County'}
                          value={filterOption.county}
                          setData={(value: string) => {
                            filterOption.county = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="filing-date" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <div className="display-flex flex-justify-space-between">
                    <span>Filing Date</span>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={filterOption.filingDate ? dayjs(filterOption.filingDate) : null}
                      format={dateFormat}
                      slotProps={{ textField: { variant: 'filled' } }}
                      onChange={(value: string | null | Dayjs) => {
                        const date = formatDate(
                          String(
                            dayjs(dateUtils.getDateFormatedDateForDateTimePicker(value as string))
                          )
                        );
                        value = date;
                        filterOption.filingDate = value;
                        updateFilterOption();
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item className="bunsiness-state" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'State/Territory'}
                    value={filterOption.state}
                    setData={(value: string) => {
                      filterOption.state = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="fein-tax-id" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'FEIN / Tax ID'}
                    value={filterOption.fein}
                    setData={(value: string) => {
                      filterOption.fein = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="business-province" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Canadian Province/Territory'}
                    value={filterOption.province}
                    setData={(value: string) => {
                      filterOption.province = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="duns" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'DUNS'}
                    value={filterOption.dunsNumber}
                    setData={(value: string) => {
                      filterOption.dunsNumber = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6} xl={6} sm={6}>
                  <Grid container className="display-flex flex-justify-space-between" spacing={0.5}>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="person-zipcode full-width  padding-left-0">
                        <TextFieldComponent
                          type={'text'}
                          label={'Zip Code'}
                          value={filterOption.zipCode}
                          setData={(value: string) => {
                            filterOption.zipCode = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} sm={6} className="tr-clear margin-0">
                      <Grid item className="person-zipcode full-width">
                        <TextFieldComponent
                          type={'text'}
                          label={'Phone'}
                          value={filterOption.phoneNumber}
                          setData={(value: string) => {
                            filterOption.phoneNumber = value;
                            updateFilterOption();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className="last-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Last Name'}
                    value={filterOption.lastName}
                    setData={(value: string) => {
                      filterOption.lastName = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item className="business-country" xs={6} md={6} lg={6} xl={6} sm={6}>
                  <TextFieldComponent
                    type={'text'}
                    label={'Country'}
                    value={filterOption.country}
                    setData={(value: string) => {
                      filterOption.country = value;
                      updateFilterOption();
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
                  <Grid container rowSpacing={0.5} columnSpacing={3}>
                    <Grid item className="secondary-last-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                      <TextFieldComponent
                        type={'text'}
                        label={'Secondary Last Name'}
                        value={filterOption.secondaryLastName}
                        setData={(value: string) => {
                          filterOption.secondaryLastName = value;
                          updateFilterOption();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
                  <Grid container rowSpacing={0.5} columnSpacing={3}>
                    <Grid item className="first-name" xs={6} md={6} lg={6} xl={6} sm={6}>
                      <TextFieldComponent
                        type={'text'}
                        label={'First Name'}
                        value={filterOption.firstName}
                        setData={(value: string) => {
                          filterOption.firstName = value;
                          updateFilterOption();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} sm={12}>
                  <Grid container rowSpacing={0.5} columnSpacing={3}>
                    <Grid item className="middle-initial" xs={6} md={6} lg={6} xl={6} sm={6}>
                      <TextFieldComponent
                        type={'text'}
                        label={'Middle Initial'}
                        value={filterOption.middleInitial}
                        setData={(value: string) => {
                          filterOption.middleInitial = value;
                          updateFilterOption();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </>
      );
    }
  };

  return (
    <DefaultModal
      open={showModal}
      className={'filter-modal search-criteria-filters'}
      title={t('DD.FILTERS')}
      body={getFilterBody()}
      confirmText={t('DD.RUN_SEARCH')}
      setConfirm={handleRunSearch}
      denyText={t('DD.CLEAR_FILTERS')}
      onClose={handleModalClose}
      cancelText={t('SAVE')}
      setCancel={save}
    />
  );
};
export default FilterCriteriaPopup;
