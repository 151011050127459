import React, { useRef, useEffect, useState } from 'react';

interface LazyImageProps {
  src: string;
  alt: string;
  key: string;
  className?: string;
  onLoad: () => void;
  shouldLoad: boolean;
}
const LazyLoadingImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  key,
  className,
  onLoad,
  shouldLoad,
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!imgRef.current || !shouldLoad) {
      return;
    }
    const options = {
      threshold: 0.1,
      rootMargin: '0px 0px 20px 0px',
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target as HTMLImageElement;
          img.src = src;
          observer.unobserve(img);
          setLoaded(true);
          onLoad();
        }
      });
    }, options);

    observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, [src, shouldLoad]);
  return (
    <img
      ref={imgRef}
      src={loaded ? src : undefined}
      alt={loaded ? alt : ''}
      key={key}
      className={className}
      loading="lazy"
    />
  );
};

export default LazyLoadingImage;
