import React from 'react';
import { Stack } from '@mui/material';
import { CONSTANTS } from '../../utils/constants/constants';
import './DrawerLayout.scss';
import DrawerList from './drawer-list/DrawerList';
import IDrawerLayout from '../../utils/entities/layouts/IDrawerLayout';
import Drawer from './drawer/Drawer';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';

const DrawerLayout = ({ open, setOpenDrawer }: IDrawerLayout) => {
  const homePageIcons = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.HOME_PAGE_ICONS
  );

  return (
    <Drawer variant="permanent" open={open} className="drawer-layout">
      <Stack direction="column" justifyContent="space-between" height={'100%'}>
        {/* Top List */}
        <DrawerList
          open={open}
          setOpenDrawer={setOpenDrawer}
          items={[...CONSTANTS.VERTICAL_BAR_ITEMS.TOP, ...homePageIcons]}
          classes="top-list"
        />

        {/* Bottom List */}
        <DrawerList
          open={open}
          setOpenDrawer={setOpenDrawer}
          items={CONSTANTS.VERTICAL_BAR_ITEMS.BOTTOM}
          classes="bottom-list"
        />
      </Stack>
    </Drawer>
  );
};

export default DrawerLayout;
