import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import LoopIcon from '@mui/icons-material/Loop';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-tabs-scrollable';
import { useIScreen } from '../../../pages/task/Task';
import { getRequestsById, updateSearchRequest } from '../../../service/due-diligence.service';
import dateUtils from '../../../utils/functions/dateUtils';
import {
  IArticle,
  IArticleData,
  IRequest,
  IRequestStatus,
  IResponse,
  ISearchResult,
} from '../../../utils/entities/dueDiligence';
import ITab from '../../../utils/entities/screen/ITab';
import EpochToDateText from '../../generic/timer/EpochToDateText';
import Loader from '../../loader/Loader';
import '../dynamic-questionaire/ModuleTabs.scss';
import AVSearchResults from './AVSearchResults';
import ArticleViewer from './ArticleViewer';
import IRSearchResults from './IRSearchResults';
import PDFHeaderToggle from './PDFHeaderToggle';
import PDFViewer from './PDFViewer';
import TRClearSearchResults from './TRClearSearchResults';
import { ISearchSections } from '../../../utils/entities/screen/IDueDiligence';

const SearchResult = (props: {
  tenant: string;
  searchResults: Array<ISearchResult>;
  searchRequests: Array<IRequest>;
  searchSections: ISearchSections[];
  disabled: boolean;
  selectedTab: number;
  searchSectionTabs: ITab[];
  setDetailedRequests: React.Dispatch<React.SetStateAction<Array<IRequest>>>;
  dateFormat: string | undefined;
  maxFileSize?: number;
}) => {
  const {
    data,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    saveTaskAsDraftClick,
    setSaveTaskAsDraftClick,
    submitClick,
    setSubmitClick,
    activeTabId,
  } = useIScreen();

  const {
    searchResults,
    searchRequests,
    searchSections,
    disabled,
    selectedTab,
    searchSectionTabs,
    setDetailedRequests,
    dateFormat,
  } = props;

  const [articles, setArticles] = useState<Array<IArticle>>();
  const [totalOverallScore, setTotalOverallScore] = useState<string | number>(0);
  const [linksReturned, setLinksReturned] = useState<string | number>(0);
  const [linksAfterFilter, setLinksAfterFilter] = useState<string | number>(0);
  const [currentTab, setCurrentTab] = useState<string>(activeTabId);
  const [tabOptions, setTabOptions] = useState<ITab[]>(searchSectionTabs);
  const [selectedSearchCriteria, setSelectedSearchCriteria] = useState<number>(selectedTab);
  const [selectedArticle, setSelectedArticle] = useState<IArticle>();
  const [selectedRequest, setSelectedRequest] = useState<string | number>();
  const [selectedRequestStatus, setSelectedRequestStatus] = useState<IRequestStatus>();
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [viewPDF, setViewPDF] = useState<boolean>(false);
  const [requestsDetailed, setRequestsDetailed] = useState<Array<IRequest>>([]);
  const [selectedRequestDetail, setSelectedRequestDetail] = useState<IRequest | undefined>();
  const [optionChosen, setOptionChosen] = useState<boolean | null>(
    searchResults[selectedSearchCriteria]
      ? searchResults[selectedSearchCriteria].entityAssociatedWithNN
      : null
  );

  useEffect(() => {
    ChangeActive(selectedTab);
  }, [selectedTab]);

  const onTabClickHandler = (e: any, index: number) => {
    const currentActiveIndex: number = tabOptions.findIndex((el) => el.isActive);
    if (index != currentActiveIndex) {
      ChangeActive(index);
    }
    setSelectedRequest('');
  };

  const ChangeActive = (index: number) => {
    const selectedTab = [...searchSectionTabs];
    selectedTab.forEach((option) => {
      option.isActive = false;
    });
    selectedTab[index].isActive = true;
    setTabOptions(selectedTab);
    setSelectedSearchCriteria(parseInt(selectedTab[index].tabId));
  };

  const getRequests = () => {
    searchRequests.map((request: IRequest) => {
      if (request.apiName === 'Negative News' || request.apiName === 'CI via Bing') {
        getRequestDetail(request);
      }
    });
  };

  const getRequestDetail = (request?: IRequest, id?: number | string) => {
    getRequestsById(request ? request.id! : id!).then((requestDetail: IRequest) => {
      setDetailedRequests((prevRequestsDetailed) => {
        const existingItem = prevRequestsDetailed?.find((item) => item.id === requestDetail.id);
        if (existingItem) {
          // Item with the same ID already exists, no need to add it again
          return prevRequestsDetailed;
        } else {
          // Item with the same ID doesn't exist, add the new item
          return [...(prevRequestsDetailed as Array<IRequest>), requestDetail];
        }
      });
      setRequestsDetailed((prevRequestsDetailed) => {
        const existingItem = prevRequestsDetailed.find((item) => item.id === requestDetail.id);
        if (existingItem) {
          // Item with the same ID already exists, no need to add it again
          return prevRequestsDetailed;
        } else {
          // Item with the same ID doesn't exist, add the new item
          return [...prevRequestsDetailed, requestDetail];
        }
      });
      if (id) {
        setSelectedRequestDetail(requestDetail);
        setSelectedRequestStatus({
          status: requestDetail.status,
          error: requestDetail.error,
        });
        getSearchResults(requestDetail);
      }
      setRefreshing(false);
    });
  };

  const RequestTooltipComponent = (request: IRequest) => {
    const locations = request.location?.map((location) => {
      return `${location.country} ${location.state} ${location.city}`;
    });
    return (
      <Tooltip
        title={
          <>
            <p style={{ fontSize: '12px' }}>Search Terms: {request?.searchTerms}</p>
            <p style={{ fontSize: '12px' }}>Party Name: {request?.partyName}</p>
            <p style={{ fontSize: '12px' }}>
              Article Date: {dateUtils.dateFormating(request?.maxDate ?? '', dateFormat)} -{' '}
              {dateUtils.dateFormating(request?.minDate ?? '', dateFormat)}
            </p>
            <p style={{ fontSize: '12px' }}>Location(s): {locations}</p>
            <p style={{ fontSize: '12px' }}>Alias(es): {request?.aliases}</p>
          </>
        }>
        <InfoIcon />
      </Tooltip>
    );
  };

  const getSearchResults = (articlesRes: IRequest) => {
    const articleResponse = articlesRes;
    if (articleResponse.response) {
      articleResponse.response = JSON.parse(articlesRes.response as string);
      (articleResponse.response?.[0] as IResponse).value = JSON.parse(
        (articleResponse.response?.[0] as IResponse).value as string
      );

      const response = articleResponse.response as IResponse[];
      const resData: IArticleData = response.find((r: IResponse) => r.name == 'articleData')
        ?.value as IArticleData;
      setTotalOverallScore(
        response.find((r: IResponse) => r.name == 'overallNegnewsScore')?.value as string
      );
      setLinksReturned(response.find((r: IResponse) => r.name == 'linksReturned')?.value as string);
      setLinksAfterFilter(
        response.find((r: IResponse) => r.name == 'linksRemainingAfterFilter')?.value as string
      );

      const updatedRes = resData.articleDetailsList.map((ele: IArticle, index: number) => {
        return {
          date: resData.articleDateList[index],
          articleScore: ele.articleScore,
          articleSentiment: ele.articleSentiment,
          title: ele.title,
          keyFindings: ele.keyFindings,
          summary: ele.summary,
          topRiskTermList: ele.topRiskTermList,
          url: ele.url,
          otherMatchTerms: ele.otherMatchTerms,
        };
      });

      setArticles(updatedRes);
    } else {
      setTotalOverallScore(0);
      setLinksReturned(0);
      setLinksAfterFilter(0);
      setArticles([]);
    }
  };

  useEffect(() => {
    selectedRequest && getRequestDetail(undefined, selectedRequest);
    setSelectedArticle(undefined);
  }, [selectedRequest]);

  const refresh = () => {
    setRefreshing(true);
    setSelectedArticle(undefined);
    selectedRequest && getRequestDetail(undefined, selectedRequest);
  };

  const SearchRequests = () => {
    return (
      <div className="search-requests">
        <Button
          className={refreshing ? 'refresh-button rotate' : 'refresh-button'}
          onClick={refresh}
          disabled={!selectedRequest}>
          <LoopIcon />
        </Button>
        <FormControl variant="standard">
          <InputLabel id="search-request-dropdown-label">{t('SELECT_A_REQUEST')}</InputLabel>
          <Select
            labelId="search-request-dropdown-label"
            id="search-request-dropdown-standard"
            className="search-request-dropdown"
            value={selectedRequest}
            disabled={disabled}
            onChange={(e) => setSelectedRequest(e.target.value)}
            label={t('SELECT_A_REQUEST')}>
            <MenuItem value="">
              <em>{t('NONE')}</em>
            </MenuItem>
            {requestsDetailed &&
              requestsDetailed.map((request: IRequest, index: number) =>
                request.applicationName === searchResults[selectedSearchCriteria].criteria ? (
                  <MenuItem value={request.id} key={index} className="search-request-option">
                    {RequestTooltipComponent(request)}
                    <span>
                      {request.partyName + ' - '}
                      <EpochToDateText epoch={request.requestDate! / 1000} />
                    </span>
                  </MenuItem>
                ) : null
              )}
            {requestsDetailed &&
              requestsDetailed.map((request: IRequest, index: number) => (
                <MenuItem value={request.id} key={index} className="search-request-option">
                  {RequestTooltipComponent(request)}
                  <span>
                    {request.partyName + ' - '}
                    <EpochToDateText epoch={request.requestDate! / 1000} />
                  </span>
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {selectedRequest && <RequestStatus selectedRequest={selectedRequest} />}
      </div>
    );
  };

  const RequestStatus = ({ selectedRequest }: { selectedRequest: string | number }) => {
    let errorMessage;
    if (selectedRequestStatus?.status?.toLowerCase() === 'Error'.toLowerCase()) {
      const startIdx = selectedRequestStatus?.error?.indexOf('{');
      const endIdx = selectedRequestStatus?.error?.lastIndexOf('}');
      const jsonString = selectedRequestStatus?.error?.slice(startIdx, endIdx ? endIdx + 1 : 0);
      errorMessage = jsonString ? JSON.parse(jsonString) : '';
    }
    return (
      <div className="request-status-wrapper">
        {selectedRequest && (
          <div
            className={
              selectedRequestStatus?.status?.toLowerCase() === 'Complete'.toLowerCase()
                ? 'request-success request-status'
                : selectedRequestStatus?.status?.toLowerCase() === 'In Progress'.toLowerCase()
                  ? 'request-info request-status'
                  : selectedRequestStatus?.status?.toLowerCase() === 'No Hits'.toLowerCase()
                    ? 'request-warning request-status'
                    : selectedRequestStatus?.status?.toLowerCase() === 'Error'.toLowerCase()
                      ? 'request-error request-status'
                      : ''
            }>
            <>
              <div className="statusIcon">
                {selectedRequestStatus?.status?.toLowerCase() === 'Complete'.toLowerCase() ? (
                  <CheckCircleIcon />
                ) : selectedRequestStatus?.status?.toLowerCase() === 'In Progress'.toLowerCase() ? (
                  <InfoIcon />
                ) : selectedRequestStatus?.status?.toLowerCase() === 'No Hits'.toLowerCase() ? (
                  <WarningIcon />
                ) : selectedRequestStatus?.status?.toLowerCase() === 'Error'.toLowerCase() ? (
                  <InfoIcon />
                ) : (
                  ''
                )}
              </div>
              <div className="statusInfo">
                <p>{selectedRequestStatus?.status}</p>
                {selectedRequestStatus?.status?.toLowerCase() === 'Error'.toLowerCase() ? (
                  <Tooltip
                    title={errorMessage ? errorMessage.message : selectedRequestStatus?.error}>
                    <span>
                      {errorMessage ? errorMessage.message : selectedRequestStatus?.error}
                    </span>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>
            </>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getRequests();
  }, [searchRequests]);

  const updateRequest = (requestId: string | number) => {
    selectedRequest &&
      getRequestsById(selectedRequest).then((articlesRes: IRequest) => {
        const requestBody = {
          caseId: articlesRes.caseId,
          apiName: articlesRes.apiName,
          applicationName: articlesRes.applicationName,
          partyName: articlesRes.partyName,
          searchTerms: articlesRes.searchTerms,
          minDate: articlesRes.minDate,
          maxDate: articlesRes.maxDate,
          location: articlesRes.location,
          aliases: articlesRes.aliases,
          userName: articlesRes.userName,
          requestDate: articlesRes.requestDate,
          responseDate: articlesRes.responseDate,
          status: articlesRes.status,
          error: articlesRes.error,
          response: articlesRes.response,
          entityAssociatedWithNN: searchResults[selectedSearchCriteria].entityAssociatedWithNN,
          tenant: articlesRes.tenant,
          projectName: articlesRes.projectName,
        };
        updateSearchRequest(requestId, requestBody);
      });
  };

  useEffect(() => {
    //Update when clicking Save and Exit
    if (saveTaskAsDraftAndExitClick) {
      updateRequest(selectedRequest!);
      setSaveTaskAsDraftAndExitClick(false);
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    //Update when clicking Save
    if (saveTaskAsDraftClick) {
      updateRequest(selectedRequest!);
      setSaveTaskAsDraftClick(false);
    }
  }, [saveTaskAsDraftClick]);

  useEffect(() => {
    //Update when clicking submit
    if (submitClick) {
      updateRequest(selectedRequest!);
      setSubmitClick(false);
    }
  }, [submitClick]);

  useEffect(() => {
    //Update draft when changing tabs
    if (
      activeTabId !== currentTab &&
      optionChosen !== selectedRequestDetail?.entityAssociatedWithNN
    ) {
      updateRequest(selectedRequest!);
    }
  }, [activeTabId]);

  const getSearchResultsContent = () => {
    const activeTabIndex = tabOptions.findIndex((el) => el.isActive);
    switch (tabOptions[activeTabIndex].apiName) {
      case 'Negative News':
        return (
          <>
            {refreshing && <Loader isMainLoader={true} />}
            <div className="newsTopBar">
              {searchResults[selectedSearchCriteria].criteria == 'Negative News' && (
                <SearchRequests />
              )}
              {searchResults[selectedSearchCriteria].criteria === 'Negative News' && (
                <PDFHeaderToggle viewPDF={viewPDF} setViewPDF={setViewPDF} />
              )}
            </div>
            {viewPDF || searchResults[selectedSearchCriteria].criteria === 'LexisNexis' ? (
              <PDFViewer requestId={selectedRequest} requestStatus={selectedRequestStatus} />
            ) : (
              <ArticleViewer
                articles={articles}
                selectedArticle={selectedArticle}
                setSelectedArticle={setSelectedArticle}
                totalOverallScore={totalOverallScore}
                linksReturned={linksReturned}
                linksAfterFilter={linksAfterFilter}
                selectedRequestDetail={selectedRequestDetail}
              />
            )}
          </>
        );
      case 'IR':
        return (
          <IRSearchResults
            maxFileSize={props.maxFileSize}
            caseId={data.caseId}
            tenant={props.tenant}
            searchResults={
              searchRequests && searchRequests.length > 0 && searchRequests[0].apiName == 'IR'
                ? searchRequests
                : null
            }
          />
        );
      case 'AV':
        return (
          <AVSearchResults
            caseId={data.caseId}
            tenant={props.tenant}
            searchResults={
              searchRequests && searchRequests.length > 0 && searchRequests[0].apiName == 'AV'
                ? searchRequests
                : null
            }
          />
        );
      case 'TR Clear':
        return (
          <TRClearSearchResults
            caseId={data.caseId}
            tenant={props.tenant}
            searchResults={
              searchRequests && searchRequests.length > 0 && searchRequests[0].apiName == 'TR Clear'
                ? searchRequests
                : null
            }
          />
        );
    }
  };

  return (
    <>
      <div className="width-100-per flex-container margin-bottom-10">
        <div className="search-tabs">
          <div>
            <Tabs
              activeTab={tabOptions.findIndex((el) => el.isActive)}
              onTabClick={onTabClickHandler}
              rightBtnIcon={'>'}
              leftBtnIcon={'<'}
              tabsScrollAmount={2}>
              {tabOptions.map((tab: ITab, tabIndex: number) => (
                <Tab key={tab.tabId}>{tab.title}</Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
      <div className="search-results">{getSearchResultsContent()}</div>
    </>
  );
};

export default SearchResult;
