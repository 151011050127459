import React from 'react';
import { useTranslation } from 'react-i18next';
import IConfirmationModalProps from '../../../utils/entities/genericComponents/modal/IConfirmationModal';
import DialogComponent from './DialogComponent';

const ConfirmationModal = (props: IConfirmationModalProps) => {
  const {
    openModal,
    setOpenModal,
    action,
    content,
    negativeAction,
    cancelOption,
    buttonsTextOverride,
    disableYes,
    showConfirmButton,
  } = { ...props };
  const { t } = useTranslation();

  const handleClose = (): void => {
    if (negativeAction) {
      negativeAction();
    }
    setOpenModal(false);
  };

  const handleConfirm = () => {
    handleClose();
    action();
  };

  return (
    <DialogComponent
      showConfirmButton={showConfirmButton}
      open={openModal}
      title={content.title}
      body={content.text}
      onClose={handleClose}
      setConfirm={handleConfirm}
      cancelText={cancelOption ? t('Cancel') : undefined}
      setCancel={cancelOption ? handleClose : undefined}
      disabled={disableYes}
      confirmText={buttonsTextOverride?.yes ?? t('YES')}
      denyText={buttonsTextOverride?.no ?? t('No')}
      maxWidth="xs"
    />
  );
};

export default ConfirmationModal;
