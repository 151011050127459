import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import IDataModelColumn from '../../../utils/entities/ag-grid/IDataModelColumn';
import agGridUtils from '../../../utils/functions/agGridUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import EpochToDateText from '../../../components/generic/timer/EpochToDateText';
import React, { useEffect } from 'react';
import LinkButton from '../../../components/generic/button/link/LinkButton';
import DateText from '../../../components/generic/timer/DateText';
import SlaAging from '../../../components/sla/SlaAging';
import TooltipComp from '../../../components/generic/tooltip/Tooltip';
import IconButtonComponent from '../../../components/generic/button/IconButtonComponent';
import { DotType } from '../../../types/dotType';
import Dot from '../../../components/generic/chip/dot/Dot';
import DateCountdown from '../../../components/generic/AgGrid/columns/DateCountdown';
import IGroupkeyPagination from '../../../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { useTranslation } from 'react-i18next';
import GeneralUtils from '../../../utils/functions/generalUtils';
import { PermissionTypes } from '../../../utils/constants/enums';
import LabeledChip from '../../../components/generic/chip/LabeledChip';
import { IUseConfigProps } from '../../../utils/entities/ag-grid/columnConfigs/IUseConfigProps';
import RestAllTags from '../../../components/generic/tag/RestAllTags';

const useConfigUpdates = (
  { columnsConfigs, selectAll, handleShowMoreClick, slaSettingsEntity }: IUseConfigProps,
  callback: (columnsConfigs: Array<IDataModelColumn>, columns: Array<ColDef | ColGroupDef>) => void
) => {
  const { t } = useTranslation();
  const columns: Array<ColDef | ColGroupDef> = [];

  const canAccessAggregateView = GeneralUtils.checkUserPermissions(
    PermissionTypes.TASK_AGGREGATE_VIEW
  );

  const canAccessCase360 = GeneralUtils.checkUserPermissions(PermissionTypes.CASE_DETAIL_CASE_360);

  useEffect(() => {
    if (callback && typeof callback === 'function') {
      columnsConfigs.forEach((c: IDataModelColumn, index: number) => {
        const column: ColDef | ColGroupDef = {
          field: c.attributeName,
          headerName: c.attributeLabel,
          headerTooltip: c.attributeLabel,
          valueGetter: (params) =>
            getCellData(params as ICellRendererParams, c.attributeLabel, c.listType),
          filter: agGridUtils.isColDateType(c.columnType)
            ? CONSTANTS.AG_GRID.FILTER.DATE
            : CONSTANTS.AG_GRID.FILTER.TEXT,
          filterParams: !agGridUtils.isColDateType(c.columnType) && {
            suppressAndOrCondition: true,
          },
        };
        if (c.columnStateProperties) {
          column.hide = c.columnStateProperties.hide ?? undefined;
          column.width = c.columnStateProperties.width;
          column.flex = c.columnStateProperties.width
            ? 0
            : (c.columnStateProperties.flex ?? undefined);
          column.sort = c.columnStateProperties.sort;
          column.sortIndex = c.columnStateProperties.sortIndex;
          column.aggFunc = c.columnStateProperties.aggFunc;
          column.pivotIndex = c.columnStateProperties.pivotIndex;
          column.pinned = c.columnStateProperties.pinned;
          column.rowGroupIndex = c.columnStateProperties.rowGroupIndex;
          column.initialWidth = c.columnStateProperties.width;
        } else {
          column.hide = false;
          column.width = undefined;
          column.flex = undefined;
          column.sort = undefined;
          column.sortIndex = undefined;
          column.aggFunc = undefined;
          column.pivotIndex = undefined;
          column.pinned = false;
          column.rowGroupIndex = undefined;
          column.initialWidth = undefined;
        }
        if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX) {
          column.checkboxSelection = function () {
            return !selectAll;
          };
          column.showDisabledCheckboxes = true;
        }
        if (c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE) {
          column.cellStyle = { alignContent: 'center' };
          column.resizable = false;
        }
        if (
          c.attributeName === CONSTANTS.AG_GRID.COLUMNS.CHECKBOX ||
          c.attributeName === CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE
        ) {
          column.enableRowGroup = false;
          column.suppressMenu = true;
          column.sortable = false;
          column.suppressMovable = true;
          column.maxWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
          column.minWidth = CONSTANTS.AG_GRID.COLUMNS.DEFAULT_WIDTH;
          column.suppressColumnsToolPanel = true;
        }
        switch (c.columnType) {
          case CONSTANTS.COLUMNTYPES.CASE_LINK:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              if (canAccessAggregateView || canAccessCase360) {
                return (
                  <LinkButton
                    url={`${CONSTANTS.PAGES_URL.CASE_DETAILS}/${params.data.caseId}`}
                    text={value}
                  />
                );
              }

              return <div> {value}</div>;
            };

            break;
          case CONSTANTS.COLUMNTYPES.EPOCH_DATE:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              return <EpochToDateText epoch={Number(value)} />;
            };
            break;
          case CONSTANTS.COLUMNTYPES.CASE_TAGS:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              const tags = JSON.parse(value ? value : null);

              if (tags) {
                return <RestAllTags tags={JSON.parse(params.value) ?? []} tagsCount={0} />;
              }
            };

            break;
          case CONSTANTS.COLUMNTYPES.TASK_LINK:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              return (
                <LinkButton
                  url={`${CONSTANTS.PAGES_URL.TASK}/${params.data.taskId}`}
                  text={value}
                />
              );
            };
            break;
          case CONSTANTS.COLUMNTYPES.DATE:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              return <DateText date={value} />;
            };
            break;
          case CONSTANTS.COLUMNTYPES.AGING:
            column.cellRenderer = (params: ICellRendererParams) => {
              const slaAging = params?.value;

              if (slaAging) {
                return <SlaAging status={slaAging} slaSettingsEntity={slaSettingsEntity} />;
              }
            };
            break;
          case CONSTANTS.COLUMNTYPES.RISK_RATING:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;
              if (c.listType == 'CASE_LIST') {
                const score = JSON.parse(value ? value : null);

                if (score) {
                  return <TooltipComp label={score?.scoreRank} tooltipText={score?.scoreValue} />;
                }
              }
              if (value) {
                let color: DotType;
                switch (value) {
                  case 'Low':
                    color = 'SUCCESS';
                    break;
                  case 'Medium':
                    color = 'WARNING';
                    break;
                  case 'High':
                    color = 'FAIL';
                    break;
                  default:
                    color = 'OFF';
                    break;
                }

                return <Dot type={color} message={value} />;
              }
            };
            break;
          case CONSTANTS.COLUMNTYPES.CASE_GAPS:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              const gapReport = JSON.parse(value ? value : null);
              return (
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                  }}>
                  {gapReport?.reportStatus?.hasIdentificationGaps && (
                    <LabeledChip label={t('ID')} isRequired isIdentified />
                  )}
                  {gapReport?.reportStatus?.hasVerificationGaps && (
                    <LabeledChip label={t('V')} isRequired isIdentified />
                  )}
                </div>
              );
            };
            break;
          case CONSTANTS.COLUMNTYPES.LIST:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              try {
                const list = JSON.parse(value ? value : null);
                return <div>{list?.join(', ')}</div>;
              } catch {
                return <div>{value}</div>;
              }
            };
            break;
          case CONSTANTS.COLUMNTYPES.DATE_COUNTDOWN:
            column.cellRenderer = (params: ICellRendererParams) => {
              const value = params?.value;

              if (value && c.additionalProperties?.length) {
                return <DateCountdown timer={value} colorTresholds={c.additionalProperties} />;
              }
            };
            break;
          case CONSTANTS.COLUMNTYPES.SHOW_MORE:
            column.cellRenderer = (params: ICellRendererParams) => {
              const key = params.node.key;
              if (params.node.group && params.node.expanded && key) {
                const savedGroupPagination: IGroupkeyPagination[] | undefined =
                  LocalStorageUtils.getSavedItem(
                    CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
                  );
                const totalElements =
                  savedGroupPagination?.find((gr) => gr.keyName === params.node.key)
                    ?.totalElements ?? 0;
                const childsNumber = getChildsCount(params.node);
                const canLoadMore = childsNumber < totalElements;

                return (
                  <>
                    {canLoadMore && (
                      <TooltipComp
                        label={t('AG_GRID.SHOW_MORE', {
                          num: childsNumber,
                          total: totalElements,
                        })}>
                        <IconButtonComponent
                          icon={'add'}
                          handleClick={() => handleShowMoreClick(key, childsNumber, params)}
                          size={'small'}
                          disableRipple
                          disableFocusRipple
                        />
                      </TooltipComp>
                    )}
                  </>
                );
              }
            };
            break;
          case CONSTANTS.COLUMNTYPES.TEXT:
            column.cellRenderer = (params: ICellRendererParams) => {
              return <div>{params?.value}</div>;
            };
            break;
        }

        columns.push(column);
      });
      callback(columnsConfigs, columns);
    }
  }, [columnsConfigs]);
};

export default useConfigUpdates;

const getCellData = (params: ICellRendererParams, colLabel: string, listType?: string) => {
  const columnConfigurations =
    listType === 'TASK_LIST'
      ? params?.data?.taskListColumnConfigurations
      : params?.data?.columnConfigurations;

  return (
    params.value ??
    columnConfigurations?.find((col: IDataModelColumn) => colLabel === col.attributeLabel)
      ?.attributeValue
  );
};

const getChildsCount = (node: any) => {
  return node.childStore.displayIndexEnd - node.childStore.displayIndexStart;
};
