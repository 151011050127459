import React, { useEffect, useState } from 'react';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { Stack, FormControl, Grid } from '@mui/material';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import QuestionTooltip from '../question-tooltip/QuestionTooltip';
import SpelUtils from '../../../../utils/functions/spelUtils';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import dateUtils from '../../../../utils/functions/dateUtils';
import DatePickerComponent from '../../../generic/inputs/date-picker/DatePickerComponent';
import { IAccessToken } from '../../../../utils/entities/authentication';
import IQuestionType from '../../../../utils/entities/questionnaire/IQuestionType';
import dayjs from 'dayjs';
import DynamicQuestionnaireUtils from '../../../../utils/functions/dynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';
import IValue from '../../../../utils/entities/screen/IValue';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';

const DatepickerQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    entity,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;

  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const storedValue: string | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );

  const oldValue = requestBody?.dynamicQuestionnaire?.[activeTabId!]?.sets[
    setIndex as number
  ].questions.find((currentQuestion: IQuestion) => currentQuestion.id == question.id) as IQuestion;

  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    {
      contextLocals,
      userRole: userInfo.role,
      ...locals,
    }
  );

  const [value, setValue] = useState<string | undefined>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
  );

  const [previousValue, setPreviousValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, oldValue?.value, entity)
  );

  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    if (value) {
      let valueArray = [value];
      if (valueArray[0] == CONSTANTS.DATES.INVALID_DATE) {
        valueArray = [];
      }
      updateValue(question.attributeName, valueArray);

      locals[question?.attributeName] = value;

      updateLocals(locals);

      setRuntimeValidationError(
        DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
          ...{
            contextLocals,
            userRole: userInfo.role,
            ...locals,
          },
          [question.attributeName]: value,
        })
      );

      if (previousValue != value) {
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, {
          isDirty: true,
        });
      }
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    setPreviousValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, oldValue?.value, entity)
    );
  }, [requestBody]);

  useEffect(() => {
    if (question.refresh) {
      setValue(dateUtils.getDateFormatToDatePicker(question.value[entity][0]));
    }
  }, [question.refresh]);

  const onChangeHandler = (newValue: string | null) => {
    if (newValue) {
      setValue(newValue);
      LocalStorageUtils.setSavedItem(
        `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
        newValue
      );
    }
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, {
        contextLocals,
        userRole: userInfo.role,
        ...locals,
      }) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>
                {question.label}
                {requiredResult ? '*' : ''}
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControl fullWidth margin="normal">
                <DatePickerComponent
                  fullWidth
                  defaultValue={value}
                  disabled={
                    disabled
                      ? disabled
                      : SpelUtils.expressionValidation(
                          question.readOnly,
                          question.readOnlyExpression,
                          {
                            contextLocals,
                            userRole: userInfo.role,
                            ...locals,
                          }
                        )
                  }
                  onChange={(value) => {
                    const date = dayjs(value).format(CONSTANTS.DATES.DEFAULT.DATE_FORMAT);
                    onChangeHandler(date);
                  }}
                  required={requiredResult}
                />
              </FormControl>
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default DatepickerQuestion;
