import { useTheme } from '@mui/material/styles';
import { Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import IVerticalBarItem from '../../../utils/entities/layouts/IVerticalBarItem';
import GeneralUtils from '../../../utils/functions/generalUtils';
import IDrawerList from '../../../utils/entities/layouts/IDrawerList';

const DrawerList = ({ open, setOpenDrawer, items, classes }: IDrawerList) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const itemOnClick = (item: IVerticalBarItem) => {
    const target = item.isToOpenOnANewTab ? '_blank' : '_self';
    const options = item.isToOpenOnANewTab ? 'noopener,noreferrer' : '';

    if (item.internalUrl) {
      if (!item.isToOpenOnANewTab) {
        navigate(item.url);
      } else {
        window.open(
          `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${item.url}`,
          target,
          options
        );
      }
    } else {
      window.open(item.url, target, options);
    }

    setOpenDrawer(false);
  };

  return (
    <List className={classes}>
      {items
        .filter(
          (item: IVerticalBarItem) =>
            !item.permissions ||
            item.permissions.every((permission) => GeneralUtils.checkUserPermissions(permission))
        )
        .map((item: IVerticalBarItem) => (
          <ListItem
            key={item.label}
            disablePadding
            sx={
              item.url == location.pathname
                ? { display: 'block', background: '#23232F' }
                : { display: 'block' }
            }>
            {item.url == location.pathname && (
              <div
                className="seleted-bar"
                style={{
                  background: theme.palette.primary.main,
                }}
              />
            )}
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
              }}
              onClick={() => itemOnClick(item)}>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                  color: 'white',
                }}>
                <Icon>{item.icon}</Icon>
              </ListItemIcon>
              <ListItemText
                primary={t(item.label)}
                sx={{ opacity: open ? 1 : 0, marginBottom: 0, color: 'white' }}
              />
            </ListItemButton>
          </ListItem>
        ))}
    </List>
  );
};

export default DrawerList;
