import { CONSTANTS } from '../constants/constants';
import IAgGridUtils from '../entities/functions/IAgGridUtils';

const agGridUtils: IAgGridUtils = {
  isColDateType: (columnType: string) => {
    return (
      columnType === CONSTANTS.COLUMNTYPES.DATE ||
      columnType === CONSTANTS.COLUMNTYPES.EPOCH_DATE ||
      columnType === CONSTANTS.COLUMNTYPES.DATE_COUNTDOWN
    );
  },
};

export default agGridUtils;
