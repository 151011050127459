import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getWorkItemList } from '../../service/global-query.service';
import { CONSTANTS } from '../../utils/constants/constants';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  ColDef,
  ColGroupDef,
  ColumnState,
  ColumnVO,
  FilterModel,
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../utils/entities/role/IUser';
import { getUser, getUsersByProjectName } from '../../service/role-manager.service';
import GeneralUtils from '../../utils/functions/generalUtils';
import ITask, { ICase } from '../../utils/entities/screen/ITask';
import { PermissionTypes } from '../../utils/constants/enums';
import IAgGridRowsTaskRes from '../../utils/entities/screen/IAgGridRowsTaskRes';
import { ViewTypes } from '../../utils/constants/enums';
import IAgGridTblPayload from '../../utils/entities/ag-grid/IAgGridTblPayload';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import { addAlert } from '../../store/actions/alerts.actions';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import ISlaSettingsEntity from '../../utils/entities/config/ISlaSettingsEntity';
import { IRole } from '../../utils/entities/role/IRole';
import IDataModelColumn from '../../utils/entities/ag-grid/IDataModelColumn';
import IGroupKeyPageRequest from '../../utils/entities/ag-grid/pagination/IGroupKeyPageRequest';
import IGroupkeyPagination from '../../utils/entities/ag-grid/pagination/IGroupkeyPagination';
import AgGridTbl from '../../components/generic/AgGrid/table/AgGridTbl';
import useConfigUpdates from './work-item-utils/useConfigUpdates';
import IWorkItemRes from '../../utils/entities/screen/tasks/IWorkItemRes';

const TaskList = (props: { slaSettingsEntity: ISlaSettingsEntity | undefined }) => {
  const { t } = useTranslation();
  const viewType = ViewTypes.TASK_LIST;
  const gridRef = useRef<AgGridReact>(null);
  const projName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const tenant: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  const gridOptions: GridOptions = {
    autoGroupColumnDef: {
      headerName: t('AG_GRID.GROUP_HEADER'),
      field: 'group',
      cellRenderer: 'agGroupCellRenderer',
      sortable: true,
      enableRowGroup: false,
      suppressMenu: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
    },
    getRowId: (params: GetRowIdParams) => {
      /* All rows will have an unique taskId, 
      Excpet the group rows, that rows will have always a single unique element
       in taskListColumnConfigurations
      */
      const col = params.data.taskListColumnConfigurations[0];
      const parentKeys = (params.parentKeys ?? []).join('-');
      return params.data.taskId ?? `${parentKeys}-${col.attributeName}-${col.attributeValue}`;
    },
  };

  const [columns, setColumns] = useState<Array<ColDef | ColGroupDef>>([]);
  const [columnsConfigs, setColumnsConfigs] = useState<Array<IDataModelColumn>>([]);
  const [pageSize, setPageSize] = useState<number>(8);
  const [pageNo, setPageNo] = useState<number>(0);
  const [gridApi, setGridApi] = useState<GridReadyEvent | undefined>(undefined);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [gridSelectedRows, setGridSelectedRows] = useState<Array<ITask> | Array<ICase>>([]);
  const [users, setUsers] = useState<Array<IUser>>([]);
  const [payload, setPayload] = useState<IAgGridTblPayload | undefined>(undefined);
  const [columnsViews, setColumnsViews] = useState<Array<IDataModelColumn>>([]);
  const [defaultView, setDefaultView] = useState<boolean>(false);
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  const [selectAllTasks, setSelectAllTasks] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG) ?? false
  );
  const [groupByParams, setGroupByParam] = useState<Array<ColumnVO> | []>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [activeRole, setActiveRole] = useState<IRole>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
  );
  const [noRows, setNoRows] = useState<boolean>(false);

  /***************************** Needed for the Clear button *****************************/
  const gridNode = gridRef.current as any;
  const columnState = gridNode?.columnApi?.api?.getColumnState();
  const filterModel = gridApi?.api.getFilterModel();
  const [columnFiltering, setColumnFiltering] = useState<boolean>(true);
  const [columnSorting, setColumnSorting] = useState<boolean>(true);
  const [columnGroup, setColumnGroup] = useState<boolean>(true);
  const [resetColumns, setResetColumns] = useState<boolean>(true);

  useEffect(() => {
    const checkIfGroup = gridNode?.columnApi?.api?.columnModel?.gridColumns?.find(
      (column: { rowGroupActive: any }) => {
        return !!column.rowGroupActive;
      }
    );
    setColumnGroup(!checkIfGroup);

    columnState?.forEach((state: ColumnState) => {
      if (state.sort === 'asc' || state.sort === 'desc') {
        setColumnSorting(false);
      }
    });

    if (filterModel && Object.keys(filterModel).length) {
      setColumnFiltering(false);
    }
  }, [columnState, filterModel]);

  const columnsMoved = () => {
    setResetColumns(false);
  };

  /***************************** End of Needed for the Clear button *****************************/

  const usercanTaskUnassign = GeneralUtils.checkUserPermissions(PermissionTypes.TASK_UNASSIGNMENT);
  const usercanFullTaskUnassign = GeneralUtils.checkUserPermissions(
    PermissionTypes.FULL_TASK_UNASSIGNMENT
  );
  const usercanTaskAssign = GeneralUtils.checkUserPermissions(PermissionTypes.TASK_REASSIGNMENT);
  const usercanFullTaskAssign = GeneralUtils.checkUserPermissions(
    PermissionTypes.FULL_TASK_REASSIGNMENT
  );

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    });

    retrieveUsers();

    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE);
      window.removeEventListener('beforeunload', function () {
        return;
      });
    };
  }, []);

  useEffect(() => {
    retrieveUsers();
  }, [activeRole]);

  useEffect(() => {
    toggleAllTasks();
  }, [totalPages, pageNo]);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE, pageSize);
    setPageNo(0);
  }, [pageSize]);

  document.addEventListener(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, (e: Event) => {
    const customEvent = e as CustomEvent;
    setActiveRole(GeneralUtils.deepCopy(customEvent.detail));
  });

  const toggleAllTasks = () => {
    const selectFlag = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    const gridNode = gridRef.current as any;
    if (gridNode?.api && selectFlag != null) {
      gridNode.api.forEachNode((node: { setSelected: (arg0: any) => any }) =>
        node.setSelected(selectFlag)
      );
    }
  };

  const retrieveUsers = () => {
    if (usercanFullTaskUnassign || usercanFullTaskAssign) {
      getUsersByProjectName(projName).then((res: Array<IUser>) => {
        setUsers(res);
      });
    } else {
      getUser().then((user) => setUsers([user]));
    }
  };

  const getRowClass = (params: { data: { status: string | null } }) => {
    if (params?.data?.status != null && (params?.data?.status as string).toUpperCase() == 'NEW') {
      return 'bold-font';
    }
  };

  const onRowSelected = () => {
    if (gridApi?.api?.getSelectedRows()) {
      const selectedRows = gridApi?.api?.getSelectedRows().filter((ele) => ele.taskId) as ITask[];
      setGridSelectedRows(selectedRows);
    }
  };

  const keepGroupByTotalElements = (groupKeyPagination: IGroupkeyPagination | undefined) => {
    if (!groupKeyPagination) {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS);
      return;
    }

    const savedGroupPagination: IGroupkeyPagination[] | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS
    );

    if (savedGroupPagination) {
      const indexOf = savedGroupPagination
        .map((gr) => gr.keyName)
        .indexOf(groupKeyPagination.keyName);

      if (indexOf < 0) {
        savedGroupPagination.push(groupKeyPagination);
      } else {
        savedGroupPagination[indexOf] = groupKeyPagination;
      }

      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS,
        savedGroupPagination
      );
    } else {
      LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.GROUP_TOTAL_ELEMENTS, [
        groupKeyPagination,
      ]);
    }
  };

  const getTasks = async (payload: IAgGridTblPayload) => {
    return await getWorkItemList(payload, CONSTANTS.TASK_LIST).then((response: IWorkItemRes) => {
      if (!response.taskPage) return;
      return response.taskPage;
    });
  };

  // Function to fetch data (simulate an API call or data source fetch)
  const fetchData = useCallback(
    async (params: IServerSideGetRowsParams, taskPayload: IAgGridTblPayload) => {
      const { request, success, fail } = params;
      const { filterModel, groupKeys } = request;
      const filter = filterModel as FilterModel;
      getTasks(taskPayload)
        .then((response: IAgGridRowsTaskRes | undefined) => {
          setPayload(structuredClone(taskPayload));
          if (response) {
            if (groupKeys.length === 0) {
              setPageNo(response.pageable.pageNumber);
              setTotalPages(response.totalPages - 1);
              setTotalElements(response.totalElements);
            }

            success({
              rowData: prepareTableData(response.content as Array<ITask>),
              rowCount: response.content.length,
            });
            toggleAllTasks();
            if (!GeneralUtils.isEmpty(filter) && !response.content.length) {
              setPageNo(0);
            }
            if (!pageNo && !response.content.length) {
              gridApi?.api.showNoRowsOverlay();
            } else {
              gridApi?.api.hideOverlay();
            }
            setNoRows(!response.content.length);

            keepGroupByTotalElements(response.groupKeyPagination);
          }
        })
        .catch(() => {
          addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          fail();
        })
        .finally(() => setDataLoading(false));
    },
    []
  );

  const saveNewPayload = (taskPayload: IAgGridTblPayload) => {
    const payload = GeneralUtils.deepCopy(taskPayload);

    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD, payload);
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LIST_PAYLOAD_UPDATED, payload);
  };

  const prepareTableData = (content: ITask[]): Array<{ [key: string]: any }> => {
    const data: Array<{ [key: string]: any }> = [...content];
    content.forEach((row) => {
      row.taskListColumnConfigurations.forEach((col) => {
        (row as { [key: string]: any })[col.attributeName] = col.attributeValue;
      });
    });
    return data;
  };

  const handleSelectAllTasks = () => {
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG, !selectAllTasks);
    setSelectAllTasks(!selectAllTasks);
    toggleAllTasks();
  };

  const getContextMenuItems = (params: GetContextMenuItemsParams) => {
    const id: string | undefined = params?.node?.data?.taskId;
    if (id) {
      return [
        {
          name: 'Open in a new tab',
          action: () => {
            window.open(`${CONSTANTS.PAGES_URL.TASK}/${id}`, '_blank');
          },
        },
        'separator',
        ...CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS,
      ];
    }
    return CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS;
  };

  const findNode = (nodes: IRowNode[], nodeId: string): IRowNode | undefined => {
    return nodes.find((node) => node.id === nodeId);
  };

  const getExpandedGroupKeysRecursive = (
    nodes: IRowNode[],
    expandedGroupKeys: string[],
    nodeId: string
  ) => {
    const node = findNode(nodes, nodeId);
    if (node?.key) {
      expandedGroupKeys.push(node.key);
    }

    const parentKey = node?.parent?.id;
    if (parentKey) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, parentKey);
    } else {
      return expandedGroupKeys;
    }
  };

  const getExpandedGroupKeys = (nodeId: string) => {
    const expandedGroupKeys: string[] = [];

    const nodes = gridApi?.api.getRenderedNodes();
    if (nodes) {
      getExpandedGroupKeysRecursive(nodes, expandedGroupKeys, nodeId);
    }

    return expandedGroupKeys.reverse();
  };

  const increaseGroupKeyPagination = (key: string, childsCount: number): IGroupKeyPageRequest => {
    const pageNumber = Math.round(
      childsCount / LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.PAGE_SIZE)
    );

    return {
      keyName: key,
      loadMoreRequest: pageNumber,
    };
  };

  const handleShowMoreClick = (key: string, childsCount: number, params: ICellRendererParams) => {
    if (params.node.id) {
      const groupKeys: string[] = getExpandedGroupKeys(params.node.id);
      const groupByParams: ColumnVO[] = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS
      );
      const payload: IAgGridTblPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD
      );

      if (payload && key) {
        const newPayload = structuredClone(payload);
        newPayload.groupKeys = groupKeys;
        newPayload.rowGroupCols = groupByParams;
        newPayload.groupKeyPageRequest = increaseGroupKeyPagination(key, childsCount);
        saveNewPayload(newPayload);
        setDataLoading(true);
        getTasks(newPayload)
          .then((res) => {
            res &&
              gridApi?.api?.applyServerSideTransaction({
                route: groupKeys,
                add: prepareTableData(res.content as Array<ITask>),
              });
          })
          .catch(() => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => setDataLoading(false));
      }
    }
  };

  /**
   * This is a custom hook which is used to update the columns configuration
   */
  useConfigUpdates(
    {
      columnsConfigs,
      selectAll: selectAllTasks,
      handleShowMoreClick,
    },
    (colConfigs: Array<IDataModelColumn>, columns: Array<ColDef | ColGroupDef>) => {
      setColumnsViews(colConfigs);
      setColumns([...columns]);
    }
  );

  return (
    <AgGridTbl
      {...{
        setTotalPages,
        selectAll: selectAllTasks,
        handleSelectAll: handleSelectAllTasks,
        payload,
        columnsViews,
        tenant,
        viewType,
        listType: 'TASK_LIST',
        fetchData,
        pageSize,
        pageNo,
        gridSelectedRows,
        noRows,
        setDefaultView,
        setColumnSorting,
        setColumnGroup,
        setColumnFiltering,
        setSelectAllChecked,
        setResetColumns,
        defaultView,
        columnFiltering,
        columnSorting,
        columnGroup,
        selectAllChecked,
        resetColumns,
        columns,
        getRowClass,
        onRowSelected,
        columnsMoved,
        getContextMenuItems,
        setGridSelectedRows,
        columnsConfigs,
        setColumnsConfigs,
        gridOptions,
        totalPages,
        totalElements,
        setPageSize,
        setPageNo,
        gridName: 'WORK_ITEMS',
        selcetAllLabel: t('TASK.SELECT_ALL'),
        groupByParams,
        usercanTaskUnassign,
        usercanTaskAssign,
        usercanFullTaskUnassign,
        usercanFullTaskAssign,
        users,
        activeRole,
        setGroupByParam,
        setColumnsViews,
        gridRef,
        dataLoading,
        setDataLoading,
        gridApi,
        setGridApi,
        gridNode,
        showAssignTaskModule: true,
        showViewsModule: true,
        showExportModule: true,
        showResetModule: true,
      }}
    />
  );
};

export default TaskList;
