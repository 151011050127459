import { IconButton, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactNode, useEffect, useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React from 'react';

type ArrowType = 'prev' | 'next';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  type: ArrowType;
}

const ArrowButton = (props: IProps) => {
  const { onClick, type } = { ...props };
  const [classes, setClasses] = useState<string>('');
  const [icon, setIcon] = useState<ReactNode>();

  useEffect(() => {
    initComp();
  }, []);

  const initComp = () => {
    let classes = 'slick-arrow ';
    let Icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;

    switch (type) {
      case 'prev':
        classes += 'prev-arrow';
        Icon = NavigateBeforeIcon;
        break;
      case 'next':
        classes += 'next-arrow';
        Icon = NavigateNextIcon;
        break;
    }

    setIcon(<Icon fontSize="large" />);
    setClasses(classes);
  };

  return (
    <IconButton onClick={onClick} className={classes} data-testid="arrow-button-component">
      {icon}
    </IconButton>
  );
};

export default ArrowButton;
