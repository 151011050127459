import React from 'react';
import { Box } from '@mui/material';
import EpochToDateText from '../../../generic/timer/EpochToDateText';
import { useTranslation } from 'react-i18next';
import IIDAndVPropertiesProps from '../../../../utils/entities/id-and-v/IIDAndVProperties';
import IIdentification from '../../../../utils/entities/id-and-v/IIdentification';
import IVerification from '../../../../utils/entities/id-and-v/IVerification';
import LabeledInput from '../../../generic/inputs/input-label/LabeledInput';

const IDAndVProperties = ({ isIdentification, label, idAndVStatus }: IIDAndVPropertiesProps) => {
  const { t } = useTranslation();

  const status: string = isIdentification
    ? (idAndVStatus as IIdentification)?.identificationStatus
    : (idAndVStatus as IVerification)?.verificationStatus;
  const on: number | undefined = isIdentification
    ? (idAndVStatus as IIdentification)?.identifiedOn
    : (idAndVStatus as IVerification)?.verifiedOn;
  const by: string | undefined = isIdentification
    ? (idAndVStatus as IIdentification)?.identifiedBy
    : (idAndVStatus as IVerification)?.verifiedBy;
  const reason: string | undefined = isIdentification
    ? (idAndVStatus as IIdentification)?.identificationReason
    : (idAndVStatus as IVerification)?.verificationReason;

  return (
    <Box>
      <LabeledInput title={isIdentification ? t('ID.LABEL') : t('V.LABEL')} value={label} />
      <LabeledInput title={isIdentification ? t('ID.ID_STATUS') : t('V.V_STATUS')} value={status} />
      {reason && (
        <LabeledInput
          title={isIdentification ? t('ID.JUSTIFICATION') : t('V.JUSTIFICATION')}
          value={reason}
        />
      )}
      {!!(on && on > 0) && (
        <LabeledInput
          title={isIdentification ? t('ID.IDENTIFIED_ON') : t('V.VERIFIED_ON')}
          value={<EpochToDateText epoch={on} />}
        />
      )}
      {by && (
        <LabeledInput
          title={isIdentification ? t('ID.IDENTIFIED_BY') : t('V.VERIFIED_BY')}
          value={by}
        />
      )}
    </Box>
  );
};

export default IDAndVProperties;
