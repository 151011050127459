import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import PlainTextButton from '../../generic/button/PlainTextButton';
import { IDropdownMenuButtonExportProps } from '../../../utils/entities/case/export/IExport';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const DropdownMenuButtonExport = ({
  title,
  options,
  clickOption,
  anchorEl,
  setAnchorEl,
}: IDropdownMenuButtonExportProps) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PlainTextButton
        icon={<FileDownloadTwoToneIcon />}
        label={title}
        handleClick={handleClick}
        classes="case-header-icons"
      />
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {options.map((opt: string, index: number) => (
          <MenuItem
            key={`export-opt-${opt}`}
            value={opt}
            onClick={() => {
              return clickOption(opt);
            }}
            disableRipple>
            {opt}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropdownMenuButtonExport;
