import React, { useContext, useEffect, useState } from 'react';
import {
  ExpandLess,
  ExpandMore,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from '@mui/icons-material';
import {
  Collapse,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import { IAggregateView } from '../../../utils/entities/case/case-detail/IAggregateView';
import { CONSTANTS } from '../../../utils/constants/constants';
import { ITasksToDisplay } from '../../../utils/entities/case/case-detail/ITasksToDisplay';
import ITaskWorkflow from '../../../utils/entities/config/ITaskWorkflow';
import { getTasksByCaseId } from '../../../service/global-query.service';
import { addAlert } from '../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { getSortedUniqueTaskWorkflow } from '../../../service/config-manager.service';
import Loader from '../../../components/loader/Loader';
import GenericContext from '../../../components/generic/timer/GenericContext';

const AggregateViewDetail = ({
  isWorkflowHidden,
  setIsWorkflowHidden,
  toggleOpen,
  openStatus,
  noData,
  isCompletedTask,
  clickHandler,
  selectedTaskName,
  caseId,
}: IAggregateView) => {
  const { selectedProject } = useContext(GenericContext);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [pendingTask, setPendingTask] = useState<ITaskWorkflow | undefined>(undefined);
  const [taskWorkflow, setTaskWorkflow] = useState<ITaskWorkflow[] | undefined>(undefined);
  const [tasksToDisplay, setTasksToDisplay] = useState<ITasksToDisplay | undefined>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    if (!selectedTaskName) {
      for (const status in tasksToDisplay) {
        for (const task of tasksToDisplay[status]) {
          if (task.taskId === id) {
            toggleOpen(status);
            setPendingTask(task);
          }
        }
      }
    }
  }, [tasksToDisplay]);

  useEffect(() => {
    setShowLoader(true);
    getSortedUniqueTaskWorkflow(
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT)
    )
      .then((res: Array<ITaskWorkflow>) => {
        setTaskWorkflow(res);
      })
      .finally(() => setShowLoader(false));
  }, [selectedProject]);

  useEffect(() => {
    if (pendingTask) {
      clickHandler(pendingTask);
    }
  }, [pendingTask]);

  useEffect(() => {
    if (taskWorkflow && caseId) {
      getTasksByCaseId(caseId).then((res) => {
        if (!res.length) {
          addAlert({ type: 'info', primaryText: t('AGGREGATE_VIEW.NO_TASKS') });
        }
        const tasks = res;

        const taskWorflowMatchedWithTaskIds: ITaskWorkflow[] = taskWorkflow.map((obj) => {
          const task = tasks.findLast(
            (t: { taskId: string; taskName: string }) => t.taskName === obj.taskName
          );
          const taskId = task ? task.taskId : null;
          const status = task ? task.internalTaskStatus : CONSTANTS.NON_EXISTENT;
          const taskStartDate = task ? task.taskStartDate : '';
          return { ...obj, taskId, status, taskStartDate } as ITaskWorkflow;
        });

        const tasksByStatus = taskWorflowMatchedWithTaskIds.reduce(
          (tasks: ITasksToDisplay, task: ITaskWorkflow) => {
            if (!tasks[task.status]) {
              tasks[task.status] = [];
            }
            tasks[task.status].push(task);
            return tasks;
          },
          {}
        );

        if (
          !LocalStorageUtils.getSavedItem(
            CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.NON_EXISTENT_TASKS
          )
        ) {
          delete tasksByStatus[CONSTANTS.NON_EXISTENT];
        }
        setTasksToDisplay(tasksByStatus);
      });
    }
  }, [taskWorkflow]);

  const sortTasksByStartDate = (tasks: Array<ITaskWorkflow>): Array<ITaskWorkflow> => {
    return tasks.sort((a, b) => Number(a.taskStartDate) - Number(b.taskStartDate));
  };

  return (
    <div className="aggregate-view">
      {showLoader ? (
        <Loader />
      ) : (
        <>
          {tasksToDisplay && (
            <div className={`task-workflow-container ${isWorkflowHidden ? 'hidden' : ''}`}>
              <Icon
                component="div"
                className="arrow-task-workflow"
                onClick={() => setIsWorkflowHidden(!isWorkflowHidden)}>
                {isWorkflowHidden ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
              </Icon>
              {!isWorkflowHidden && (
                <List>
                  {Object.keys(tasksToDisplay).map((status: string) => (
                    <div key={status}>
                      <ListItemButton onClick={() => toggleOpen(status)}>
                        <ListItemText
                          primaryTypographyProps={{ className: 'font-display' }}
                          primary={status}
                        />
                        {openStatus === status ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      <Collapse
                        component="li"
                        in={openStatus === status}
                        timeout="auto"
                        unmountOnExit>
                        <List>
                          {sortTasksByStartDate(tasksToDisplay[status]).map(
                            (task: ITaskWorkflow) => (
                              <ListItem
                                key={task.taskName}
                                secondaryAction={
                                  task?.taskId && (
                                    <IconButton
                                      onClick={() =>
                                        window.open(
                                          `${CONSTANTS.PAGES_URL.TASK}/${task.taskId}`,
                                          '_blank'
                                        )
                                      }>
                                      <LaunchIcon />
                                    </IconButton>
                                  )
                                }
                                disablePadding
                                sx={{ margin: 0 }}>
                                <ListItemButton
                                  onClick={() => {
                                    if (task.taskId !== pendingTask?.taskId) {
                                      navigate('');
                                      setPendingTask(task);
                                    }
                                  }}>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      className:
                                        selectedTaskName == task.taskName
                                          ? 'entity-el font-nest-display'
                                          : 'entity-el',
                                    }}
                                    primary={task.description}
                                  />
                                </ListItemButton>
                              </ListItem>
                            )
                          )}
                        </List>
                      </Collapse>
                    </div>
                  ))}
                </List>
              )}
            </div>
          )}
          {noData ? (
            <div className="aggregate-view-nodata-warning">
              <p>{t('CASE_ROLES.TASK_DOES_NOT_EXIST')}</p>
            </div>
          ) : (
            <div className={`aggregate-view-outlet ${isWorkflowHidden ? 'close' : 'open'}`}>
              <Outlet context={{ isCompletedTask }} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AggregateViewDetail;
