import { Avatar } from '@mui/material';
import React from 'react';
import IAvatarComponent from '../../../utils/entities/genericComponents/avatar/IAvatarComponent';
import './AvatarComponent.scss';

const AvatarComponent = ({ text }: IAvatarComponent) => {
  return (
    <Avatar className="avatar-component" data-testid="avatar-component">
      {text}
    </Avatar>
  );
};

export default AvatarComponent;
