import React, { useEffect, useState } from 'react';
import { getCase360HeaderByProject } from '../../../service/screen-generator.service';
import { CONSTANTS } from '../../../utils/constants/constants';
import ICaseDetailsHeaderProps from '../../../utils/entities/pages/case-detail/case-header/ICaseHeader';
import ICaseHeaderAttributes from '../../../utils/entities/screen/ICaseHeaderAttributes';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import AggregateViewHeader from '../aggregate-view/AggregateViewHeader';
import Case360Header from '../case-360/Case360Header';

const CaseDetailHeader = ({
  caseId,
  caseRoles,
  casePermissions,
  selectedTab,
}: ICaseDetailsHeaderProps) => {
  const projName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const [caseHeaderData, setCaseHeaderData] = useState<ICaseHeaderAttributes[]>([]);

  useEffect(() => {
    getCase360HeaderByProject(projName, caseId)
      .then((res) => {
        setCaseHeaderData(res);
      })
      .catch(() => {
        setCaseHeaderData([]);
      });
  }, []);

  if (selectedTab?.[0]) {
    switch (selectedTab[0].title) {
      case 'Aggregate View':
        return (
          <AggregateViewHeader
            caseId={caseId}
            caseRoles={caseRoles}
            casePermissions={casePermissions}
          />
        );
      case 'Case 360':
        if (caseHeaderData.length > 0) {
          return (
            <Case360Header
              caseId={caseId}
              caseRoles={caseRoles}
              caseHeaderData={caseHeaderData}
              setCaseHeaderData={setCaseHeaderData}
            />
          );
        } else {
          return (
            <AggregateViewHeader
              caseId={caseId}
              caseRoles={caseRoles}
              casePermissions={casePermissions}
            />
          );
        }

      default:
        return null; // Fallback case if selectedTab doesn't match any known titles
    }
  } else {
    return null; // Handle the case where selectedTab is undefined or empty
  }
};

export default CaseDetailHeader;
