import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { IPdfDetails } from '../../../utils/entities/case/IPdfType';
import { useTranslation } from 'react-i18next';
import { IExportModalBodyProps } from '../../../utils/entities/case/export/IExport';

const ExportModalBody = (props: IExportModalBodyProps) => {
  const { selectedPdf, setSelectedPdf, pdfList } = { ...props };
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="pdf-dropdown-label">{t('PDF_TYPE_LABEL')}</InputLabel>
        <Select
          labelId="pdf-dropdown-label"
          id="pdf-dropdown-standard"
          className="pdf-dropdown"
          value={selectedPdf}
          onChange={(e: SelectChangeEvent) => {
            setSelectedPdf(e.target.value);
          }}
          label={t('PDF_TYPE_LABEL')}>
          {pdfList?.map((pdf: IPdfDetails, index: number) => (
            <MenuItem key={index} value={pdf.pdfType}>
              {pdf.pdfType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ExportModalBody;
