import { Grid } from '@mui/material';
import React, { useState } from 'react';
import dateUtils from '../../utils/functions/dateUtils';
import Loader from '../loader/Loader';
import { IChart } from '../../utils/entities/chart';
import ChartSelector from './ChartSelector';
import DateFilter from './filters/DateFilter';
import './ChartContainer.scss';

const ChartContainer = (props: IChart) => {
  const { data, chartType, loading, isDatePickerEnabled } = props;

  const [toDate, setToDate] = useState<string | null | undefined>(dateUtils.getLastDate(0));
  const [fromDate, setFromDate] = useState<string | null | undefined>(undefined);

  const chipClickHandle = (bachDay: number) => {
    setFromDate(dateUtils.getLastDate(bachDay));
    setToDate(dateUtils.getLastDate(0));
  };

  return (
    <Grid item xs={12} className="charts-grid">
      {isDatePickerEnabled && (
        <Grid item xs={12} className="charts-date-picker-container">
          <DateFilter
            toDate={toDate}
            fromDate={fromDate}
            setToDate={setToDate}
            setFromDate={setFromDate}
            chipClickHandle={chipClickHandle}
          />
        </Grid>
      )}
      {loading ? <Loader /> : <ChartSelector data={data} chartType={chartType} />}
    </Grid>
  );
};

export default ChartContainer;
