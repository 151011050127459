import React, { useEffect, useState } from 'react';
import { useIScreen } from '../../../pages/task/Task';
import { CircularProgress, Grid, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import dateUtils from '../../../utils/functions/dateUtils';
import DefaultModal from '../../generic/modal/Modal';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { sendDraft } from '../../../service/task-manager.service';
import SpelUtils from '../../../utils/functions/spelUtils';
import { IAccessToken } from '../../../utils/entities/authentication';
import GeneralUtils from '../../../utils/functions/generalUtils';
import IScreen from '../../../utils/entities/screen/IScreen';
import TimerDatepickerComponent from './TimerDatepickerComponent';
import TimerButtonComponent from './TimerButtonComponent';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import { themeEY } from '../../../utils/constants/theme';

const TaskTimer = () => {
  const {
    data,
    loading,
    setLoading,
    requestBody,
    setRequestBody,
    activeTabId,
    setError,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
  } = useIScreen();
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const [pause, setPause] = useState<boolean>(false);
  const [stop, setStop] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [click, setClick] = useState<string | undefined>();

  const checkDisabled = () => {
    return isCompletedTask || !hasAssignee || assignee != loggedUser;
  };

  const checkNotShowing = () => {
    return (
      checkDisabled() ||
      stop ||
      (requestBody?.timer?.editEndDateExpression
        ? !checkExpression(requestBody.timer.editEndDateExpression)
        : false)
    );
  };

  useEffect(() => {
    if (data && activeTabId) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
      setLoading(false);
    } else {
      setError('Invalid Screen File or TabId ' + JSON.stringify(data));
    }
  }, [data]);

  useEffect(() => {
    if (requestBody?.timer) {
      setPause(requestBody.timer.paused);
      setStop(requestBody.timer.removed);
    }
  }, [requestBody]);

  if (requestBody == null || loading) {
    return (
      <>
        <CircularProgress />
        <p>{t('LOADING')}</p>
      </>
    );
  }

  const timerClick = (action: string | undefined) => {
    if (requestBody?.timer) {
      if (action === 'play') {
        setPause(false);
        requestBody.timer.paused = false;
      } else if (action === 'pause') {
        setPause(true);
        requestBody.timer.paused = true;
      } else {
        setStop(true);
        setPause(false);
        requestBody.timer.paused = false;
        requestBody.timer.removed = true;
      }
      sendDraft(requestBody);
    }
  };

  const checkExpression = (expression: string) => {
    return SpelUtils.expressionValidation(true, expression, {
      userRole: userInfo.role,
      timer: requestBody.timer,
    });
  };

  const datePickerDate = (epochDate: number | undefined) => {
    return epochDate
      ? dayjs(
          dateUtils.epochToDateFormated(
            epochDate,
            LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT),
            LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
          ),
          LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT)
        )
      : '';
  };

  const checkIsDirty = () => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, {
      isDirty: !(data?.timer?.taskFinishDate === requestBody?.timer?.taskFinishDate),
    });
  };

  return (
    <div className="margin-inline-20 wrapper">
      <ThemeProvider theme={themeEY}>
        <Grid container item spacing={3} xs={8} className="margin-top-10">
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimerDatepickerComponent
                label={t('TIMER_TASK_START')}
                value={datePickerDate(requestBody?.timer?.taskStartDate)}
                disabled={true}
              />
              <TimerDatepickerComponent
                label={t('TIMER_TASK_END')}
                value={datePickerDate(requestBody?.timer?.taskFinishDate)}
                minDate={datePickerDate(requestBody?.timer?.taskStartDate)}
                disabled={checkNotShowing()}
                onChange={(value) => {
                  const date = String(value);
                  const epochTime = new Date(date).getTime() / 1000;
                  if (requestBody.timer) {
                    requestBody.timer.taskFinishDate = epochTime;
                  }
                  checkIsDirty();
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} className="flex-column flex-justify-center">
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              className="full-height">
              {!checkNotShowing() && (
                <TimerButtonComponent
                  title={pause ? t('START_TIMER_TITLE') : t('PAUSE_TIMER_TITLE')}
                  value={pause ? 'play' : 'pause'}
                  onClick={(e) => {
                    setShowModal(true);
                    setClick(pause ? 'play' : 'pause');
                  }}
                  icon={pause ? PlayCircleIcon : PauseCircleIcon}
                />
              )}
              {!checkNotShowing() && !pause && (
                <TimerButtonComponent
                  title={t('DELETE_TIMER_TITLE')}
                  value={'stop'}
                  onClick={(e) => {
                    setShowModal(true);
                    setClick('stop');
                  }}
                  icon={StopCircleIcon}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
      {showModal && (
        <DefaultModal
          open={showModal}
          className="confirmation-modal"
          title={
            click === 'play'
              ? t('START_TIMER_TITLE')
              : click === 'pause'
                ? t('PAUSE_TIMER_TITLE')
                : click === 'stop'
                  ? t('DELETE_TIMER_TITLE')
                  : ''
          }
          body={
            <p>
              {click === 'play'
                ? t('START_TIMER_BODY')
                : click === 'pause'
                  ? t('PAUSE_TIMER_BODY')
                  : click === 'stop'
                    ? t('DELETE_TIMER_BODY')
                    : ''}
            </p>
          }
          setConfirm={() => {
            timerClick(click);
            setShowModal(false);
          }}
          onClose={() => {
            setShowModal(false);
          }}
          confirmText={t('YES')}
          denyText={t('Cancel')}
        />
      )}
    </div>
  );
};

export default TaskTimer;
