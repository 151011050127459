import React, { useEffect, useState } from 'react';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { Grid, Tooltip } from '@mui/material';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import TooltipComp from '../../../generic/tooltip/Tooltip';
import { IRiskScoreProps } from '../../../../utils/entities/case/risk-score/IRiskScore';
import LinearProgressComponent from '../../../generic/progress/LinearProgressComponent';

const RiskScore = (props: IRiskScoreProps) => {
  const { attribute, type } = { ...props };

  const [riskScore, setRiskScore] = useState<number | undefined>(undefined);
  const [riskScoreRank, setRiskScoreRank] = useState<string | undefined>(undefined);

  useEffect(() => {
    setRiskScore(getRiskScore());
    setRiskScoreRank(getRiskScoreRank());
  }, [attribute]);

  const getRiskScore = () => {
    if (attribute.attributeValue) {
      const scoreVal =
        type === 'combined'
          ? JSON.parse(attribute.attributeValue)?.riskScoreValue
          : attribute.attributeValue;

      return scoreVal ? parseInt(scoreVal) : undefined;
    }
    return undefined;
  };

  const getRiskScoreRank = () => {
    if (attribute.attributeValue) {
      const scoreRank =
        type === 'combined'
          ? JSON.parse(attribute.attributeValue)?.riskScoreRank
          : attribute.attributeValue;

      return scoreRank ? scoreRank.toUpperCase() : undefined;
    }
    return undefined;
  };

  return (
    <>
      {type === 'value' && riskScore != undefined && <span>{riskScore}</span>}
      {type === 'rank' && riskScoreRank != undefined && <TooltipComp label={riskScoreRank} />}
      {type === 'combined' && riskScore != undefined && riskScoreRank != undefined && (
        <Tooltip title={riskScore}>
          <Grid container columnSpacing={1} className="align-center">
            <Grid item xs={6}>
              <LinearProgressComponent value={riskScore} />
            </Grid>
            <Grid item xs={6} className="ellipsis">
              {riskScoreRank}
            </Grid>
          </Grid>
        </Tooltip>
      )}
    </>
  );
};

export default RiskScore;
