import React, { useState } from 'react';
import CaseHeader from '../../../components/task/dynamic-questionaire/CaseHeader/CaseHeader';
import { CONSTANTS } from '../../../utils/constants/constants';
import ICase360HeaderProps from '../../../utils/entities/case/case-detail/case-header/ICase360Header';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';

const Case360Header = ({
  caseId,
  caseRoles,
  caseHeaderData,
  setCaseHeaderData,
}: ICase360HeaderProps) => {
  const tenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  const [status, setStatus] = useState<string>('');
  const [headerHeight, setHeaderHeight] = useState<number>(0); // TODO: Move this

  return (
    <div>
      <CaseHeader
        setAttributes={setCaseHeaderData}
        status={status}
        disabledActions
        isDirty={false}
        headerHeight={headerHeight}
        setHeaderHeight={setHeaderHeight}
        tenant={tenant}
        attributes={caseHeaderData}
        caseId={caseId}
        setStatus={setStatus}
        caseRoles={caseRoles}
      />
    </div>
  );
};

export default Case360Header;
