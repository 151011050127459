import { LinearProgress } from '@mui/material';
import React from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import ILinearProgressComponentProps from '../../../utils/entities/genericComponents/progress/IProgress';
import GeneralUtils from '../../../utils/functions/generalUtils';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';

const LinearProgressComponent = ({ min, max, value }: ILinearProgressComponentProps) => {
  const getRiskScoreClass = (val: number) => {
    // TODO !!!!!!!
    if (
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT).includes(
        'CCB'
      )
    ) {
      switch (true) {
        case val >= 0 && val <= 70:
          return 'red';
        case val >= 71 && val <= 89:
          return 'orange';
        case val >= 90 && val <= 99:
          return 'yellow';
        case val >= 100:
          return 'green';
        default:
          return '';
      }
    } else {
      switch (true) {
        case val >= 0 && val <= 55:
          return 'green';
        case val >= 56 && val <= 85:
          return 'yellow';
        case val >= 86 && val <= 400:
          return 'orange';
        case val >= 401:
          return 'red';
        default:
          return '';
      }
    }
  };

  return (
    <LinearProgress
      data-testid="linear-progress-component"
      className={getRiskScoreClass(value)}
      variant="determinate"
      value={GeneralUtils.normalize(value, min ?? 0, max ?? 100)}
      sx={{ height: '20px' }}
    />
  );
};

export default LinearProgressComponent;
