import { createTheme } from '@mui/material';
import { CONSTANTS } from './constants';

export const themeEY = createTheme({
  palette: {
    primary: {
      main: CONSTANTS.COLORS.PRIMARY,
    },
    secondary: {
      main: CONSTANTS.COLORS.SECONDARY,
    },
  },
  typography: {
    fontFamily: 'EYInterstate, ui-monospace, serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
});
