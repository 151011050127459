import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { IErrorStack } from '../../../../../utils/entities/dynamic-questionnaire/IErrorStack';
import ErrorMessage from '../../../../generic/error-message/ErrorMessage';

const ErrorStack = (props: IErrorStack) => {
  const { entity, error, runtimeValidationError, required, specialCharsError, value } = props;
  const { t } = useTranslation();

  const isEmpty = () => {
    return value == null || value?.length == 0 || value === '';
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {runtimeValidationError && <ErrorMessage errorMessage={t('RUNTIME_VALIDATION_ERROR_MSG')} />}
      {error?.get(entity) && required && isEmpty() && (
        <ErrorMessage errorMessage={t('REQUIRED_FIELD_ERROR_MSG')} />
      )}
      {specialCharsError && <ErrorMessage errorMessage={specialCharsError} />}
    </Stack>
  );
};

export default ErrorStack;
