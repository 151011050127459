import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITableHeader } from '../../../utils/entities/screen/ITableHeader';
import { ITableAttributes } from '../../../utils/entities/screen/ITableAttributes';
import GeneralUtils from '../../../utils/functions/generalUtils';
import { ColDef, GridReadyEvent, RowClickedEvent, RowEvent } from 'ag-grid-community';
import { CONSTANTS } from '../../../utils/constants/constants';
import EpochToDateText from '../../../components/generic/timer/EpochToDateText';
import ICase360Table from '../../../utils/entities/screen/ICase360Table';
import { AgGridReact } from 'ag-grid-react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import IconButtonComponent from '../../../components/generic/button/IconButtonComponent';
import AttributesModal from '../../../components/task/case-360/AttributesModal';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import GenericContext from '../../../components/generic/timer/GenericContext';
import { getCaseDetailCase360 } from '../../../service/screen-generator.service';
import Loader from '../../../components/loader/Loader';
import ICreateCase360DataTemplate from '../../../utils/entities/case-360/ICreateCase360DataTemplate';
import { ICase360Props } from '../../../utils/entities/pages/case-detail/case-360/ICase360';

const Case360 = ({ caseId }: ICase360Props) => {
  const { t } = useTranslation();
  const { dateFormat } = useContext(GenericContext);

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [selectedModalHeaders, setSelectedModalHeaders] = useState<Array<ITableHeader>>([]);
  const [selectedModalAttributes, setSelectedModalAttributes] = useState<
    ITableAttributes | undefined
  >(undefined);
  const [tables, setTables] = useState<Array<ICase360Table>>();
  const [showLoader, setShowLoader] = useState<boolean>(true);

  useEffect(() => {
    setShowLoader(true);
    getCaseDetailCase360(
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT),
      caseId
    )
      .then((data: ICreateCase360DataTemplate) => {
        setTables(data.dataTables);
      })
      .finally(() => setShowLoader(false));
  }, []);

  const getColumnDefs = (index: number) => {
    return configureColumns(tables?.[index]?.tableHeaders ?? []);
  };

  const configureColumns = (colConfigs: ITableHeader[]) => {
    const columns: Array<ColDef> = [];
    colConfigs.forEach((c: ITableHeader) => {
      const column: any = {
        field: c.attributeName,
        headerName: c.label,
        headerTooltip: c.label,
        filter: CONSTANTS.AG_GRID.FILTER.TEXT,
        filterParams: { suppressAndOrCondition: true },
        visible: c.visible,
      };
      if (c.attributeFormat) {
        switch (c.attributeFormat.toUpperCase()) {
          case CONSTANTS.COLUMNTYPES.DATE:
            (column.filter = CONSTANTS.AG_GRID.FILTER.DATE),
              (column.cellRenderer = (params: { value: string }) => {
                return <EpochToDateText epoch={Number(params.value) / 1000} />;
              });
            break;

          case CONSTANTS.COLUMNTYPES.CURRENCY:
            (column.filter = CONSTANTS.AG_GRID.FILTER.NUMBER),
              (column.cellRenderer = (params: any) => {
                return (
                  <div>
                    {GeneralUtils.currencyConvertorFun(Number(params.value), 'en-US', 'USD')}
                  </div>
                );
              });
            break;
          default:
            column.cellRenderer = (params: any) => {
              // c.attributeFormat this we will receive as `$${parseFloat(params.value).toFixed(2)} `
              return <div>{eval(c.attributeFormat ?? '')}</div>;
            };
            break;
        }
      }
      column.visible && columns.push(column);
    });

    return columns;
  };

  const getRowData = (tableAttributes?: Array<ITableAttributes>) => {
    if (tableAttributes && Object.values(tableAttributes).length > 0) {
      return Object.values(tableAttributes);
    }
    return [];
  };

  const onCloseDialog = () => {
    setModalIsVisible(false);
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  };

  const handleRowClicked = (row: RowClickedEvent, table: ICase360Table) => {
    setModalIsVisible(true);

    const modalAttr = Object.values(table?.tableAttributes).find(
      (tblAttr: any) => tblAttr === row.data
    );

    setSelectedModalHeaders(
      table.modalHeaders.filter((ele) => {
        if (ele.visible) {
          return ele;
        } else {
          modalAttr && delete modalAttr[ele.attributeName];
        }
      })
    );
    setSelectedModalAttributes(modalAttr);
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div className="margin-inline-20 case360 case360-wide-wrapper" data-testid="case360-main">
          {tables?.map((table: ICase360Table, index: number) => (
            <div key={'table-' + index}>
              <h3>{table.tableName}</h3>
              <div
                className={`ag-theme-alpine ${
                  getRowData(table.tableAttributes).length > 10 ? 'case360-table-height' : ''
                }`}>
                <AgGridReact
                  pagination={true}
                  domLayout={
                    getRowData(table.tableAttributes).length > 10 ? 'normal' : 'autoHeight'
                  }
                  suppressRowClickSelection={true}
                  columnDefs={getColumnDefs(index)}
                  defaultColDef={CONSTANTS.AG_GRID.DEFAULT_COL_DEF}
                  rowGroupPanelShow={'always'}
                  rowData={getRowData(table.tableAttributes)}
                  animateRows={true}
                  onRowClicked={(row: RowClickedEvent) => handleRowClicked(row, table)}
                  onGridReady={onGridReady}
                  getContextMenuItems={() => CONSTANTS.AG_GRID.CONTEXT_MENU_ITEMS}
                />
              </div>
            </div>
          ))}
          <Dialog
            open={modalIsVisible}
            onClose={onCloseDialog}
            classes={{ paper: 'case360-dialog' }}>
            <DialogTitle>
              {
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>{t('ENTITY_ATTRIBUTES')}</Box>
                  <Box>
                    <IconButtonComponent icon="close" handleClick={onCloseDialog} />
                  </Box>
                </Box>
              }
            </DialogTitle>
            <DialogContent dividers>
              <div className="modal-container">
                {selectedModalAttributes && (
                  <AttributesModal
                    attributes={selectedModalAttributes}
                    headers={selectedModalHeaders}
                    index={0}
                    dateFormat={dateFormat}
                  />
                )}
                {selectedModalAttributes && (
                  <AttributesModal
                    attributes={selectedModalAttributes}
                    headers={selectedModalHeaders}
                    index={1}
                    dateFormat={dateFormat}
                  />
                )}
              </div>
            </DialogContent>
          </Dialog>
          {!tables && <p>{t('TAB_DATA_MISSING')}</p>}
        </div>
      )}
    </>
  );
};

export default Case360;
