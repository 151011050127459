import React, { useState } from 'react';
import AlertComponent from '../components/generic/alert/AlertComponent';
import { IAlertProps } from '../utils/entities/genericComponents/IAlertComponent';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import store from '../store/store';
import { removeFirstAlert } from '../store/actions/alerts.actions';

function AlertsManager() {
  const duration = 3000;

  const [alerts, setAlerts] = useState<IAlertProps[]>([]);

  store.subscribe(() => {
    setAlerts([...store.getState().alertsReducer]);
  });

  const handleClose = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'timeout') {
      removeFirstAlert();
    }
    return;
  };

  return (
    <>
      {alerts.length > 0 && (
        <Snackbar
          open={alerts.length > 0}
          autoHideDuration={duration}
          key={alerts[0].msg.primaryText as string}
          onClose={(e, r) => handleClose(e, r)}>
          <div>
            <AlertComponent
              msg={{
                type: alerts[0].msg.type,
                primaryText: alerts[0].msg.primaryText as string,
                secondaryText: alerts[0].msg.secondaryText,
              }}
              onClose={() => removeFirstAlert()}
            />
          </div>
        </Snackbar>
      )}
    </>
  );
}

export default AlertsManager;
