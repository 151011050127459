import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './utils/authConfig';
import { LicenseInfo } from '@mui/x-license-pro';
import { LicenseManager } from 'ag-grid-enterprise';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

LicenseManager.setLicenseKey(window.__RUNTIME_CONFIG__?.REACT_APP_AG_GRID_LICENSE_KEY);
LicenseInfo.setLicenseKey(window.__RUNTIME_CONFIG__?.REACT_APP_MUI_LICENSE_KEY);
const configuration: Configuration = msalConfig;

const pca = new PublicClientApplication(configuration);
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
