export const msalConfig = {
  auth: {
    clientId: window.__RUNTIME_CONFIG__?.REACT_APP_AUTHCONFIG_CLIENTID as string,
    authority: `https://login.microsoftonline.com/${window.__RUNTIME_CONFIG__?.REACT_APP_AUTHCONFIG_TENANTID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.__RUNTIME_CONFIG__?.REACT_APP_AUTHCONFIG_REDIRECT_URI as string,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};
