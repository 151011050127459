import React, { ErrorInfo } from 'react';
import ErrorPage from '../../../pages/error-pages/ErrorPage';
import {
  ErrorBoundaryProps,
  ErrorBoundaryState,
} from '../../../utils/entities/genericComponents/error-boundary/IErrorBoundary';

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, componentStack: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.setState({ hasError: true, error, componentStack: errorInfo.componentStack ?? null });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage pageType="error" toRedirect history={this.props.history} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
