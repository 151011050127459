import React from 'react';
import DatepickerQuestion from './DatepickerQuestion';
import DropdownQuestion from './DropdownQuestion';
import MultiselectQuestion from './MultiselectQuestion';
import NumericQuestion from './NumericQuestion';
import RadioQuestion from './RadioQuestion';
import TextQuestion from './TextQuestion';
import TypeAheadTextQuestion from './TypeAheadTextQuestion';
import TypeAheadMultiSelectQuestion from './TypeAheadMultiSelectQuestion';
import MultiSelectTextAreaQuestion from './MultiSelectTextareaQuestion';
import TextAreaQuestion from './TextAreaQuestion';
import GuidanceTextQuestion from './guidance-text-question/GuidanceTextQuestion';
import IQuestionType from '../../../../utils/entities/questionnaire/IQuestionType';
import DynamicTableQuestion from './DynamicTableQuestion';

const Question = (props: IQuestionType) => {
  const properties = { ...props };

  switch (props?.question?.type?.toUpperCase()) {
    case 'TEXT':
      properties.multiline = false;
      return <TextQuestion {...properties} />;
    case 'DROPDOWN':
      return <DropdownQuestion {...properties} />;
    case 'MULTI':
      return <MultiselectQuestion {...properties} />;
    case 'NUMBER':
      return <NumericQuestion {...properties} />;
    case 'RADIO':
      return <RadioQuestion {...properties} />;
    case 'DATEPICKER':
      return <DatepickerQuestion {...properties} key={properties.entity} />;
    case 'TEXTAREA':
      properties.multiline = true;
      return <TextAreaQuestion {...properties} />;
    case 'TYPEAHEADTEXT':
      return <TypeAheadTextQuestion {...properties} />;
    case 'TYPEAHEADMULTI':
      properties.multiline = false;
      return <TypeAheadMultiSelectQuestion {...properties} />;
    case 'TYPEAHEADMULTITEXTAREA':
      properties.multiline = true;
      return <TypeAheadMultiSelectQuestion {...properties} />;
    case 'MULTITEXTAREA':
      return <MultiSelectTextAreaQuestion {...properties} />;
    case 'GUIDANCE_TEXT':
      return <GuidanceTextQuestion {...properties} />;
    case 'ENTITY_DATA_TABLE':
      return <DynamicTableQuestion {...properties} />;
    default:
      return <p>Question type {properties.question.type} not implemented</p>;
  }
};

export default Question;
