import React, { SyntheticEvent } from 'react';
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  FilterOptionsState,
  FormControl,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { IAttribute } from 'fso-fincrime-sdk-ui';
import AutocompleteValue from '../../../../types/autocompleteValue';
import { addAlert } from '../../../../store/actions/alerts.actions';
import RenderOption from './RenderOptions';
import { IAutocompleteProps } from '../../../../utils/entities/genericComponents/IAutocomplete';
import Loader from '../../../loader/Loader';
import Fuse from 'fuse.js';
import PopperComponent from '../../popper/PopperComponent';

const AutocompleteComponent = (props: IAutocompleteProps) => {
  const {
    multiple,
    disabled,
    placeholder,
    options,
    value,
    setData,
    maxTags,
    required,
    label,
    getOptionLabel,
    icon,
    formClasses,
    setInputValue,
    noOptionsText,
    groupBy,
    clearOnBlur,
    open,
    onClose,
    renderOptionsType,
    isLoading,
    autocompleteRef,
  } = {
    ...props,
  };
  const { t } = useTranslation();

  let fuse: Fuse<AutocompleteValue> | undefined;

  if (renderOptionsType === 'fuzzymatch' || renderOptionsType === 'serverside') {
    fuse = new Fuse(options, {
      keys: ['name'],
      includeMatches: true,
      minMatchCharLength: 2,
    });
  }

  const onChange = (event: SyntheticEvent, newValue: AutocompleteValue | AutocompleteValue[]) => {
    let isValidValue = false;

    if (Array.isArray(newValue)) {
      isValidValue = (newValue as string[]).every((val: string) =>
        GeneralUtils.isValidInfoSecString(val)
      );
    } else if (typeof newValue === 'string') {
      isValidValue = GeneralUtils.isValidInfoSecString(newValue);
    } else if (typeof newValue === 'object' && newValue !== null) {
      isValidValue = GeneralUtils.isValidInfoSecString((newValue as IAttribute).name);
    }

    if (newValue == null || isValidValue) {
      setData(newValue);
    } else {
      addAlert({ type: 'error', primaryText: t('INFOSEC_CHARS_ERROR') });
    }
  };

  const selectRenderOptions = () => {
    switch (renderOptionsType) {
      case 'fuzzymatch':
      case 'serverside':
        return {
          renderOption: (
            props: React.HTMLAttributes<HTMLLIElement>,
            option: AutocompleteValue,
            state: AutocompleteRenderOptionState
          ) => {
            if (fuse != undefined) {
              if (Array.isArray(option)) {
                option = option?.join();
              } else if (typeof option === 'object' && option !== null) {
                option = option?.name;
              }

              /* 
                TODO: Uncomment the following code when this issue is fixed: https://github.com/krisk/Fuse/issues/761
              */
              // const matches = fuse
              //   .search(state.inputValue)
              //   .map((res) => res.matches)
              //   .find((m) => m?.[0].value === option)?.[0];

              // Only highlight with fuzzy match if the match is on the display label
              // if (matches?.key === 'name') {
              //   return (
              //     <RenderOption
              //       props={props}
              //       option={option}
              //       inputValue={state.inputValue}
              //       fuzeMatches={matches.indices}
              //       key={`render-option-fuse-${option}`}
              //     />
              //   );
              // }

              return (
                <RenderOption
                  props={props}
                  option={option}
                  inputValue={state.inputValue}
                  key={`render-option-${option}`}
                />
              );
            }

            return (
              <RenderOption
                props={props}
                option={option}
                inputValue={state.inputValue}
                key={`render-option-${option}`}
              />
            );
          },
        };
      default:
        return { renderOption: undefined };
    }
  };

  const selectFilterOptions = () => {
    switch (renderOptionsType) {
      case 'fuzzymatch':
        return {
          filterOptions: (
            options: AutocompleteValue[],
            state: FilterOptionsState<AutocompleteValue>
          ) => {
            if (fuse != undefined) {
              return fuse.search(state.inputValue).map((res) => res.item);
            }
            return options;
          },
        };
      case 'serverside':
        return {
          filterOptions: (
            options: AutocompleteValue[],
            state: FilterOptionsState<AutocompleteValue>
          ) => {
            return options;
          },
        };
      default:
        return { filterOptions: undefined };
    }
  };

  return (
    <FormControl fullWidth margin="normal" className={formClasses}>
      <span data-testid="label-first-name">
        {label}
        {required ? '*' : ' '}
      </span>
      <Autocomplete
        data-testid="autocomplete-text-box"
        PopperComponent={PopperComponent}
        loading={isLoading}
        loadingText={<Loader />}
        open={open}
        onClose={onClose}
        clearOnBlur={clearOnBlur ?? true}
        selectOnFocus
        disabled={disabled}
        multiple={multiple}
        noOptionsText={noOptionsText}
        options={options}
        value={value}
        onChange={onChange}
        limitTags={maxTags}
        getOptionLabel={getOptionLabel}
        onInputChange={(e, value) => setInputValue && setInputValue(value)}
        groupBy={groupBy}
        disableClearable={options.length <= 1}
        {...selectFilterOptions()}
        {...selectRenderOptions()}
        renderInput={(params) => (
          <TextField
            variant="standard"
            InputProps={{ ...params.InputProps }}
            disabled={params.disabled}
            fullWidth={params.fullWidth}
            id={params.id}
            inputProps={params.inputProps}
            size={params.size}
            placeholder={placeholder ?? ''}
          />
        )}
      />
      {icon ?? null}
    </FormControl>
  );
};

export default AutocompleteComponent;
