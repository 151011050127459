import { Alert, AlertTitle, Grid } from '@mui/material';
import React from 'react';
import { IAlertProps } from '../../../utils/entities/genericComponents/IAlertComponent';
import generalUtils from '../../../utils/functions/generalUtils';

const AlertComponent: React.FC<IAlertProps> = (props) => {
  const { msg, onClose, className } = { ...props };

  return (
    <Grid container className={`alertContainer ${className}`}>
      <Grid item={true} xs={12} className="dropdownToRightCorner">
        <Alert severity={msg.type} className="alert-wrapper" onClose={onClose}>
          <AlertTitle>
            {msg.primaryText && typeof msg.primaryText == 'string'
              ? generalUtils.decodeHtmlEntity(msg.primaryText)
              : (msg.primaryText as React.JSX.Element)}
          </AlertTitle>
          {msg.secondaryText && generalUtils.decodeHtmlEntity(msg.secondaryText as string)}
        </Alert>
      </Grid>
    </Grid>
  );
};

export default AlertComponent;
