import { Tooltip, List, ListItem, TextField } from '@mui/material';
import React, { useState, ChangeEvent } from 'react';
import './CaseTags.scss';
import { useTranslation } from 'react-i18next';
import { IAddTags } from '../../../../../utils/entities/case/CaseHeader/IAddTags';

const AddTags = ({
  showAddButton,
  setShowAddButton,
  addBtnRef,
  value,
  setValue,
  allTags,
}: IAddTags) => {
  const { t } = useTranslation();

  const [filteredItem, setFilteredItem] = useState<Array<string>>(allTags);

  const performfilter = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = allTags.filter(
        (item) => item.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      );
      setFilteredItem(results);
    } else {
      setFilteredItem([...allTags]);
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      className="add-button-container">
      {showAddButton ? (
        <Tooltip title={t('ADD_TAG')} placement="bottom">
          <button
            className="add-button"
            onClick={() => {
              setShowAddButton(false);
            }}>
            +
          </button>
        </Tooltip>
      ) : (
        <div className="search">
          <TextField
            id="add_tag"
            ref={addBtnRef}
            size="small"
            data-testid="search-bar"
            onKeyUp={performfilter}
            value={value}
            variant="standard"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
          />
          {filteredItem.length > 0 && (
            <List className="list-options">
              {filteredItem.map((item) => (
                <ListItem
                  key={item}
                  onClick={() => {
                    setValue(item);
                  }}>
                  {item}
                </ListItem>
              ))}
            </List>
          )}
        </div>
      )}
    </div>
  );
};

export default AddTags;
