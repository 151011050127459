import React, { useEffect } from 'react';
import { SarApp } from 'fso-fincrime-sar-frontend-ui';
import 'fso-fincrime-sar-frontend-ui/build/index.css';
import { useParams } from 'react-router-dom';
import { useIScreen } from '../../pages/task/Task';
import DynamicQuestionnaireUtils from '../../utils/functions/dynamicQuestionnaireUtils';
import GeneralUtils from '../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import { CONSTANTS } from 'fso-fincrime-sdk-ui';
import IScreen from '../../utils/entities/screen/IScreen';
import ISarState from '../../utils/entities/tasks/sar/ISarState';

const SarUi = () => {
  const { tabId } = useParams();
  const {
    setLoading,
    setRequestBody,
    activeTabId,
    data,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
  } = useIScreen();
  const initialState: ISarState = {
    sarDocumentId: '',
    SarCaseSubmissionId: '',
    sourceName: '',
    sourceRefId: '',
  };
  const { sarDocumentId, SarCaseSubmissionId } = initialState;

  useEffect(() => {
    if (saveTaskAsDraftAndExitClick) {
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 1000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  useEffect(() => {
    if (data && activeTabId) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
      setLoading(false);
    }
  }, [data]);

  return (
    // TODO: Rename this data-testid and refactor scss to use class instead of data-testid
    <div data-testid="app-main" key={tabId} className="show-scroll overflow-y-scroll">
      {data.sar && data.sar[data.activeTabId]?.caseId && (
        <SarApp
          sarDocumentId={sarDocumentId}
          SarCaseSubmissionId={SarCaseSubmissionId}
          sourceName={'CMT'}
          sourceRefId={data.sar[data.activeTabId]?.caseId}
        />
      )}
    </div>
  );
};

export default SarUi;
