import { Grid, IconButton, SvgIconTypeMap, Tooltip } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

const TimerButtonComponent = (props: {
  title: React.ReactNode;
  value: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
}): JSX.Element => {
  return (
    <Grid item>
      <Tooltip title={props.title}>
        <IconButton className="timer-button" value={props.value} onClick={props.onClick}>
          <props.icon />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default TimerButtonComponent;
