import axios, { AxiosError, AxiosResponse } from 'axios';
import { IRequestsStatus } from '../utils/entities/dataBlock/IDataBlock';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/functions/serviceUtils';

export const sendRTJRequestsDataBlock = (dataBlock: IRequestsStatus): Promise<IRequestsStatus> => {
  return axios
    .post(`/sendRTJDataBlock`, dataBlock, axiosConfig)
    .then((res: AxiosResponse<IRequestsStatus>) => {
      return serviceUtils.processSuccess<IRequestsStatus>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};
