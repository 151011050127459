import { gql, useQuery } from '@apollo/client';

export const QUERY_TAGS_BY_ID = gql`
  query GetCaseTags($caseId: String) {
    case(caseId: $caseId) {
      tags {
        name
        method
      }
    }
  }
`;

export const GQL_TAGS_BY_ID = (caseId: string) => {
  const { error, data, loading, refetch } = useQuery(QUERY_TAGS_BY_ID, {
    variables: {
      caseId,
    },
  });
  return {
    data,
    error,
    loading,
    refetch,
  };
};
