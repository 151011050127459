import { Grid } from '@mui/material';
import React from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IAttributesModalProps } from '../../../utils/entities/case-360/ICase360';
import EpochToDateText from '../../generic/timer/EpochToDateText';
import TooltipComp from '../../generic/tooltip/Tooltip';
import './AttributesModal.scss';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { ITableHeader } from '../../../utils/entities/screen/ITableHeader';
import dateUtils from '../../../utils/functions/dateUtils';
import { useTranslation } from 'react-i18next';

const AttributesModal = (props: IAttributesModalProps) => {
  const { attributes, headers, index, dateFormat } = { ...props };
  const timezone: string = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE);
  const { t } = useTranslation();

  const getAttrEle = (headers: Array<ITableHeader>, key: string) => {
    return headers.find((el: ITableHeader) => el.attributeName == key);
  };

  const getTimeZone = (c: ITableHeader | undefined) => {
    if (c && c?.displayOriginalTimezoneFormat && c?.originalTimezone) {
      return c.originalTimezone;
    }
    return timezone;
  };

  const dateTextTootipLabel = (value: string, c: ITableHeader | undefined): string => {
    return c?.displayOriginalTimezoneFormat
      ? t('CASE_360.MY_TIMEZONE_DATE') +
          ' ' +
          dateUtils.epochToDateFormated(Number(value) / 1000, dateFormat, timezone)
      : '';
  };

  return (
    <div className="modal-parts">
      <hr />
      {Object.keys(attributes).map((key: string, idx: number) => (
        <>
          {idx % 2 == index && (
            <React.Fragment key={key}>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={4} md={4} lg={4}>
                  {getAttrEle(headers, key)?.label}
                </Grid>
                <Grid item xs={8} md={8} lg={8} className="text-align-right">
                  {getAttrEle(headers, key)?.attributeFormat?.toUpperCase() ===
                  CONSTANTS.COLUMNTYPES.DATE ? (
                    <TooltipComp
                      label={dateTextTootipLabel(attributes[key], getAttrEle(headers, key))}>
                      <EpochToDateText
                        epoch={Number(attributes[key]) / 1000}
                        timeZone={getTimeZone(getAttrEle(headers, key))}
                      />
                    </TooltipComp>
                  ) : (
                    <TooltipComp label={attributes[key]} />
                  )}
                </Grid>
              </Grid>
              <hr />
            </React.Fragment>
          )}
        </>
      ))}
    </div>
  );
};

export default AttributesModal;
