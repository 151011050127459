import React from 'react';
import { useTranslation } from 'react-i18next';
import { deleteCustomView } from '../../../../service/role-manager.service';
import ConfirmationModal from '../../modal/ConfirmationModal';
import { addAlert } from '../../../../store/actions/alerts.actions';
import { IDeleteViewProps } from '../../../../utils/entities/views/IViews';

const DeleteView = ({
  toDeleteView,
  showDeleteModal,
  setShowDeleteModal,
  setIsSelectOpen,
  getViews,
  setIsLoading,
}: IDeleteViewProps) => {
  const { t } = useTranslation();

  const handleDeleteView = () => {
    setIsSelectOpen(false);
    if (toDeleteView.id) {
      setIsLoading(true);
      deleteCustomView(toDeleteView.id)
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('VIEW_DELETED'),
          });
          getViews();
        })
        .catch(() => {
          setIsLoading(false);
          addAlert({
            type: 'error',
            primaryText: t('ERROR_MESSAGE'),
          });
        });
    } else {
      addAlert({
        type: 'error',
        primaryText: t('ERROR_MESSAGE'),
      });
    }
  };

  return (
    <ConfirmationModal
      showConfirmButton
      openModal={showDeleteModal}
      setOpenModal={setShowDeleteModal}
      content={{
        title: t('DELETE_VIEW'),
        text: `${t('DELETE_CONFIRMATION')} ${toDeleteView.label}?`,
      }}
      action={handleDeleteView}
    />
  );
};

export default DeleteView;
