import React, { useEffect, useState } from 'react';
import { caseRefresh, getCase } from '../../../../service/case-manager.service';
import {
  Button,
  Divider,
  Tooltip,
  IconButton,
  Stack,
  FormControlLabel,
  Grid,
  Box,
} from '@mui/material';
import { CONSTANTS } from '../../../../utils/constants/constants';
import './DocumentsPanel.scss';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { saveAs } from 'file-saver';
import { useTranslation, Trans } from 'react-i18next';
import { getDocumentMetadataList } from '../../../../service/document-manager.service';
import ICase from '../../../../utils/entities/case/ICase';
import { updateDocument } from '../../../../service/data-block-builder.service';
import Loader from '../../../loader/Loader';
import EditIcon from '@mui/icons-material/Edit';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlainTextButton from '../../../generic/button/PlainTextButton';
import { addAlert } from '../../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import LaunchIcon from '@mui/icons-material/Launch';
import TooltipComp from '../../../generic/tooltip/Tooltip';
import { IPermission } from '../../../../utils/entities/role';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckboxComponent from '../../../generic/inputs/checkbox/CheckboxComponent';
import IEvidenceDocument from '../../../../utils/entities/case/IEvidenceDocument';
import IDocumentMetadata from '../../../../utils/entities/case/IDocumentMetadata';
import ConfirmationModal from '../../../generic/modal/ConfirmationModal';
import { IConfirmationContent } from '../../../../utils/entities/genericComponents/IConfirmationContent';
import EpochToDateText from '../../../generic/timer/EpochToDateText';
import FileUploadDialog from './FileUploadDialog';
import Dot from '../../../generic/chip/dot/Dot';
import {
  IDocumentConfiguration,
  IDocumentsPanelProps,
} from '../../../../utils/entities/document-center';
import { getDocumentCenterConfigurations } from '../../../../service/config-manager.service';
import IReceivedItem from '../../../../utils/entities/case/IReceivedItem';
import { getDocumentById } from '../../../../service/content-manager.service';
import { sendDraft } from '../../../../service/task-manager.service';
import { documentActionType } from '../../../../types/document/documentAction';
import { updateDocumentType } from '../../../../types/document/updateDocumentType';
import { PermissionTypes } from '../../../../utils/constants/enums';

const DocumentsPanel = (props: IDocumentsPanelProps) => {
  const {
    caseId,
    openModal,
    hasAssignee,
    tenant,
    fileType,
    maxFileSize,
    requestBody,
    caseDisplayId,
    internalStatus,
  } = {
    ...props,
  };
  const { t } = useTranslation();
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const canDelete =
    GeneralUtils.checkUserPermissions(
      PermissionTypes.DOCUMENT_CENTER_DELETE_DOCUMENTS,
      casePermissions
    ) && !internalStatus;
  const canRestore =
    GeneralUtils.checkUserPermissions(
      PermissionTypes.DOCUMENT_CENTER_RESTORE_DOCUMENTS,
      casePermissions
    ) && !internalStatus;
  const canEdit =
    GeneralUtils.checkUserPermissions(
      PermissionTypes.DOCUMENT_CENTER_EDIT_DOCUMENTS,
      casePermissions
    ) && !internalStatus;
  const canDownload = GeneralUtils.checkUserPermissions(
    PermissionTypes.DOCUMENT_CENTER_DOWNLOAD_DOCUMENTS,
    casePermissions
  );
  const canDownloadSoftDeletedDocus = GeneralUtils.checkUserPermissions(
    PermissionTypes.DOCUMENT_CENTER_DOWNLOAD_SOFT_DELETED_DOCUMENTS,
    casePermissions
  );

  const [guidItemAttr, setGuidItemAttr] = useState<Array<IReceivedItem> | undefined>();
  const [documentList, setDocumentList] = useState<Array<IEvidenceDocument>>([]);
  const [documentListIdxFileToEdit, setDocumentListIdxFileToEdit] = useState<number | undefined>(
    undefined
  );
  const [isFileDialogOpen, setIsFileDialogOpen] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [confirmModalContent, setConfirmModalContent] = useState<IConfirmationContent>({
    title: '',
    text: '',
  });
  const [selectedActionType, setSelectedActionType] = useState<documentActionType | undefined>(
    undefined
  );
  const [documentConfigurations, setDocumentConfigurations] = useState<
    Array<IDocumentConfiguration>
  >([]);
  const virusScanProjectConfig = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED
  );

  useEffect(() => {
    getDocuments(caseId);
    getDocumentConfigs();
    sendDraft(requestBody);
    return () => {
      LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED,
        virusScanProjectConfig
      );
    };
  }, []);

  const getDocuments = (caseId: string) => {
    getCase(caseId)
      .then((caseFile: ICase) => {
        setDocListWithMetadata(caseFile.evidenceDocuments || []);
        setGuidItemAttr(caseFile.items);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('ERROR') }));
  };

  const updateDocumentListEventListener = (e: MessageEvent) => {
    if (e.data === caseId) {
      getDocuments(caseId);
    }
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.DOCUMENT_ADD_UPDATE, caseRefresh(props.caseId), true, {
    document_add_update: updateDocumentListEventListener,
  });

  const getDocumentConfigs = () => {
    getDocumentCenterConfigurations(props.tenant)
      .then((docConfigs) => {
        setDocumentConfigurations(docConfigs);
      })
      .catch(() => addAlert({ type: 'error', primaryText: t('DOC_CENTER.ERROR_CONFIG') }));
  };

  const canUserDownloadDoc = (evidenceDocument: IEvidenceDocument) => {
    return (
      canDownload &&
      ((evidenceDocument.metadata?.delete && canDownloadSoftDeletedDocus) ||
        !evidenceDocument.metadata?.delete) &&
      documentConfigurations.find((config) => config.field === 'documentPreview')?.enabled
    );
  };

  const setDocListWithMetadata = async (docs: IEvidenceDocument[]) => {
    const documentGuidList: Array<string> = [];

    docs.map((doc: IEvidenceDocument) => {
      documentGuidList.push(doc.guid);
    });

    getDocumentMetadataList(props.tenant, documentGuidList)
      .then((metaDataRes: Array<IDocumentMetadata>) => {
        const documetsTemp: IEvidenceDocument[] = [];

        for (const [idx, metadata] of metaDataRes.entries()) {
          const doc = docs.find((docs) => docs.guid === metadata.documentGUID);
          if (doc) {
            documetsTemp[idx] = {
              guid: doc.guid,
              issuedDate: doc?.issuedDate,
              documentDescription: doc?.documentDescription,
              checked: doc?.checked,
              metadata: metadata,
              type: doc?.type,
            };
          } else {
            addAlert({ type: 'error', primaryText: t('ERROR') });
          }
        }
        setDocumentList([...documetsTemp]);
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ERROR') });
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const addFileTrigger = () => {
    setIsFileDialogOpen(true);
  };

  const handleDocSelection = (index: number) => {
    const docListTemp = [...documentList];
    const checked = !docListTemp[index].checked;
    docListTemp[index].checked = checked;
    setDocumentList([...docListTemp]);

    LocalStorageUtils.setSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG,
      docListTemp.every((doc) => doc.checked)
    );
  };

  const selectAllDocs = () => {
    const selected = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG, !selected);

    const docListTemp = [...documentList];
    for (const doc of docListTemp) {
      doc.checked = !selected;
    }

    setDocumentList([...docListTemp]);
  };

  const getSelectedDocuments = () => {
    return documentList.filter((doc) => doc.checked);
  };

  const getFilesToBeDeleted = () => {
    return getSelectedDocuments().filter((files) => !files.metadata?.delete);
  };

  const getFilesToBeRestored = () => {
    return getSelectedDocuments().filter((files) => files.metadata?.delete);
  };

  const handleDocActions = (type: documentActionType) => {
    const toDelete = getFilesToBeDeleted().map((doc, index) => (
      <li key={index}>{doc.documentDescription}</li>
    ));
    const toRestore = getFilesToBeRestored().map((doc, index) => (
      <li key={index}>{doc.documentDescription}</li>
    ));

    let title = '',
      body = <></>;
    switch (type) {
      case 'delete':
        title = t('DELETE');
        body = (
          <>
            {toDelete.length > 0 && (
              <Trans
                i18nKey="DOC_CENTER.DELETE_FILES"
                components={[<ul key="delete-list">{toDelete}</ul>]}
              />
            )}
            {toRestore.length > 0 && (
              <Trans
                i18nKey="DOC_CENTER.NOT_ABLE_TO_DELETE"
                components={[<ul key="restore-list">{toRestore}</ul>]}
              />
            )}
          </>
        );
        break;
      case 'restore':
        title = t('RESTORE');
        body = (
          <>
            {toRestore.length > 0 && (
              <Trans
                i18nKey="DOC_CENTER.RESTORE_FILES"
                components={[<ul key="restore-list">{toRestore}</ul>]}
              />
            )}
            {toDelete.length > 0 && (
              <Trans
                i18nKey="DOC_CENTER.NOT_ABLE_TO_RESTORE"
                components={[<ul key="delete-list">{toDelete}</ul>]}
              />
            )}
          </>
        );
        break;
    }

    setConfirmModalContent({ title: title, text: body });
    setConfirmModalOpen(true);
    setSelectedActionType(type);
  };

  const handleUpdateDocuments = () => {
    let docs: IEvidenceDocument[] = [];
    let isDelete: updateDocumentType = null;
    let successMsg = '';
    switch (selectedActionType) {
      case 'delete':
        docs = getFilesToBeDeleted();
        isDelete = 'SOFT_DELETE';
        successMsg = t('DOC_CENTER.FILE_DELETED');
        break;
      case 'restore':
        docs = getFilesToBeRestored();
        isDelete = null;
        successMsg = t('DOC_CENTER.FILE_RESTORED');
        break;
      case 'edit':
        docs = [documentList[documentListIdxFileToEdit as number]];
        isDelete = documentList[documentListIdxFileToEdit as number].metadata?.delete
          ? 'SOFT_DELETE'
          : null;
        setDocumentListIdxFileToEdit(undefined);
        successMsg = t('DOC_CENTER.FILE_UPDATED');
        break;
    }

    if (docs.length === 0) {
      return;
    }

    setShowLoader(true);
    for (const file of docs) {
      updateDocument(
        {
          documentGUID: file.guid,
          caseId: caseId,
          documentOriginalName: file.documentDescription,
          documentType: file.metadata?.documentType,
          documentSubType: file.metadata?.documentSubType,
          expirationDate: file.metadata?.expirationDate,
          ingestionDate: file.metadata?.ingestionDate,
          active: file.metadata?.active,
          delete: isDelete, // TODO -> This should be a string 'delete' | 'restore' | 'edit'
          documentName: file.metadata?.documentName,
        },
        tenant,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME)
      )
        .then(() => {
          addAlert({ type: 'success', primaryText: successMsg });
          getDocuments(caseId);
          LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG);
        })
        .catch((error) => {
          setShowLoader(false);
          addAlert({ type: 'error', primaryText: error });
        });
    }
  };

  const getDocName = (evidenceDocument: IEvidenceDocument) => {
    return evidenceDocument?.metadata?.documentName ?? evidenceDocument.documentDescription;
  };

  return (
    <Box className="drawerPanelView">
      {showLoader && <Loader isMainLoader={true} />}

      <div className="header-section input-side">
        <div className="left-side">
          <b>{t('DOCUMENTS')}</b>
        </div>
        {!internalStatus && (
          <div className="right-side">
            <PlainTextButton
              handleClick={() => {
                addFileTrigger();
                setSelectedActionType('upload');
              }}
              isDisabled={!hasAssignee}
              classes={`add-document ${!hasAssignee ? 'disabled-color' : ''}`}
              icon={<AddCircleOutlineIcon />}
              label={t('ADD_DOCUMENT')}
            />
            <IconButton onClick={() => openModal(false)}>
              <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
            </IconButton>
          </div>
        )}
      </div>

      <Divider className="divider" />

      <div className="content-section">
        {documentList.length > 0 && (canDelete || canRestore) && (
          <Stack spacing={2} direction="row" justifyContent="space-between">
            <FormControlLabel
              control={
                <CheckboxComponent
                  checked={
                    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.SELECT_ALL_FLAG) ??
                    false
                  }
                  onClick={() => selectAllDocs()}
                />
              }
              label={<span className="label bold-font">{t('SELECT_ALL_DOCS')}</span>}
            />
            <Stack spacing={2} direction="row" justifyContent="space-between">
              {canDelete && (
                <IconButton
                  disabled={!documentList.some((doc) => doc.checked)}
                  onClick={() => handleDocActions('delete')}>
                  <DeleteOutlineIcon />
                </IconButton>
              )}
              {canRestore && (
                <IconButton
                  disabled={!documentList.some((doc) => doc.checked)}
                  onClick={() => handleDocActions('restore')}>
                  <RestoreIcon />
                </IconButton>
              )}
            </Stack>
          </Stack>
        )}

        {documentList.map(
          (evidenceDocument: IEvidenceDocument, index: number) =>
            ((evidenceDocument.metadata?.delete && canRestore) ||
              !evidenceDocument.metadata?.delete) && (
              <div
                className={`each-document input-side card-view document-card-drawer-content ${
                  evidenceDocument.metadata?.delete ? 'disabled-opacity' : ''
                }`}
                key={evidenceDocument.guid}>
                <Grid container spacing={0}>
                  {CONSTANTS.DOC_CENTER.FILE_SECTION_COLS.map((col, i) => (
                    <Grid item xs={col.size} key={`${col.label}-${i}`}>
                      <span className="drawer-label">{t(col.label)}</span>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={0} className="drawer-font-style">
                  <Grid item xs={1} className="align-vertical-center">
                    {!internalStatus && (
                      <CheckboxComponent
                        checked={evidenceDocument.checked ?? false}
                        onClick={() => handleDocSelection(index)}
                      />
                    )}
                  </Grid>

                  <Grid item xs={3} className="align-vertical-center">
                    <Tooltip
                      title={<div>{getDocName(evidenceDocument)}</div>}
                      placement="bottom-start"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'common.black',
                            '& .MuiTooltip-arrow': {
                              color: 'common.black',
                            },
                          },
                        },
                      }}>
                      {canUserDownloadDoc(evidenceDocument) ? (
                        <Button
                          className="link"
                          onClick={() => {
                            saveAs(
                              getDocumentById(evidenceDocument.guid, caseId, tenant),
                              evidenceDocument.documentDescription
                            );
                          }}>
                          <div className="ellipsis">{getDocName(evidenceDocument)}</div>
                        </Button>
                      ) : (
                        <div className="ellipsis">{getDocName(evidenceDocument)}</div>
                      )}
                    </Tooltip>
                    {canUserDownloadDoc(evidenceDocument) && (
                      <IconButton
                        className="icon-button-default link"
                        onClick={() => {
                          window.open(
                            getDocumentById(evidenceDocument.guid, caseId, tenant),
                            '_blank'
                          );
                        }}>
                        <LaunchIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={2} className="align-vertical-center">
                    {evidenceDocument?.metadata?.documentSubType && (
                      <TooltipComp label={evidenceDocument?.metadata?.documentSubType} />
                    )}
                  </Grid>
                  <Grid item xs={2} className="align-vertical-center">
                    <EpochToDateText epoch={evidenceDocument.issuedDate} />
                  </Grid>
                  <Grid item xs={2} className="align-vertical-center">
                    <EpochToDateText epoch={evidenceDocument?.metadata?.expirationDate} />
                  </Grid>
                  <Grid item xs={2} justifyContent="flex-end" className="align-vertical-center">
                    {/* Document Status */}
                    <Tooltip
                      title={
                        <div className="tooltip-text">
                          <span className="tooltip-label">
                            {evidenceDocument?.metadata?.active
                              ? t('DOC_CENTER.DOCUMENT_ACTIVE')
                              : t('DOC_CENTER.DOCUMENT_INACTIVE')}
                          </span>
                        </div>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: 'common.black',
                            '& .MuiTooltip-arrow': {
                              color: 'common.black',
                            },
                          },
                        },
                      }}
                      arrow>
                      <span>
                        <Dot type={evidenceDocument?.metadata?.active ? 'SUCCESS' : 'FAIL'} />
                      </span>
                    </Tooltip>
                    {/* Edit Document */}
                    {canEdit && !evidenceDocument?.metadata?.delete && (
                      <Tooltip
                        title={
                          <div className="tooltip-text">
                            <span className="tooltip-label">{t('DOC_CENTER.EDIT_DOC')}</span>
                          </div>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.black',
                              '& .MuiTooltip-arrow': {
                                color: 'common.black',
                              },
                            },
                          },
                        }}
                        arrow>
                        <IconButton
                          className="info-btn color-grey"
                          onClick={() => {
                            setDocumentListIdxFileToEdit(index);
                            setIsFileDialogOpen(true);
                            setSelectedActionType('edit');
                          }}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {/* Uploaded By */}
                    {evidenceDocument?.metadata?.uploadedBy && (
                      <Tooltip
                        title={
                          <div className="tooltip-text">
                            <span className="tooltip-label">{t('DOC_CENTER.UPLOADED_BY')}</span>
                            <span>{evidenceDocument?.metadata?.uploadedBy}</span>
                          </div>
                        }
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: 'common.black',
                              '& .MuiTooltip-arrow': {
                                color: 'common.black',
                              },
                            },
                          },
                        }}
                        arrow>
                        <IconButton className="info-btn">
                          <PersonOutlineIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </div>
            )
        )}
      </div>

      {selectedActionType && (
        <FileUploadDialog
          guidItemAttr={guidItemAttr}
          caseId={caseId}
          caseDisplayId={caseDisplayId}
          hasAssignee={hasAssignee}
          tenant={tenant}
          fileType={fileType}
          maxFileSize={maxFileSize}
          addFileTrigger={addFileTrigger}
          setShowLoader={setShowLoader}
          setDocumentList={setDocumentList}
          isFileDialogOpen={isFileDialogOpen}
          setIsFileDialogOpen={setIsFileDialogOpen}
          documentList={documentList}
          documentListIdxFileToEdit={documentListIdxFileToEdit}
          handleUpdateDocuments={handleUpdateDocuments}
          editMode={selectedActionType}
          setEditMode={setSelectedActionType}
          documentConfigurations={documentConfigurations}
          getDocuments={getDocuments}
          taskId={requestBody.id}
          enableSaveSync={requestBody.enableSaveSync}
          isVerificationProcess={false}
        />
      )}

      <ConfirmationModal
        showConfirmButton
        openModal={confirmModalOpen}
        setOpenModal={setConfirmModalOpen}
        action={handleUpdateDocuments}
        content={confirmModalContent}
      />
    </Box>
  );
};

export default DocumentsPanel;
