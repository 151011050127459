import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../../utils/constants/constants';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
} from '@mui/material';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import DocumentsPanel from '../document/DocumentsPanel';
import CaseAudit from '../../CaseAudit';
import { IPermission } from '../../../../utils/entities/role';
import './CaseHeader.scss';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { IAccessToken } from '../../../../utils/entities/authentication';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import CaseHeaderBanner from './case-header-banner/CaseHeaderBanner';
import CaseHeaderAccordion from './case-header-accordion/CaseHeaderAccordion';
import CaseLinkContainer from './case-link/CaseLinkContainer';
import { ICaseHeaderProps } from '../../../../utils/entities/case/CaseHeader/ICaseHeaderProps';

const CaseHeader = ({
  attributes,
  formId,
  setSubmitClick,
  setSaveTaskAsDraftClick,
  setClaimClick,
  setSaveTaskAsDraftAndExitClick,
  setStatus,
  tenant,
  slaLabels,
  statusOptions,
  disabledActions,
  hasAssignee,
  assignee,
  loggedUser,
  caseId,
  caseDisplayId,
  cancelTask,
  fileType,
  maxFileSize,
  dateFormat,
  hasNoSets,
  isDirty,
  setHeaderHeight,
  requestBody,
  setRequestBody,
  caseRoles,
  processBar,
  contextLocals,
  setToExit,
  showDeleteButton,
}: ICaseHeaderProps) => {
  const navigate = useNavigate();
  const casePermissions: IPermission[] = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS
  );
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const headerCollapse = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.HEADER_COLLAPSE
  );
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);

  const [openHeader, setOpenHeader] = useState<boolean>(false);
  const [tryOpenAccordion, setTryOpenAccordion] = useState<boolean>(false);
  const [holdAccordion, setHoldAccordion] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openAudit, setOpenAudit] = useState<boolean>(false);

  const userIsNotAssignee =
    disabledActions || !(hasAssignee && GeneralUtils.userHasAccess(assignee, loggedUser));

  useEffect(() => {
    GeneralUtils.triggerEvt(
      EVENTS_CONSTANTS.HEADER_EXPAND_STATUS,
      GeneralUtils.deepCopy(headerCollapse)
    );
    setTimeout(() => {
      //Needed since there is no way to check when the accordion ends its animation
      setHeaderHeight((componentRef.current?.offsetHeight as number) + 100);
    }, 1000);
  }, [headerCollapse]);

  const closeAlert = () => {
    navigate(CONSTANTS.PAGES_URL.INBOX);
  };

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      if (!holdAccordion) {
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.HEADER_COLLAPSE,
          headerCollapse
        );
      }
      setHoldAccordion(false);
    }
  }, [tryOpenAccordion]);

  const renderElement = () => (
    <Box className="case-header font-size-12" ref={componentRef}>
      {setClaimClick && (
        <CaseHeaderBanner
          hasAssignee={hasAssignee}
          setClaimClick={setClaimClick}
          formId={formId}
          assignee={assignee}
          userInfo={userInfo}
          internalTaskStatus={disabledActions}
        />
      )}
      {cancelTask && (
        <Dialog fullWidth={true} maxWidth={'xs'} open={true} aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">{t('CASE_CLOSE')}</DialogTitle>
          <DialogContent dividers>{t('CASE_CONTENT')}</DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className="button-style"
              onClick={() => {
                closeAlert();
              }}>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <CaseHeaderAccordion
        tenant={tenant}
        attributes={attributes}
        tryOpenAccordion={tryOpenAccordion}
        headerCollapse={headerCollapse}
        setTryOpenAccordion={setTryOpenAccordion}
        openHeader={openHeader}
        setOpenHeader={setOpenHeader}
        formId={formId}
        userIsNotAssignee={userIsNotAssignee}
        setSubmitClick={setSubmitClick}
        caseId={caseId}
        casePermissions={casePermissions}
        setShowModal={setShowModal}
        setOpenAudit={setOpenAudit}
        setOpenModal={setOpenModal}
        setStatus={setStatus}
        setHoldAccordion={setHoldAccordion}
        statusOptions={statusOptions}
        slaLabels={slaLabels}
        caseRoles={caseRoles}
        internalStatus={disabledActions}
        processBar={processBar}
        requestBody={requestBody}
        setRequestBody={setRequestBody}
        contextLocals={contextLocals}
        isDirty={isDirty}
        setToExit={setToExit}
        setSaveTaskAsDraftAndExitClick={setSaveTaskAsDraftAndExitClick}
        setSaveTaskAsDraftClick={setSaveTaskAsDraftClick}
        showDeleteButton={showDeleteButton}
      />
      {requestBody && (
        <Drawer
          className="drawer-layout"
          anchor="right"
          open={openModal}
          onClose={() => setOpenModal(false)}>
          <DocumentsPanel
            caseId={caseId}
            caseDisplayId={caseDisplayId}
            openModal={setOpenModal}
            hasAssignee={hasAssignee}
            tenant={tenant}
            maxFileSize={maxFileSize}
            fileType={fileType}
            requestBody={requestBody}
            internalStatus={disabledActions}
          />
        </Drawer>
      )}
      <Drawer
        className="drawer-layout"
        anchor="right"
        open={openAudit}
        onClose={() => setOpenAudit(false)}>
        <CaseAudit caseId={caseId} openAudit={setOpenAudit} />
      </Drawer>
      <CaseLinkContainer
        showModal={showModal}
        setShowModal={setShowModal}
        caseId={caseId}
        caseDisplayId={caseDisplayId}
        dateFormat={dateFormat}
        userInfo={userInfo}
        internalStatus={disabledActions}
      />
    </Box>
  );
  return !hasNoSets ? renderElement() : <></>;
};

export default CaseHeader;
