import React from 'react';
import { Alert } from '@mui/material';

interface IProps {
  errorMessage: string | null;
}

const ErrorMessage = ({ errorMessage }: IProps) => {
  return (
    <Alert
      data-testid="error-message"
      variant="outlined"
      severity="error"
      sx={{ width: '100%', border: 'none' }}>
      {errorMessage}
    </Alert>
  );
};

export default ErrorMessage;
