export const API_CONSTANTS = {
  TASK: '/task',
  SCREEN_REFRESH: '/screenRefresh',
  CASE_ROLES_REFRESH: '/caseRolesRefresh',
  TAB: '/tab',
  FILTER: 'filter',
  MAIL_SEND: '/mail',
  RFI_TEMPLATE_DETAIL: '/message/template',
  RFI_TEMPLATE_LIST: '/message/templateNames',
  API_GATEWAY: '/apiGatewayService/execute',
  AUTHENTICATION_SERVICE: '/authenticationService',
  DATAGATEWAY_SERVICE: '/dataGatewayService/graphql',
} as const;
