import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { createBrowserHistory } from 'history';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useIScreen } from '../../pages/task/Task';
import {
  getAllDueDiligenceRequests,
  getDueDiligenceAddParty,
  postDueDiligencePartyDetails,
} from '../../service/due-diligence.service';
import { CONSTANTS } from '../../utils/constants/constants';
import { EVENTS_CONSTANTS } from '../../utils/constants/event_constants';
import dateUtils from '../../utils/functions/dateUtils';
import { ICaseParty, IRequest, ISearchResult } from '../../utils/entities/dueDiligence';
import { IAddPartyBody } from '../../utils/entities/screen/IAddPartyBody';
import { IAddRemoveParty } from '../../utils/entities/screen/IAddRemoveParty';
import { ICity } from '../../utils/entities/screen/IDueDiligence';
import IRefData from '../../utils/entities/screen/IRefData';
import IScreen from '../../utils/entities/screen/IScreen';
import ITab from '../../utils/entities/screen/ITab';
import DynamicQuestionnaireUtils from '../../utils/functions/dynamicQuestionnaireUtils';
import GeneralUtils from '../../utils/functions/generalUtils';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import ErrorBoundary from '../generic/error-boundary/ErrorBoundary';
import TextFieldComponent from '../generic/inputs/text-field/TextFieldComponent';
import GenericContext from '../generic/timer/GenericContext';
import './due-dilligence/DueDilligence.scss';
import PartyDetailPanel from './due-dilligence/PartyDetailPanel';
import PartySelection from './due-dilligence/PartySelection';
import SearchCriteria from './due-dilligence/SearchCriteria';
import SearchResult from './due-dilligence/SearchResult';
import { getCase } from '../../service/case-manager.service';
import ICase from '../../utils/entities/case/ICase';
import IReceivedItem from '../../utils/entities/case/IReceivedItem';

interface searchInfo {
  name: string;
  date: string;
}
interface lastSearch {
  [key: string]: Array<searchInfo>;
}

const DueDiligence = (props: { maxFileSize?: number }) => {
  const {
    data,
    setLoading,
    saveTaskAsDraftAndExitClick,
    setSaveTaskAsDraftAndExitClick,
    isCompletedTask,
    hasAssignee,
    assignee,
    loggedUser,
    activeTabId,
    requestBody,
    setRequestBody,
  } = useIScreen();
  const dateFormat = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT);

  const caseDueDiligence = data.dueDiligence[data.activeTabId];
  const screenDueDiligenceParties = caseDueDiligence.caseParties;
  const screenDueDiligenceSearchSections = caseDueDiligence.searchSections;
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<Array<ISearchResult>>([
    {
      criteria: 'Negative News',
      entityAssociatedWithNN: false,
    },
    {
      criteria: 'LexisNexis',
      entityAssociatedWithNN: false,
    },
    {
      criteria: 'TR Clear',
      entityAssociatedWithNN: false,
    },
    {
      criteria: 'Address Validation',
      entityAssociatedWithNN: false,
    },
    {
      criteria: 'Internet Research',
      entityAssociatedWithNN: false,
    },
  ]);

  const { caseHeaderToggle } = useContext(GenericContext);

  const searchSectionTabs: Array<ITab> = screenDueDiligenceSearchSections.flatMap((obj, index) =>
    obj.searchOptions.map((option) => ({
      tabId: index.toString(),
      title: option.searchOptionTitle,
      type: '',
      isActive: index == selectedTab,
      confirmationModal: false,
      visible: true,
      visibilityExpression: null,
      apiName: option.apiName,
    }))
  );

  const [searchRequests, setSearchRequests] = useState<Array<IRequest>>([]);
  const [openAddParty, setOpenAddParty] = React.useState<boolean>(false);
  const [addParty, setAddParty] = React.useState<string>('');
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openErrorAlert, setOpenErrorAlert] = useState<boolean>(false);
  const [casePartiesOptions, setCasePartiesOptions] = useState<Array<ICaseParty>>([]);
  const [requestsNumber, setRequestsNumber] = useState<number>();
  const [initialLastSearches, setInitialLastSearches] = useState<lastSearch>();
  const [templateDeleted, setTemplateDeleted] = useState<boolean>(false);
  const [templateError, setTemplateError] = useState<boolean>(false);
  const [allParties, setAllParties] = useState<Array<ICaseParty>>();
  const [newSelectedParty, setNewSelectedParty] = useState<ICaseParty>();
  const [countries, setCountries] = useState<Array<IRefData>>();
  const [cities, setCities] = useState<Array<ICity>>();
  const [selectedCountry, setSelectedCountry] = useState<IRefData>();
  const [selectedCity, setSelectedCity] = useState<ICity>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [detailedRequests, setDetailedRequests] = useState<Array<IRequest>>([]);
  const [addressAttributes, setAddressAttributes] = useState<Array<IReceivedItem>>([]);

  useEffect(() => {
    screenDueDiligenceSearchSections.map((section, index) => {
      section.searchOptions.map((searchOption) => {
        if (searchOption.apiName == 'Negative News' && 'countryListRefData' in searchOption) {
          setCountries(searchOption.countryListRefData);
        }
        if (searchOption.apiName == 'Negative News' && 'cityListRefData' in searchOption) {
          setCities(searchOption.cityListRefData);
        }
      });
    });
  }, [showModal]);

  const parties: ICaseParty[] = [];
  parties.push(...casePartiesOptions, ...screenDueDiligenceParties);

  useEffect(() => {
    setAllParties(parties);
  }, [casePartiesOptions]);

  useEffect(() => {
    setLoading(false);
    getRequests(
      data.caseId,
      screenDueDiligenceSearchSections[selectedTab].searchOptions[0].apiName
    );
    if (data && activeTabId && !requestBody) {
      const body = GeneralUtils.deepCopy<IScreen>(data);
      setRequestBody(body);
    }
  }, [data]);

  useEffect(() => {
    getCase(data.caseId).then((caseData: ICase) => {
      setAddressAttributes(caseData.items.filter((el) => el.itemType === 'ADDRESS'));
    });
  }, [data]);

  const getRequests = (caseId: string, name: string) => {
    setSelectedTab(searchSectionTabs.findIndex((el) => el.apiName === name));

    if (name !== 'AV' && name !== 'IR' && name !== 'TR Clear') {
      getAllDueDiligenceRequests(caseId, name)
        .then((requests: Array<IRequest>) => {
          const searchDate = (name: string) => {
            //Return the most recent request where the apiName is equal to the name and get it's request date
            const matchingRequests = requests.filter((item) => item.apiName === name);
            const pop = matchingRequests && matchingRequests.pop();

            return pop
              ? dateUtils.epochToDateFormated(
                  Number(pop?.requestDate),
                  LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT),
                  LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
                )
              : '';
          };
          setInitialLastSearches({
            'Negative News': [
              {
                name: 'Negative News (CI via Bing)',
                date: searchDate('Negative News')!,
              },
              {
                name: 'RDC',
                date: searchDate('RDC')!,
              },
            ],
            '3rd Party': [
              {
                name: 'LexisNexis',
                date: searchDate('LexisNexis')!,
              },
            ],
            'Internet Research': [
              {
                name: 'CI via Bing',
                date: searchDate('Negative News')!,
              },
              {
                name: 'RDC',
                date: searchDate('RDC')!,
              },
            ],
          });
          setSearchRequests(requests);
          setRequestsNumber(requests.length);
        })
        .catch((error) => console.error('ERROR:', error));
    }
  };

  useEffect(() => {
    //Update draft when clicking Save and Exit
    if (saveTaskAsDraftAndExitClick) {
      setTimeout(() => {
        DynamicQuestionnaireUtils.cleanDynamicQuestionnaireStorage();
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.NAVIGATE_EVT, {
          params: CONSTANTS.PAGES_URL.INBOX,
        });
        setSaveTaskAsDraftAndExitClick(false);
      }, 1000);
    }
  }, [saveTaskAsDraftAndExitClick]);

  const handleClose = () => {
    setOpenAddParty(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddParty(event.target.value);
  };

  const handleAddParty = () => {
    const request: IAddRemoveParty = {
      caseId: data.caseId,
      party: addParty,
    };
    getDueDiligenceAddParty(request, data.tenant)
      .then((res: IAddPartyBody) => {
        if (res.status == CONSTANTS.DD.STATUS) {
          setOpenAddParty(false);
          setAddParty('');
          setOpenAlert(true);
          getPartyDetails();
        } else {
          setOpenErrorAlert(true);
        }
        setTimeout(() => {
          setOpenAlert(false);
          setOpenErrorAlert(false);
        }, 2000);
      })
      .catch((error: unknown) => {
        console.error(error);
      });
  };

  const getPartyDetails = () => {
    postDueDiligencePartyDetails(data.caseId, caseDueDiligence.caseParties).then((res) => {
      setCasePartiesOptions(res);
    });
  };

  useEffect(() => {
    getPartyDetails();
  }, [templateDeleted]);

  const addPartyDialogBox = () => {
    return (
      <Dialog open={openAddParty} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>{t('ADD_PARTY_LABEL')}</DialogTitle>
        <DialogContent>
          <TextFieldComponent
            type="text"
            label={t('ADD_PARTY_LABEL')}
            value={addParty}
            setData={(value: string) => setAddParty(value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="assignButton"
            disabled={!addParty}
            onClick={handleAddParty}>
            {t('ADD')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const checkDisabled = () => {
    return isCompletedTask || !hasAssignee || assignee != loggedUser;
  };

  useEffect(() => {
    //Hide alerts after 3 seconds
    setTimeout(() => {
      setTemplateDeleted(false);
      setTemplateError(false);
    }, 3000);
  }, [templateDeleted, templateError]);
  const params = useParams();
  const history = createBrowserHistory();

  return (
    <ErrorBoundary history={history} params={params}>
      <div className={caseHeaderToggle ? 'due-diligence-wrapper' : 'wrapper'}>
        {templateDeleted && (
          <Grid container className="alertContainer">
            <Grid item xs={12} className="dropdownToRightCorner">
              <Alert
                severity="success"
                className="alert-wrapper"
                onClose={() => setTemplateDeleted(false)}>
                {t('PARTY_DELETED')}
              </Alert>
            </Grid>
          </Grid>
        )}
        {templateError && (
          <Grid container className="alertContainer">
            <Grid item xs={12} className="dropdownToRightCorner">
              <Alert severity="error" onClose={() => setTemplateError(false)}>
                {t('ERROR_MESSAGE')}
              </Alert>
            </Grid>
          </Grid>
        )}
        {openAlert ? (
          <Grid container className="alertContainer">
            <Grid item xs={12} className="dropdownToRightCorner">
              <Alert
                severity="success"
                className="alert-wrapper"
                onClose={() => {
                  setOpenAlert(false);
                }}>
                {t('PARTY_ADD_SUCCESS')}
              </Alert>
            </Grid>
          </Grid>
        ) : null}
        {openErrorAlert ? (
          <Grid container className="alertContainer">
            <Grid item xs={12} className="dropdownToRightCorner">
              <Alert
                severity="error"
                className="alert-wrapper"
                onClose={() => {
                  setOpenErrorAlert(false);
                }}>
                {t('PARTY_ALREADY_PRESENT')}
              </Alert>
            </Grid>
          </Grid>
        ) : null}
        {openAddParty && addPartyDialogBox()}
        <div className="nexus-row due-diligence-container due-diligence">
          <div className="nexus-col-xs-4 nexus-col-md-3 due-diligence-detail">
            <PartySelection
              setOpenAddParty={setOpenAddParty}
              disabled={checkDisabled()}
              setTemplateDeleted={setTemplateDeleted}
              setTemplateError={setTemplateError}
              allParties={allParties}
              setAllParties={setAllParties}
              newSelectedParty={newSelectedParty}
              setNewSelectedParty={setNewSelectedParty}
            />
            {
              <PartyDetailPanel
                setNewSelectedParty={setNewSelectedParty}
                newSelectedParty={newSelectedParty}
              />
            }
          </div>
          <div className="nexus-col-xs-4 nexus-col-md-5 search-criteria-container">
            <SearchCriteria
              tenant={data.tenant}
              getRequests={getRequests}
              initialLastSearches={initialLastSearches!}
              disabled={checkDisabled()}
              searchSections={screenDueDiligenceSearchSections}
              newSelectedParty={newSelectedParty}
              setNewSelectedParty={setNewSelectedParty}
              setCountries={setCountries}
              countries={countries}
              setCities={setCities}
              cities={cities}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              setShowModal={setShowModal}
              showModal={showModal}
              setDetailedRequests={setDetailedRequests}
              detailedRequests={detailedRequests}
              dateFormat={dateFormat}
              addressAttributes={addressAttributes}
            />
          </div>
        </div>
        <div className="nexus-row due-diligence-container">
          <SearchResult
            tenant={data.tenant}
            searchResults={searchResults}
            searchRequests={searchRequests}
            searchSections={screenDueDiligenceSearchSections}
            disabled={checkDisabled()}
            selectedTab={selectedTab}
            searchSectionTabs={searchSectionTabs}
            setDetailedRequests={setDetailedRequests}
            dateFormat={dateFormat}
            maxFileSize={props.maxFileSize}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default DueDiligence;
