import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Menu, MenuItem, TextField } from '@mui/material';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { createCustomView } from '../../../../service/role-manager.service';
import PrimaryButton from '../../buttons/PrimaryButton';
import { addAlert } from '../../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { IAccessToken } from '../../../../utils/entities/authentication';
import { ISaveViewProps } from '../../../../utils/entities/views/IViews';

const SaveView = ({
  payload,
  columnsViews,
  setSelectedViewName,
  tenant,
  type,
  createViews,
  anchorEl,
  setAnchorEl,
  views,
  setViews,
  gridApi,
}: ISaveViewProps) => {
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const open = Boolean(anchorEl);

  const [customViewName, setCustomViewName] = useState<string>('');

  const handleSaveViewClose = () => {
    setCustomViewName('');
    setAnchorEl(null);
  };

  const handleSaveView = () => {
    const colStates = gridApi.api.getColumnState();
    for (const columnView of columnsViews) {
      const colState = colStates.find((colState) => colState?.colId === columnView.attributeName);
      if (colState) {
        columnView.columnStateProperties = { ...colState };
      }
    }
    createCustomView(customViewName, {
      label: customViewName,
      inputs: payload,
      columns: columnsViews,
      userEmail: userInfo.user,
      tenant: tenant,
      viewType: type,
      userCreated: true,
      isDefault: false,
    })
      .then((view) => {
        setSelectedViewName(customViewName);
        view.inputs.tenant = tenant;
        createViews([view], [{ ...view.inputs, filterName: view.label }]).then((resp) =>
          setViews([...views, resp[0]])
        );
        addAlert({
          type: 'success',
          primaryText: t('VIEW_CREATED'),
        });
      })
      .catch(() => {
        addAlert({
          type: 'error',
          primaryText: t('VIEW_NOT_CREATED'),
        });
      })
      .finally(() => {
        handleSaveViewClose();
      });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleSaveViewClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      slotProps={{ paper: { className: 'setting-shadow no-focus' } }}>
      <div>
        <MenuItem>
          <TextField
            data-testid="input-textbox-maxlen"
            variant="standard"
            placeholder={t('NAME_VIEW')}
            size="small"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCustomViewName(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          />
        </MenuItem>

        <Divider className="thicker-divider" />

        <MenuItem
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '0.75rem',
          }}>
          <PrimaryButton
            text={t('SAVE')}
            isDisabled={customViewName.length <= 0}
            isPositiveButton
            onClick={handleSaveView}
          />
          <PrimaryButton text={t('CANCEL')} onClick={handleSaveViewClose} />
        </MenuItem>
      </div>
    </Menu>
  );
};

export default SaveView;
