import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import PlainTextButton from '../../generic/button/PlainTextButton';
import AssignmentReturnedOutlinedIcon from '@mui/icons-material/AssignmentReturnedOutlined';
import { useTranslation } from 'react-i18next';
import DialogComponent from '../../generic/modal/DialogComponent';
import { assignBulkTasksToUser, assignTaskToUser } from '../../../service/task-manager.service';
import { addAlert } from '../../../store/actions/alerts.actions';
import { IAccessToken } from '../../../utils/entities/authentication';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import AutocompleteComponent from '../../generic/inputs/autocomplete/AutocompleteComponent';
import IAssignTaskProps from '../../../utils/entities/task-list/IAssignTask';

const AssignTask = ({
  gridSelectedRows,
  isUnAssign,
  bulkAssign,
  users,
  reloadGrid,
  taskListLoading,
  setTaskListLoading,
}: IAssignTaskProps) => {
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const title = isUnAssign
    ? t('TASK_ASSIGNMENT.UNASSIGN.UNASSIGN_TASK')
    : t('TASK_ASSIGNMENT.ASSIGN.ASSIGN_TASK');

  const [openAssign, setOpenAssign] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>(userInfo.user);

  useEffect(() => {
    if (taskListLoading) {
      setTimeout(() => {
        reloadGrid();
        setTaskListLoading(false);
      }, 5000);
    }
  }, [taskListLoading]);

  const handleClose = () => {
    setTaskListLoading(true);
    setOpenAssign(false);
    setSelectedUser(userInfo.user);
  };

  const handleConfirmButton = () => {
    if (bulkAssign) {
      const bulkTasksPayload = LocalStorageUtils.getSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD
      );
      delete bulkTasksPayload.pageSize;
      delete bulkTasksPayload.pageNumber;
      delete bulkTasksPayload.assignee;
      bulkTasksPayload.user = selectedUser;
      bulkTasksPayload.removeAssignee = isUnAssign ?? false;
      assignBulkTasksToUser(bulkTasksPayload)
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('TASK_ASSIGNMENT.SUCCESS'),
          });
        })
        .catch(() =>
          addAlert({
            type: 'error',
            primaryText: t('TASK_ASSIGNMENT.ERROR'),
          })
        );
    } else {
      assignTaskToUser({
        taskIds: gridSelectedRows.map((value) => value.taskId),
        user: selectedUser ?? '',
        removeAssignee: isUnAssign ?? false,
      })
        .then(() => {
          addAlert({
            type: 'success',
            primaryText: t('TASK_ASSIGNMENT.SUCCESS'),
          });
        })
        .catch(() =>
          addAlert({
            type: 'error',
            primaryText: t('TASK_ASSIGNMENT.ERROR'),
          })
        );
    }

    handleClose();
  };

  const assignBody = () => {
    return (
      <>
        {users?.length ? (
          <div>
            {bulkAssign ? (
              <Typography variant="body1" gutterBottom className="numberAssignedTasks">
                {t('TASK_ASSIGNMENT.ASSIGN.BULK')}
              </Typography>
            ) : (
              <Typography variant="body1" gutterBottom className="numberAssignedTasks">
                {t('TASK_ASSIGNMENT.ASSIGN.YOU_HAVE_SELECTED', { count: gridSelectedRows.length })}
              </Typography>
            )}

            <AutocompleteComponent
              options={users.map((user) => user.userName)}
              value={selectedUser}
              setData={(userName) => setSelectedUser(userName as string)}
              label={t('ASSIGN_TO')}
            />
          </div>
        ) : (
          <Typography variant="body1" gutterBottom className="numberAssignedTasks">
            {t('ERROR_FETCHING')}
          </Typography>
        )}
      </>
    );
  };

  const unassignBody = () => {
    return (
      <>
        {bulkAssign ? (
          <Typography variant="body1" gutterBottom className="numberAssignedTasks">
            {t('TASK_ASSIGNMENT.UNASSIGN.BULK')}
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom className="numberAssignedTasks">
            {t('TASK_ASSIGNMENT.UNASSIGN.YOU_HAVE_SELECTED', { count: gridSelectedRows.length })}
          </Typography>
        )}
      </>
    );
  };

  const handleOpenAssign = () => {
    if (!gridSelectedRows.length) {
      return;
    }
    setOpenAssign(true);
  };

  return (
    <>
      <PlainTextButton
        isDisabled={gridSelectedRows.length > 0 ? false : true}
        handleClick={handleOpenAssign}
        classes={`text-transform-capitalize bold-font ${!gridSelectedRows.length ? 'lighter-text' : ''}`}
        icon={isUnAssign ? <AssignmentReturnedOutlinedIcon /> : <AssignmentTurnedInOutlinedIcon />}
        label={title}
      />

      <DialogComponent
        showConfirmButton
        open={openAssign}
        title={title}
        body={isUnAssign ? unassignBody() : assignBody()}
        onClose={() => setOpenAssign(false)}
        setConfirm={handleConfirmButton}
        confirmText={
          isUnAssign ? t('TASK_ASSIGNMENT.UNASSIGN.UNASSIGN') : t('TASK_ASSIGNMENT.ASSIGN.ASSIGN')
        }
      />
    </>
  );
};

export default AssignTask;
