import { ListItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IRenderOptionProps } from '../../../../utils/entities/genericComponents/IAutocomplete';
import FuzzyHighlight from '../../fuzzy-highlight/FuzzyHighlight';

const RenderOption = ({ props, option, inputValue, fuzeMatches }: IRenderOptionProps) => {
  const { t } = useTranslation();

  if (Array.isArray(option)) {
    option = option?.join();
  } else if (typeof option === 'object' && option !== null) {
    option = option?.name;
  }

  const matches = inputValue.length
    ? option?.toLowerCase().includes(inputValue.toLowerCase() ?? false)
    : false;
  const inputValueIndex = option?.toLowerCase()?.indexOf(inputValue?.toLowerCase());

  const getText = (option: string): string | React.JSX.Element => {
    if (fuzeMatches?.length) {
      return <FuzzyHighlight matches={fuzeMatches} option={option} />;
    } else if (matches && inputValueIndex != null) {
      return (
        <FuzzyHighlight
          matches={[[inputValueIndex, inputValueIndex + inputValue.length]]}
          option={option}
        />
      );
    } else return option;
  };

  return (
    <ListItem {...props} key={option}>
      {option ? getText(option) : t('ERROR')}
    </ListItem>
  );
};

export default RenderOption;
