import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICaseLinkTableProps } from '../../../../../../utils/entities/case/CaseHeader/ICaseLinkTable';
import './CaseLinkTable.scss';

const CaseLinkTable = ({
  typedCase,
  cases,
  rows,
  columns,
  handleRowSelection,
  isRowsLoading,
}: ICaseLinkTableProps) => {
  const { t } = useTranslation();

  const getRowsOverlay = (isLoading: boolean) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: '20px',
        }}>
        <p>{isLoading ? t('LOADING') : t('CASE_LINK.NO_CASES')} </p>
      </div>
    );
  };

  return typedCase.length > 1 ? (
    <div className="case-link-table show-scroll border">
      <div style={{ height: '100%', minWidth: '400px' }}>
        {!isRowsLoading ? (
          cases ? (
            <DataGrid
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              disableDensitySelector
              disableColumnMenu
              columnHeaderHeight={49}
              rowHeight={42}
              rows={rows}
              columns={columns}
              pageSizeOptions={[5]}
              onRowSelectionModelChange={handleRowSelection}
              sx={{ overflowX: 'scroll' }}
              style={{ height: 300, width: '100%', border: '0' }}
            />
          ) : (
            getRowsOverlay(false)
          )
        ) : (
          getRowsOverlay(true)
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CaseLinkTable;
