import React from 'react';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Chip, Stack, Tooltip } from '@mui/material';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import Calendar from '@mui/icons-material/Event';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../utils/constants/constants';
import dayjs, { Dayjs } from 'dayjs';
import dateUtils from '../../../utils/functions/dateUtils';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { IDateFilterProps } from '../../../utils/entities/chart';

const DateFilter = (props: IDateFilterProps) => {
  const { setToDate, setFromDate, toDate, fromDate, chipClickHandle } = props;
  const { t } = useTranslation();
  const dateFormat = LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT);

  const dateRangeChange = (value: DateRange<Dayjs>) => {
    const v0 = value[0];
    if (v0) {
      setFromDate(v0.toDate()?.toString());
    }

    const v1 = value[1];
    if (v1) {
      setToDate(v1.toDate()?.toString());
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
    return formattedDate;
  };

  return (
    <div className="date-range-option" data-testid="date-filter-chart">
      <Tooltip
        title={`From: ${dateUtils.dateFormating(
          formatDate(dateUtils.getDateFormatedDateForDateTimePicker(fromDate ?? '') as string),
          dateFormat
        )} - To: ${dateUtils.dateFormating(
          formatDate(dateUtils.getDateFormatedDateForDateTimePicker(toDate ?? '') as string),
          dateFormat
        )}`}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              value={[
                dayjs(dateUtils.getDateFormatedDateForDateTimePicker(fromDate ?? '')),
                dayjs(dateUtils.getDateFormatedDateForDateTimePicker(toDate ?? '')),
              ]}
              onChange={dateRangeChange}
              slots={{ field: SingleInputDateRangeField }}
              className="Datepicker-option"
              label={t('SELECT_DATE')}
              slotProps={{
                textField: { InputProps: { endAdornment: <Calendar /> }, size: 'small' },
              }}
              format={dateFormat}
            />
          </LocalizationProvider>
        </div>
      </Tooltip>
      <Stack direction="row" spacing={1} className="shortcut-option">
        {CONSTANTS.DATES.DATE_SHORTCUTS.map((date) => (
          <Chip label={date.label} key={date.label} onClick={() => chipClickHandle(date.value)} />
        ))}
      </Stack>
    </div>
  );
};

export default DateFilter;
