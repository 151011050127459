import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import DrawerLayout from '../DrawerLayout/DrawerLayout';
import ErrorBoundary from '../../components/generic/error-boundary/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './BaseLayout.scss';
import IBaseLayout from '../../utils/entities/layouts/IBaseLayout';
import Navbar from '../navbar/Navbar';
import AppBar from './app-bar/AppBar';

const BaseLayout = (props: IBaseLayout) => {
  const { children, projects, selectedProject, setSelectedProject } = { ...props };
  const params = useParams();
  const history = createBrowserHistory();

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" color="secondary" elevation={0}>
          <ErrorBoundary history={history} params={params}>
            <Navbar
              openDrawer={openDrawer}
              setOpenDrawer={setOpenDrawer}
              projects={projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}
            />
          </ErrorBoundary>
        </AppBar>
        <ErrorBoundary history={history} params={params}>
          <DrawerLayout open={openDrawer} setOpenDrawer={setOpenDrawer} />
        </ErrorBoundary>
      </Box>
      <div className="main-body-children">{children}</div>
    </>
  );
};

export default BaseLayout;
