import React, { HTMLAttributes } from 'react';
import { CONSTANTS } from '../../utils/constants/constants';
import { SlaStatusType } from '../../types/slaAging';
import './SlaAging.scss';
import ISlaSettingsEntity from '../../utils/entities/config/ISlaSettingsEntity';

interface IProps {
  status: SlaStatusType;
  slaSettingsEntity: ISlaSettingsEntity | undefined;
}

const SlaAging = (props: IProps) => {
  const { status, slaSettingsEntity } = { ...props };

  const getSlaClassName = () => {
    switch (status) {
      case CONSTANTS.SLA.ON_TRACK.value:
        return 'color-sla-on-track';
      case CONSTANTS.SLA.OFF_TRACK.value:
        return 'color-sla-off-track';
      case CONSTANTS.SLA.AT_RISK.value:
        return 'color-sla-at-risk';
      default:
        return '';
    }
  };
  const getSlaStyle = (slaSettingsEntity: ISlaSettingsEntity): string => {
    switch (status) {
      case CONSTANTS.SLA.ON_TRACK.value:
        return slaSettingsEntity.slaColours[CONSTANTS.SLA.ON_TRACK.value + '_COLOUR'];
      case CONSTANTS.SLA.OFF_TRACK.value:
        return slaSettingsEntity.slaColours[CONSTANTS.SLA.OFF_TRACK.value + '_COLOUR'];
      case CONSTANTS.SLA.AT_RISK.value:
        return slaSettingsEntity.slaColours[CONSTANTS.SLA.AT_RISK.value + '_COLOUR'];
      default:
        return '';
    }
  };

  const getSlaLabel = (): string => {
    switch (status) {
      case CONSTANTS.SLA.ON_TRACK.value:
        return slaSettingsEntity
          ? slaSettingsEntity.slaLabels[CONSTANTS.SLA.ON_TRACK.value]
          : CONSTANTS.SLA.ON_TRACK.label;
      case CONSTANTS.SLA.OFF_TRACK.value:
        return slaSettingsEntity
          ? slaSettingsEntity.slaLabels[CONSTANTS.SLA.OFF_TRACK.value]
          : CONSTANTS.SLA.OFF_TRACK.label;
      case CONSTANTS.SLA.AT_RISK.value:
        return slaSettingsEntity
          ? slaSettingsEntity.slaLabels[CONSTANTS.SLA.AT_RISK.value]
          : CONSTANTS.SLA.AT_RISK.label;
      default:
        return '';
    }
  };

  const getSlaColour = (): HTMLAttributes<HTMLSpanElement> =>
    slaSettingsEntity
      ? { style: { color: getSlaStyle(slaSettingsEntity) } }
      : { className: getSlaClassName() };

  return <span {...getSlaColour()}>{getSlaLabel()}</span>;
};

export default SlaAging;
