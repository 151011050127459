import React, { useState } from 'react';
import './CopyButton.scss';
import IconButtonComponent from '../IconButtonComponent';
import { iconType } from '../../../../types/iconType';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { addAlert } from '../../../../store/actions/alerts.actions';
import { useTranslation } from 'react-i18next';

interface ICopyButtonProps {
  value: string;
}

const CopyButton = (props: ICopyButtonProps) => {
  const { t } = useTranslation();
  const { value } = { ...props };

  const [icon, setIcon] = useState<iconType>('copy');
  const [showIcon, setShowIcon] = useState<boolean>(false);

  const copy = () => {
    setIcon('check');
    setShowIcon(true);
    GeneralUtils.copyTextToClipboard(value)
      .then(() => addAlert({ type: 'success', primaryText: t('COPY.SUCCESS', { value }) }))
      .catch(() => addAlert({ type: 'success', primaryText: t('COPY.ERROR') }));
    setTimeout(() => {
      setIcon('copy');
      setShowIcon(false);
    }, 3000);
  };

  return (
    <IconButtonComponent
      data-testid="copy-button-component"
      icon={icon}
      handleClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        copy();
      }}
      size="small"
      classes={`copy-button ${!showIcon ? 'hide' : ''}`}
    />
  );
};

export default CopyButton;
