import IBusinessKeysMap from '../entities/case/IBusinessKeysMap';
import ICaseHeaderAttributes from '../entities/screen/ICaseHeaderAttributes';
import IContextLocals from '../entities/screen/IContextLocals';
import IContextLocalsEntity from '../entities/screen/IContextLocalsEntity';
import IQuestion from '../entities/screen/IQuestion';
import { IQuestionTableAttributes } from '../entities/screen/IQuestionTableAttributes';
import IScreen from '../entities/screen/IScreen';
import ISet from '../entities/screen/ISet';
import GeneralUtils from './generalUtils';

const screenRefreshTableUtils = {
  updateEntities: (question: IQuestion, newQuestion: IQuestion) =>
    question.dataTable.entityIds.map(
      (oldentity: string) =>
        GeneralUtils.getNewEntityId(
          oldentity,
          question.dataTable.businessKeys,
          newQuestion.dataTable.businessKeys
        )[0]
    ),
  updatetableAttributesEntities: (question: IQuestion, newQuestion: IQuestion) => {
    const tableAttributesEntities: { [key: string]: IQuestionTableAttributes } = {};
    Object.keys(question.dataTable.tableAttributes).forEach((key: string) => {
      tableAttributesEntities[
        GeneralUtils.getNewEntityId(
          key,
          question.dataTable.businessKeys,
          newQuestion.dataTable.businessKeys
        )[0]
      ] = question.dataTable.tableAttributes[key];
    });
    return tableAttributesEntities;
  },
  updateBusinessKeysEntities: (question: IQuestion, newQuestion: IQuestion) => {
    question.dataTable.businessKeys.forEach((bk: IBusinessKeysMap, bkIndex: number) => {
      question.dataTable.businessKeys[bkIndex].entityId = GeneralUtils.getNewEntityId(
        bk.entityId,
        question.dataTable.businessKeys,
        newQuestion.dataTable.businessKeys
      )[0];
    });
  },
  addNewEntities: (question: IQuestion, newQuestion: IQuestion) => {
    newQuestion.dataTable.entityIds.forEach((newEntity: string) => {
      if (!question.dataTable.entityIds.some((entity: string) => entity == newEntity)) {
        question.dataTable.entityIds.push(newEntity);
        question.dataTable.tableAttributes = {
          ...question.dataTable.tableAttributes,
          [newEntity]: newQuestion.dataTable.tableAttributes[newEntity],
        };
        const newBks = newQuestion.dataTable.businessKeys.find(
          (newBks: IBusinessKeysMap) => newBks.entityId == newEntity
        );
        if (newBks) {
          question.dataTable.businessKeys.push(newBks);
        }
      }
    });
    return newQuestion;
  },
  removeDeletedEntities: (question: IQuestion, newQuestion: IQuestion) => {
    question.dataTable.entityIds.forEach((entity: string, entityIndex) => {
      if (!newQuestion.dataTable.entityIds.some((newEntity: string) => entity == newEntity)) {
        delete question.dataTable.entityIds[entityIndex];
        delete question.dataTable.tableAttributes[entity];
        delete question.dataTable.businessKeys[
          question.dataTable.businessKeys.findIndex(
            (bks: IBusinessKeysMap) => bks.entityId == entity
          )
        ];
      }
    });
  },
};

const ScreenRefreshUtils = {
  updateSetArrayEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    newIndexBodySet: number
  ) =>
    body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].entityIds.map(
      (oldentity: string) =>
        GeneralUtils.getNewEntityId(
          oldentity,
          body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys,
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
        )[0]
    ),
  updateQuestionValueEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    bodyQuestionIndex: number,
    newIndexBodySet: number
  ) => {
    const valueEntities: { [key: string]: Array<string> } = {};
    Object.keys(
      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[bodyQuestionIndex].value
    ).forEach((key: string) => {
      valueEntities[
        GeneralUtils.getNewEntityId(
          key,
          body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys,
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
        )[0]
      ] =
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].value[key];
    });
    return valueEntities;
  },
  updateResponseHasChangedEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    bodyQuestionIndex: number,
    newIndexBodySet: number
  ) => {
    const responseHasChangedEntities: { [key: string]: boolean } = {};
    Object.keys(
      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[bodyQuestionIndex]
        .responseHasChanged
    ).forEach((key: string) => {
      responseHasChangedEntities[
        GeneralUtils.getNewEntityId(
          key,
          body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys,
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
        )[0]
      ] =
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].responseHasChanged[key];
    });
    return responseHasChangedEntities;
  },
  updateWasChangedEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    bodyQuestionIndex: number,
    newIndexBodySet: number
  ) => {
    const wasChangedEntities: { [key: string]: boolean } = {};
    if (
      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[bodyQuestionIndex]
        .wasChanged
    ) {
      Object.keys(
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[bodyQuestionIndex]
          .wasChanged
      ).forEach((key: string) => {
        wasChangedEntities[
          GeneralUtils.getNewEntityId(
            key,
            body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys,
            newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
          )[0]
        ] =
          body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
            bodyQuestionIndex
          ].wasChanged[key];
      });
    }
    return wasChangedEntities;
  },
  updateContextLocalsEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    businessKeys: Array<Array<IBusinessKeysMap>>
  ) => {
    const contextLocalsEntities: { [key: string]: IContextLocalsEntity } = {};
    Object.keys(body.contextLocals).forEach((key: string) => {
      if (key == 'header' || key == 'rootEntity') {
        contextLocalsEntities[key] = body.contextLocals[key];
      } else {
        body.dynamicQuestionnaire[activeTabId].sets.forEach(
          (bodySet: ISet, indexBodySet: number) => {
            const newIndexBodySet: number = body.dynamicQuestionnaire[activeTabId].sets.findIndex(
              (newBodySet: ISet) =>
                newBodySet.id == body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].id
            );
            const newEntityArray = GeneralUtils.getNewEntityId(
              key,
              businessKeys[indexBodySet],
              newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
            );

            if (newEntityArray && newEntityArray?.length != 0)
              contextLocalsEntities[newEntityArray[0]] = body.contextLocals[key];
          }
        );
      }
    });
    return contextLocalsEntities;
  },
  updateBusinessKeysEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    businessKeys: Array<Array<IBusinessKeysMap>>,
    newIndexBodySet: number
  ) => {
    businessKeys[indexBodySet].forEach((bk: IBusinessKeysMap, bkIndex: number) => {
      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys[bkIndex].entityId =
        GeneralUtils.getNewEntityId(
          bk.entityId,
          businessKeys[indexBodySet],
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
        )[0];
    });
  },
  updateRootIdEntities: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    indexBodySet: number,
    newIndexBodySet: number
  ) => {
    for (let setI = 0; setI < body.dynamicQuestionnaire[activeTabId].sets.length; setI++) {
      const rootId = GeneralUtils.getNewEntityId(
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].rootId,
        body.dynamicQuestionnaire[activeTabId].sets[setI].businessKeys,
        newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].businessKeys
      )[0];

      if (rootId) {
        return rootId;
      }
    }
    return '';
  },
  updateEntityIds: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    const bksBySet: Array<Array<IBusinessKeysMap>> = [];

    body.dynamicQuestionnaire[activeTabId].sets.forEach((bodySet: ISet, indexBodySet: number) => {
      const newIndexBodySet: number = newBody.dynamicQuestionnaire[activeTabId].sets.findIndex(
        (newBodySet: ISet) =>
          newBodySet.id == body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].id
      );
      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].entityIds =
        ScreenRefreshUtils.updateSetArrayEntities(
          body,
          newBody,
          activeTabId,
          indexBodySet,
          newIndexBodySet
        );

      bodySet.questions.forEach((bodyQuestion: IQuestion, bodyQuestionIndex: number) => {
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].value = ScreenRefreshUtils.updateQuestionValueEntities(
          body,
          newBody,
          activeTabId,
          indexBodySet,
          bodyQuestionIndex,
          newIndexBodySet
        );

        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].responseHasChanged = ScreenRefreshUtils.updateResponseHasChangedEntities(
          body,
          newBody,
          activeTabId,
          indexBodySet,
          bodyQuestionIndex,
          newIndexBodySet
        );

        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].wasChanged = ScreenRefreshUtils.updateWasChangedEntities(
          body,
          newBody,
          activeTabId,
          indexBodySet,
          bodyQuestionIndex,
          newIndexBodySet
        );

        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].identificationStatus =
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].questions[
            bodyQuestionIndex
          ].identificationStatus;

        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].questions[
          bodyQuestionIndex
        ].verificationStatus =
          newBody.dynamicQuestionnaire[activeTabId].sets[newIndexBodySet].questions[
            bodyQuestionIndex
          ].verificationStatus;
      });

      bksBySet[indexBodySet] = GeneralUtils.deepCopy(
        body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].businessKeys
      );

      body.dynamicQuestionnaire[activeTabId].sets[indexBodySet].rootId =
        ScreenRefreshUtils.updateRootIdEntities(
          body,
          newBody,
          activeTabId,
          indexBodySet,
          newIndexBodySet
        );

      // updateSetArrayEntities,  updateQuestionValueEntities,
      // updateResponseHasChangedEntities, updateWasChangedEntities, updateRootIdEntities
      // must run before since this function changes bks in which the other functions are dependent
      // if order needs to be changed follow updateContextLocalsEntities example
      ScreenRefreshUtils.updateBusinessKeysEntities(
        body,
        newBody,
        activeTabId,
        indexBodySet,
        bksBySet,
        newIndexBodySet
      );
    });

    return body;
  },
  updateQuestionsWithouConsideringDynamicTable: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string
  ) => {
    body.dynamicQuestionnaire[activeTabId].sets.forEach((bodySet: ISet, bodySetIndex: number) => {
      const newBodySetIndex: number = newBody.dynamicQuestionnaire[activeTabId].sets.findIndex(
        (newBodySet: ISet) =>
          newBodySet.id == body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].id
      );
      bodySet.questions.forEach((bodyQuestion: IQuestion, bodyQuestionIndex: number) => {
        body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].entityIds.forEach(
          (entity: string) => {
            if (
              body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].questions[bodyQuestionIndex]
                .type != 'ENTITY_DATA_TABLE'
            ) {
              if (
                !(
                  body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].questions[
                    bodyQuestionIndex
                  ].wasChanged &&
                  body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].questions[
                    bodyQuestionIndex
                  ].wasChanged[entity]
                )
              ) {
                //change value and contextLocals value
                body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].questions[
                  bodyQuestionIndex
                ].value[entity] =
                  newBody.dynamicQuestionnaire[activeTabId].sets[newBodySetIndex].questions[
                    bodyQuestionIndex
                  ].value[entity];

                const contextLocalsEntity: string =
                  body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].rootId == entity
                    ? 'rootEntity'
                    : entity;

                body.contextLocals[contextLocalsEntity][bodyQuestion.attributeName] =
                  newBody.contextLocals[contextLocalsEntity][bodyQuestion.attributeName];

                if (
                  entity == 'rootEntity' &&
                  Object.keys(body.contextLocals['header']).includes(bodyQuestion.attributeName)
                ) {
                  body.contextLocals['header'][bodyQuestion.attributeName] =
                    newBody.contextLocals['header'][bodyQuestion.attributeName];
                }
              }
            } else {
              body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].questions[
                bodyQuestionIndex
              ].dataTable =
                newBody.dynamicQuestionnaire[activeTabId].sets[newBodySetIndex].questions[
                  bodyQuestionIndex
                ].dataTable;
            }
          }
        );
      });
    });

    return body;
  },
  updateQuestions: (
    body: IScreen,
    newBody: IScreen,
    activeTabId: string,
    bodyCaseHeader: Array<ICaseHeaderAttributes>,
    oldCaseHeader: Array<ICaseHeaderAttributes>,
    oldContextLocals: IContextLocals,
    contextLocalsEntitiesMapNewToOld: { [key: string]: string }
  ) => {
    body.dynamicQuestionnaire[activeTabId].sets.forEach((bodySet: ISet, bodySetIndex: number) => {
      const newBodySetIndex: number = newBody.dynamicQuestionnaire[activeTabId].sets.findIndex(
        (newBodySet: ISet) =>
          newBodySet.id == body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].id
      );
      bodySet.questions.forEach((bodyQuestion: IQuestion, bodyQuestionIndex: number) => {
        body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].entityIds.forEach(
          (entity: string) => {
            const contextLocalsEntity: string =
              body.dynamicQuestionnaire[activeTabId].sets[bodySetIndex].rootId == entity
                ? 'rootEntity'
                : entity;

            const newBodyQuestion: IQuestion | undefined = newBody.dynamicQuestionnaire[
              activeTabId
            ].sets[newBodySetIndex].questions.find((q: IQuestion) => q.id == bodyQuestion.id);
            if (newBodyQuestion) {
              ScreenRefreshUtils.updateQuestion(
                bodyQuestion,
                newBodyQuestion,
                entity,
                contextLocalsEntity,
                body.contextLocals,
                newBody.contextLocals,
                bodyCaseHeader,
                oldCaseHeader,
                oldContextLocals,
                contextLocalsEntitiesMapNewToOld
              );
            }
          }
        );
      });
    });

    return body;
  },
  updateQuestion: (
    bodyQuestion: IQuestion,
    newBodyQuestion: IQuestion,
    entity: string,
    contextLocalsEntity: string,
    bodyContextLocals: IContextLocals,
    newBodyContextLocals: IContextLocals,
    bodyCaseHeader: Array<ICaseHeaderAttributes>,
    oldCaseHeader: Array<ICaseHeaderAttributes>,
    oldContextLocals: IContextLocals,
    contextLocalsEntitiesMapNewToOld: { [key: string]: string }
  ) => {
    if (bodyQuestion.type != 'ENTITY_DATA_TABLE') {
      if (!(bodyQuestion.wasChanged && bodyQuestion.wasChanged[entity])) {
        //change value and contextLocals value
        bodyQuestion.value[entity] = newBodyQuestion.value[entity];
        if (bodyContextLocals[contextLocalsEntity]) {
          bodyContextLocals[contextLocalsEntity][bodyQuestion.attributeName] =
            newBodyContextLocals[contextLocalsEntity][bodyQuestion.attributeName];
        }
        if (
          entity == 'rootEntity' &&
          Object.keys(bodyContextLocals['header']).includes(bodyQuestion.attributeName)
        ) {
          bodyContextLocals['header'][bodyQuestion.attributeName] =
            newBodyContextLocals['header'][bodyQuestion.attributeName];
        }
      } else {
        ScreenRefreshUtils.changeBackCaseHeaderValue(
          bodyQuestion.attributeName,
          bodyCaseHeader,
          oldCaseHeader
        );

        if (contextLocalsEntity == 'rootEntity' || contextLocalsEntity == 'header') {
          bodyContextLocals[contextLocalsEntity][bodyQuestion.attributeName] =
            oldContextLocals[contextLocalsEntity][bodyQuestion.attributeName];
        } else {
          if (bodyContextLocals[contextLocalsEntity]) {
            bodyContextLocals[contextLocalsEntity][bodyQuestion.attributeName] =
              oldContextLocals[contextLocalsEntitiesMapNewToOld[contextLocalsEntity]][
                bodyQuestion.attributeName
              ];
          }
        }
        if (bodyContextLocals['header'][bodyQuestion.attributeName] != undefined) {
          bodyContextLocals['header'][bodyQuestion.attributeName] =
            oldContextLocals['header'][bodyQuestion.attributeName];
        }
      }
    } else {
      bodyQuestion.dataTable = newBodyQuestion.dataTable;
    }
  },
  addNewEntities: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    newBody.dynamicQuestionnaire[activeTabId].sets.forEach(
      (newBodySet: ISet, newBodySetIndex: number) => {
        newBodySet.entityIds.forEach((newBodyEntityId: string, newBodyEntityIdIndex: number) => {
          const bodySet: ISet | undefined = body.dynamicQuestionnaire[activeTabId].sets.find(
            (set: ISet) => set.id == newBodySet.id
          );
          if (bodySet) {
            let exists: boolean = false;
            bodySet.entityIds.forEach((bodyEntityId: string) => {
              if (newBodyEntityId == bodyEntityId) {
                exists = true;
              }
            });
            if (!exists) {
              //at this point, newBodyEntityId is a new entity to add
              // entityds
              bodySet.entityIds.splice(newBodyEntityIdIndex, 0, newBodyEntityId);

              bodySet.questions.forEach((bodyQuestion: IQuestion, bodyQuestionIndex: number) => {
                // valueEntities
                const newValueEntity: Array<string> =
                  newBodySet.questions[bodyQuestionIndex].value[newBodyEntityId];
                bodyQuestion.value[newBodyEntityId] = newValueEntity;
                // responseHasChanged
                const responseHasChangedEntity: boolean =
                  newBodySet.questions[bodyQuestionIndex].responseHasChanged[newBodyEntityId];
                bodyQuestion.responseHasChanged[newBodyEntityId] = responseHasChangedEntity;
                // wasChanged doesnt need to be added, doesnt come from backend
              });

              //    businessKeys
              const newBks = newBodySet.businessKeys.find(
                (bks: IBusinessKeysMap) => bks.entityId == newBodyEntityId
              );
              if (newBks) {
                bodySet.businessKeys.splice(newBodyEntityIdIndex, 0, newBks);
              }
            }
          }
        });
      }
    );

    Object.keys(newBody.contextLocals).forEach((newBodyEntityId) => {
      if (
        !Object.keys(body.contextLocals).some(
          (bodyEntityId: string) => bodyEntityId == newBodyEntityId
        )
      ) {
        body.contextLocals[newBodyEntityId] = newBody.contextLocals[newBodyEntityId];
      }
    });

    return body;
  },
  removeDeletedEntities: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    body.dynamicQuestionnaire[activeTabId].sets.forEach((bodySet: ISet) => {
      bodySet.entityIds.forEach((bodyEntityId: string) => {
        const newBodySet = newBody.dynamicQuestionnaire[activeTabId].sets.find(
          (set: ISet) => set.id == bodySet.id
        );
        if (newBodySet) {
          let exists: boolean = false;
          newBodySet.entityIds.forEach((newBodyEntityId: string) => {
            if (bodyEntityId == newBodyEntityId) {
              exists = true;
            }
          });
          if (!exists) {
            //at this point, BodyEntityId is a new entity to add

            // entityIds
            bodySet.entityIds = bodySet.entityIds.filter(
              (entityId: string) => entityId != bodyEntityId
            );

            bodySet.questions.forEach((bodyQuestion: IQuestion) => {
              // valueEntities
              delete bodyQuestion.value[bodyEntityId];
              // responseHasChanged
              delete bodyQuestion.responseHasChanged[bodyEntityId];
              // wasChanged
              if (bodyQuestion.wasChanged) {
                delete bodyQuestion.wasChanged[bodyEntityId];
              }
            });

            //    rootId cannot be removed

            //    businessKeys
            bodySet.businessKeys.filter((bks: IBusinessKeysMap) => bks.entityId != bodyEntityId);

            //    contextLocals
            delete body.contextLocals[bodyEntityId];
          }
        }
      });
    });
    return body;
  },
  addNewSets: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    newBody.dynamicQuestionnaire[activeTabId].sets.forEach(
      (newBodySet: ISet, bodySetIndex: number) => {
        if (
          !body.dynamicQuestionnaire[activeTabId].sets.some((set: ISet) => set.id == newBodySet.id)
        ) {
          body.dynamicQuestionnaire[activeTabId].sets.splice(bodySetIndex, 0, newBodySet);
        }
      }
    );
    return body;
  },
  removeDeletedSets: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    body.dynamicQuestionnaire[activeTabId].sets.forEach((bodySet: ISet, bodySetIndex: number) => {
      if (
        !newBody.dynamicQuestionnaire[activeTabId].sets.some((set: ISet) => set.id == bodySet.id)
      ) {
        body.dynamicQuestionnaire[activeTabId].sets.splice(bodySetIndex, 1);
      }
    });
    return body;
  },
  changeBackCaseHeaderValue: (
    bodyQuestionAttributeName: string,
    bodyCaseHeader: Array<ICaseHeaderAttributes>,
    oldCaseHeader: Array<ICaseHeaderAttributes>
  ) => {
    const findPredicate = (caseheader: ICaseHeaderAttributes) =>
      caseheader.attributeName == bodyQuestionAttributeName;
    const bodyCaseHeaderAttribute = bodyCaseHeader.find(findPredicate);
    const oldCaseHeaderAttribute = oldCaseHeader.find(findPredicate);
    if (bodyCaseHeaderAttribute && oldCaseHeaderAttribute) {
      bodyCaseHeaderAttribute.attributeValue = oldCaseHeaderAttribute.attributeValue;
    }
  },
  contextLocalsEntitiesMapNewToOld: (body: IScreen, newBody: IScreen, activeTabId: string) => {
    const localsMap: { [key: string]: string } = {};

    newBody.dynamicQuestionnaire[activeTabId].sets.forEach((newBodySet: ISet) => {
      newBodySet.entityIds.forEach((newBodyEntity: string) => {
        const bodySet = body.dynamicQuestionnaire[activeTabId].sets.find(
          (set: ISet) => set.id == newBodySet.id
        );
        const oldBks = bodySet?.businessKeys;
        if (oldBks) {
          const oldEntity = GeneralUtils.getNewEntityId(
            newBodyEntity,
            newBodySet.businessKeys,
            oldBks
          )[0];
          localsMap[newBodyEntity] = oldEntity;
        }
      });
    });

    return localsMap;
  },
  table: screenRefreshTableUtils,
};

export default ScreenRefreshUtils;
