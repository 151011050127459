import { Tabs } from '@mui/material';
import React, { useState } from 'react';
import DefaultModal from '../modal/Modal';
import { useTranslation } from 'react-i18next';
import './TabsComponent.scss';
import { ITabsProps } from '../../../utils/entities/genericComponents/ITabsComponent';
import ITab from '../../../utils/entities/screen/ITab';

const TabsComponent = (props: ITabsProps) => {
  const {
    activeTabId,
    className,
    tabs,
    tabsMap,
    checkConfirmationModal,
    tabChanged,
    needsConfirmation,
    setModalConfirmationClick,
    customTabClick,
  } = { ...props };

  const [activeTab, setActiveTab] = useState<ITab[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const tabClickFunction = (newValue: string) => {
    const changedTab = tabs.filter((tab: ITab) => tab.tabId === activeTabId);
    const selectedTab = tabs.filter((tab: ITab) => tab.tabId === newValue);
    setActiveTab(selectedTab);

    if (needsConfirmation && changedTab[0].tabId === activeTabId) {
      if (changedTab[0].confirmationModal && checkConfirmationModal) {
        //If confirmationModal and the "Yes" button in that modal was clicked. The modal will appear when we change tabs
        setShowConfirmationModal(true);
      } else {
        changeTab(selectedTab);
      }
    }
  };

  const changeTab = (selectedTab: ITab[]) => {
    selectedTab[0].isActive = true;
    tabChanged(selectedTab[0].tabId);
  };

  const handleTabsChange = (event: React.SyntheticEvent, newValue: string) => {
    tabClickFunction(newValue);
    customTabClick?.(event, newValue);
  };

  return (
    <>
      {needsConfirmation && (
        <DefaultModal
          open={showConfirmationModal}
          className="confirmation-modal"
          title="Confirm"
          body={<p>{t('SAVE_CHANGES')}</p>}
          setConfirm={() => {
            changeTab(activeTab);
            setShowConfirmationModal(false);
            setModalConfirmationClick?.(true);
          }}
          onClose={() => {
            changeTab(activeTab);
            setShowConfirmationModal(false);
            setModalConfirmationClick?.(false);
          }}
          confirmText={t('YES')}
          denyText={t('NO')}
        />
      )}
      <Tabs
        value={activeTabId}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="nav tabs"
        className={`tabs ${className ?? ''}`}>
        {tabsMap}
      </Tabs>
    </>
  );
};

export default TabsComponent;
