import moment from 'moment';
import 'moment-timezone';
import { CONSTANTS } from '../constants/constants';
import IDateUtils from '../entities/functions/IDateUtils';
import LocalStorageUtils from './localStorageUtils';

const dateUtils: IDateUtils = {
  getDateFromDateTime: (caseCreatedOn: string) => {
    return caseCreatedOn?.split('T')[0];
  },
  getDateFormatFromDateFormat: (dateFormat: string) => {
    return dateFormat.slice(0, 10);
  },
  getTimeFormatFromDateFormat: (dateFormat: string) => {
    return dateFormat.slice(11) || CONSTANTS.DATES.DEFAULT.TIME_FORMAT;
  },
  addDays: (date: string, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  },
  addMonths: (date: string, months: number) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  },
  addYears: (date: string, years: number) => {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + years);
    return newDate;
  },
  getLastDate: (backDate: number) => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() - backDate).toString();
  },
  /**
   * This function is responsible to formate date in yyyy-mm-dd
   * @param stringDate
   */
  getDateFormatedDateForDateTimePicker: (stringDate: string) => {
    if (!stringDate) return null;
    const today = new Date(stringDate);
    const yyyy = today.getFullYear();
    let mm: string | number = today.getMonth() + 1; // Months start at 0!
    let dd: string | number = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return yyyy + '-' + mm + '-' + dd;
  },
  /**
   * This function is responsible to formate date in DD/MM/YYYY
   * @param stringDate
   */
  getDateFormatedDateForDashboard: (stringDate: string) => {
    if (!stringDate) return null;
    const today = new Date(stringDate);
    const yyyy = today.getFullYear();
    let mm: string | number = today.getMonth() + 1; // Months start at 0!
    let dd: string | number = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '/' + mm + '/' + yyyy;
  },
  /**
   * This function will convert the YYYY-MM-DD to MM-DD-YYYY string value including all Timezone
   * @param inputDate (YYYY-MM-DD type value)
   * @returns (MM-DD-YYYY type value)
   */
  formatToMMddYYYY: (inputDate: string): string => {
    const date = new Date(inputDate + 'T00:00:00');
    if (!isNaN(date.getTime())) {
      return new Intl.DateTimeFormat().format(date);
    }
    return '';
  },
  getDateFormatToDatePicker: (date: string | undefined) => {
    if (date) {
      const newDate = moment(new Date(date)).format('YYYY-MM-DD');
      return newDate === CONSTANTS.DATES.INVALID_DATE ? undefined : newDate;
    }
    return undefined;
  },
  getDateFormatToDatePickerByEpcoh: (epoch: number | undefined) => {
    return epoch && epoch !== 0
      ? dateUtils.getDateFormatToDatePicker(dateUtils.epochToDate(epoch).toString())
      : undefined;
  },
  /**
   * This function will convert stringDate to dd MMM yyyy formate EX: 19 Feb 2022
   * @param stringDate
   */
  getFormatedDate: (stringDate: string) => {
    const date = new Date(stringDate);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  },
  timeStampToDate: (timeStamp_value: number) => {
    const theDate = new Date(timeStamp_value * 1000);
    const mm = theDate.getMonth() + 1; // getMonth() is zero-based
    const dd = theDate.getDate();
    return [(mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, theDate.getFullYear()].join('/');
  },
  timeZone: (timeStamp_value: number, timeZone: string, dateFormate?: string) => {
    const theDate = new Date(timeStamp_value * 1000);
    if (timeStamp_value && timeZone && dateFormate) {
      return moment.tz(theDate, timeZone).format(dateFormate);
    }
    if (timeStamp_value && timeZone) {
      return moment.tz(theDate, timeZone).format(CONSTANTS.DATES.DOCUMNET_DATE_FORMAT);
    }
  },
  timeStampWithSeconds: (timeStamp_value: number | string, timeZone: string) => {
    if (timeStamp_value && timeZone) {
      const format = CONSTANTS.DATES.FORMAT_TIME;
      return moment.utc(timeStamp_value, format).tz(timeZone).format(CONSTANTS.DATES.FORMAT_TIME);
    }
  },
  timeStampWithDate: (timeStamp_value: number | string | undefined, timeZone: string) => {
    if (timeStamp_value && timeZone) {
      return moment
        .utc(timeStamp_value)
        .tz(timeZone)
        .format(CONSTANTS.DATES.DEFAULT_DATE_FORMAT_WITH_TIME);
    }
  },
  epochToDate: (epoch: number) => {
    const date = new Date(0);
    date.setUTCSeconds(epoch);
    return date;
  },
  epochToDateFormated: (epoch: number, format: string, timeZone: string) => {
    return dateUtils.dateFormating(
      dateUtils
        .epochToDate(epoch)
        .toLocaleString(CONSTANTS.DATES.DATE_FORMAT, { timeZone: timeZone }),
      format
    );
  },
  dateToEpoch: (dateString: string | null | undefined, timeZone: string) => {
    if (dateString) {
      const date = new Date(dateString);
      date.setHours(23);
      date.setMinutes(59);
      date.setSeconds(59);

      const utcDate = new Date(
        date.toLocaleString(CONSTANTS.DATES.DATE_FORMAT, { timeZone: 'UTC' })
      );
      const tzDate = new Date(
        date.toLocaleString(CONSTANTS.DATES.DATE_FORMAT, { timeZone: timeZone })
      );
      const offset = utcDate.getTime() - tzDate.getTime();
      date.setTime(date.getTime() + offset);

      return Date.parse(date.toString()) / 1000;
    }
    return 0;
  },
  //to use in case audit to output the day on 00h 00min 00s
  dateFormatToEpoch: (stringDate: string) => {
    const date = new Date(stringDate);
    const result = date.getTime() / 1000;
    return String(result);
  },
  dateFormating: (dateString: string, format?: string, timeString?: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const time = timeString ?? `${hours}:${minutes}`;
    const sTime = timeString ?? `${hours}:${minutes}:${seconds}`;

    let formattedDate = '';
    switch (format) {
      case 'MM/DD/YYYY':
        formattedDate = `${month}/${day}/${year}`;
        break;
      case 'DD/MM/YYYY':
        formattedDate = `${day}/${month}/${year}`;
        break;
      case 'YYYY/MM/DD':
        formattedDate = `${year}/${month}/${day}`;
        break;
      case 'MM/DD/YYYY HH:mm':
        formattedDate = `${month}/${day}/${year} ${time}`;
        break;
      case 'DD/MM/YYYY HH:mm':
        formattedDate = `${day}/${month}/${year} ${time}`;
        break;
      case 'YYYY/MM/DD HH:mm':
        formattedDate = `${year}/${month}/${day} ${time}`;
        break;
      case 'YYYY/MM/DD HH:mm:ss':
        formattedDate = `${year}/${month}/${day} ${sTime}`;
        break;
      case 'MM/DD/YYYY HH:mm:ss':
        formattedDate = `${month}/${day}/${year} ${sTime}`;
        break;
      default:
        formattedDate = dateString; // If the format is not recognized, use the original date string
        break;
    }

    return formattedDate;
  },
  dateFormatToGeneric: (dateString: string, format: string) => {
    return moment(dateString, format).format();
  },
  getDateText: (date: string, dateFormat: string): string =>
    date &&
    dateUtils.dateFormating(
      dateUtils.timeStampWithDate(
        date,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
      ) as string,
      dateFormat
    ),
};

export default dateUtils;
