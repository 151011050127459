import { AxiosRequestConfig } from 'axios';
import { API_CONSTANTS } from '../utils/constants/api_constants';

const defaultHeaders = {
  'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
  'X-XSS-Protection': '1, mode=block',
  'X-Content-Type-Options': 'nosniff',
  'Content-Security-Policy': "default-src 'self'",
};

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${API_CONSTANTS.API_GATEWAY}`,
  // baseURL: `http://localhost:8080/execute`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    ...defaultHeaders,
  },
  withCredentials: true,
};

export const axiosConfigAuth: AxiosRequestConfig = {
  baseURL: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${API_CONSTANTS.AUTHENTICATION_SERVICE}`,
  // baseURL: `http://localhost:8081`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    ...defaultHeaders,
  },
  withCredentials: true,
};

export const axiosConfigScan: AxiosRequestConfig = {
  baseURL: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${API_CONSTANTS.API_GATEWAY}`,
  // baseURL: `http://localhost:8080/execute`,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...defaultHeaders,
  },
  withCredentials: true,
};

export const axiosBlob: AxiosRequestConfig = {
  ...axiosConfig,
  responseType: 'blob',
};

export default axiosConfig;
