import React from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import { ITag, ITagsContainer } from '../../../utils/entities/genericComponents/tag/ITag';
import Tag from './Tag';

const TagsContainer = ({ tags, tagsCount, handleDelete, showDeleteButton }: ITagsContainer) => {
  return (
    <div className="align-vertical-center">
      {tags?.map((tag: ITag, i: number) => {
        if (i < tagsCount) {
          return (
            <Tag
              showTooltip
              tag={tag}
              key={tag.index}
              handleDelete={
                tag.method === CONSTANTS.MANUAL && showDeleteButton ? handleDelete : undefined
              }
            />
          );
        }
      })}
    </div>
  );
};

export default TagsContainer;
