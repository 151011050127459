import React, { useEffect, useState } from 'react';
import { CircularProgress, Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/icons/logo.png';
import { useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../../utils/constants/constants';
import IErrorPageProps from '../../utils/entities/pages/error-pages/IErrorPage';

const ErrorPage = ({ toRedirect, pageType, history }: IErrorPageProps) => {
  const { t } = useTranslation();
  const navigate = toRedirect ? useNavigate() : null;

  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');

  const countdown = () => {
    let timer = 5;

    const countdownInterval = setInterval(function () {
      timer--;

      if (timer <= 0) {
        clearInterval(countdownInterval);
        if (history) {
          history.back();
        } else {
          navigate && navigate(CONSTANTS.PAGES_URL.INBOX);
        }
      }
    }, 1000);
  };

  useEffect(() => {
    switch (pageType) {
      case '404':
        setTitle(t('ERROR_PAGE.PAGE_NOT_EXIST.TITLE'));
        setSubTitle('');
        break;
      case 'unauthorized':
        setTitle(t('ERROR_PAGE.UNAUTHORIZE.TITLE'));
        setSubTitle(t('ERROR_PAGE.UNAUTHORIZE.SUB_TITLE'));
        break;
      case 'login':
        setTitle('');
        setSubTitle(t('ERROR_PAGE.LOGIN.SUB_TITLE'));
        break;
      case 'error':
        setTitle(t('ERROR_PAGE.ERROR.TITLE'));
        setSubTitle('');
        break;
    }

    if (toRedirect) {
      countdown();
    }
  }, []);

  return (
    <Box className="unauthorize">
      <Container className="margin-top-25">
        <Box
          style={{
            color: 'white',
            fontSize: 'xx-large',
            textAlign: 'center',
            marginBottom: '50px',
          }}>
          <img src={Logo} alt="logo" style={{ height: '100px' }} />
        </Box>

        <Box style={{ color: 'white', textAlign: 'center' }}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold' }}>
            {title}
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
            {subTitle}
          </Typography>

          {toRedirect && (
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              {t('ERROR_PAGE.REDIRECT')}
            </Typography>
          )}

          {(toRedirect || pageType === 'login') && (
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              <CircularProgress sx={{ color: '#ffe600' }} />
            </Typography>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ErrorPage;
