import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../utils/constants/constants';
import { Box, DialogActions, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import AssignCaseComponent from '../assign-case-roles/AssignCaseRoles';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import './AssignCaseModal.scss';
import PrimaryButton from '../../generic/buttons/PrimaryButton';
import { addAlert } from '../../../store/actions/alerts.actions';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import {
  IAssignCaseModalProps,
  ICaseRolesDiff,
} from '../../../utils/entities/case/CaseCreation/IAssignCaseModal';
import ObjectUtils from '../../../utils/functions/objectUtils';
import ICaseRoles from '../../../utils/entities/case/ICaseRoles';
import { sendRTJRequestsDataBlock } from '../../../service/action-handler.service';
import ArrayUtils from '../../../utils/functions/arrayUtils';
import Loader from '../../loader/Loader';
import { IAccessToken } from '../../../utils/entities/authentication';

const AssignCaseModal = ({
  isOpen,
  closeDlg,
  caseId,
  originalCaseRoles,
  assignedCaseRoles,
  setAssignedCaseRoles,
  isCaseRolesEditable,
  isCaseRolesSelfEditable,
  isCaseRolesAdmin,
}: IAssignCaseModalProps) => {
  const { t } = useTranslation();
  const tenant: string = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const [rolesReady, setRolesReady] = useState<boolean>(false);
  const [acceptedRequests, setAcceptedRequests] = useState<string[]>([]);
  const [deniedRequests, setDeniedRequests] = useState<string[]>([]);
  const [showMainLoader, setShowMainLoader] = useState<boolean>(false);

  const getRolesDiff = (): ICaseRolesDiff => {
    const caseRolesToAdd: ICaseRoles[] = [];
    const caseRolesToRemove: ICaseRoles[] = [];

    for (const assignedCaseRole of assignedCaseRoles) {
      const original = originalCaseRoles.find((role) => role.name === assignedCaseRole.name);
      if (original?.users.length) {
        const { added, removed } = ArrayUtils.getArrayDifference(
          original.users,
          assignedCaseRole.users
        );
        if (added.length) {
          const originalAdded = { ...original };
          originalAdded.users = added;
          caseRolesToAdd.push(originalAdded);
        }
        if (removed.length) {
          const originalRemoved = { ...original };
          originalRemoved.users = removed;
          caseRolesToRemove.push(originalRemoved);
        }
      } else {
        caseRolesToAdd.push(assignedCaseRole);
      }
    }
    return { caseRolesToAdd, caseRolesToRemove };
  };

  const handleSubmit = () => {
    if (
      !acceptedRequests.length &&
      !deniedRequests.length &&
      ObjectUtils.compareObjects(originalCaseRoles, assignedCaseRoles)
    ) {
      addAlert({
        type: 'info',
        primaryText: t('CASE_ROLES.SAVE_NO_CHANGES'),
      });
    } else {
      const { caseRolesToAdd, caseRolesToRemove } = getRolesDiff();
      setShowMainLoader(true);
      sendRTJRequestsDataBlock({
        dataBlock: {
          caseId: caseId,
          tenant: tenant,
          source: {
            name: 'null',
            type: 'null',
            category: null,
            evidenceDocument: null,
          },
          caseData: {
            caseRolesToAdd,
            caseRolesToRemove,
          },
          taskDetails: {
            caseId: caseId,
            draft: true,
            assignee: userInfo.user,
          },
        },
        approvedIds: acceptedRequests,
        deniedIds: deniedRequests,
      })
        .then(() => {
          setShowMainLoader(false);
          addAlert({
            type: 'success',
            primaryText: t('CASE_ROLES.ASSIGN_ROLE_SUCCESS'),
          });
        })
        .catch(() => {
          addAlert({
            type: 'error',
            primaryText: t('ERROR_MESSAGE'),
          });
          setShowMainLoader(false);
        })
        .finally(() => closeDlg());
    }
  };

  return (
    <>
      {showMainLoader && <Loader isMainLoader />}
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={isOpen}
        onClose={() => closeDlg()}
        className="assign-case-modal">
        <DialogTitle id="confirm-dialog">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>{t('CASE_ROLES.CASE_ROLES')}</Box>
            <Box>
              <IconButton onClick={() => closeDlg()}>
                <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers className="show-scroll">
          <AssignCaseComponent
            isCaseRolesEditable={isCaseRolesEditable}
            isCaseRolesSelfEditable={isCaseRolesSelfEditable}
            assignedCaseRoles={assignedCaseRoles}
            onChangeAssignedCaseRoles={(assignedCaseRoles: ICaseRoles[]) =>
              setAssignedCaseRoles(assignedCaseRoles)
            }
            setRolesReady={setRolesReady}
            isCaseRolesAdmin={isCaseRolesAdmin}
            caseId={caseId}
            acceptedRequests={acceptedRequests}
            setAcceptedRequests={setAcceptedRequests}
            deniedRequests={deniedRequests}
            setDeniedRequests={setDeniedRequests}
          />
        </DialogContent>
        <DialogActions className="upload-dialog-action">
          <PrimaryButton
            text={t('ASSIGN')}
            onClick={handleSubmit}
            isDisabled={!rolesReady}
            isPositiveButton
          />
          <PrimaryButton text={t('CANCEL')} onClick={closeDlg} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignCaseModal;
