import { Button } from '@mui/material';
import React from 'react';
import IDarkButtonProps from '../../../utils/entities/genericComponents/button/dark-button.ts/IDarkButton';

const DarkButton = ({ text, onClick, isDisabled, formId, classes, endIcon }: IDarkButtonProps) => {
  return (
    <Button
      data-testid="dark-button"
      type="button"
      onClick={onClick}
      form={formId}
      variant="contained"
      color="secondary"
      disableElevation
      className={`text-transform-none border-radius-0 ${classes ?? ''}`}
      disabled={isDisabled}
      endIcon={endIcon}>
      {text}
    </Button>
  );
};

export default DarkButton;
