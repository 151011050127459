import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IGenerateAndTimeStampProps } from '../../../utils/entities/narrative';
import EpochToDateText from '../../generic/timer/EpochToDateText';

const GenerateAndTimeStamp = (props: IGenerateAndTimeStampProps): JSX.Element => {
  const { openedNarrativeVersion } = { ...props };
  const { t } = useTranslation();

  return (
    <div className="box-label-value">
      <Typography className="bold-font header-title font-size-12">{t('LAST_UPDATE')}</Typography>
      <Typography className="font-size-12">
        {openedNarrativeVersion && (
          <EpochToDateText epoch={openedNarrativeVersion?.narrativeGeneratedTime} />
        )}
      </Typography>
    </div>
  );
};
export default GenerateAndTimeStamp;
