import React from 'react';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EpochToDateText from '../../../../../generic/timer/EpochToDateText';
import './SourceCard.scss';
import ISourceCardProps from '../../../../../../utils/entities/id-and-v/ISourceCard';
import LabeledInput from '../../../../../generic/inputs/input-label/LabeledInput';

const SourceCard = ({ source }: ISourceCardProps) => {
  const { t } = useTranslation();

  return (
    <Card className="card-case">
      <CardContent>
        <LabeledInput title={`${t('SOURCE.SOURCE_NAME')}`} value={source.name} />
        <LabeledInput title={`${t('SOURCE.SOURCE_TYPE')}`} value={source.type} />
        <LabeledInput
          title={`${t('SOURCE.INGESTION_DATE')}`}
          value={<EpochToDateText epoch={source.ingestionDate} />}
        />
        {source.ingestionBy && (
          <LabeledInput title={`${t('SOURCE.INGESTION_BY')}`} value={source.ingestionBy} />
        )}
        {source.value && <LabeledInput title={`${t('SOURCE.VALUE')}`} value={source.value} />}
      </CardContent>
    </Card>
  );
};

export default SourceCard;
