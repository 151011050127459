import { Chip } from '@mui/material';
import React from 'react';
import { ITagProps } from '../../../utils/entities/genericComponents/tag/ITag';
import TooltipComp from '../tooltip/Tooltip';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const Tag = ({ tag, showTooltip, handleDelete }: ITagProps) => {
  const nameMaxLength = 11;

  return (
    <Chip
      label={
        showTooltip && tag.name.length > nameMaxLength ? (
          <TooltipComp label={tag.name} />
        ) : (
          <span className="ellipsis">{tag.name}</span>
        )
      }
      className="fc-nexus-chip"
      deleteIcon={<ClearOutlinedIcon />}
      onDelete={handleDelete ? () => handleDelete(tag) : undefined}
    />
  );
};

export default Tag;
