import React, { useEffect, useState, ReactElement } from 'react';
import { Grid, List, ListItem } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ISet from '../../utils/entities/screen/ISet';
import IQuestionnaire from '../../utils/entities/screen/IQuestionnaire';
import IContextLocals from '../../utils/entities/screen/IContextLocals';
import SpelUtils from '../../utils/functions/spelUtils';
import { CONSTANTS } from '../../utils/constants/constants';
import TooltipComp from '../generic/tooltip/Tooltip';
import { IAccessToken } from '../../utils/entities/authentication';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import BadgeComponent from '../generic/badge/BadgeComponent';
import { colorType } from '../../types/layout/colorType';
import ITaskQuestionSetsProps from '../../utils/entities/screen/tasks/dynamic-questionnaire/ITaskQuestionSets';
import { useIScreen } from '../../pages/task/Task';

const TaskQuestionSets = (props: ITaskQuestionSetsProps) => {
  const {
    dynamicQuestionnaire,
    setIndex,
    setSetIndex,
    selectedEntity,
    setSelectedEntity,
    contextLocals,
    getSetTotalUnansweredCount,
    checkSetVisibilty,
    setSelectedEntityIndex,
    selectedEntityIndex,
    setIsSetEntityChanged,
    isSetEntityChanged,
    badgeHighlight,
  } = { ...props };

  const { sseActivate, setSSEActivate } = useIScreen();

  const [questionnaire, setQuestionnaire] = useState<IQuestionnaire>(dynamicQuestionnaire);
  const [visibleSetIndex, setVisibleSetIndex] = useState<number>(setIndex);

  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  useEffect(() => {
    if (sseActivate) {
      setVisibleSetIndex(setIndex);
    }
  }, [setIndex]);

  useEffect(() => {
    if (sseActivate) {
      setSSEActivate(false);
    }
    setQuestionnaire(dynamicQuestionnaire);
  }, [dynamicQuestionnaire]);

  const calculateSetIndex = (i: number) => {
    return questionnaire.sets.findIndex((s: ISet) => s.id == visibleSets()[i].id);
  };

  const changeSetHandler = (newIndex: number) => {
    const index = calculateSetIndex(newIndex);
    setVisibleSetIndex(newIndex);
    if (questionnaire !== undefined && setIndex != index) {
      setSetIndex(index);
      changeEntityHandler(questionnaire.sets[index].entityIds[0], index);
    }
  };

  const changeEntityHandler = (newEntity: string, set: number) => {
    if (selectedEntity != newEntity) {
      const index = questionnaire.sets[set].entityIds.findIndex((entity) => entity === newEntity);
      setSelectedEntity(newEntity);
      setSelectedEntityIndex(index);
      setIsSetEntityChanged(true);
    }
  };
  const visibleSets = () =>
    questionnaire.sets.filter(
      (set: ISet) =>
        checkSetVisibilty(set) &&
        set.questions.some((q) =>
          set.entityIds.some((entityId) => {
            return SpelUtils.expressionValidation(q.visible, q.visibilityExpression, {
              contextLocals: contextLocals,
              userRole: userInfo.role,
              ...contextLocals[set.root && entityId == set.rootId ? 'rootEntity' : entityId],
            });
          })
        )
    );

  const getBadgeColor = (set: ISet, currentIndex: number): colorType => {
    if (badgeHighlight) {
      return getSetTotalUnansweredCount(set, currentIndex) == 0 ? 'secondary' : 'error';
    }
    return 'secondary';
  };

  return (
    <>
      {questionnaire && (
        <Grid item xs={3} className="task-question-sets">
          <List>
            {visibleSets().map((set: ISet, currentIndex: number) => {
              return (
                <ListItem key={'container-' + currentIndex}>
                  <div key={'inner-container-' + currentIndex}>
                    <ListItemButton
                      key={currentIndex}
                      onClick={() => {
                        changeSetHandler(currentIndex);
                      }}
                      className={visibleSetIndex == currentIndex ? 'active' : ''}>
                      <ListItemText
                        primaryTypographyProps={{ className: 'font-display' }}
                        primary={set.name}
                      />
                      <BadgeComponent
                        badgeContent={Number(getSetTotalUnansweredCount(set, currentIndex))}
                        color={getBadgeColor(set, currentIndex)}
                      />
                    </ListItemButton>
                    <Collapse
                      key={'child-' + currentIndex}
                      component="div"
                      in={visibleSetIndex == currentIndex}
                      timeout="auto"
                      unmountOnExit>
                      <List disablePadding>
                        {set.entityIds.map((entityId: string, index: number) => (
                          <React.Fragment key={index}>
                            {set.questions.some((q) =>
                              SpelUtils.expressionValidation(q.visible, q.visibilityExpression, {
                                contextLocals: contextLocals,
                                userRole: userInfo.role,
                                ...contextLocals[
                                  set.root && entityId == set.rootId ? 'rootEntity' : entityId
                                ],
                              })
                            ) && (
                              <ListItemButton
                                key={index}
                                onClick={() => {
                                  changeEntityHandler(entityId, currentIndex);
                                }}
                                className={selectedEntity == entityId ? 'active' : ''}>
                                <ListItemText
                                  key={index}
                                  primaryTypographyProps={{
                                    className:
                                      selectedEntity == entityId
                                        ? 'entity-el font-nest-display'
                                        : 'entity-el',
                                  }}
                                  primary={((): string | ReactElement => {
                                    let entity = entityId;
                                    if (entityId == set.rootId) {
                                      entity = 'rootEntity';
                                    }
                                    if (
                                      contextLocals[entity] &&
                                      contextLocals[entity][set.attributeDisplayName]
                                    ) {
                                      return (
                                        <TooltipComp
                                          label={
                                            props.contextLocals[entity][
                                              set.attributeDisplayName
                                            ] as unknown as string
                                          }
                                          bigEllipsis={true}
                                        />
                                      );
                                    }
                                    return (
                                      <TooltipComp label={set.entitySubType + ' ' + (index + 1)} />
                                    );
                                  })()}
                                />
                              </ListItemButton>
                            )}
                          </React.Fragment>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      )}
    </>
  );
};

export default TaskQuestionSets;
