import React from 'react';
import './Loader.scss';
import { Box, CircularProgress } from '@mui/material';
import ILoaderProps from '../../utils/entities/loader/ILoader';

const Loader = ({ isMainLoader }: ILoaderProps) => {
  return (
    <Box data-testid="loader" className={`loader ${isMainLoader ? 'main' : ''}`}>
      <CircularProgress />
    </Box>
  );
};

export default Loader;
