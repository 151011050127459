import { Badge } from '@mui/material';
import React from 'react';
import IBadgeProps from '../../../utils/entities/genericComponents/badge/IBadge';

const BadgeComponent = (props: IBadgeProps) => {
  const { badgeContent, maxCount, color } = props;

  return (
    <Badge
      data-testid="badge-component"
      color={color}
      badgeContent={badgeContent}
      max={maxCount ?? 999}
      showZero
    />
  );
};

export default BadgeComponent;
