import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import English from '../../assets/locales/en/lang.json';
import French from '../../assets/locales/fr/lang.json';

const resources = {
  English,
  French,
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: 'English',
    defaultNS: 'translations',
    fallbackLng: 'English',
  })
  .then();
