import { TableCell } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITableHeaderProps } from '../../../../utils/entities/document-center';

const TableHeader = (props: ITableHeaderProps) => {
  const { headers, documentConfigurations } = { ...props };
  const { t } = useTranslation();

  return (
    <>
      {headers.map((col, i) => (
        <TableCell key={`${col.label}_${i}`} data-testid={`column-header-${i}`} align="center">
          {col.label === 'VIRUS_SCAN' &&
            documentConfigurations.find((config) => config.field === 'virusScan')?.enabled &&
            t(col.label)}
          {col.label !== 'VIRUS_SCAN' && t(col.label)}
          {documentConfigurations.find((config) => config.field === col.value)?.required && '*'}
        </TableCell>
      ))}
    </>
  );
};

export default TableHeader;
