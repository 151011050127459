import React, { ChangeEvent } from 'react';
import { TextField } from '@mui/material';
import { IMetadataInputs } from '../../../../utils/entities/meta-data/IMetaDataInput';

const TextMetadata = (props: IMetadataInputs) => {
  const { attribute, showLabel, valueUpdated } = { ...props };

  const onFilterTextBoxChanged = (e: ChangeEvent<HTMLInputElement>) => {
    attribute.value = e.target.value;
    valueUpdated(e);
  };

  return (
    <TextField
      variant="standard"
      fullWidth
      required={attribute.required}
      label={showLabel ? attribute.title : ''}
      name={attribute.name}
      value={attribute.value ?? ''}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterTextBoxChanged(e)}
      type={attribute.type}
      disabled={attribute.disabled}
    />
  );
};

export default TextMetadata;
