import React from 'react';
import { IAutocompleteServerProps } from '../../../../utils/entities/genericComponents/autocomplete/IAutocompleteServer';
import AutocompleteComponent from './AutocompleteComponent';
import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import '../autocomplete/AutocompleteComponent.scss';

const AutocompleteServerComponent = (props: IAutocompleteServerProps) => {
  const {
    multiple,
    disabled,
    options,
    value,
    setData,
    required,
    getOptionLabel,
    setInputValue,
    noOptionsText,
    groupBy,
    open,
    onClose,
    isLoading,
    autocompleteRef,
  } = {
    ...props,
  };
  const { t } = useTranslation();

  return (
    <AutocompleteComponent
      autocompleteRef={autocompleteRef}
      clearOnBlur={false}
      placeholder={t('SEARCH_3_DOTS')}
      formClasses={'align-self-end autocomplete-server-component'}
      renderOptionsType={'serverside'}
      isLoading={isLoading}
      open={open}
      onClose={onClose}
      options={options}
      value={value}
      setData={setData}
      setInputValue={setInputValue}
      getOptionLabel={getOptionLabel}
      noOptionsText={noOptionsText}
      groupBy={groupBy}
      required={required}
      disabled={disabled}
      multiple={multiple}
      icon={<SearchIcon />}
    />
  );
};

export default AutocompleteServerComponent;
