import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  IRequest,
  TRClearQAFlagRequest,
  GenerateTRReportRequest,
  GenerateIRReportRequest,
  GetReportforIRRequest,
  GenerateAVReportRequest,
  DeleteIRSCreenshotsRequest,
  IComment,
  ICaseParty,
} from '../utils/entities/dueDiligence';
import { IAddPartyBody } from '../utils/entities/screen/IAddPartyBody';
import { IAddRemoveParty } from '../utils/entities/screen/IAddRemoveParty';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/functions/serviceUtils';
import { API_CONSTANTS } from '../utils/constants/api_constants';

export const getAllSearchRequestsByCase = (caseId: string): Promise<Array<IRequest>> =>
  axios
    .get(`/getAllDueDiligenceRequests/${encodeURIComponent(caseId)}`, axiosConfig)
    .then((res: AxiosResponse<Array<IRequest>>) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

/**
 * This API will return the list of articles and there detail
 * @param id
 */
export const getRequestsById = (id: number | string) =>
  axios
    .get(`/getDueDiligenceRequestById/${encodeURIComponent(id ? id.toString() : '')}`, axiosConfig)
    .then((res: AxiosResponse<IRequest>) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getAllDueDiligenceRequests = (
  caseID: string,
  apiName: string
): Promise<Array<IRequest>> =>
  axios
    .get(
      `/getDueDiligenceRequestsByCaseIdAndApiName/${encodeURIComponent(
        caseID
      )}/${encodeURIComponent(apiName)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IRequest>>) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const createRequest = (reqBody: IRequest) =>
  axios
    .post(`/createDueDiligenceRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      if (res.status == 500) {
        console.error('Error Creating a Request : ', res);
        throw res;
      }
      if (res.status == 200) {
        console.debug('Request Created :', reqBody);
      }
    })
    .catch(function (error) {
      console.error('Error Creating a Request: ', error.toJSON());
      throw error.message;
    });

export const updateSearchRequest = (requestId?: string | number, reqBody?: IRequest) =>
  axios
    .put(
      `/updateDueDiligenceRequest/${encodeURIComponent(requestId ? requestId.toString() : '')}`,
      reqBody,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      if (res.status == 500) {
        throw res;
      }
      if (res.status == 200) {
        console.debug('Updated request :', reqBody);
      }
    })
    .catch(function (error) {
      console.error('Error updating request: ', error.toJSON());
      throw error;
    });

export const getCommentForCase = (caseId: string): Promise<IComment> =>
  axios
    .get(`/getDueDiligenceComments/${encodeURIComponent(caseId)}`, axiosConfig)
    .then((res: AxiosResponse<IComment>) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const createCommentForCase = (reqBody: IComment): Promise<IComment> =>
  axios
    .post(`/createDueDiligenceComment`, reqBody, axiosConfig)
    .then((res: AxiosResponse<IComment>) => {
      return res.data;
    })
    .catch((error: AxiosError | any) => {
      throw serviceUtils.processError(error);
    });

export const updateCommentForCase = (commentId?: number, reqBody?: IComment) =>
  axios
    .put(
      `/updateDueDiligenceComment/${encodeURIComponent(commentId ? commentId.toString() : '')}`,
      reqBody,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      if (res.status == 500) {
        throw res;
      }
      if (res.status == 200) {
        console.debug('Updated comment :', reqBody);
      }
    })
    .catch(function (error) {
      console.error('Error updating comment: ', error.toJSON());
      throw error;
    });

export const getDueDiligenceAddParty = (
  reqBody: IAddRemoveParty,
  tenant: string
): Promise<IAddPartyBody> =>
  axios
    .post(`/addDueDiligenceParty?tenant=${encodeURIComponent(tenant)}`, reqBody, axiosConfig)
    .then((res: AxiosResponse<IAddPartyBody>) => {
      return serviceUtils.processSuccess<IAddPartyBody>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const dueDiligenceRemoveParty = (reqBody: IAddRemoveParty): Promise<IAddPartyBody> =>
  axios
    .post(`/removeDueDiligenceParty`, reqBody, axiosConfig)
    .then((res: AxiosResponse<IAddPartyBody>) => {
      return serviceUtils.processSuccess<IAddPartyBody>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const postDueDiligencePartyDetails = (
  caseId: string,
  payload: Array<ICaseParty>
): Promise<Array<ICaseParty>> =>
  axios
    .post(`/getDueDiligencePartiesByCaseId/${caseId}`, payload, axiosConfig)
    .then((res: AxiosResponse<ICaseParty[]>) => {
      return serviceUtils.processSuccess<ICaseParty[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getPDFById = (id: number | string) =>
  `${axiosConfig.baseURL}/apiGatewayService/execute/getDueDiligencePdfById/${encodeURIComponent(
    id
  )}`;

export const postTRClearBusinessQuickAnalysisFlag = (reqBody: TRClearQAFlagRequest) =>
  axios
    .post(`/duediligenceBusinessAnalysisRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('Request Created :', reqBody);
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const postTRClearPersonQuickAnalysisFlag = (reqBody: TRClearQAFlagRequest) =>
  axios
    .post(`/duediligencePersonAnalysisRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('Request Created :', reqBody);
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const getTRClearPersonQuickAnalysisFlag = (
  caseID: string,
  apiName: string,
  searchType: string,
  groupId: string,
  personEntityId: string
) =>
  axios
    .get(
      `/getDueDiligenceResponseByCaseIdAndAPINameAndSearchTypeAndGroupIdAndEntityId?caseId=${encodeURIComponent(
        caseID
      )}&apiName=${encodeURIComponent(apiName)}&searchType=${encodeURIComponent(
        searchType
      )}&groupId=${encodeURIComponent(groupId)}&personEntityId=${encodeURIComponent(
        personEntityId
      )}`,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getTRClearBusinessQuickAnalysisFlag = (
  caseID: string,
  apiName: string,
  searchType: string,
  groupId: string,
  companyEntityId: string
) =>
  axios
    .get(
      `/getDueDiligenceResponseByCaseIdAndAPINameAndSearchTypeAndGroupIdAndCompanyEntityId?caseId=${encodeURIComponent(
        caseID
      )}&apiName=${encodeURIComponent(apiName)}&searchType=${encodeURIComponent(
        searchType
      )}&groupId=${encodeURIComponent(groupId)}&companyEntityId=${encodeURIComponent(
        companyEntityId
      )}`,
      axiosConfig
    )
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const generateTRReport = (reqBody: GenerateTRReportRequest) =>
  axios
    .post(`/createDueDiligenceReportRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const generateIRReport = (reqBody: GenerateIRReportRequest) =>
  axios
    .post(`/createDueDiligenceReportRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const generateAVReport = (reqBody: GenerateAVReportRequest) =>
  axios
    .post(`/createDueDiligenceReportRequest`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const deleteUploadIRScreenshots = (id: string, reqBody: DeleteIRSCreenshotsRequest) =>
  axios
    .put(`/sendRequestToDeleteImage/${id}`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch(function (error) {
      throw serviceUtils.processError(error);
    });

export const refreshResults = (caseID: string, apiName: string) =>
  `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/dueDiligenceService${API_CONSTANTS.SCREEN_REFRESH}/${encodeURIComponent(caseID)}/${encodeURIComponent(apiName)}`;
