import IStringUtils from '../entities/functions/IStringUtils';

const StringUtils: IStringUtils = {
  getSubStringAfterKey: (baseString: string, key: string, end?: number) => {
    const indexOf = baseString.indexOf(key);
    return indexOf >= 0 ? baseString.substring(indexOf + key.length, end) : '';
  },
  compareStrings: (str1: string, str2: string) => {
    return str1.toLocaleLowerCase() === str2.toLowerCase();
  },
};

export default StringUtils;
