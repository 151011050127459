import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import './Views.scss';
import { IconButton, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import IAgGridTblPayload from '../../../../utils/entities/ag-grid/IAgGridTblPayload';
import ICustomView from '../../../../utils/entities/views/ICustomView';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { getUserCustomViewsByType } from '../../../../service/role-manager.service';
import { getViewsCountByListType } from '../../../../service/global-query.service';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { IViewsProps } from '../../../../utils/entities/views/IViews';
import TextButton from '../../button/text/TextButton';
import SaveView from './SaveView';
import DeleteView from './DeleteView';
import Loader from '../../../loader/Loader';
import { IRole } from '../../../../utils/entities/role';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { IGenericStringObject } from '../../../../utils/entities/IGenericObject';
import { IAccessToken } from '../../../../utils/entities/authentication';

const Views = ({
  payload,
  columnsViews,
  defaultColumns,
  tenant,
  type,
  onSelectView,
  gridApi,
}: IViewsProps) => {
  const { t } = useTranslation();
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [views, setViews] = useState<ICustomView[]>([]);
  const [selectedViewName, setSelectedViewName] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [toDeleteView, setToDeleteView] = useState<ICustomView | undefined>(undefined);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeRole, setActiveRole] = useState<IRole>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
  );

  document.addEventListener(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, (e: any) => {
    setActiveRole(GeneralUtils.deepCopy(e.detail));
  });

  useEffect(() => {
    getViews();
  }, [tenant]);

  useEffect(() => {
    selectView(selectedViewName || getDefaultViewName());
  }, [views]);

  useEffect(() => {
    selectView(getDefaultViewName());
  }, [activeRole]);

  const updateDefaultView = (viewName: string) => {
    const viewKey = `${tenant}_${activeRole.name}_${type}`;
    let defaultView: IGenericStringObject | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.DEFAULT_VIEW
    );
    if (defaultView) {
      defaultView[viewKey] = viewName;
    } else {
      defaultView = { [viewKey]: viewName };
    }
    LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DEFAULT_VIEW, defaultView);
  };

  const getDefaultViewFromDatabase = (): ICustomView => {
    const savedDefaultView: ICustomView | undefined = views.find((view) => view.isDefault);

    return savedDefaultView ?? views[0];
  };

  const getDefaultViewName = (): string => {
    const defaultView: string | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.DEFAULT_VIEW
    )?.[`${tenant}_${activeRole.name}_${type}`];

    return defaultView ?? getDefaultViewFromDatabase()?.label;
  };

  const selectView = (viewName: string) => {
    const view = views.find((v) => v.label === viewName) ?? getDefaultViewFromDatabase();
    if (view) {
      setSelectedViewName(view.label);
      updateDefaultView(view.label);
      onSelectView(
        view.inputs,
        view.columns.length > 0 ? view.columns : defaultColumns,
        view?.userCreated
      );
    }
  };

  const createViews = async (views: ICustomView[], viewsCountPayload: Array<IAgGridTblPayload>) => {
    await getViewsCountByListType(viewsCountPayload, type).then((count) => {
      for (const view of views) {
        view.total = count[view.label];
      }
    });

    return views;
  };

  const getViews = async () => {
    const viewsCountPayload: Array<IAgGridTblPayload> = [];
    setIsLoading(true);
    getUserCustomViewsByType(
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT),
      type
    )
      .then((views: Array<ICustomView>) => {
        views.forEach((view) => {
          view.inputs.tenant = tenant;
          view.inputs.assignee = userInfo.user;
          viewsCountPayload.push({
            ...view.inputs,
            filterName: view.label,
          });
        });

        createViews(views, viewsCountPayload).then((views) => setViews([...views]));
      })
      .finally(() => setIsLoading(false));
  };

  const openSaveView = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteIconClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    view: ICustomView
  ) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setToDeleteView(view);
  };

  return (
    <>
      <div className="views-wrapper center-aligned">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {views && (
              <Select
                fullWidth
                variant="standard"
                type="custom"
                value={selectedViewName}
                onChange={(e: SelectChangeEvent) => selectView(e.target.value)}
                renderValue={(label) => label}
                className="views-dropdown"
                sx={{ minWidth: 200 }}
                open={isSelectOpen}
                onOpen={() => setIsSelectOpen(true)}
                onClose={() => setIsSelectOpen(false)}>
                {views.map((view) => (
                  <MenuItem key={view.id} value={view.label} className="views-dropdown-option">
                    <Stack justifyContent="space-between" className="select-wrapper" spacing={1}>
                      <span className="view-total">{`(${view.total})`}</span>
                      <span className="view-label">{view.label}</span>
                      {view.userCreated && (
                        <IconButton
                          className="view-delete-link"
                          onClick={(e) => handleDeleteIconClick(e, view)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            )}
            <TextButton label={t('SAVE_VIEW')} handleClick={openSaveView} />
          </>
        )}
      </div>
      {toDeleteView && (
        <DeleteView
          toDeleteView={toDeleteView}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setIsSelectOpen={setIsSelectOpen}
          getViews={getViews}
          setIsLoading={setIsLoading}
        />
      )}
      <SaveView
        gridApi={gridApi}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        payload={payload}
        columnsViews={columnsViews}
        setSelectedViewName={setSelectedViewName}
        tenant={tenant}
        type={type}
        createViews={createViews}
        views={views}
        setViews={setViews}
      />
    </>
  );
};

export default Views;
