import React from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import './Modal.scss';
import IModalProps from '../../../utils/entities/genericComponents/modal/IModal';

const DefaultModal: React.FC<IModalProps> = (props) => {
  const {
    open,
    className,
    title,
    body,
    setConfirm,
    onClose,
    setCancel,
    confirmText,
    denyText,
    cancelText,
    disabled,
  } = { ...props };
  return (
    // TOOD this needs to be converted to a dialog to mantain the style
    <Modal
      open={open}
      onClose={onClose}
      className={'default-modal ' + className}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box>
        <Grid className="modal-header">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
          </Typography>
        </Grid>
        <Grid className="modal-body">
          <Box id="modal-modal-description">{body}</Box>
        </Grid>
        <Grid className="modal-footer">
          <Button disabled={disabled} onClick={setConfirm}>
            {confirmText}
          </Button>
          <Button className="cancel-button" onClick={onClose}>
            {denyText}
          </Button>
          {cancelText && (
            <Button className="cancel-button" onClick={setCancel}>
              {cancelText}
            </Button>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default DefaultModal;
