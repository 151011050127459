import React from 'react';
import { useTranslation } from 'react-i18next';
import LaunchIcon from '@mui/icons-material/Launch';
import { Button, Tooltip } from '@mui/material';
import IFilePreview from '../../../utils/entities/genericComponents/file-preview/IFilePreview';

const FilePreview = ({ data, type }: IFilePreview) => {
  const { t } = useTranslation();

  const getHeight = () => {
    // Do not stretch image in y axis
    return type.includes('image') ? '' : '100%';
  };

  return (
    <object
      data={data}
      type={type}
      width="100%"
      height={getHeight()}
      data-testid="file-preview-component">
      <Tooltip title={t('NOT_SUPPORTED')}>
        <Button
          className="link"
          onClick={() => {
            window.open(data, '_blank');
          }}>
          <LaunchIcon />
        </Button>
      </Tooltip>
    </object>
  );
};

export default FilePreview;
