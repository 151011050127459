import React from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import './NoNotification.scss';

const NoNotification = () => {
  const { t } = useTranslation();

  return (
    <div className="no-notification" data-testid="no-notification-component">
      <Stack justifyContent="center" alignItems="center">
        <IconButton disabled>
          <NotificationsIcon style={{ fontSize: 200 }} />
        </IconButton>
        <Typography className="color-grey" component="p">
          {t('NOTIFICATION_CENTER.NO_NOTIFICATIONS')}
        </Typography>
      </Stack>
    </div>
  );
};

export default NoNotification;
