import ILocalStorageUtils from '../entities/functions/ILocalStorageUtils';

const LocalStorageUtils: ILocalStorageUtils = {
  setSavedItem: (key: string, item: any) => {
    if (item != null || item != undefined) localStorage.setItem(key, JSON.stringify(item));
  },
  getSavedItem: (key: string) => {
    const data = localStorage.getItem(key);
    if (!data) return null;

    try {
      return JSON.parse(data);
    } catch (error) {
      console.error('Error in setting local storage :', error);
      return null;
    }
  },
  removeSavedItem: (key: string) => {
    localStorage.removeItem(key);
  },
};

export default LocalStorageUtils;
