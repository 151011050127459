import { gql, useQuery } from '@apollo/client';

export const QUERY_LAUNCH_PROFILE = gql`
  query GetCaseData($caseId: String!) {
    case(caseId: $caseId) {
      id
      version
      tenant
      createdOn
      statusList
      versionDate
      dataParticleReferences
      dataParticles {
        guid
        caseId
        createdOn
        root
        type
        subType
        policyGUID
        policyVersion
        dataBlockSummary {
          guid
          recycling {
            recycled
            originalCaseId
            originalGUID
            recycledOn
          }
          taskDetails {
            taskId
            caseId
            taskGUID
            taskName
            status
            active
            canceled
            policyGUID
            policyVersion
          }
          source {
            name
            type
            category
            sourceCreatedOn
            ingestionDate
            evidenceDocument {
              guid
              issuedDate
              documentDescription
            }
          }
        }
        content {
          businessKeys {
            name
            value
            values
          }
          parentBusinessKeys {
            name
            value
            values
          }
          childBusinessKeys {
            name
            value
            values
          }
          attributes {
            name
            value
            values
          }
        }
        tenant
      }
      caseData {
        name
        value
        values
      }
      evidenceDocuments {
        guid
        issuedDate
        documentDescription
      }
      items {
        guid
        root
        itemType
        businessKeys {
          name
          value
          values
        }

        dataParticleReferences
        attributes
      }
      customerRiskAssessment {
        value
        percentage
        status
      }
      customerRiskAssessment {
        value
        percentage
        status
      }
      relationships {
        guid
        parentGUID
        childGUID
        relationshipType
        parentSubType
        childSubType
        businessKeys {
          name
          value
          values
        }
        parentBusinessKeys {
          name
          value
          values
        }
        childBusinessKeys {
          name
          value
          values
        }
        dataParticleReferences
        attributes
      }
      gapReport {
        relationShips
        reportStatus {
          hasGaps
          hasIdentificationGaps
          hasVerificationGaps
        }
      }
      tags {
        name
        method
      }
      draft
      riskScore {
        scoreRank
        scoreValue
        attributesReport {
          entityType
          entitySubType
          attributeName
          attributeValue
          weight
          contributingFactorCategory
          root
        }
      }
      slaStatus {
        timeUnits
        elapsedUnits
        slaStatus
      }
      caseRoles {
        name
        project
        users
      }
    }
  }
`;

export const QUERY_AUDIT_TABLE = gql`
  query ($caseId: String!, $language: String!) {
    searchByCaseIdAndLanguage(caseId: $caseId, language: $language) {
      id
      event
      timestamp
      author
      auditTemplates
      description
    }
  }
`;

export const query = (caseId: any) => {
  const { error, data, loading } = useQuery(QUERY_LAUNCH_PROFILE, {
    variables: {
      caseId,
    },
  });
  return {
    data,
    error,
    loading,
  };
};

export const queryTable = (caseId: any, language: any) => {
  const { error, data, loading } = useQuery(QUERY_AUDIT_TABLE, {
    fetchPolicy: 'network-only',
    variables: {
      caseId,
      language,
    },
  });
  return {
    data,
    error,
    loading,
  };
};
