import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IArticle, IRequest, ILocation } from '../../../utils/entities/dueDiligence';
import Link from '@mui/material/Link';
import { CONSTANTS } from '../../../utils/constants/constants';
import dateUtils from '../../../utils/functions/dateUtils';

interface Props {
  htmlString: string;
}

/** * This function will responsible to display detail about article * @param props * @returns */
const ArticleDetail = (props: {
  articleDetail: IArticle;
  selectedRequestDetail: IRequest | undefined;
}) => {
  const { t } = useTranslation();

  const highlightText = (
    text: string,
    toBeHighlighted: string | RegExp | undefined,
    color: string,
    weight: string
  ): string => {
    if (!toBeHighlighted) {
      return text;
    }

    const regex = new RegExp(`\\b${toBeHighlighted}\\b`, 'gi'); //Find all words equal to toBeHighlighted

    return text.replace(
      regex,
      `<span style="color: ${color}; font-weight: ${weight};">${toBeHighlighted}</span>`
    );
  };

  const highlightParty = (
    text: string,
    party: string | undefined,
    color: string,
    weight: string
  ): string => {
    return highlightText(text, party, color, weight);
  };

  const highlightTerm = (
    selectedRequestDetail: IRequest | undefined,
    text: string,
    terms: string[] | undefined,
    color: string,
    weight: string
  ) => {
    if (selectedRequestDetail && terms) {
      terms?.map((term: string) => {
        text = highlightText(text, term, color, weight);
      });
    }
    return text;
  };

  const hightlightLocation = (
    selectedRequestDetail: IRequest | undefined,
    locations: ILocation[] | undefined,
    text: string,
    color: string,
    weight: string
  ) => {
    if (selectedRequestDetail?.location && locations) {
      locations?.forEach((location: ILocation) => {
        text = highlightText(text, location.country, color, weight);
        text = highlightText(text, location.state, color, weight);
        text = highlightText(text, location.city, color, weight);
      });
    }
    return text;
  };

  const hightlightAliases = (
    selectedRequestDetail: IRequest | undefined,
    aliases: Array<string> | undefined,
    text: string,
    color: string,
    weight: string
  ) => {
    if (selectedRequestDetail?.location && aliases) {
      aliases?.forEach((alias: string) => {
        text = highlightText(text, alias, color, weight);
      });
    }
    return text;
  };

  const highlightDate = (
    text: string,
    date: string | undefined,
    color: string,
    weight: string
  ): string => {
    return highlightText(text, date, color, weight);
  };

  const generateHighlightedKeyFindings = (
    articleDetail: IArticle,
    selectedRequestDetail: IRequest | undefined
  ): string[] => {
    const highlightedFindings: string[] = [];

    articleDetail.keyFindings.forEach((finding: string) => {
      let highlightedFinding = finding;

      highlightedFinding = highlightParty(
        highlightedFinding,
        selectedRequestDetail?.partyName,
        CONSTANTS.COLORS.YELLOW,
        'bold'
      );
      highlightedFinding = highlightTerm(
        selectedRequestDetail,
        highlightedFinding,
        selectedRequestDetail?.searchTerms?.split(',').map((term: string) => term.trim()),
        CONSTANTS.COLORS.RED,
        'bold'
      );
      highlightedFinding = hightlightLocation(
        selectedRequestDetail,
        selectedRequestDetail?.location,
        highlightedFinding,
        CONSTANTS.COLORS.ORANGE,
        'bold'
      );
      highlightedFinding = hightlightAliases(
        selectedRequestDetail,
        selectedRequestDetail?.aliases,
        highlightedFinding,
        CONSTANTS.COLORS.YELLOW,
        'bold'
      );
      highlightedFinding = highlightDate(
        highlightedFinding,
        (selectedRequestDetail?.minDate, selectedRequestDetail?.maxDate),
        CONSTANTS.COLORS.YELLOW,
        'bold'
      );

      highlightedFindings.push(highlightedFinding);
    });

    return highlightedFindings;
  };

  const generateHighlightedSummary = (
    articleDetail: IArticle,
    selectedRequestDetail: IRequest | undefined
  ): string => {
    let highlightedSummary = articleDetail.summary;

    highlightedSummary = highlightParty(
      highlightedSummary,
      selectedRequestDetail?.partyName,
      CONSTANTS.COLORS.YELLOW,
      'bold'
    );
    highlightedSummary = highlightTerm(
      selectedRequestDetail,
      highlightedSummary,
      selectedRequestDetail?.searchTerms?.split(',').map((term: string) => term.trim()),
      CONSTANTS.COLORS.RED,
      'bold'
    );
    highlightedSummary = hightlightLocation(
      selectedRequestDetail,
      selectedRequestDetail?.location,
      highlightedSummary,
      CONSTANTS.COLORS.ORANGE,
      'bold'
    );
    highlightedSummary = hightlightAliases(
      selectedRequestDetail,
      selectedRequestDetail?.aliases,
      highlightedSummary,
      CONSTANTS.COLORS.YELLOW,
      'bold'
    );
    highlightedSummary = highlightDate(
      highlightedSummary,
      (selectedRequestDetail?.minDate, selectedRequestDetail?.maxDate),
      CONSTANTS.COLORS.ORANGE,
      'bold'
    );

    return highlightedSummary;
  };

  const keyFindings = `<ul>${generateHighlightedKeyFindings(
    props.articleDetail,
    props.selectedRequestDetail
  )
    .map((key: string, index: number) => {
      return `<li key="${index}">${key}</li>`;
    })
    .join('')}<ul>`;

  //Render a string into HTML without tinymce or quill
  const HtmlRenderer: React.FC<Props> = ({ htmlString }) => {
    const renderHtml = (htmlString: string): ReactNode => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const elements = Array.from(doc.body.childNodes);
      return elements.map((element, index) => {
        if (element instanceof HTMLElement) {
          const { tagName, attributes } = element;
          const props: { [key: string]: string | React.CSSProperties } = {};
          for (let i = 0; i < attributes.length; i++) {
            const { name, value } = attributes[i];
            if (name === 'style') {
              const styles = value.split(';').reduce(
                (acc, style) => {
                  const [property, propertyValue] = style.split(':');
                  if (property && propertyValue) {
                    acc[property.trim()] = propertyValue.trim();
                  }
                  return acc;
                },
                {} as { [key: string]: string }
              );
              props.style = styles as React.CSSProperties;
            } else {
              props[name] = value;
            }
          }

          if (tagName.toLowerCase() === 'ul') {
            const liElements = Array.from(element.querySelectorAll('li')).map(
              (liElement, liIndex) => {
                return <li key={liIndex}>{renderHtml(liElement.innerHTML)}</li>;
              }
            );
            return (
              <ul key={index} {...props}>
                {liElements}
              </ul>
            );
          } else if (tagName.toLowerCase() === 'li') {
            return (
              <li key={index} {...props}>
                {renderHtml(element.innerHTML)}
              </li>
            );
          } else {
            return React.createElement(
              tagName.toLowerCase(),
              { ...props, key: index },
              element.innerHTML
            );
          }
        } else {
          return element.textContent;
        }
      });
    };

    const renderedHtml = renderHtml(htmlString);

    return <div>{renderedHtml}</div>;
  };
  return (
    <div className="margin-20">
      <div className="center-aligned-space-between width-100-per ">
        <div className="display-inline-flex">
          <div className="article-title">{props.articleDetail.title}</div>
          <div className="title-percentage color-green">
            ({Number(props.articleDetail.articleScore * 100).toFixed(2)}%)
          </div>
        </div>
      </div>
      <Link
        className="link"
        underline="hover"
        onClick={() => window?.open(props.articleDetail.url, '_blank')}>
        {props.articleDetail.url}
      </Link>
      <div>
        <div className="display-inline-flex">
          <div className="lighter-text margin-right-5">{t('DD.PUBLISHED')}:</div>
          <div>{dateUtils.getFormatedDate(props.articleDetail.date)}</div>
        </div>
      </div>
      <div>
        <div className="display-inline-flex">
          <div className="lighter-text margin-right-5">{t('DD.TOP_SEARCH_TERMS')}:</div>
          <div>{props.articleDetail.topRiskTermList.map((term: string) => term + ' ')}</div>
        </div>
      </div>
      <label className="label">{t('DD.KEY_FINDINGS')}</label>
      {keyFindings && <HtmlRenderer htmlString={keyFindings} />}
      <label className="label">{t('DD.ARTICLE_SUMMARY')}</label>
      {generateHighlightedSummary(props.articleDetail, props.selectedRequestDetail) && (
        <HtmlRenderer
          htmlString={generateHighlightedSummary(props.articleDetail, props.selectedRequestDetail)}
        />
      )}
    </div>
  );
};

export default ArticleDetail;
