import React from 'react';
import { InputLabel } from '@mui/material';
import TooltipComp from '../../../generic/tooltip/Tooltip';
import ILabeledInput from '../../../../utils/entities/genericComponents/input/input-label/ILabeledInput';

const LabeledInput = ({ title, value }: ILabeledInput) => {
  return (
    <InputLabel className="text-form">
      <b>{title}: </b>
      <TooltipComp label={value as string} />
    </InputLabel>
  );
};

export default LabeledInput;
