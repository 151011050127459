import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import React from 'react';
import { CONSTANTS } from '../../../utils/constants/constants';
import dateUtils from '../../../utils/functions/dateUtils';
import { IRfiMailListProps } from '../../../utils/entities/rfi/IRfi';
import IMailDetail from '../../../utils/entities/screen/IMailDetail';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';

const RfiMaiList = (props: IRfiMailListProps) => {
  const { rfiMailList, loggedUser, dateFormat } = { ...props };
  return (
    <Table className="link-case-table" size="small" sx={{ borderCollapse: 'inherit' }}>
      <TableHead>
        <TableRow>
          {CONSTANTS.RFI_TABLE.map((col: string, i: number) => (
            <TableCell key={col} data-testid={`column-header-${i}`}>
              {col}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rfiMailList.map((row: IMailDetail) => (
          <TableRow
            data-testid="row-1"
            key={`${row.createdBy}-${row.createdDate}-${row.caseId}-${row.status}`}>
            <TableCell>
              {loggedUser &&
                dateUtils.dateFormating(
                  dateUtils.timeStampWithSeconds(
                    row.createdDate,
                    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
                  ) as string,
                  dateFormat
                )}
            </TableCell>
            <TableCell>{row.recipient}</TableCell>
            <TableCell>{row.createdBy}</TableCell>
            <TableCell>{row.type}</TableCell>
            <TableCell>{row.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RfiMaiList;
