import React, { ForwardedRef, forwardRef } from 'react';
import { Popper, PopperProps } from '@mui/material';

const PopperComponent = forwardRef<HTMLDivElement, PopperProps>(function PopperComponent(
  props: PopperProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return <Popper {...props} ref={ref} />;
});

export default PopperComponent;
