import React, { useEffect, useState } from 'react';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Task from './pages/task/Task';
import Inbox from './pages/Inbox';
import ErrorPage from './pages/error-pages/ErrorPage';
import BaseLayout from './layouts/base-layout/BaseLayout';
import TaskQuestionnaire from './components/task/TaskQuestionnaire';
import { CONSTANTS } from './utils/constants/constants';
import { useIsAuthenticated, useMsalAuthentication, useMsal } from '@azure/msal-react';
import { AccountInfo, InteractionType } from '@azure/msal-browser';
import { getProjectSetting } from './service/config-manager.service';
import { IUser, IUserTimeZone, IRole } from './utils/entities/role';
import { getUserSettings, getProjSecurityAssets, getUser } from './service/role-manager.service';
import IProjectSettings from './utils/entities/config/IProjectSettings';
import TaskCase360 from './components/task/TaskCase360';
import TransactionReviewer from './components/task/TransactionReviewer';
import CaseDetail from './pages/case-detail/CaseDetail';
import Rfi from './components/task/Rfi';
import 'fso-fincrime-transaction-reviewer-ui/dist/index.css';
import DueDiligence from './components/task/DueDiligence';
import { useCookies } from 'react-cookie';
import { authenticateUser } from './service/authentication.service';
import jwtDecode from 'jwt-decode';
import Narrative from './components/task/Narrative';
import CaseList from './pages/work-items/CaseList';
import TaskTimer from './components/task/task-timer/TaskTimer';
import AboutPage from './pages/about-page/AboutPage';
import ConfigPage from './pages/config-page/ConfigPage';
import { ApolloProvider } from '@apollo/client';
import { client } from './service/apollo-client.data-gateway';
import IRoute from './utils/entities/screen/IRoute';
import AlertsManager from './managers/AlertsManager';
import LocalStorageUtils from './utils/functions/localStorageUtils';
import { EVENTS_CONSTANTS } from './utils/constants/event_constants';
import GenericContext from './components/generic/timer/GenericContext';
import ISlaSettingsEntity from './utils/entities/config/ISlaSettingsEntity';
import { themeEY } from './utils/constants/theme';
import ActiveRoleContext from './contexts/ActiveRoleContext';
import { IToken, IAccessToken } from './utils/entities/authentication';
import SarUi from './components/task/SarUi';
import '@material-design-icons/font';
import { PermissionTypes } from './utils/constants/enums';

function App() {
  const { accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { result } = useMsalAuthentication(InteractionType.Redirect);
  const [, setCookie, removeCookie] = useCookies([
    CONSTANTS.COOKIES.ID_TOKEN,
    CONSTANTS.COOKIES.ACCESS_TOKEN,
    CONSTANTS.COOKIES.REFRESH_TOKEN,
    CONSTANTS.COOKIES.ASSETS,
  ]);
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const projectSettingsName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );
  const projectSettingsTenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  const [caseHeaderToggle, setCaseHeaderToggle] = useState(true);
  const [userWaRetrieved, setUserWaRetrieved] = useState<boolean>(false);
  const [fileType, setFileType] = useState<Array<string>>([]);
  const [maxFileSize, setMaxFileSize] = useState<number>();
  const [user, setUser] = useState<IUser>();
  const [slaSettingsEntity, setSlaSettingsEntity] = useState<ISlaSettingsEntity>();
  const [idTokenCookieReady, setIdTokenCookieReady] = useState<boolean>(false);
  const [dateFormat, setDateFormat] = useState<string>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT) ??
      window.__RUNTIME_CONFIG__?.REACT_APP_DEFAULT_DATE_FORMAT
  );
  const [routing, setRouting] = useState<boolean>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING) ?? false
  );
  const [activeRole, setActiveRole] = useState<IRole>(
    LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
  );

  const retrieveUser = () => {
    getUser().then((user: IUser) => {
      setUser(user);
      setSelectedProject(defaultProject(user));
      LocalStorageUtils.setSavedItem(
        CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE,
        user.activeRole
      );
    });
  };

  const defaultProject = (paramUser?: IUser) => {
    let currentUser;
    if (paramUser) {
      currentUser = paramUser;
    } else {
      currentUser = user;
    }

    return projectSettingsName || currentUser?.projects?.[0] || '';
  };

  const [selectedProject, setSelectedProject] = useState<string>(defaultProject());
  useEffect(() => {
    if (selectedProject) {
      getProjectSetting(selectedProject).then((settings: IProjectSettings) => {
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME,
          settings.projectName
        );
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT,
          settings.tenantEntities.find((te) => te.active)?.tenant
        );
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.NON_EXISTENT_TASKS,
          settings.nonExistentTasks
        );
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.HOME_PAGE_ICONS,
          settings.homePageIconsEntities
        );
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.IS_DOCUMENT_SCAN_ENABLED,
          settings.antiVirus
        );

        setFileType(settings.fileTypes);
        setMaxFileSize(settings.maxFileSize);
        setSlaSettingsEntity(settings.slaSettingsEntity);

        getUserSettings().then((res: IUserTimeZone) => {
          LocalStorageUtils.setSavedItem(
            CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE,
            res.timezone ?? settings.defaultTimezone
          );
          LocalStorageUtils.setSavedItem(
            CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING,
            LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING) ??
              res.redirectToInboxForManualCaseCreation
          );
          setRouting(
            LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.ROUTING) ??
              res.redirectToInboxForManualCaseCreation
          );
        });
      });

      getProjSecurityAssets(selectedProject).then((data) => {
        setCookie(
          CONSTANTS.COOKIES.ASSETS,
          {
            assets: data[0]?.assets ?? [],
          },
          { path: '/' }
        );
      });
    }
  }, [selectedProject]);

  useEffect(() => {
    if (isAuthenticated) {
      if (result?.idToken) {
        setCookie(CONSTANTS.COOKIES.ID_TOKEN, result.idToken, {
          path: '/',
          sameSite: 'none',
          secure: true,
        });
        setIdTokenCookieReady(true);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (idTokenCookieReady) {
      setIdTokenCookieReady(false);
      loginUser(accounts);
    }
  }, [idTokenCookieReady]);

  useEffect(() => {
    if (!userWaRetrieved) {
      setUserWaRetrieved(true);
      retrieveUser();
    }
  }, [userInfo]);

  useEffect(() => {
    LocalStorageUtils.removeSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.CASE_PERMISSIONS);
  }, []);

  const loginUser = (accounts: AccountInfo[]) => {
    if (result?.idToken) {
      authenticateUser(accounts[0].username).then((token: IToken) => {
        removeCookie(CONSTANTS.COOKIES.ID_TOKEN);
        const userInfo: IAccessToken = jwtDecode(token.accessToken);
        LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO, userInfo);
        retrieveUser();
      });
    }
  };

  document.addEventListener(EVENTS_CONSTANTS.DATE_FORMAT_CHANGE, (e: any) => {
    setDateFormat(e.detail);
  });

  document.addEventListener(EVENTS_CONSTANTS.ROUTING_CHANGE, (e: any) => {
    setRouting(e.detail);
  });

  document.addEventListener(EVENTS_CONSTANTS.HEADER_EXPAND_STATUS, (e: any) => {
    setCaseHeaderToggle(e.detail);
  });

  document.addEventListener(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, (e: any) => {
    setActiveRole(e.detail);
  });

  const tabsRoutes = () => {
    return (
      <>
        <Route path="narrative/:tabId" element={<Narrative />} />
        <Route path="transactionReviewer/:tabId" element={<TransactionReviewer />} />
        <Route path="sar-ui/:tabId/*" element={<SarUi />} />
        <Route path="case360/:tabId" element={<TaskCase360 />} />
        <Route path="rfi" element={<Rfi />} />
        <Route path="due-diligence/:tabId" element={<DueDiligence maxFileSize={maxFileSize} />} />
        <Route path="questionnaire/:tabId" element={<TaskQuestionnaire />} />
        <Route path="screening/:tabId" element={<p>SCREENING PLACEHOLDER</p>} />
        <Route path="case-overview/:tabId" element={<p>CASE OVERVIEW PLACEHOLDER</p>} />
        <Route path="timer/:tabId" element={<TaskTimer />} />
      </>
    );
  };

  const routes: IRoute[] = [
    {
      path: `${CONSTANTS.PAGES_URL.TASK}/:id`,
      element: (
        <Task
          fileType={fileType}
          maxFileSize={maxFileSize}
          slaLabels={slaSettingsEntity?.slaLabels}
        />
      ),
      child: tabsRoutes(),
      permissions: [],
    },
    {
      path: `${CONSTANTS.PAGES_URL.CASE_DETAILS}/:caseId`,
      element: (
        <ApolloProvider client={client}>
          <CaseDetail />
        </ApolloProvider>
      ),
      child: (
        <Route
          path="task/:id"
          element={
            <Task
              fileType={fileType}
              maxFileSize={maxFileSize}
              slaLabels={slaSettingsEntity?.slaLabels}
            />
          }>
          {tabsRoutes()}
        </Route>
      ),
      permissions: [],
    },
    {
      path: CONSTANTS.PAGES_URL.INBOX,
      element: <Inbox withChart slaSettingsEntity={slaSettingsEntity} />,
      permissions: [],
    },
    {
      path: CONSTANTS.PAGES_URL.TASK_LIST,
      element: <Inbox slaSettingsEntity={slaSettingsEntity} />,
      permissions: [],
    },
    {
      path: CONSTANTS.PAGES_URL.CASE_LIST,
      element: <CaseList slaSettingsEntity={slaSettingsEntity} />,
      permissions: [PermissionTypes.CASE_LIST],
    },
    {
      path: CONSTANTS.PAGES_URL.ABOUT_PAGE,
      element: <AboutPage />,
      permissions: [],
    },
    {
      path: CONSTANTS.PAGES_URL.CONFIG_PAGE,
      element: <ConfigPage />,
      permissions: [],
    },
    {
      path: '/',
      element: <Navigate to={CONSTANTS.PAGES_URL.INBOX} />,
      permissions: [],
    },
    {
      path: '*',
      element: <ErrorPage toRedirect pageType="404" />,
      permissions: [],
    },
  ];

  if (!user) {
    return <ErrorPage pageType="login" />;
  }

  return (
    <ThemeProvider theme={themeEY}>
      {
        <BrowserRouter basename="/">
          {userInfo && projectSettingsName && projectSettingsTenant && user && user.isActive && (
            <BaseLayout
              projects={user.projects}
              selectedProject={selectedProject}
              setSelectedProject={setSelectedProject}>
              <ActiveRoleContext.Provider value={activeRole}>
                <GenericContext.Provider
                  value={{
                    dateFormat,
                    caseHeaderToggle,
                    routing,
                    setSelectedProject,
                    selectedProject,
                  }}>
                  <Routes>
                    {routes.map((route) => (
                      <Route key={route.path} path={route.path} element={route.element}>
                        {route.child}
                      </Route>
                    ))}
                  </Routes>
                </GenericContext.Provider>
              </ActiveRoleContext.Provider>
            </BaseLayout>
          )}
          {(!user || !user.isActive) && <ErrorPage pageType="unauthorized" />}
        </BrowserRouter>
      }
      <AlertsManager />
      <CssBaseline />
    </ThemeProvider>
  );
}

export default App;
