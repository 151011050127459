import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import serviceUtils from '../utils/functions/serviceUtils';
import axiosConfig, { axiosBlob } from './axios.config';

export const getDocumentContent = (guid: string, caseId: string, tenant: string): Promise<Blob> =>
  axios
    .get(`/getDocumentById/${guid}/${caseId}/${tenant}`, axiosBlob)
    .then((res: AxiosResponse<Blob>) => {
      return serviceUtils.processSuccess<Blob>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDocumentById = (guid: string, caseId: string, tenant: string) =>
  `${axiosConfig.baseURL}/getDocumentById/${guid}/${caseId}/${tenant}`;

export const deleteDocumentById = (guid: string) =>
  axios.delete(`${axiosConfig.baseURL}/deleteDocument/${guid}`);

// content manager service direct call
export const contentManagerServiceAxiosConfig: AxiosRequestConfig = {
  // baseURL: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}${API_CONSTANTS.API_GATEWAY}`,
  baseURL: `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/contentManagerService`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'X-XSS-Protection': '1, mode=block',
    'X-Content-Type-Options': 'nosniff',
    'Content-Security-Policy': "default-src 'self'",
  },
  withCredentials: true,
};

export const uploadDocumentAsBinary = (
  file: File,
  docName: string,
  projectName: string,
  expirationDate: number,
  documentName?: string,
  documentType?: string,
  documentSubType?: string,
  uploadedBy?: string
) => {
  const bodyFormData: FormData = new FormData();
  bodyFormData.append('file', file, docName);
  bodyFormData.append('projectName', projectName);
  bodyFormData.append('expirationDate', expirationDate.toString());
  documentName && bodyFormData.append('documentName', documentName);
  documentType && bodyFormData.append('documentType', documentType);
  documentSubType && bodyFormData.append('documentSubType', documentSubType);
  uploadedBy && bodyFormData.append('uploadedBy', uploadedBy);

  return axios
    .postForm(`/uploadDocumentAsBinary`, bodyFormData, axiosConfig)
    .then((res: AxiosResponse) => {
      return res.data;
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
};
