import React, { useEffect, useState } from 'react';
import { Grid, IconButton, List } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronRightIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { IAboutPage, IAboutPageProps } from '../../utils/entities/screen/IAboutPage';
import RichTextComponent from '../../components/rich-text/RichTextComponent';
import Loader from '../../components/loader/Loader';
import AboutPageConfigs from './AboutPageConfigs';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import PlainTextButton from '../../components/generic/button/PlainTextButton';
import {
  createAboutPage,
  deleteAboutPage,
  getAboutPageById,
  getAboutPages,
  updateAboutPage,
} from '../../service/config-manager.service';
import { useCookies } from 'react-cookie';
import { CONSTANTS } from '../../utils/constants/constants';
import { uploadDocument } from '../../service/document-manager.service';
import docUtils from '../../utils/functions/docUtils';
import ConfirmationModal from '../../components/generic/modal/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { PermissionTypes } from '../../utils/constants/enums';
import GeneralUtils from '../../utils/functions/generalUtils';
import { addAlert } from '../../store/actions/alerts.actions';
import './AboutPage.scss';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import PageListItem from './PageListItem';
import filePickerCallback from '../../types/rich-text/filePickerCallback';

export const sortPagesAsc = (pages: IAboutPage[]) => {
  return pages.sort((p1, p2) => p1.pageOrder - p2.pageOrder);
};

const AboutPage = (props: IAboutPageProps) => {
  const { editMode } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const [assetsCookies] = useCookies([CONSTANTS.COOKIES.ASSETS]);

  const [pages, setPages] = useState<Array<IAboutPage>>([]);
  const [content, setContent] = useState<string>('');
  const [isPageListOpen, setIsPageListOpen] = useState<boolean>(true);
  const [selectedPageId, setSelectedPageId] = useState<number>(0);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isNewPage, setIsNewPage] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [userCanAccess, setUserCanAccess] = useState<boolean>(
    !!assetsCookies.assets.assets.length &&
      GeneralUtils.checkUserPermissions(PermissionTypes.ABOUT_PAGE)
  );

  useEffect(() => {
    loadAllPages();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setUserCanAccess(
        !!assetsCookies.assets.assets.length &&
          GeneralUtils.checkUserPermissions(PermissionTypes.ABOUT_PAGE)
      );
    }, 5000);
  }, [userCanAccess]);

  const loadAllPages = () => {
    setShowLoader(true);
    getAboutPages(projectName).then((pages: Array<IAboutPage>) => {
      handleSelectedPageChanged(pages);
      loadDefaultPage(pages);
      setShowLoader(false);
    });
  };

  const loadDefaultPage = (pages: Array<IAboutPage>) => {
    const defPageId = getDefaultPage(pages)?.pageId ?? 0;
    defPageId ? loadPage(defPageId) : null;
    setSelectedPageId(defPageId);
  };

  const handleSelectedPageChanged = (pages: Array<IAboutPage>) => {
    setPages([...pages]);
    setIsNewPage(false);
  };

  const handleSubmit = () => {
    const page: IAboutPage = getPageById(pages, selectedPageId);
    page.pageContent = content;
    setShowLoader(true);
    if (isNewPage) {
      createAboutPage(page, projectName).then((pages: Array<IAboutPage>) => {
        handleSelectedPageChanged(pages);
        setShowLoader(false);
      });
    } else {
      updateAboutPage(page, projectName).then((pages: Array<IAboutPage>) => {
        handleSelectedPageChanged(pages);
        setShowLoader(false);
      });
    }
  };

  const handleViewPageList = () => {
    setIsPageListOpen(!isPageListOpen);
  };

  const handleAddNewPage = () => {
    const pageId = 0;
    pages.push({
      projectName: projectName,
      pageId: pageId,
      parentPageId: null,
      pageName: '',
      assets: assetsCookies.assets.assets,
      open: false,
      children: [],
      isDefault: false,
      pageOrder: 0,
    });
    setSelectedPageId(pageId);
    setContent('');
    setIsNewPage(true);
  };

  const getPageById: any = (pages: Array<IAboutPage>, pageId: number) => {
    for (const page of pages) {
      if (isSelectedPage(page, pageId)) {
        return page;
      }

      const p: any = getPageById(page.children, pageId);
      if (p) {
        return p;
      }
    }
  };

  const getDefaultPage: any = (pages: Array<IAboutPage>) => {
    for (const page of pages) {
      if (isDefaultPage(page)) {
        return page;
      }

      const p: IAboutPage = getDefaultPage(page.children);
      if (p) {
        return p;
      }
    }
  };

  const isDefaultPage = (page: IAboutPage) => {
    return page.isDefault;
  };

  const isSelectedPage = (page: IAboutPage, pageId: number) => {
    return page.pageId === pageId;
  };

  const loadPage = (pageId: number) => {
    setShowLoader(true);
    getAboutPageById(pageId, projectName).then((res: IAboutPage) => {
      setContent(res.pageContent ?? '');
      setShowLoader(false);
      setSelectedPageId(pageId);
    });
  };

  const deletePage = () => {
    setShowLoader(true);
    deleteAboutPage(selectedPageId, projectName).then((pages: Array<IAboutPage>) => {
      handleSelectedPageChanged(pages);
      loadDefaultPage(pages);
      setShowLoader(false);
    });
  };

  const expandList = (pageId: number) => {
    const page: IAboutPage = getPageById(pages, pageId);
    page ? (page.open = !page.open) : null;
    setPages([...pages]);
  };

  const fileUploadHandler = async (
    cb: filePickerCallback,
    file: File,
    fileType: string,
    reader: FileReader,
    blobCache: any
  ) => {
    setShowLoader(true);
    if (await docUtils.scanDocument([file])) {
      uploadDocument(file, file.name, projectName, [`PageId: ${selectedPageId}`])
        .then((data) => {
          if (typeof data === 'object') {
            setOpenModal(true);
            data = data.msg; // File GUID
          }

          if (fileType === 'image') {
            const id = 'blobid' + new Date().getTime();
            const blobInfo = blobCache.create(id, file, (reader.result as string).split(',')[1]);
            blobCache.add(blobInfo);

            cb(blobInfo.blobUri(), { title: file.name, alt: file.name });
          } else {
            cb(
              `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/contentManagerService/document/${data}`,
              { title: file.name, text: file.name }
            );
          }

          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
          addAlert({
            type: 'error',
            primaryText: error,
          });
        });
    } else {
      setShowLoader(false);
      addAlert({
        type: 'error',
        primaryText: t('DOCUMENT_FAILED_VIRUS_SCAN'),
      });
    }
  };

  const setNewPageName = (newName: string) => {
    const page = getPageById(pages, selectedPageId);
    page ? (page.pageName = newName) : null;
    setPages([...pages]);
  };

  if (!userCanAccess) {
    navigate(CONSTANTS.PAGES_URL.INBOX);
    addAlert({
      type: 'error',
      primaryText: t('ACCESS_NOT_GRANTED'),
    });
  }

  return (
    <>
      <ConfirmationModal
        showConfirmButton
        openModal={openModal}
        setOpenModal={setOpenModal}
        action={() => (document.querySelector("button[title='URL']") as HTMLElement)?.click()}
        content={{
          title: t('FILE_AREADY_EXISTS'),
          text: t('REUPLOAD_FILE_QUESTION'),
        }}
        buttonsTextOverride={{
          yes: t('UPLOAD_NEW'),
          no: t('USE_EXISTING_FILE'),
        }}
      />
      <Grid container justifyContent="flex-start" className="about-page">
        <Grid
          container
          item
          xs={isPageListOpen ? 2 : 1}
          style={{ maxWidth: !isPageListOpen ? '50px' : '' }}
          justifyContent="flex-start"
          direction="column"
          className="sidebar">
          <Grid container direction="row" className="full-height">
            {isPageListOpen && (
              <Grid
                item
                container
                xs={11}
                direction="column"
                className="sidebar-main"
                justifyContent="space-between">
                <List aria-labelledby="nested-list-subheader">
                  {sortPagesAsc(pages).map((page) => (
                    <PageListItem
                      key={page.pageId}
                      page={page}
                      level={0}
                      editMode={!!editMode}
                      selectedPageId={selectedPageId}
                      expandList={expandList}
                      loadPage={loadPage}
                      setNewPageName={setNewPageName}
                    />
                  ))}
                </List>
                {editMode && (
                  <div style={{ justifyContent: 'space-between' }} className="border-top">
                    <PlainTextButton
                      label={t('CREATE_PAGE')}
                      icon={<AddCircleOutlineIcon />}
                      handleClick={handleAddNewPage}
                    />
                  </div>
                )}
              </Grid>
            )}
            <Grid container item xs={1} direction="column" justifyContent="center">
              <IconButton
                disableRipple={true}
                sx={{ width: 40 }}
                onClick={handleViewPageList}
                className="no-focus">
                {isPageListOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={isPageListOpen ? 10 : 11}
          justifyContent="flex-start"
          className="about-page-main-area">
          {showLoader && <Loader isMainLoader={true} />}
          {(selectedPageId || isNewPage) && editMode && (
            <AboutPageConfigs
              pages={pages}
              isNewPage={isNewPage}
              selectedPageId={selectedPageId}
              getPageById={getPageById}
              setPages={setPages}
              resetPages={loadAllPages}
              deletePage={deletePage}
              handleSubmit={handleSubmit}
            />
          )}
          {(selectedPageId || isNewPage) && (
            <RichTextComponent
              required
              value={content}
              setData={setContent}
              label={''}
              readOnly={!editMode}
              inline={!editMode}
              enableLinks
              enableImgs
              fileUploadHandler={fileUploadHandler}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AboutPage;
