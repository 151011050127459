import { gql, useQuery } from '@apollo/client';
import { client } from '../apollo-client.data-gateway';
import ICaseRoles from '../../utils/entities/case/ICaseRoles';

const GET_CASE_ROLES = gql`
  query CaseRoles($caseId: String) {
    case(caseId: $caseId) {
      caseRoles {
        id
        name
        min
        max
        project
        users
      }
    }
  }
`;

export const GQL_CASE_ROLES = (
  caseId: string | undefined
): { data: Array<ICaseRoles> | undefined } => {
  const { data } = useQuery(GET_CASE_ROLES, {
    variables: {
      caseId: caseId,
    },
    skip: !caseId,
    client: client,
  });

  return { data: data ? data?.case?.caseRoles : undefined };
};
