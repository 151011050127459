import React from 'react';
import { PermissionTypes } from '../../../../../utils/constants/enums';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GeneralUtils from '../../../../../utils/functions/generalUtils';
import { useTranslation } from 'react-i18next';
import { ICaseHeaderFeatureButtonsProps } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderFeatureButtonsProps';
import { ICaseHeaderFeatureProps } from '../../../../../utils/entities/case/CaseHeader/ICaseHeaderFeatureProps';
import PlainTextButton from '../../../../generic/button/PlainTextButton';

const CaseHeaderFeatureButtons = ({
  setShowModal,
  setOpenAudit,
  setOpenModal,
  casePermissions,
  showDocumentCenterButton,
}: ICaseHeaderFeatureButtonsProps) => {
  const { t } = useTranslation();

  const featureButtons: Array<ICaseHeaderFeatureProps> = [
    {
      text: 'LINK_CASES',
      onClick: () => {
        setShowModal(true);
      },
      icon: <LinkIcon />,
      classes: 'case-header-icons',
      size: 1,
      permission: PermissionTypes.LINK_CASES_VIEW,
      visible: true,
    },
    {
      text: 'AUDIT_HISTORY',
      onClick: () => setOpenAudit(true),
      icon: <ContentPasteIcon />,
      classes: 'case-header-icons',
      size: 1,
      visible: true,
    },
    {
      text: 'DOCUMENTS',
      onClick: () => setOpenModal(true),
      icon: <AssignmentOutlinedIcon />,
      classes: 'case-header-icons',
      size: 1,
      visible: showDocumentCenterButton,
    },
  ];

  return (
    <>
      {featureButtons
        .filter((button) => button.visible)
        .map((button: ICaseHeaderFeatureProps) => {
          if (
            button.permission &&
            !GeneralUtils.checkUserPermissions(button.permission, casePermissions)
          ) {
            return null;
          }

          return (
            <PlainTextButton
              key={button.text}
              icon={button.icon}
              label={t(button.text)}
              handleClick={button.onClick}
            />
          );
        })}
    </>
  );
};

export default CaseHeaderFeatureButtons;
