import axios, { AxiosError, AxiosResponse } from 'axios';
import IProjectSettings from '../utils/entities/config/IProjectSettings';
import ITaskWorkflow from '../utils/entities/config/ITaskWorkflow';
import { ISearchTerms } from '../utils/entities/dueDiligence';
import axiosConfig from './axios.config';
import { IAboutPage } from '../utils/entities/screen/IAboutPage';
import serviceUtils from '../utils/functions/serviceUtils';
import { IProjectSecurityAttributes } from '../utils/entities/authentication';
import { IDataModelVersion, IDataModel, IGroup } from '../utils/entities/dataModel';
import { IDocumentTypeEntity } from '../utils/entities/case/IDocumentTypeEntity';
import { IDocumentConfiguration } from '../utils/entities/document-center';
import { ICaseDataConfigs } from '../utils/entities/case/CaseCreation/ICaseData';
import IPdfType from '../utils/entities/case/IPdfType';
import { IGlobalSearchMapping } from '../utils/entities/global-search/IGlobalSearch';
import { IConfigTag } from '../utils/entities/case/CaseHeader/IConfigTag';
import { ICaseLinkTag } from '../utils/entities/case/CaseHeader/ICaseLinkTag';
import IDataModelColumn from '../utils/entities/ag-grid/IDataModelColumn';

export const getProjectSetting = (projectName: string): Promise<IProjectSettings> =>
  axios
    .get(`/getProjectSettingsbyProjectName/${encodeURIComponent(projectName)}`, axiosConfig)
    .then((res: AxiosResponse<IProjectSettings>) => {
      return serviceUtils.processSuccess<IProjectSettings>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getProjectSettingByTenant = (tenant: string): Promise<IProjectSettings> =>
  axios
    .get(`/getProjectSettingsbyTenant/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<IProjectSettings>) => {
      return serviceUtils.processSuccess<IProjectSettings>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDataBlockModelByTenant = (tenant: string): Promise<Array<IDocumentTypeEntity>> =>
  axios
    .get(`/getDataBlockModelByTenant/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<Array<IDocumentTypeEntity>>) => {
      return serviceUtils.processSuccess<Array<IDocumentTypeEntity>>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getColumnConfig = (tenant: string, type: string): Promise<Array<IDataModelColumn>> =>
  axios
    .get(
      `/getColumnConfigByTenantAndType/${encodeURIComponent(tenant)}/${encodeURIComponent(type)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IDataModelColumn>>) => {
      return serviceUtils.processSuccess<IDataModelColumn[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getSortedUniqueTaskWorkflow = (tenant: string): Promise<Array<ITaskWorkflow>> =>
  axios
    .get(`/getSortedTaskWorkflows/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<Array<ITaskWorkflow>>) => {
      return serviceUtils.processSuccess<ITaskWorkflow[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getAllCaseTags = (): Promise<Array<IConfigTag>> =>
  axios
    .get(`/getCaseTags`, axiosConfig)
    .then((res: AxiosResponse<Array<IConfigTag>>) => {
      return serviceUtils.processSuccess<IConfigTag[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getCaseLinkOptions = (): Promise<Array<ICaseLinkTag>> =>
  axios
    .get(`/getCaseLinkOptions`, axiosConfig)
    .then((res: AxiosResponse<Array<ICaseLinkTag>>) => {
      return serviceUtils.processSuccess<ICaseLinkTag[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getSearchTerms = (tenant: string): Promise<Array<ISearchTerms>> =>
  axios
    .get(`/getDueDiligenceFilterByTenant/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<Array<ISearchTerms>>) => {
      return serviceUtils.processSuccess<ISearchTerms[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDataModelByTenant = (tenant: string): Promise<IDataModel> =>
  axios
    .get(`/getDataModelByTenant/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<IDataModel>) => {
      return serviceUtils.processSuccess<IDataModel>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDataModelItemsByTenant = (
  tenant: string,
  useInCaseCreation?: boolean,
  ignoreRelationships?: boolean
): Promise<Array<IGroup>> =>
  axios
    .get(
      `/getDataModelItemsByTenant/${encodeURIComponent(tenant)}${serviceUtils.QueryParamsSerializer(
        { useInCaseCreation: useInCaseCreation, ignoreRelationships: ignoreRelationships }
      )}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IGroup>>) => {
      return serviceUtils.processSuccess<Array<IGroup>>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDataModelItemById = (id: string): Promise<IGroup> =>
  axios
    .get(`/getDataModelItemById/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<IGroup>) => {
      return serviceUtils.processSuccess<IGroup>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDataModelVersion = async (tenant: string): Promise<IDataModelVersion> =>
  axios
    .get(`/getDataModelVersionByTenant/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<IDataModelVersion>) => {
      return serviceUtils.processSuccess<IDataModelVersion>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getDocumentCenterConfigurations = (
  tenant: string
): Promise<Array<IDocumentConfiguration>> =>
  axios
    .get(`/getDocumentCenterConfig/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<Array<IDocumentConfiguration>>) => {
      return serviceUtils.processSuccess<IDocumentConfiguration[]>(res);
    })
    .catch((error: AxiosError) => {
      throw serviceUtils.processError(error);
    });

// #region ABOUT_PAGE

export const getAboutPages = async (projectName: string): Promise<IAboutPage[]> =>
  axios
    .get(`/getAboutPage?projectName=${encodeURIComponent(projectName)}`, axiosConfig)
    .then((res: AxiosResponse<Array<IAboutPage>>) => {
      return serviceUtils.processSuccess<IAboutPage[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const createAboutPage = async (
  newPage: IAboutPage,
  projectName: string
): Promise<IAboutPage[]> =>
  axios
    .post(`/createAboutPage?projectName=${encodeURIComponent(projectName)}`, newPage, axiosConfig)
    .then((res: AxiosResponse<Array<IAboutPage>>) => {
      return serviceUtils.processSuccess<IAboutPage[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getAboutPageById = async (id: number, projectName: string): Promise<IAboutPage> =>
  axios
    .get(`/getAboutPageById/${id}?projectName=${encodeURIComponent(projectName)}`, axiosConfig)
    .then((res: AxiosResponse<IAboutPage>) => {
      return serviceUtils.processSuccess<IAboutPage>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const updateAboutPage = async (
  page: IAboutPage,
  projectName: string
): Promise<IAboutPage[]> =>
  axios
    .put(
      `/updateAboutPageById/${page.pageId}?projectName=${encodeURIComponent(projectName)}`,
      page,
      axiosConfig
    )
    .then((res: AxiosResponse<IAboutPage[]>) => {
      return serviceUtils.processSuccess<IAboutPage[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const deleteAboutPage = async (id: number, projectName: string): Promise<IAboutPage[]> =>
  axios
    .delete(
      `/deleteAboutPageById/${id}?projectName=${encodeURIComponent(projectName)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IAboutPage>>) => {
      return serviceUtils.processSuccess<IAboutPage[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

// #endregion

export const getProjSecurityAttributes = (
  project?: string
): Promise<Array<IProjectSecurityAttributes>> =>
  axios
    .get(`/getProjectSecurityAttributes${project ? `?projectName=${project}` : ''}`, axiosConfig)
    .then((res: AxiosResponse<Array<IProjectSecurityAttributes>>) => {
      return serviceUtils.processSuccess<IProjectSecurityAttributes[]>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

//#region MANUAL_CASE_CREATION

export const getCaseDataAttributes = (
  tenant: string
): Promise<Array<ICaseDataConfigs> | undefined> =>
  axios
    .get(`/getCaseDataAttributes/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<Array<ICaseDataConfigs>>) => {
      return serviceUtils.processSuccess<ICaseDataConfigs[]>(res);
    })
    .catch((error: AxiosError) => {
      if (error?.response?.status == 404) {
        return undefined;
      }

      throw serviceUtils.processError(error);
    });

export const getGlobalSearchMappingBySource = (
  tenant: string,
  sourceName: string
): Promise<Array<IGlobalSearchMapping>> =>
  axios
    .get(
      `/globalSearchMapping/${encodeURIComponent(tenant)}/${encodeURIComponent(sourceName)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<Array<IGlobalSearchMapping>>) => {
      return serviceUtils.processSuccess<IGlobalSearchMapping[]>(res);
    })
    .catch((error: AxiosError) => {
      throw serviceUtils.processError(error);
    });

//#endregion
export const getPdfInfo = (tenant: string): Promise<IPdfType> =>
  axios
    .get(`/pdfInfo/${encodeURIComponent(tenant)}`, axiosConfig)
    .then((res: AxiosResponse<IPdfType>) => {
      return serviceUtils.processSuccess<IPdfType>(res);
    })
    .catch((error: AxiosError) => {
      throw serviceUtils.processError(error);
    });
