import React from 'react';
import { CONSTANTS } from '../../../../../utils/constants/constants';
import { IAccessToken } from '../../../../../utils/entities/authentication';
import { IWorkflowBarProps } from '../../../../../utils/entities/case/CaseHeader/workflow-bar/IWorkflowBar';
import LocalStorageUtils from '../../../../../utils/functions/localStorageUtils';
import SpelUtils from '../../../../../utils/functions/spelUtils';
import StepperComponent from '../../../../generic/navigation/StepperComponent';

const WorklowBar = ({ steps, allCompleted, contextLocals }: IWorkflowBarProps) => {
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const locals = contextLocals?.['rootEntity'];
  const context = {
    contextLocals,
    userRole: userInfo.role,
    ...locals,
  };

  const getActiveStep = (): number => {
    let index: number = -1;
    for (const [i, step] of steps.entries()) {
      if (!SpelUtils.expressionValidation(true, step.condition, context)) {
        break;
      }
      index = i;
    }

    return index;
  };

  const isAllCompleted = () => {
    if (allCompleted) {
      return SpelUtils.expressionValidation(true, allCompleted, context);
    }
    return false;
  };

  return (
    <StepperComponent
      steps={steps.map((step) => step.label)}
      activeStep={getActiveStep()}
      allCompleted={isAllCompleted()}
    />
  );
};

export default WorklowBar;
