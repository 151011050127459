import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckboxComponent from './CheckboxComponent';
import { IMultipleCheckboxComponentProps } from '../../../../utils/entities/genericComponents/ICheckbox';

const MultipleCheckboxComponent = ({
  checkboxes,
  selected,
  setSelected,
}: IMultipleCheckboxComponentProps) => {
  const { t } = useTranslation();

  const indeterminate = selected.length > 0 && selected.length < checkboxes.length;
  const allSelected = selected.length === checkboxes.length;

  const handleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected([...checkboxes.filter((c) => !c.disabled).map((c) => c.id)]);
    }
  };

  const handleRoleSelected = (roleId: string) => {
    const currentIndex = selected.indexOf(roleId);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(roleId);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  return (
    <>
      <FormControlLabel
        disabled={checkboxes.every((checkbox) => checkbox.disabled)}
        control={<CheckboxComponent checked={allSelected} indeterminate={indeterminate} />}
        label={t('SELECT_ALL')}
        onClick={handleSelectAll}
      />
      <List>
        {checkboxes.map((checkbox) => (
          <ListItem key={checkbox.id} disablePadding>
            <ListItemButton
              disabled={checkbox.disabled}
              onClick={() => handleRoleSelected(checkbox.id)}
              dense>
              <ListItemIcon>
                <CheckboxComponent checked={selected.indexOf(checkbox.id) !== -1} />
              </ListItemIcon>
              <ListItemText primary={checkbox.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default MultipleCheckboxComponent;
