import { Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import React from 'react';
import { CONSTANTS } from '../../../../../../utils/constants/constants';
import dateUtils from '../../../../../../utils/functions/dateUtils';
import { ILinkCase } from '../../../../../../utils/entities/case/CaseHeader/ILinkCase';
import { PermissionTypes } from '../../../../../../utils/constants/enums';
import GeneralUtils from '../../../../../../utils/functions/generalUtils';
import { deleteCaseLink } from '../../../../../../service/case-manager.service';
import { addAlert } from '../../../../../../store/actions/alerts.actions';
import { useTranslation } from 'react-i18next';
import { ILinkedCasesProps } from '../../../../../../utils/entities/case/CaseHeader/case-link/linked-cases/ILinkedCases';
import IconButtonComponent from '../../../../../generic/button/IconButtonComponent';
import Loader from '../../../../../loader/Loader';

const LinkedCasesTable = ({
  retrieveLinks,
  timeZone,
  dateFormat,
  caseId,
  linkCaseDetails,
  isLinkedCasesLoading,
}: ILinkedCasesProps) => {
  const { t } = useTranslation();
  const canDeleteLinkCases = GeneralUtils.checkUserPermissions(PermissionTypes.LINK_CASES_DELETE);

  const deleteLink = (data: ILinkCase) => {
    deleteCaseLink(data)
      .then(() => {
        retrieveLinks(caseId);
        addAlert({
          type: 'success',
          primaryText: t('CASE_LINK.DELETED'),
        });
      })
      .catch(() => {
        addAlert({
          type: 'error',
          primaryText: t('ERROR_MESSAGE'),
        });
      });
  };

  return (
    <>
      {isLinkedCasesLoading ? (
        <Loader />
      ) : (
        <Table
          className="link-case-table"
          size="small"
          sx={{ display: linkCaseDetails.length ? '' : 'none' }}>
          <TableHead>
            <TableRow>
              {CONSTANTS.LINK_COLUMN.map((col: string, i: number) => (
                <TableCell key={col} data-testid={`column-header-${i}`}>
                  <b>{col}</b>
                </TableCell>
              ))}
              {canDeleteLinkCases && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {linkCaseDetails.map((row: ILinkCase) => (
              <TableRow
                data-testid="row-1"
                key={`${row.caseId}-${row.linkCaseId}-${row.relation}-${row.createdOn}`}>
                <TableCell>{row.linkCaseId}</TableCell>
                <TableCell>{row.relation}</TableCell>
                <TableCell>
                  {dateUtils.dateFormating(
                    dateUtils.timeZone(Number(row.createdOn), timeZone) as string,
                    dateFormat
                  )}
                </TableCell>
                <TableCell>{row.createdBy}</TableCell>
                {canDeleteLinkCases && (
                  <TableCell>
                    <IconButtonComponent icon={'delete'} handleClick={() => deleteLink(row)} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default LinkedCasesTable;
