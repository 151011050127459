import React, { useState, useEffect, useRef } from 'react';
import {
  Grid,
  Tooltip,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
  TableBody,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  IRSearchResult,
  IRLink,
  IResultUrl,
  IGenerateReport,
  IFileContent,
  IFileContentData,
  IDeleteDocList,
  ISelectedLinkData,
} from '../../../utils/entities/dueDiligence';
import {
  getAllDueDiligenceRequests,
  generateIRReport,
  deleteUploadIRScreenshots,
  refreshResults,
} from '../../../service/due-diligence.service';
import {
  getDocumentContent,
  deleteDocumentById,
  uploadDocumentAsBinary,
} from '../../../service/content-manager.service';
import CheckboxComponent from '../../generic/inputs/checkbox/CheckboxComponent';
import { addAlert } from '../../../store/actions/alerts.actions';
import { useTranslation } from 'react-i18next';
import { IAccessToken } from '../../../utils/entities/authentication';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../utils/constants/constants';
import IconButtonComponent from '../../generic/button/IconButtonComponent';
import PrimaryButton from '../../generic/buttons/PrimaryButton';
import ConfirmationModal from '../../generic/modal/ConfirmationModal';
import { IConfirmationContent } from '../../../utils/entities/genericComponents/IConfirmationContent';
import axios from 'axios';
import PlainTextButton from '../../generic/button/PlainTextButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IFileUrl, IFile } from '../../../utils/entities/document-center';
import { useMsal } from '@azure/msal-react';
import mime from 'mime';
import docUtils from '../../../utils/functions/docUtils';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import InfoIcon from '@mui/icons-material/Info';
import GeneralUtils from '../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import LazyLoadingImage from './LazyLoadingComponent';
import Loader from '../../loader/Loader';

const IRSearchResults = (props: {
  caseId: string;
  tenant: string;
  searchResults: any;
  maxFileSize?: number;
}) => {
  const [resultsRefreshing, setResultsRefreshing] = useState<boolean>(false);
  const [imageDocId, setImageDocId] = useState<Array<string>>([]);
  const [searchResults, setSearchResults] = useState<Array<IRSearchResult>>([]);
  const [fileContent, setFileContent] = useState<IFileContentData>({
    status: '',
    list: [],
    link: '',
    text: '',
  });
  const [generateReportList, setGenerateReportList] = useState<Array<IGenerateReport>>([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [confirmModalContent, setConfirmModalContent] = useState<IConfirmationContent>({
    title: '',
    text: '',
  });
  const [deleteDocList, setDeleteDocList] = useState<IDeleteDocList>({
    id: '',
    oldImageDocIds: [],
    oldDocumentManagerIds: [],
  });
  const [isFileDialogOpen, setIsFileDialogOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<Array<IFile>>([]);
  const [dragActive, setDragActive] = useState<boolean>(false);
  const [fileUrls, setFileUrls] = useState<Array<IFileUrl>>([]);
  const [dueDiligenceId, setDueDiligenceId] = useState<string>('');
  const [loadedImages, setLoadedImages] = useState<number>(0);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const interactedDataRef = useRef<ISelectedLinkData | null>(null);

  const projectName = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
  );

  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const { t } = useTranslation();

  useEffect(() => {
    GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IR_RESULTS_LOADING_EVT, false);
  }, []);

  useEffect(() => {
    setResultsRefreshing(true);
    getIRSearchResults();
  }, [props.caseId]);

  useEffect(() => {
    if (props.searchResults) {
      setSearchResults(props.searchResults);
      setResultsRefreshing(false);
    }
  }, [props.searchResults]);

  const updateLiveIRDataEventListener = (e: MessageEvent) => {
    setSearchResults(JSON.parse(e.data));
    getRecordWithUpdatedData(JSON.parse(e.data));
  };

  const getRecordWithUpdatedData = (records: Array<IRSearchResult>) => {
    const selectedData = interactedDataRef.current || null;
    if (selectedData) {
      const initialAccumulator: ISelectedLinkData | null = null;
      const matchingRecord = records[0]?.Data?.reduce((acc: ISelectedLinkData | null, record) => {
        if (record.id === selectedData.id && record.resultUrls) {
          const matchingResultUrl = record.resultUrls.find(
            (resultUrl: IResultUrl) => resultUrl.link === selectedData.link
          );
          if (matchingResultUrl) {
            acc = {
              documentManagerIds: matchingResultUrl?.documentManagerIds,
              status: record?.status,
              link: matchingResultUrl?.link,
              text: matchingResultUrl?.text,
              id: record?.id,
            };
          }
        }
        return acc;
      }, initialAccumulator);
      handleVisibilityIconClick(
        matchingRecord?.documentManagerIds ?? [],
        matchingRecord?.status ?? '',
        matchingRecord?.link ?? '',
        matchingRecord?.text ?? '',
        matchingRecord?.id ?? ''
      );
    }
  };

  GeneralUtils.sseEvent(
    EVENTS_CONSTANTS.IR_RESULTS_LOADING_EVT,
    refreshResults(props.caseId, 'IR'),
    true,
    {
      ir_search: updateLiveIRDataEventListener,
    }
  );

  const getIRSearchResults = () => {
    getAllDueDiligenceRequests(props.caseId, 'IR').then((apiResponse: any) => {
      if (apiResponse) {
        setSearchResults(apiResponse);
        setResultsRefreshing(false);
      }
    });
  };

  const getLinksCount = () => {
    return searchResults && searchResults.length > 0 && searchResults[0]?.Data
      ? searchResults[0]?.Data.reduce((acc, curr) => {
          return acc + (curr.resultUrls && curr.resultUrls.length ? curr.resultUrls.length : 0);
        }, 0)
      : 0;
  };

  const getLinks = () => {
    const acc: Array<IRLink> = [];
    if (searchResults && searchResults.length > 0 && searchResults[0]?.Data) {
      searchResults[0]?.Data.map((curr) => {
        if (curr.resultUrls !== null) {
          curr.resultUrls.map((el: IResultUrl) => {
            if (el.link && curr.entity) {
              acc.push({
                id: curr.id,
                link: el.link,
                text: el.text,
                imageDocId: el.imageDocId,
                imageDocIds: el.imageDocIds,
                documentManagerIds: el.documentManagerIds,
                searchTerm: curr.entity,
                partyName: curr.partyName,
                status: curr.status,
                error: curr.error,
              });
            }
          });
        }
      });
    }
    return acc;
  };

  const handleGenerateReport = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (generateReportList.length != 0) {
      generateIRReport({
        apiName: 'IR-REPORT',
        app: projectName,
        applicationName: projectName,
        caseId: props.caseId,
        color: '1',
        partyName: generateReportList[generateReportList.length - 1].partyName,
        projectName: projectName,
        tenant: props.tenant,
        userName: userInfo.user,
        reportData: generateReportList.map((el) => {
          return {
            ...el,
            imageDocID: null,
            documentManagerID: null,
            searchDate: null,
            userInputAddress: null,
            formattedAddress: null,
            finalAddress: null,
          };
        }),
        addressValidation: [{}],
      }).then((apiResponse: any) => {
        addAlert({
          type: 'success',
          primaryText: t('REQUEST_SUCCESS'),
        });
        setGenerateReportList([]);
      });
    }
  };

  const scrollImageContainerToTop = () => {
    if (imageContainerRef.current) {
      imageContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleVisibilityIconClick = (
    documentManagerIds: Array<string>,
    status: string,
    link: string,
    text: string,
    id: string
  ) => {
    if (status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS) {
      setFileContent({
        status: CONSTANTS.DD.DD_IR_STATUS_INPROGRESS,
        list: [],
        link: link,
        text: text,
      });
      return;
    }
    if (status == CONSTANTS.DD.DD_IR_STATUS_COMPLETE) {
      const tempFileContent: Array<IFileContent> = [];
      scrollImageContainerToTop();
      setLoadedImages(0);
      setImageDocId(documentManagerIds);
      if (documentManagerIds.length == 0) {
        setFileContent({
          status: CONSTANTS.DD.DD_IR_STATUS_COMPLETE,
          list: [],
          link: link,
          text: text,
        });
        setDueDiligenceId(id);
        return;
      }

      for (const imageDoc in documentManagerIds) {
        getDocumentContent(documentManagerIds[imageDoc].split('.')[0], props.caseId, props.tenant)
          .then((blobData) => {
            const reader = new FileReader();
            reader.readAsDataURL(blobData);
            reader.onloadend = function () {
              const base64data = reader.result ? reader.result.toString() : '';
              tempFileContent.push({ index: imageDoc, data: base64data });
              setFileContent({
                status: CONSTANTS.DD.DD_IR_STATUS_COMPLETE,
                list: [...tempFileContent],
                link: link,
                text: text,
              });
              return;
            };
          })
          .catch((error: string | number) => {
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          });
      }
    }
  };

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    imageDocIds: Array<string>,
    link: string,
    searchTerm: string,
    imageDocId: string,
    partyName: string
  ) => {
    if (event.target.checked) {
      setGenerateReportList([
        ...generateReportList,
        {
          link: link,
          imageDocIDs: imageDocIds,
          searchTerm: searchTerm,
          imageDocId: imageDocId,
          partyName: partyName ? partyName : CONSTANTS.DD.DUE_DILIGENCE_EMPTY_PARTY_NAME,
        },
      ]);
    } else {
      setGenerateReportList(generateReportList.filter((el) => el.imageDocIDs != imageDocIds));
    }
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const tempGenerateReportList = getLinks()
        .filter(
          (el) =>
            !(
              el.documentManagerIds.length == 0 || el.status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS
            )
        )
        .map((el) => {
          return {
            link: el.link,
            imageDocIDs: el.imageDocIds,
            searchTerm: el.searchTerm,
            imageDocId: el.imageDocId,
            partyName: el.partyName,
          };
        });
      setGenerateReportList(tempGenerateReportList);
    } else {
      setGenerateReportList([]);
    }
  };

  const isSelectAllChecked = () => {
    return (
      getLinks().filter(
        (el) =>
          !(el.documentManagerIds.length == 0 || el.status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS)
      ).length == generateReportList.length && generateReportList.length != 0
    );
  };

  const handleDeleteIconClick = (
    id: string,
    imageDocIds: Array<string>,
    documentManagerIds: Array<string>
  ) => {
    setDeleteDocList({
      id: id,
      oldImageDocIds: imageDocIds,
      oldDocumentManagerIds: documentManagerIds,
    });
    const title = t('DELETE');
    const body = <div className="text-align-center">{t('DD.IR_DELETE_SCREENSHOT')}</div>;
    setConfirmModalContent({ title: title, text: body });
    setConfirmModalOpen(true);
  };

  const handleDeleteDocuments = () => {
    const requests = deleteDocList.oldDocumentManagerIds.map((imageDocId) => {
      deleteDocumentById(imageDocId.split('.')[0]);
    });

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          setConfirmModalOpen(false);
          deleteUploadIRScreenshots(deleteDocList.id, {
            operationType: 'delete',
            link: '',
            text: '',
            oldImageDocIds: deleteDocList.oldImageDocIds,
            oldDocumentManagerIds: deleteDocList.oldDocumentManagerIds,
            newDocumentMangerId: [],
          })
            .then((apiResponse: any) => {
              setDeleteDocList({ id: '', oldImageDocIds: [], oldDocumentManagerIds: [] });
              addAlert({
                type: 'success',
                primaryText: t('REQUEST_SUCCESS'),
              });
              getIRSearchResults();
              GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IR_RESULTS_LOADING_EVT, false);
              setFileContent({ status: '', list: [], link: '', text: '' });
            })
            .catch((error: string | number) => {
              addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
            });
        })
      )
      .catch((error: string | number) => {
        addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
      });
  };

  const handleCancelDeleteDocuments = () => {
    setConfirmModalOpen(false);
    setDeleteDocList({ id: '', oldImageDocIds: [], oldDocumentManagerIds: [] });
  };

  const addFileTrigger = () => {
    setIsFileDialogOpen(true);
  };

  const inputRef = useRef(null);
  const { accounts } = useMsal();

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e?.dataTransfer?.files?.[0]) {
      const files: Array<File> = Array.from(e.dataTransfer.files);
      scanDocument(files);
      handleUploadFiles(files);
    }
  };

  const selectFile = (event: any) => {
    const files: Array<File> = Array.from(event.target.files);

    let fileNameError = false;
    let fileSizeError = false;

    files.map((file: File) => {
      if (
        file.name.split('.').pop()?.toLowerCase() !== 'png' &&
        file.name.split('.').pop()?.toLowerCase() !== 'jpeg' &&
        file.name.split('.').pop()?.toLowerCase() !== 'jpg'
      ) {
        addAlert({
          type: 'error',
          primaryText: t('DD.IR_UPLOAD_FILE_FORMAT_ERROR'),
        });
        fileNameError = true;
      }
    });

    if (fileNameError) {
      return;
    }

    files.map((file: File) => {
      if (props.maxFileSize && file.size > props.maxFileSize) {
        addAlert({
          type: 'error',
          primaryText: t('DD.IR_UPLOAD_FILE_SIZE_ERROR', { num: formatFileSize() }),
        });
        fileSizeError = true;
      }
    });

    if (fileSizeError) {
      return;
    }

    const urls: Array<IFileUrl> = files.map((file: File) => {
      return {
        url: URL.createObjectURL(file),
        type: file.type,
      };
    });
    setFileUrls([...fileUrls, ...urls]);
    scanDocument(files);
    handleUploadFiles(files);
  };

  const handleUploadFiles = (files: Array<File>) => {
    const uploaded: IFile[] = [];
    files.some((file: IFile) => {
      file.active = true;
      file.documentName = file.name;
      file.documentType = CONSTANTS.DOC_CENTER.DOC_TYPE;
      file.documentSubType = '';
      file.expirationDate = CONSTANTS.NULL_EPOCH;
      file.uploadedBy = accounts[0]?.username;
      if (uploaded.findIndex((f) => f.name === file.name) === -1 && !isDoubleExtension(file.name)) {
        uploaded.push(file);
      } else {
        addAlert({
          type: 'error',
          primaryText: t('DOC_CENTER.FILE_INVALID'),
        });
      }
    });
    setSelectedFiles(uploaded);
  };

  const isDoubleExtension = (fileName: string) => {
    const namePieces = fileName.split('.');
    if (namePieces.length == 1) {
      return true;
    }
    if (namePieces.length == 2) {
      return false;
    }
    return namePieces.length > 2 && mime.getType(namePieces[namePieces.length - 2].toLowerCase());
  };

  const scanDocument = (files: Array<File>) => {
    docUtils
      .scanDocument(files)
      .then((passed: boolean) => {
        files.forEach(() => virusResponse(passed));
      })
      .catch(() => {
        virusResponse(false);
      });
  };

  const virusResponse = (passed: boolean) => {
    if (!passed) {
      addAlert({
        type: 'error',
        primaryText: t('DOCUMENT_FAILED_VIRUS_SCAN'),
      });
    }
  };

  const outsideButtonClick = () => {
    document.getElementById('selectImage')?.click();
    addFileTrigger();
  };

  const closeDialog = () => {
    setFileUrls([]);
    setSelectedFiles([]);
    setIsFileDialogOpen(false);
  };

  const handleUploadFilesComplete = () => {
    uploadFiles();
    closeDialog();
  };

  const uploadFiles = () => {
    selectedFiles.map((file: IFile | any) => {
      return uploadDocumentAsBinary(
        file,
        file.name,
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME),
        file.expirationDate ?? 0,
        file.documentName,
        file.documentType,
        file.documentSubType,
        file.uploadedBy
      )
        .then((res) => {
          deleteUploadIRScreenshots(dueDiligenceId, {
            operationType: 'replace',
            link: fileContent.link,
            text: fileContent.text,
            oldImageDocIds: [],
            oldDocumentManagerIds: [],
            newDocumentMangerId: [
              res.concat(
                '.',
                selectedFiles[0].name.split(/[.]+/).pop()?.toLowerCase() == 'jpeg'
                  ? 'jpg'
                  : (selectedFiles[0].name.split(/[.]+/).pop()?.toLowerCase() ?? '')
              ),
            ],
          })
            .then((apiResponse: any) => {
              setFileContent({ status: '', list: [], link: '', text: '' });
              setDueDiligenceId('');
              addAlert({
                type: 'success',
                primaryText: t('REQUEST_SUCCESS'),
              });
            })
            .catch((error: string | number) => {
              addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
            });
        })
        .catch((error: any) => {
          addAlert({
            type: 'error',
            primaryText: error ?? '',
          });
          return null;
        })
        .finally(() => {
          GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IR_RESULTS_LOADING_EVT, false);
        });
    });
  };

  const deleteFile = () => {
    setFileUrls([]);
    setSelectedFiles([]);
  };

  const formatFileSize = () => {
    if (props.maxFileSize === 0) return '0 ';

    const i = Math.floor(
      Math.log(props.maxFileSize as number) / Math.log(CONSTANTS.DOC_CENTER.BYTE_TRESHOLD)
    );
    return (
      Math.round(
        parseFloat(
          ((props.maxFileSize as number) / Math.pow(CONSTANTS.DOC_CENTER.BYTE_TRESHOLD, i)).toFixed(
            CONSTANTS.DOC_CENTER.DECIMALS
          )
        )
      ) + `${CONSTANTS.FILE_SIZE[i]}`
    );
  };

  const handleInterimData = (data: ISelectedLinkData) => {
    interactedDataRef.current = data;
  };

  return (
    <>
      <div
        data-testid="ir-search-results"
        className="card-view search-criteria width-100-per search-results-list show-scroll overflow-y-scroll">
        <div className="av-ir-tr-clear">
          <Grid container className="padding-10">
            <Grid item xs={12} md={12} lg={12} xl={8} sm={12}>
              <div className="links-returned">Links Returned</div>
              <div className="color-green">{getLinksCount()}</div>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              xl={4}
              sm={12}
              className="padding-0 text-align-right">
              <PrimaryButton
                isDisabled={generateReportList.length == 0}
                onClick={handleGenerateReport}
                classes="generate-repot-btn"
                isPositiveButton={true}
                text={t('DD.GENERATE_REPORT')}
              />
            </Grid>
          </Grid>
          <Grid container className="padding-left-right-10">
            {getLinks().length > 0 && (
              <Grid container>
                <Grid item xs={9} md={9} lg={9} xl={9} sm={9} />
                <Grid item xs={3} md={3} lg={3} xl={3} sm={3} className="text-align-right">
                  <span>{t('SELECT')}</span>
                  <CheckboxComponent
                    checked={isSelectAllChecked()}
                    onClick={(e) => {
                      handleSelectAllClick(e);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {getLinks()
              .slice()
              .reverse()
              .map((el, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12}
                    sm={12}
                    key={index}
                    className={
                      el.link === interactedDataRef?.current?.link
                        ? 'border-top active'
                        : 'border-top'
                    }>
                    <Grid container id="ir-link">
                      <Grid
                        item
                        xs={6}
                        md={6}
                        lg={7}
                        xl={8}
                        sm={6}
                        className="ir-link margin-vertical-10"
                        onClick={() => {
                          handleInterimData({
                            documentManagerIds: el.documentManagerIds,
                            status: el.status,
                            link: el.link,
                            text: el.text,
                            id: el.id,
                          });
                          handleVisibilityIconClick(
                            el.documentManagerIds,
                            el.status,
                            el.link,
                            el.text,
                            el.id
                          );
                        }}>
                        <div className="link break-word padding-left-0">{el.link}</div>
                        <div className="break-word">
                          <span>{el.text}</span>
                        </div>
                      </Grid>
                      <Grid item xs={6} md={6} lg={5} xl={4} sm={6} className="text-align-right">
                        <Tooltip title={<div>Delete screenshot for this link</div>} arrow>
                          <span>
                            <IconButtonComponent
                              handleClick={() =>
                                handleDeleteIconClick(el.id, el.imageDocIds, el.documentManagerIds)
                              }
                              classes="deleteIRIcon"
                              icon="delete"
                              isDisabled={
                                el.documentManagerIds.length == 0 ||
                                el.status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS
                              }
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title={<div>Open the link in a new tab</div>} arrow>
                          <span>
                            <IconButtonComponent
                              classes="link"
                              handleClick={() => window.open(el.link, '_blank')}
                              icon={'launch'}
                            />
                          </span>
                        </Tooltip>
                        <CheckboxComponent
                          checked={
                            generateReportList.filter((e) => {
                              return e.imageDocIDs === el.imageDocIds;
                            }).length > 0 ?? false
                          }
                          isDisabled={
                            el.status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS ||
                            el.documentManagerIds.length == 0
                          }
                          onClick={(e) => {
                            handleCheckboxClick(
                              e,
                              el.imageDocIds,
                              el.link,
                              el.searchTerm,
                              el.imageDocId,
                              el.partyName
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
      <div
        className="card-view search-criteria width-100-per search-results-details show-scroll overflow-y-scroll"
        ref={imageContainerRef}>
        {fileContent &&
          fileContent.status == CONSTANTS.DD.DD_IR_STATUS_COMPLETE &&
          fileContent.list.length > 0 &&
          (fileContent.list.length === imageDocId.length ? (
            <>
              {fileContent.list
                .sort((a, b) => (parseInt(a.index) > parseInt(b.index) ? 1 : -1))
                .map((file, idx) => (
                  <LazyLoadingImage
                    src={file.data}
                    alt={'IR_search_result_snap_shot' + idx}
                    className="ir-image-snap-shot"
                    key={'IR_search_result_snap_shot' + idx}
                    onLoad={() => {
                      setLoadedImages((prev) => prev + 1);
                    }}
                    shouldLoad={idx <= loadedImages}
                  />
                ))}
            </>
          ) : (
            <Loader />
          ))}

        {fileContent && fileContent.status == CONSTANTS.DD.DD_IR_STATUS_INPROGRESS && (
          <div className="av-invalid-search-container">{t('DD.DELETE_SCREENSHOT_IN_PROGRESS')}</div>
        )}
        {fileContent &&
          fileContent.status == CONSTANTS.DD.DD_IR_STATUS_COMPLETE &&
          fileContent.list.length == 0 && (
            <div className="av-invalid-search-container">
              <PlainTextButton
                handleClick={() => {
                  addFileTrigger();
                }}
                classes={`upload-screenshot`}
                icon={<AddCircleOutlineIcon />}
                label={t('DD.UPLOAD_SCREENSHOT')}
              />
            </div>
          )}
      </div>
      <ConfirmationModal
        showConfirmButton
        openModal={confirmModalOpen}
        setOpenModal={setConfirmModalOpen}
        action={handleDeleteDocuments}
        negativeAction={handleCancelDeleteDocuments}
        content={confirmModalContent}
      />
      {isFileDialogOpen && (
        <Dialog
          fullWidth={true}
          maxWidth={'xl'}
          open={isFileDialogOpen}
          onClose={() => closeDialog()}
          aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>{t('DOC_CENTER.UPLOAD')}</Box>
              <Box>
                <IconButton onClick={() => closeDialog()}>
                  <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers className="Content-style">
            <form id="form-file-upload" onDragEnter={handleDrag}>
              <div
                className={dragActive ? 'drag-active border-style' : 'border-style'}
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}>
                <Button onClick={outsideButtonClick} className="link-cursor add-document">
                  <CloudUploadIcon data-testid="icon-size-md" />
                  <span className="margin-left-right-10 text-transform-capitalize">
                    {t('DOC_CENTER.DRAG_DROP')}
                  </span>
                  <span style={{ marginLeft: 10, marginRight: 20 }}>{t('OR')}</span>
                  <AddCircleOutlineIcon />
                  <span className="margin-left-right-10 text-transform-capitalize">
                    {t('DOC_CENTER.ADD_FILES')}
                  </span>
                  <input
                    id="selectImage"
                    style={{ display: 'none' }}
                    onChange={selectFile}
                    type="file"
                    ref={inputRef}
                  />
                </Button>
              </div>
              <div>
                <InfoIcon />
                <span className="margin-left-10" style={{ verticalAlign: 'super' }}>
                  {t('DOC_CENTER.INFORM', {
                    num: formatFileSize(),
                    format: 'PNG, JPG, JPEG',
                  })}
                </span>
              </div>
            </form>
            <div>
              {selectedFiles && selectedFiles.length > 0 && (
                <div className="capture-document-info body-style">
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="filesTable">
                      <TableHead className="header-case">
                        <TableRow>
                          <TableCell>File Name</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedFiles.map((row: IFile, i: number) => (
                          <TableRow key={`frag_${row.name}`}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="center">
                              <IconButtonComponent handleClick={() => deleteFile()} icon="delete" />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="upload-dialog-action">
              <div>
                <button
                  className="nexus-btn margin-left-right-10 text-transform-capitalize"
                  onClick={() => closeDialog()}>
                  {t('CANCEL')}
                </button>
              </div>
              <div>
                <button
                  className="nexus-btn margin-left-right-10 text-transform-capitalize"
                  onClick={handleUploadFilesComplete}>
                  {t('DOC_CENTER.UPLOAD')}
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default IRSearchResults;
