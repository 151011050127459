import React, { useEffect } from 'react';
import ITab from '../../../utils/entities/screen/ITab';
import './ModuleTabs.scss';
import SpelUtils from '../../../utils/functions/spelUtils';
import { Stack, Tab } from '@mui/material';
import TabsComponent from '../../generic/tabs/TabsComponent';
import { CONSTANTS } from '../../../utils/constants/constants';
import { IAccessToken } from '../../../utils/entities/authentication';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import ErrorIcon from '@mui/icons-material/Error';
import { ITabBarComponent } from '../../../utils/entities/screen/tasks/ITabBarComponent';

const TabBarComponent = (props: ITabBarComponent) => {
  const {
    tabs,
    activeTabId,
    tabChanged,
    invalidTabs,
    setModalConfirmationClick,
    checkConfirmationModal,
    contextLocals,
  } = {
    ...props,
  };
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  useEffect(() => {
    //Add a value to the tab we want to add the confirmation modal
    tabs.forEach((tab: ITab) => {
      tab.confirmationModal = tab.type === 'NARRATIVE';
    });
  }, [tabs]);

  return (
    <div className="margin-inline-20">
      <TabsComponent
        activeTabId={activeTabId}
        className="taskTabs"
        tabs={tabs}
        tabChanged={tabChanged}
        tabsMap={tabs
          .filter((tab: ITab) =>
            contextLocals
              ? SpelUtils.expressionValidation(tab.visible, tab.visibilityExpression, {
                  contextLocals: contextLocals,
                  userRole: userInfo.role,
                })
              : tab.visible
          )
          .map((tab: ITab) => (
            <Tab
              value={tab.tabId}
              label={
                <Stack direction="row">
                  <div className="align-self-center">{tab.title}</div>
                  {invalidTabs?.find((invalidTab) => invalidTab.tabId === tab.tabId) && (
                    <ErrorIcon color="error" className="margin-left-10" />
                  )}
                </Stack>
              }
              key={tab.tabId}
            />
          ))}
        checkConfirmationModal={checkConfirmationModal}
        needsConfirmation={true}
        setModalConfirmationClick={setModalConfirmationClick}
      />
    </div>
  );
};

export default TabBarComponent;
