import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import ConfirmationModal from '../generic/modal/ConfirmationModal';
import LockIcon from '@mui/icons-material/Lock';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { IRichTextComponentProps } from '../../utils/entities/rich-text/IRichText';
import filePickerCallback from '../../types/rich-text/filePickerCallback';
import './RichTextComponent.scss';
import Loader from '../loader/Loader';

const RichTextComponent = (props: IRichTextComponentProps) => {
  const {
    required,
    readOnly,
    value,
    label,
    maxLength,
    setData,
    hideToolbar,
    noBorder,
    inline,
    enableLinks,
    enableImgs,
    enableMedia,
    fileUploadHandler,
    setOpenVersionDropdown,
    checkInputClick,
    setCheckInputClick,
  } = { ...props };
  const { t } = useTranslation();
  const editorRef = useRef<Editor | any | null>(null);
  const iconSvg = ReactDOMServer.renderToStaticMarkup(<LockIcon />);
  const svgIconWithXlmns = iconSvg.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
  const resultUrl = "url('data:image/svg+xml," + svgIconWithXlmns + "')";

  const customTag = 'fincrime-configuration-custom-element';
  const customTagEach = 'fincrime-configuration-custom-element-each';
  const customTagFreeText = 'fincrime-configuration-custom-element-free-text';

  const linkPlugin = enableLinks ? 'link' : '';
  const imgPlugin = enableImgs ? 'image' : '';
  const mediaPlugin = enableMedia ? 'media' : '';

  const toolbarOpts =
    'fontfamily fontsize | undo redo | styleselect | formatselect | bold italic underline backcolor forecolor | ' +
    ' alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lineheight | removeformat | ' +
    linkPlugin +
    ' ' +
    imgPlugin +
    ' ' +
    mediaPlugin;
  const pluginsOpts = `importcss lists autoresize ${linkPlugin} ${imgPlugin} ${mediaPlugin}`;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedElement, setSelectedElement] = useState<HTMLElement>();
  const [isLoading, setIsLoading] = useState<boolean>(!editorRef.current);

  const initFilePicker = {
    image_title: true,
    file_picker_types: `file ${imgPlugin}`,
    file_picker_callback: function (
      cb: filePickerCallback,
      value: string,
      meta: { [key: string]: any }
    ) {
      const input = document.createElement('input');
      const type = meta?.filetype === 'image' ? 'image/*' : meta?.filetype;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', type);

      input.onchange = function (this: GlobalEventHandlers, e: Event) {
        const files: FileList | null = (e.target as HTMLInputElement)?.files;
        const file = files ? files['0'] : null;
        if (file && editorRef?.current) {
          const reader = new FileReader();
          reader.onload = function () {
            fileUploadHandler?.(
              cb,
              file,
              meta?.filetype ?? '',
              reader,
              editorRef.current.editorUpload.blobCache
            );
          };

          reader.readAsDataURL(file);
        }
      };

      input.click();
    },
  };

  const extendedOpts = `${customTag}[id|name|contenteditable|class|onclick|alt],${customTagFreeText}[id|name|contenteditable|class|onclick|alt],${customTagEach}[id|name|contenteditable|class|onclick|alt]`;
  const contentStyleOpts = `
  body {
    font-family: Arial;
  }
  .blue-bubble {
    background-color: #a3dbff;
    border-radius: 5px;
    padding: 3px;
    color: black;
  }
  .blue-bubble[contenteditable="false"]::before{
    content: ${resultUrl};
    width: 1em;
    height: 1em;
    display: inline-block;
    background-size: 100%;
  }
  .orange-bubble {
    background-color: #ffe567;
    border-radius: 5px;
    padding: 3px;
  }
  ${customTagEach}{
    display: inline-block;
  }
  `;

  const unlockBubble = () => {
    if (selectedElement) {
      selectedElement.setAttribute('contenteditable', 'true');
      selectedElement.setAttribute('data-mce-contenteditable', 'true');
    }
  };

  const isBlueBubbleLocked = (element: HTMLElement) => {
    return (
      (element.tagName == customTag.toUpperCase() ||
        element.tagName == customTagEach.toUpperCase()) &&
      element.classList.contains('blue-bubble') &&
      element.getAttribute('contenteditable') == 'false'
    );
  };

  const onChange = (newValue: string) => {
    if (maxLength !== undefined) {
      const newRemainingChars = maxLength - newValue.replace(/(<([^>]+)>)/gi, '').length;
      if (newRemainingChars <= 0) {
        return;
      }
    }
    setData && setData(newValue);
  };

  const getAllIndexes = (string: string, substring: string): Array<number> => {
    const indexes: Array<number> = [];
    for (let i = 0; i < string.length - substring.length + 1; i++) {
      if (string.substring(i, substring.length + i) == substring) {
        indexes.push(i);
      }
    }
    return indexes;
  };

  const replaceLast = (original: string, what: string, replacement: string) => {
    const pcs = original.split(what);
    const lastPc = pcs.pop();
    return pcs.join(what) + replacement + lastPc;
  };

  const getClosingTagAndPositionsInside = (string: string, char1: string, char2: string) => {
    const start = string.indexOf(char1);
    const end = string.indexOf(char2);
    return { substring: string.slice(start, end + 1), start: start, end: end };
  };

  const removeClosingtags = (template: string): string => {
    const tag = getClosingTagAndPositionsInside(template, '<', '>');
    let slicedTemplate = template;
    if (
      tag.substring != '' &&
      tag.substring.charAt(1) == '/' &&
      tag.substring != `</${customTagEach}>`
    ) {
      slicedTemplate = removeClosingtags(template.replace(tag.substring, ''));
    }
    return slicedTemplate;
  };

  const getOpeningTagAndPositionsInside = (string: string, char1: string, char2: string) => {
    const start = string.lastIndexOf(char1);
    const end = string.lastIndexOf(char2);
    return { substring: string.slice(start, end + 1), start: start, end: end };
  };

  const removeOpeningtags = (template: string): string => {
    const tag = getOpeningTagAndPositionsInside(template, '<', '>');
    let slicedTemplate = template;
    if (
      tag.substring != '' &&
      tag.substring.charAt(1) != '/' &&
      !tag.substring.includes(customTagEach)
    ) {
      slicedTemplate = removeOpeningtags(replaceLast(template, tag.substring, ''));
    }
    return slicedTemplate;
  };

  const getPosition = (string: string, subString: string, index: number) => {
    return string.split(subString, index).join(subString).length;
  };

  const getStringBetweenstrings = (string: string, char1: string, char2: string) => {
    const start = string.indexOf(char1);
    let end = string.indexOf(char2);
    if (char1 == char2) {
      end = getPosition(string, char2, 2);
    }
    return { substring: string.slice(start, end + 1), start: start, end: end };
  };

  const insertAt = (str: string, substring: string, index: number) => {
    return str.slice(0, index) + substring + str.slice(index);
  };

  const clearTemplate = (template: string) => {
    const positions = getAllIndexes(template, customTagEach);

    let resolvedTemplate = template;
    for (let i = positions.length - 1; i >= 0; i--) {
      const roughBeforeTag = resolvedTemplate.slice(0, positions[i]);
      const roughAfterTag = resolvedTemplate.slice(
        positions[i] + customTagEach.length,
        resolvedTemplate.length
      );
      if (resolvedTemplate.charAt(positions[i] - 1) != '/') {
        const beforeTag = replaceLast(roughBeforeTag, '<', '');
        const idContent = getStringBetweenstrings(roughAfterTag, '"', '"');
        const afterTagNoId = roughAfterTag.replace(idContent.substring, '');
        const restOfTag = afterTagNoId.substring(0, afterTagNoId.indexOf('>') + 1);
        const afterTag = afterTagNoId.replace(restOfTag, '');
        const cleanBeforeTag = removeOpeningtags(beforeTag);
        const cleanAfterTag = removeClosingtags(afterTag);
        const tag = `<${customTagEach}${insertAt(restOfTag, idContent.substring, idContent.start)}`;
        resolvedTemplate = `${cleanBeforeTag}${tag}${cleanAfterTag}`;
      } else {
        const beforeTag = replaceLast(roughBeforeTag, '</', '');
        const afterTag = roughAfterTag.replace('>', '');
        const cleanBeforeTag = removeOpeningtags(beforeTag);
        const cleanAfterTag = removeClosingtags(afterTag);
        resolvedTemplate = `${cleanBeforeTag}</${customTagEach}>${cleanAfterTag}`;
      }
    }

    return resolvedTemplate;
  };

  return (
    <div className="rich-text-component flex-column">
      {label && (
        <label htmlFor="react-quill">
          {label}
          {required ? '*' : ''} :
        </label>
      )}

      {isLoading && <Loader />}
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(event: Event, editor: Editor) => {
          editorRef.current = editor;
          setIsLoading(false);
        }}
        value={clearTemplate(value)}
        disabled={readOnly}
        onEditorChange={onChange}
        init={
          {
            inline: inline,
            skin: noBorder ? 'borderless' : 'oxide',
            statusbar: false,
            height: 400,
            menubar: false,
            plugins: pluginsOpts,
            toolbar: hideToolbar ? false : toolbarOpts,
            extended_valid_elements: extendedOpts,
            custom_elements: `~${customTag},~${customTagFreeText},${customTagEach}`,
            ...initFilePicker,
            link_default_target: '_blank',
            link_target_list: false,
            content_style: contentStyleOpts,
            setup: function (editor: any) {
              editor.on('click', (e: any) => {
                const element: HTMLElement = e.target;

                if (isBlueBubbleLocked(element)) {
                  setOpenModal(true);
                  if (setOpenVersionDropdown) {
                    setOpenVersionDropdown(false);
                  }
                  setSelectedElement(element);
                }
              });
              if (!checkInputClick) {
                editor.on('keydown', () => {
                  if (setCheckInputClick) {
                    setCheckInputClick(true);
                  }
                });
              }
            },
          } as any
        }
      />
      <ConfirmationModal
        showConfirmButton
        openModal={openModal}
        setOpenModal={setOpenModal}
        action={unlockBubble}
        content={{
          title: t('LOCKED_FIELD'),
          text: t('LOCKED_FIELD_TEXT'),
        }}
        buttonsTextOverride={{
          yes: t('UNLOCK'),
          no: t('CANCEL'),
        }}
      />
    </div>
  );
};

export default RichTextComponent;
