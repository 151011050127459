import React, { useCallback, useMemo } from 'react';

import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';

import { addAlert } from '../../../../store/actions/alerts.actions';
import ITextFieldComponentProps from '../../../../utils/entities/genericComponents/input/input-label/ITextFieldComponent';

const TextFieldComponent: React.FC<ITextFieldComponentProps> = ({
  disabled = false,
  max = '',
  maxLength = CONSTANTS.TEXT_FIELD_LENGTH,
  min = '',
  minLength = 0,
  placeholder = '',
  required = false,
  type = 'text',
  value,
  label = '',
  setData,
  cols = 100,
  blur,
  icon,
  errorExternal,
  setErrorMessageValue,
  readonly,
}) => {
  const { t } = useTranslation();
  const errorMessage = useMemo(() => t('INFOSEC_CHARS_ERROR'), [t]);

  const onChange = useCallback(
    (newValue: string) => {
      const isValidInfoSecString = GeneralUtils.isValidInfoSecString(newValue);
      if (isValidInfoSecString) {
        setData(newValue);
        setErrorMessageValue?.('');
      } else {
        addAlert({ type: 'error', primaryText: errorMessage });
        if (errorExternal) {
          setErrorMessageValue?.(errorMessage);
        }
      }
    },
    [setData, setErrorMessageValue, addAlert, errorMessage, errorExternal]
  );

  return (
    <FormControl fullWidth margin="normal">
      <span data-testid="label-first-name">
        {label}
        {required ? '*' : ' '}
      </span>
      <TextField
        variant="standard"
        aria-label={label}
        aria-describedby={label}
        disabled={disabled}
        placeholder={placeholder ?? ''}
        required={required}
        type={type}
        data-testid="input-text-box"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputProps={{ minLength: minLength, maxLength: maxLength, min: min, max: max }}
        onBlur={blur}
        InputProps={{
          endAdornment: icon,
        }}
      />
    </FormControl>
  );
};

export default TextFieldComponent;
