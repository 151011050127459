import { IAlertProps } from '../../utils/entities/genericComponents/IAlertComponent';

interface IAction {
  type: 'SHIFT_ALERT' | 'PUSH_ALERT';
  payload?: IAlertProps;
}

// Alerts reducer works as a FIFO
// Equal adjacents messages will be ignored
const alertsReducer = (state: IAlertProps[] = [], action: IAction) => {
  switch (action.type) {
    case 'PUSH_ALERT':
      if (state.length > 0) {
        const lastAlert = state[state.length - 1].msg;
        if (
          lastAlert.primaryText === action.payload?.msg.primaryText &&
          lastAlert.type === action.payload?.msg.type
        ) {
          return [...state];
        }
      }
      return [...state, action.payload];
    case 'SHIFT_ALERT':
      return [...state.slice(1)];
    default:
      return state;
  }
};

export default alertsReducer;
