import React, { useEffect, useState } from 'react';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import QuestionTooltip from '../question-tooltip/QuestionTooltip';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, ListItemText, MenuItem, Select, Stack, Tooltip } from '@mui/material';
import ISelectOption from '../../../../utils/entities/genericComponents/select/ISelectOption';
import SpelUtils from '../../../../utils/functions/spelUtils';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import CheckboxComponent from '../../../generic/inputs/checkbox/CheckboxComponent';
import { IAccessToken } from '../../../../utils/entities/authentication';
import IQuestionType from '../../../../utils/entities/questionnaire/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/dynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import { IRefData } from 'fso-fincrime-sdk-ui';
import IQuestion from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';
import IValue from '../../../../utils/entities/screen/IValue';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';

const MultiselectQuestion = (props: IQuestionType) => {
  const {
    question,
    updateValue,
    updateLocals,
    disabled,
    locals,
    contextLocals,
    entity,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const { t } = useTranslation();
  const readOnlyResult = SpelUtils.expressionValidation(
    question.readOnly,
    question.readOnlyExpression,
    {
      contextLocals,
      userRole: userInfo.role,
      ...locals,
    }
  );
  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    {
      contextLocals,
      userRole: userInfo.role,
      ...locals,
    }
  );
  const storedValue: string[] | undefined = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  )?.split(',');

  const oldValue = requestBody?.dynamicQuestionnaire?.[activeTabId!]?.sets[
    setIndex as number
  ].questions.find((currentQuestion: IQuestion) => currentQuestion.id == question.id) as IQuestion;

  const [value, setValue] = useState<Array<string>>(
    DynamicQuestionnaireUtils.getQuestionValueAsArray(storedValue, question.value, entity)
  );

  const [previousValue, setPreviousValue] = useState<Array<string>>(
    DynamicQuestionnaireUtils.getQuestionValueAsArray(storedValue, oldValue?.value, entity)
  );

  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  const multiSelectOptions: Array<ISelectOption> = question.responseOptions.map(
    (element: IRefData) => {
      return {
        label: element.label,
        value: element.value,
        isSelected: value?.includes(element.value),
      };
    }
  );

  const [multiSelectData, setMultiSelectData] = useState<Array<ISelectOption>>(multiSelectOptions);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );
    const valueArray = [...value];
    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value?.toString();

    updateLocals(locals);

    if (value) {
      for (const val of value) {
        if (
          DynamicQuestionnaireUtils.hasRuntimeError(question.validation, val, {
            ...{
              contextLocals,
              userRole: userInfo.role,
              ...locals,
            },
            [question.attributeName]: val,
          })
        ) {
          setRuntimeValidationError(true);
          break;
        }
      }
    }

    if (previousValue.toString() != value.toString()) {
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, {
        isDirty: true,
      });
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsArray(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    setPreviousValue(
      DynamicQuestionnaireUtils.getQuestionValueAsArray(storedValue, oldValue?.value, entity)
    );
  }, [requestBody]);

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity]);
    }
  }, [question.refresh]);

  const setSelectedValues = (data: ISelectOption[]) => {
    return data.filter((element) => element.isSelected).map((element) => element.value);
  };

  const setOptionStatusForMultiSelect = (selectedValues: string | string[]) => {
    const updatedSelectState = multiSelectData.map((obj) => ({
      ...obj,
      isSelected: selectedValues.includes(obj.value),
    }));
    setMultiSelectData(updatedSelectState);

    const valueToSet = updatedSelectState.filter((ele) => ele.isSelected).map((ele) => ele.value);

    setValue(valueToSet);
  };

  const handleSelectAll = (event: { target: { checked: boolean } }) => {
    const updateSelectState = [...multiSelectOptions];
    updateSelectState.forEach((obj) => (obj.isSelected = event.target.checked));
    setMultiSelectData([...updateSelectState]);
    const valueToSet = updateSelectState
      .filter((ele: ISelectOption) => ele.isSelected)
      .map((ele: ISelectOption) => ele.value);

    setValue(valueToSet);
  };

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, {
        contextLocals,
        userRole: userInfo.role,
        ...locals,
      }) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>
                {question.label}
                {requiredResult ? '*' : ''}
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Tooltip
                title={setSelectedValues(multiSelectData).join(', ')}
                PopperProps={{ style: { zIndex: 0 } }}>
                <FormControl fullWidth margin="normal">
                  <Select
                    data-testid="custom-multi-select"
                    size="small"
                    variant="standard"
                    displayEmpty
                    multiple
                    fullWidth
                    value={setSelectedValues(multiSelectData)}
                    onChange={(event) => {
                      const selectedValues = event.target.value;
                      setOptionStatusForMultiSelect(selectedValues);
                    }}
                    renderValue={(selected) => selected.join(', ')}
                    required={requiredResult}
                    disabled={disabled ? disabled : readOnlyResult}
                    className={disabled || readOnlyResult ? 'disabled-multi disabled' : ''}>
                    <MenuItem onChange={(event: any) => handleSelectAll(event)}>
                      <CheckboxComponent />
                      <ListItemText primary={t('SELECT_ALL')} />
                    </MenuItem>
                    {multiSelectData.map(({ value, label, isSelected }, i: number) => (
                      <MenuItem key={i} value={value}>
                        <CheckboxComponent checked={isSelected} />
                        <ListItemText primary={label ? label : value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default MultiselectQuestion;
