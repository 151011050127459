import React from 'react';

interface IProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isPositiveButton?: boolean;
  classes?: string;
}

const PrimaryButton = (props: IProps) => {
  const { text, onClick, isDisabled, isPositiveButton, classes } = { ...props };

  return (
    <button
      onClick={(event) => onClick(event)}
      disabled={isDisabled}
      className={`${classes ?? ''} ${isPositiveButton ? 'nexus-btn-primary' : 'nexus-btn'}`}
      data-testid={isPositiveButton ? 'btn-confirm' : 'btn-cancel'}>
      {text}
    </button>
  );
};

export default PrimaryButton;
