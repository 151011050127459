import React from 'react';
import { Stack, Toolbar } from '@mui/material';
import Logo from '../../assets/icons/logo.png';
import NotificationCenter from '../../components/header/notification-center/NotificationCenter';
import SettingMenu from '../../components/header/SettingMenu';
import './Navbar.scss';
import INavbar from '../../utils/entities/layouts/INavbar';
import IconButtonComponent from '../../components/generic/button/IconButtonComponent';
import SwitchProject from '../../components/header/SwitchProject';
import SwitchRole from '../../components/header/switch-role/SwitchRole';

const Navbar = ({
  openDrawer,
  setOpenDrawer,
  projects,
  selectedProject,
  setSelectedProject,
}: INavbar) => {
  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Toolbar className="navbar">
      <IconButtonComponent
        handleClick={handleDrawer}
        icon="menu"
        color="inherit"
        edge="start"
        sx={{
          marginRight: 1,
          alignSelf: 'self-end',
        }}
      />
      <img src={Logo} className="logo-img margin-right-10 margin-left-10" alt="logo" />
      <div className="navbar-container">
        <SwitchProject
          projects={projects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      </div>
      <Stack direction="row" spacing={0}>
        <SettingMenu />
        <NotificationCenter />
      </Stack>
      <SwitchRole />
    </Toolbar>
  );
};

export default Navbar;
