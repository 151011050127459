import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../loader/Loader';
import './WorkloadCards.scss';
import {
  ICardViewDetails,
  IWorkloadCard,
  IWorloadCardsProps,
} from '../../../utils/entities/chart/ICards';

const WorkloadCards = (props: IWorloadCardsProps) => {
  const { data, loading } = { ...props } as { data: ICardViewDetails; loading?: boolean };
  const { t } = useTranslation();

  const [content, setContent] = useState<IWorkloadCard[] | undefined>([]);

  useEffect(() => {
    if (data) {
      setContent([
        { value: data.caseClosed, text: t('#_CASES_CLOSED') },
        { value: data.caseOutstanding, text: t('#_CASES_OUTSTANDING') },
        {
          value: `${data.overallCompletedPercentage}%`,
          text: t('OVERALL_%_COMPLETE'),
        },
      ]);
    }
  }, [data]);

  return (
    <div className="card-details">
      <Grid container spacing={2}>
        {loading ? (
          <Loader />
        ) : content ? (
          content.map((ele) => (
            <Grid item xs={4} key={ele.text}>
              <Card
                sx={{
                  minHeight: 120,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    {ele.value}
                  </Typography>
                  <Typography sx={{ mb: 1.5, fontSize: '0.8rem' }} color="text.secondary">
                    {ele.text}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <p>{t('ERROR_FETCHING')}</p>
        )}
      </Grid>
    </div>
  );
};

export default WorkloadCards;
