import React, { useState } from 'react';
import INotification from '../../../utils/entities/header/notification-center/INotification';
import { notitifcationRefresh } from '../../../service/notification_manager.service';
import IconButtonComponent from '../../generic/button/IconButtonComponent';
import GeneralUtils from '../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import '../../../styles/FCNexus.scss';
import NotificationMenu from './notification/NotificationMenu';

const NotificationCenter = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);

  const isOpenMenu = Boolean(anchorEl);

  const sseEventListener = (e: MessageEvent) => {
    const notificationsTemp = [...notifications];
    const newNotifications = JSON.parse(e.data);
    notificationsTemp.unshift(newNotifications);
    setNotifications(notificationsTemp);
  };

  GeneralUtils.sseEvent(EVENTS_CONSTANTS.TASK_LOADING_EVT, notitifcationRefresh(), false, {
    notifications_refresh: sseEventListener,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="notification-center">
      <IconButtonComponent
        color="inherit"
        icon={'notifications'}
        handleClick={handleClick}
        badgeContent={totalNotifications}
      />

      <NotificationMenu
        anchorEl={anchorEl}
        isOpenMenu={isOpenMenu}
        onClose={handleClose}
        setTotalNotifications={setTotalNotifications}
      />
    </div>
  );
};

export default NotificationCenter;
