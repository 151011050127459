import React, { useState } from 'react';
import { Box, Divider, Drawer, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RichTextComponent from '../../../rich-text/RichTextComponent';
import './QuestionTooltip.scss';
import IQuestionTooltipProps from '../../../../utils/entities/dynamic-questionnaire/question-tooltip/IQuestionTooltip';

const QuestionTooltip = (props: IQuestionTooltipProps) => {
  const { question } = props;

  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();

  const toggleGuidanceDrawer = () => {
    if (question.guidance) {
      setOpenDrawer(!openDrawer);
    }
  };

  const generateGuidanceDrawer = () => {
    return (
      <Box className="drawerPanelView guidance-drawer">
        <div className="header-section input-side">
          <div className="left-side">
            <RichTextComponent required value={`<b>${question.label}</b>`} readOnly inline />
          </div>
          <div className="right-side">
            <IconButton onClick={() => setOpenDrawer(false)}>
              <CloseOutlinedIcon fontSize="large" className="dark-icon-default" />
            </IconButton>
          </div>
        </div>

        <Divider className="divider" />

        <div className="content-section">
          <RichTextComponent required value={question.guidance} readOnly inline />
        </div>
      </Box>
    );
  };

  return (
    <div className="question-tootip-container">
      <Tooltip
        title={
          <>
            {question.tooltip && (
              <div className="margin-bottom-10">
                <RichTextComponent required value={question.tooltip} readOnly inline />
              </div>
            )}
            {question.guidance && (
              <div className="link-no-color" onClick={toggleGuidanceDrawer}>
                {t('MORE_INFORMATION')}
              </div>
            )}
          </>
        }
        arrow
        classes={{ arrow: 'question-arrow', tooltip: 'question-tootip' }}
        onClick={toggleGuidanceDrawer}
        style={{
          visibility: !(!!question.tooltip || !!question.guidance) ? 'hidden' : 'initial',
        }}>
        <HelpOutlineIcon />
      </Tooltip>
      {question.guidance && (
        <Drawer
          className="drawer-layout"
          anchor="right"
          open={openDrawer}
          onClose={toggleGuidanceDrawer}>
          {generateGuidanceDrawer()}
        </Drawer>
      )}
    </div>
  );
};

export default QuestionTooltip;
