import { IPermission } from '../entities/role/IPermission';
import { useEffect, useRef } from 'react';
import ICaseRoles from '../entities/case/ICaseRoles';
import StringUtils from './stringUtils';
import LocalStorageUtils from './localStorageUtils';
import { CONSTANTS } from '../constants/constants';
import IBusinessKeysMap from '../entities/case/IBusinessKeysMap';
import { IDataModelVersion } from '../entities/dataModel/IDataModelVersion';
import IQuestion from '../entities/screen/IQuestion';
import IBusinessKey from '../entities/businessKey/IBusinessKey';
import IGeneralUtils from '../entities/functions/IGeneralUtils';
import { PermissionTypes } from '../constants/enums';

const GeneralUtils: IGeneralUtils = {
  /**
   * This function will be responsible for returning plain text among the HTML template
   * @param str
   * @returns
   */
  strip_html_tags: (str: string) => {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  },
  /**
     * To subcsribe the event:
     * document.addEventListener('eventName', (e: any) => {
     });
     *
     * @param evt
     * @param data1
     */
  triggerEvt: (evt: string, data1: unknown) => {
    if (evt) {
      const event = new CustomEvent(evt, { detail: data1 });
      document.dispatchEvent(event);
    }
  },
  isEmpty: (obj: Record<string, unknown>) => {
    return Object.keys(obj).length === 0;
  },
  userHasAccess: (assignee: string | undefined, loggedUser: string | undefined): boolean => {
    if (assignee && loggedUser) {
      return StringUtils.compareStrings(assignee, loggedUser);
    } else {
      return false;
    }
  },
  /**
   * This function will return tre or false flag based on logged in user permission
   * @param permissions
   * @param permissonType
   * @param casePermissions
   * @returns
   */
  checkUserPermissions: (
    permissonType: string,
    casePermissions?: Array<IPermission> | null | undefined
  ) => {
    const rolePermissions: Array<IPermission> =
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)?.permissions ??
      [];

    return (
      casePermissions?.some((permission: IPermission) => permission.name == permissonType) ||
      rolePermissions?.some((permission: IPermission) => permission.name == permissonType)
    );
  },

  /**
   * This funstion will fetch the appropreate case permissions from the case roles based on loggedIn user role
   * @param assignedCaseRoles
   * @param caseRoles
   * @param currentLoggedUser
   * @returns
   */
  getCasePermissionsFromCaseRoles: (
    assignedCaseRoles: Array<ICaseRoles>,
    caseRoles: Array<ICaseRoles>,
    currentLoggedUser: string
  ) => {
    const activeCaseRole = assignedCaseRoles.filter(
      (e) =>
        e.users.findIndex((element) => {
          return element.toLowerCase() === currentLoggedUser;
        }) >= 0
    );

    const activePermissions = caseRoles
      .filter((e) => activeCaseRole.findIndex((f) => f.name == e.name) >= 0)
      .map((a) => a?.permissions);

    return activePermissions.flat(1);
  },
  /**
   * This function will convert the amount in currancy formate
   * @param price
   * @param countryType
   * @param currencyName
   */
  currencyConvertorFun: (price: number, countryType: string, currencyName: string) => {
    return price.toLocaleString(countryType, {
      style: 'currency',
      currency: currencyName,
    });
  },

  /**
   * This function will check if the current user has the admin permission
   * @param role
   * @returns
   */
  checkIfOwner: (role: ICaseRoles): boolean => {
    return (
      role.permissions.findIndex((e: IPermission) => e.name === PermissionTypes.CASE_ROLES_ADMIN) >=
      0
    );
  },
  isValidInfoSecString: (val: string) => {
    const infoSecChars = window.__RUNTIME_CONFIG__?.REACT_APP_INFOSEC_CHARS;

    if (infoSecChars) {
      const prohibitedChars = infoSecChars
        .split(';')
        .map((char: string) => char.replace(/'/g, '').trim());

      return !new RegExp(`[${prohibitedChars.join('\\')}]`).test(val);
    }
    return true;
  },
  useDebounce: (func: any, timeout = 100) => {
    let timer: any;
    const deferred = () => {
      clearTimeout(timer);
      timer = setTimeout(func, timeout);
    };
    const ref = useRef(deferred);
    return ref.current;
  },
  downloadByBlob: (binaryString: string, blobType: string, fileName: string) => {
    const blob = new Blob([binaryString], {
      type: blobType,
    });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },

  getCaseDisplayId: (caseId: string, caseDisplayID: string | null | undefined) => {
    return caseDisplayID || caseId;
  },
  copyTextToClipboard: (value: string) => {
    return navigator.clipboard
      .writeText(value)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },
  // A shallow copy using {... object} will duplicate the top-level properties
  // but the properties as an Object are copied as a reference after.
  // Using JSON.parse(JSON.stringify(object) the whole object will be duplicated
  deepCopy: <T>(data: T | null | undefined): T => {
    return JSON.parse(JSON.stringify(data));
  },
  normalize: (value: number, min: number, max: number) => {
    const normalized = ((value - min) * 100) / (max - min);

    return Math.max(0, Math.min(normalized, 100));
  },

  sseEvent: (
    event: string,
    url: string,
    shouldClose: boolean,
    addEventListeners?: { [key: string]: any },
    onOpen?: ((ev: Event) => any) | null,
    onError?: ((ev: Event) => any) | null,
    onMessage?: ((ev: MessageEvent<any>) => any) | null
  ) => {
    const eventSourceRef = useRef<EventSource | null>(null); // useRef to keep track of EventSource instance
    const errorCountRef = useRef<number>(0); // Ref to keep track of error count
    const retry_count =
      parseInt(window.__RUNTIME_CONFIG__?.REACT_APP_INCREMENT_SSE_RETRY_COUNT) ?? 5;

    useEffect(() => {
      GeneralUtils.triggerEvt(event, true);

      // Function to create EventSource instance
      const createEventSource = () => {
        const eventSource = new EventSource(url);

        if (onOpen) {
          eventSource.onopen = onOpen;
        }

        eventSource.onerror = (e: Event) => {
          if (errorCountRef.current >= retry_count) {
            // Close SSE if it tries to run more than 5 times
            eventSource.close();
          } else {
            // Increase error count
            errorCountRef.current++;
            if (eventSource.readyState === EventSource.CLOSED && e.type === 'error') {
              if (onError) {
                onError(e);
              }
              // Close and recreate EventSource on error
              eventSource.close();
              createEventSource();
            }
          }
        };

        if (onMessage) {
          eventSource.onmessage = onMessage;
        }

        if (addEventListeners) {
          Object.keys(addEventListeners).forEach((event) => {
            eventSource.addEventListener(event, addEventListeners[event]);
          });
        }

        // Update eventSourceRef with the current EventSource instance
        eventSourceRef.current = eventSource;
      };

      // Call the function to create EventSource instance
      createEventSource();

      // Cleanup function
      return () => {
        if (shouldClose && eventSourceRef.current) {
          addEventListeners &&
            Object.keys(addEventListeners).forEach((event) => {
              eventSourceRef.current?.removeEventListener(event, addEventListeners[event]);
            });
          errorCountRef.current = 0;
          eventSourceRef.current.close(); // Close EventSource when component unmounts
        }
      };
    }, []); // Empty dependency array ensures this effect runs only once

    // Return eventSourceRef to the caller, if needed
    return eventSourceRef;
  },
  decodeHtmlEntity: (encodedString: string): string => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  },
  getNewEntityId: (
    entityIdToFind: string,
    oldBks: Array<IBusinessKeysMap>,
    newBks: Array<IBusinessKeysMap>
  ): Array<string> => {
    const oldBusinessKeys = oldBks?.find(
      (entity) => entity.entityId === entityIdToFind
    )?.businessKeys;

    if (!oldBusinessKeys) {
      return [];
    }

    const entityIds: Array<string | undefined> = newBks.map((entity) => {
      const isMatch = oldBusinessKeys.some((bkGroup1) => {
        return entity.businessKeys.some((bkGroup2) => {
          return bkGroup1.every((bk1) => {
            return bkGroup2.some((bk2) => {
              return bk1.name === bk2.name && bk1.value === bk2.value;
            });
          });
        });
      });

      if (isMatch) {
        return entity.entityId;
      }
    });

    return entityIds.filter(Boolean) as Array<string>;
  },
  getTableBKS: (dataModelVersion: IDataModelVersion, question: IQuestion): Array<string> => {
    const bks: Array<string> = [];
    dataModelVersion.items.forEach((item) => {
      if (item.itemCode === question.entitySubType) {
        item.businessKeys.map((bk: IBusinessKey) => {
          bks.push(bk.keyName[0]);
        });
      }
    });
    return bks;
  },
};

export default GeneralUtils;
