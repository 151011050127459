import React from 'react';
import { Button, ClickAwayListener, Grid, Tooltip, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Dropdown.scss';

interface DropdownProps {
  setClickAway: (event: MouseEvent | TouchEvent) => void;
  className: string;
  setClose: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  setOpen: (event: Event | React.SyntheticEvent<Element, Event>) => void;
  open: boolean;
  list: React.ReactNode;
  label?: string;
  selectedOption: string;
}
const DropdownComponent: React.FC<DropdownProps> = (props): JSX.Element => {
  const { setClickAway, className, setClose, setOpen, open, list, label, selectedOption } = {
    ...props,
  };

  return (
    <>
      <Grid>
        <ClickAwayListener onClickAway={setClickAway}>
          <Tooltip
            className={'dropdown-wrapper ' + className}
            arrow
            PopperProps={{
              disablePortal: true,
            }}
            onClose={setClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={list}
            componentsProps={{
              tooltip: {
                sx: {
                  padding: '15px',
                  bgcolor: 'common.white',
                  maxWidth: '500px',
                  width: '600px',
                  color: 'rgba(0, 0, 0, 0.87)',
                  border: '1px solid #C4C4CD',
                  '& .MuiTooltip-arrow': {
                    color: 'common.white',
                    '&:: before': {
                      bgcolor: 'common.white',
                      border: '1px solid #999',
                    },
                  },
                },
              },
            }}>
            <Grid item className="align-item-center">
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  open ? setClose(e) : setOpen(e);
                }}
                className="status-list full-width">
                <div className="box-label-value full-width">
                  {label && (
                    <Typography className="bold-font header-title font-size-12">{label}</Typography>
                  )}
                  <Typography className="font-size-12 status-selected">{selectedOption}</Typography>
                </div>
                <ArrowDropDownIcon className={open ? 'opened' : ''} />
              </Button>
            </Grid>
          </Tooltip>
        </ClickAwayListener>
      </Grid>
    </>
  );
};

export default DropdownComponent;
