import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../../../loader/Loader';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import Metadata from '../../../task/dynamic-questionaire/metadata/Metadata';
import './CaptureCaseAttributes.scss';
import IconButtonComponent from '../../../generic/button/IconButtonComponent';
import ICaseAttributes from '../../../../utils/entities/case/CaseCreation/ICaseAttributes';
import {
  getCaseDataAttributes,
  getGlobalSearchMappingBySource,
} from '../../../../service/config-manager.service';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { addAlert } from '../../../../store/actions/alerts.actions';
import { CONSTANTS } from '../../../../utils/constants/constants';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { PermissionTypes } from '../../../../utils/constants/enums';
import GlobalSearch from '../../../global-search/GlobalSearch';
import {
  IGlobalSearchMapping,
  IGlobalSearchResult,
} from '../../../../utils/entities/global-search/IGlobalSearch';
import { ApolloProvider } from '@apollo/client';
import { client } from '../../../../service/apollo-client.data-gateway';
import ISimpleAttribute from '../../../../utils/entities/case/ISimpleAttribute';
import BusinessKeys from '../../../../types/businessKeys';
import { IDataModelVersionAttributes } from '../../../../utils/entities/dataModel';
import { IMetadataAttr } from '../../../../utils/entities/meta-data/IMetaDataAttr';

const CaptureCaseAttributes = (props: ICaseAttributes) => {
  const {
    group,
    setCaseAttributes,
    setAllCaseAttributesChecksPassed,
    setCaseData,
    setAllCaseDataChecksPassed,
    setValidateBks,
  } = { ...props };
  const { t } = useTranslation();
  const userCanCaptureCaseData = GeneralUtils.checkUserPermissions(
    PermissionTypes.CAPTURE_CASE_DATA
  );
  const userCanGlobalSearch = GeneralUtils.checkUserPermissions(
    PermissionTypes.CREATE_CASE_GLOBAL_SEARCH
  );
  const tenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  const [attributesInput, setAttributesInput] = useState<IMetadataAttr[]>([]);
  const [businessKeys, setBusinessKeys] = useState<BusinessKeys>([]);
  const [caseDataConfigs, setCaseDataConfigs] = useState<IMetadataAttr[] | undefined>(undefined);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [globalSearchResult, setGlobalSearchResult] = useState<IGlobalSearchResult | undefined>(
    undefined
  );

  useEffect(() => {
    if (group) {
      if (userCanCaptureCaseData) {
        setShowLoader(true);
      }
      setAttributesInput([...handleAttrFields()]);
    }
  }, [group]);

  useEffect(() => {
    if (tenant) {
      if (userCanCaptureCaseData) {
        setShowLoader(true);
        getCaseDataAttributes(tenant)
          .then((caseData) => {
            const data: IMetadataAttr[] | undefined = caseData?.map((data) => {
              return {
                title: data.attributeDescription,
                name: data.attributeName,
                type: data.dataType,
                multiple: data.multiple,
                enum: data.enum,
                value: '',
                values: [],
                required: data.mandatoryForCaseCreation,
              };
            });
            setCaseDataConfigs(data);
            setAllCaseDataChecksPassed(checkIfIsReady(data));
          })
          .catch(() => {
            setCaseDataConfigs(undefined);
            setAllCaseDataChecksPassed(checkIfIsReady(undefined));
            addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') });
          })
          .finally(() => {
            setShowLoader(false);
          });
      } else {
        setAllCaseDataChecksPassed(true);
      }
    }
  }, [tenant]);

  useEffect(() => {
    if (userCanGlobalSearch && globalSearchResult?.source) {
      setShowLoader(true);
      getGlobalSearchMappingBySource(tenant, globalSearchResult.source)
        .then((searchMapping: Array<IGlobalSearchMapping>) => {
          const attributes: Array<IMetadataAttr> = mapAttributesByGlobalSearch(searchMapping);
          setAttributesInput([...attributes]);
          setCaseAttributes([[...convertToBkStructure(attributes)]]);
          setValidateBks(getBks(attributes));
          setAllCaseAttributesChecksPassed(checkIfIsReady(attributes) && checkBks(attributes));
          setShowLoader(false);
          setGlobalSearchResult(undefined);
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR') }));
    }
  }, [globalSearchResult]);

  const handleAttrFields = (): IMetadataAttr[] => {
    const bkNamess = group?.businessKeys?.map((bk: { keyName: string[] }) => bk.keyName) ?? [];

    const businessKeys: BusinessKeys = [];
    for (const bkNames of bkNamess) {
      if (
        (Array.isArray(bkNames) &&
          bkNames.every((name) =>
            group?.dataModelVersionAttributes?.find(
              (attr: IDataModelVersionAttributes) =>
                attr.requestedForCaseCreation && attr.name === name
            )
          )) ||
        (!Array.isArray(bkNames) &&
          group?.dataModelVersionAttributes?.find(
            (attr: IDataModelVersionAttributes) =>
              attr.requestedForCaseCreation && attr.name === bkNames
          ))
      ) {
        const bks = [];
        for (const bkName of bkNames) {
          bks.push({
            name: bkName,
            value: '',
            values: null,
          });
        }
        businessKeys.push(bks);
      }
    }
    setBusinessKeys(businessKeys);
    const getAttrName = (attr: IDataModelVersionAttributes) => {
      return attr.dataBlockOrigins[0]?.dataBlockAttributeNameExpression
        ? attr.dataBlockOrigins[0]?.dataBlockAttributeNameExpression
        : attr.name;
    };

    return (
      group?.dataModelVersionAttributes
        ?.filter((attr: IDataModelVersionAttributes) => attr.requestedForCaseCreation)
        .map((attr: IDataModelVersionAttributes) => {
          return {
            title: attr.description,
            name: getAttrName(attr),
            type: attr.dataType,
            multiple: attr.multiple ?? false,
            enum: attr.enumCode ?? [],
            value: '',
            values: [],
            required: attr.mandatoryForCaseCreation,
            disabled: attr.readOnlyForCaseCreation,
          };
        }) ?? []
    );
  };

  const convertToBkStructure = (data: IMetadataAttr[]) => {
    return data
      .filter((data) => data.value?.length || data.values?.length)
      .map((data) => {
        return {
          name: data.name,
          value: data.value,
          values: data.values?.length ? data.values : null,
        };
      });
  };

  const handleAttrsUpdate = () => {
    if (attributesInput) {
      const attributes = [...resetAttributes(attributesInput)];
      setAttributesInput(attributes);
      setCaseAttributes([[...convertToBkStructure(attributes)]]);
      setValidateBks(getBks(attributes));
      setAllCaseAttributesChecksPassed(checkIfIsReady(attributes) && checkBks(attributes));
    } else {
      setCaseAttributes([]);
      setAllCaseAttributesChecksPassed(false);
    }
  };

  const handleCaseDataUpdate = () => {
    if (caseDataConfigs) {
      setCaseDataConfigs([...caseDataConfigs]);
      setCaseData([[...convertToBkStructure(caseDataConfigs)]]);
      setAllCaseDataChecksPassed(checkIfIsReady(caseDataConfigs));
    } else {
      setCaseDataConfigs(undefined);
      setCaseData([]);
      setAllCaseDataChecksPassed(false);
    }
  };

  const checkIfIsReady = (data: IMetadataAttr[] | undefined) => {
    return !data?.filter((attr) => attr.required && !(attr.value?.length || attr.values?.length))
      ?.length;
  };

  const checkBks = (data: IMetadataAttr[]) => {
    let result = false;

    for (const bks of businessKeys) {
      const res = bks.every((bk) => bk.value?.length || bk.values?.length);
      if (res) {
        result = true;
      }
    }

    return result;
  };

  const getBks = (data: IMetadataAttr[]): BusinessKeys => {
    for (const bks of businessKeys) {
      for (const bk of bks) {
        const attr = data.find((attr) => attr.name === bk.name);
        if (attr) {
          bk.value = attr.value;
          bk.values = attr.values?.length ? attr.values : null;
        }
      }
    }

    return businessKeys;
  };

  const mapAttributesByGlobalSearch = (
    globalSearchMapping: Array<IGlobalSearchMapping>
  ): Array<IMetadataAttr> => {
    if (globalSearchResult) {
      const globalSearchResultAttributes = globalSearchResult.attributes;
      const attributes: Array<IMetadataAttr> = [...resetAttributes(attributesInput)];

      globalSearchMapping.forEach((mapping) => {
        const attribute: IMetadataAttr | undefined = attributes.find(
          (attribute: IMetadataAttr) => mapping.dataModelAttribute == attribute.name
        );

        if (attribute) {
          const globalSearchAttribute: ISimpleAttribute | undefined =
            globalSearchResultAttributes.find(
              (globalSearchAttribute: ISimpleAttribute) =>
                globalSearchAttribute.name == mapping.sourceAttribute
            );

          if (globalSearchAttribute) {
            attribute.value = globalSearchAttribute.value;
            attribute.updated = true;
            attribute.format = mapping.fieldFormat || '';
          }
        }
      });

      return attributes;
    } else {
      return [];
    }
  };

  const resetAttributes = (attributes: Array<IMetadataAttr>): Array<IMetadataAttr> => {
    return attributes.map((attr: IMetadataAttr) => {
      return {
        ...attr,
        updated: false,
      };
    });
  };

  return (
    <div className="capture-case-attributes">
      {attributesInput.length > 0 && !showLoader ? (
        <>
          <Stack spacing={2} direction="row" justifyContent={'space-between'} className="title">
            <Stack spacing={2} direction="row">
              <Typography variant="h6" gutterBottom sx={{ textDecorationLine: 'underline' }}>
                {t('DEFINE_ENTRY_ATTRIBUTES')}
              </Typography>
              <Tooltip
                title={
                  <div>
                    {t('BUSINESS_KEY_TOOLTIP')}
                    {businessKeys?.map((item, i) => (
                      <div key={i}>{item.map((itm) => itm.name).join(' + ')}</div>
                    ))}
                  </div>
                }
                placement="left"
                arrow>
                <span>
                  <IconButtonComponent icon="info" />
                </span>
              </Tooltip>
            </Stack>
            {userCanGlobalSearch && (
              <ApolloProvider client={client}>
                <GlobalSearch setResult={setGlobalSearchResult} />
              </ApolloProvider>
            )}
          </Stack>
          <Box className="preview-container scrollTable">
            <Grid
              container
              spacing={5}
              justifyContent="flex-start"
              className="preview-container-column">
              {attributesInput.map((attr) => (
                <Metadata
                  attribute={attr}
                  valueUpdated={handleAttrsUpdate}
                  key={`attr-${attr.name}`}
                />
              ))}

              {userCanCaptureCaseData &&
                caseDataConfigs?.map((attr) => (
                  <Metadata
                    attribute={attr}
                    valueUpdated={handleCaseDataUpdate}
                    key={`data-${attr.name}`}
                  />
                ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CaptureCaseAttributes;
