import { createContext } from 'react';
import { CONSTANTS } from '../utils/constants/constants';
import { IRole } from '../utils/entities/role';
import LocalStorageUtils from '../utils/functions/localStorageUtils';

const ActiveRoleContext = createContext<IRole>(
  LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)
);

export default ActiveRoleContext;
