import React from 'react';
import { Button } from '@mui/material';
import ITextButtonProps from '../../../../utils/entities/genericComponents/button/text/ITextButton';
import './TextButton.scss';

const TextButton = ({ label, handleClick, isDisabled }: ITextButtonProps) => {
  return (
    <Button
      data-testid="text-button-component"
      disabled={isDisabled}
      onClick={handleClick}
      className={`text-button link ${handleClick ? 'cursor-pointer' : 'cursor-auto'}`}
      disableRipple>
      <span>{label}</span>
    </Button>
  );
};

export default TextButton;
