import { IAlertMsg } from '../../utils/entities/genericComponents/IAlertComponent';
import store from '../store';

export const removeFirstAlert = () => {
  store.dispatch({ type: 'SHIFT_ALERT' });
};

export const addAlert = (msg: IAlertMsg) => {
  store.dispatch({
    type: 'PUSH_ALERT',
    payload: {
      msg,
      onClose: () => {},
    },
  });
};
