import { Chip, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONSTANTS } from '../../../utils/constants/constants';
import { ITag, ITagsContainer } from '../../../utils/entities/genericComponents/tag/ITag';
import Tag from './Tag';

const RestAllTags = ({ tags, tagsCount, handleDelete }: ITagsContainer) => {
  const { t } = useTranslation();

  return (
    <div className="view-more">
      <Tooltip
        title={
          <Stack direction="column" spacing="0">
            {tags.map((tag: ITag, index: number) => {
              if (index >= tagsCount) {
                return (
                  <Tag
                    tag={tag}
                    key={tag.index ?? `${tag.name}-${index}`}
                    handleDelete={tag.method === CONSTANTS.MANUAL ? handleDelete : undefined}
                  />
                );
              }
            })}
          </Stack>
        }>
        <Chip
          label={
            tagsCount === 0
              ? `${tags.length - tagsCount} ${t('TAGS')}`
              : `+ ${tags.length - tagsCount} ${t('MORE')}`
          }
          className="fc-nexus-chip"
        />
      </Tooltip>
    </div>
  );
};

export default RestAllTags;
