import React from 'react';
import { iconType } from '../../../types/iconType';
import RestoreIcon from '@mui/icons-material/Restore';
import GroupIcon from '@mui/icons-material/Group';
import LockIcon from '@mui/icons-material/Lock';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import LinkIcon from '@mui/icons-material/Link';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoopIcon from '@mui/icons-material/Loop';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EditNoteIcon from '@mui/icons-material/EditNote';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import ChevronLeftIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ChevronRightIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CircleIcon from '@mui/icons-material/Circle';
import HistoryIcon from '@mui/icons-material/History';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Calendar from '@mui/icons-material/Event';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import MenuIcon from '@mui/icons-material/Menu';
import LaunchIcon from '@mui/icons-material/Launch';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IIconComponentProps from '../../../utils/entities/genericComponents/button/icon/IIconComponent';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkIcon from '@mui/icons-material/Work';
import DashboardIcon from '@mui/icons-material/Dashboard';

const IconComponent = ({ icon }: IIconComponentProps) => {
  const iconsMap: Record<iconType, React.JSX.Element> = {
    cloudUpload: <CloudUploadIcon />,
    restore: <RestoreIcon />,
    group: <GroupIcon />,
    lock: <LockIcon />,
    navigateNext: <NavigateNextIcon />,
    navigateBefore: <NavigateBeforeIcon />,
    clear: <ClearOutlinedIcon />,
    notifications: <NotificationsIcon />,
    play: <PlayCircleIcon />,
    pause: <PauseCircleIcon />,
    stop: <StopCircleIcon />,
    arrowDropDown: <ArrowDropDownIcon />,
    LocalOffer: <LocalOfferOutlinedIcon />,
    VPNKey: <VpnKeyIcon />,
    delete: <DeleteOutlineIcon />,
    postAdd: <PostAddIcon />,
    check: <CheckIcon />,
    cancel: <CancelIcon />,
    importExport: <ImportExportIcon />,
    filterList: <FilterListIcon />,
    download: <DownloadIcon />,
    link: <LinkIcon />,
    assignment: <AssignmentOutlinedIcon />,
    paste: <ContentPasteIcon />,
    warning: <WarningIcon />,
    checkCircle: <CheckCircleIcon />,
    loop: <LoopIcon />,
    fileDownload: <FileDownloadTwoToneIcon />,
    help: <HelpOutlineIcon />,
    editNote: <EditNoteIcon />,
    assignmentTurnedIn: <AssignmentTurnedInOutlinedIcon />,
    chevronLeft: <ChevronLeftIcon />,
    chevronRight: <ChevronRightIcon />,
    circle: <CircleIcon />,
    history: <HistoryIcon />,
    search: <SearchIcon />,
    add: <AddIcon />,
    expand: <ExpandMoreIcon />,
    edit: <EditIcon />,
    person: <PersonOutlineIcon />,
    addCircle: <AddCircleOutlineIcon />,
    info: <InfoIcon />,
    close: <CloseOutlinedIcon />,
    restart: <RestartAltIcon />,
    playCircle: <PlayCircleOutlineIcon />,
    calendar: <Calendar />,
    filterListOff: <FilterListOffIcon />,
    menu: <MenuIcon />,
    launch: <LaunchIcon />,
    copy: <ContentCopyIcon />,
    visibility: <VisibilityIcon />,
    dashboard: <DashboardIcon />,
    work: <WorkIcon />,
    adminPanelSettings: <AdminPanelSettingsIcon />,
  };

  return <>{iconsMap[icon]}</>;
};

export default IconComponent;
