import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../buttons/PrimaryButton';
import IconButtonComponent from '../button/IconButtonComponent';
import IDialogComponentProps from '../../../utils/entities/genericComponents/modal/IDialogComponent';

const DialogComponent = ({
  open,
  title,
  body,
  setConfirm,
  onClose,
  setCancel,
  confirmText,
  denyText,
  cancelText,
  disabled,
  maxWidth,
  showConfirmButton,
}: IDialogComponentProps) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{title}</Box>
          <Box>
            <IconButtonComponent icon="close" handleClick={onClose} />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" gap={2}>
          <Box mt={2} gap={3} display="flex" flexDirection="column">
            {body}
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions className="upload-dialog-action">
        {showConfirmButton && setConfirm && (
          <PrimaryButton
            isPositiveButton
            text={confirmText ?? t('SUBMIT')}
            onClick={setConfirm}
            isDisabled={disabled}
          />
        )}
        <PrimaryButton text={denyText ?? t('CANCEL')} onClick={onClose} />
        {cancelText && setCancel && <PrimaryButton text={cancelText} onClick={setCancel} />}
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
