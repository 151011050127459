import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_CONSTANTS } from '../utils/constants/api_constants';
import { IAssign } from '../utils/entities/role';
import IScreen from '../utils/entities/screen/IScreen';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/functions/serviceUtils';
import ITaskError from '../utils/entities/screen/tasks/ITaskError';
import IClaim from '../utils/entities/IClaim';
import IBulkClaim from '../utils/entities/case/IBulkClaim';

let getTabAbortController: AbortController | undefined;

export const getTask = (id: string): Promise<IScreen | number> =>
  axios
    .get(`/getScreenFile/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<IScreen>) => {
      return serviceUtils.processSuccess<IScreen>(res);
    })
    .catch((error: AxiosError | string) => {
      if (
        axios.isAxiosError(error) &&
        (error?.response?.status === 307 || error?.response?.status === 401)
      ) {
        return error?.response?.status;
      } else {
        throw serviceUtils.processError(error);
      }
    });

export const getCompletedTask = (id: string): Promise<IScreen> =>
  axios
    .get(`/getCompletedTask/${encodeURIComponent(id)}`, axiosConfig)
    .then((res: AxiosResponse<IScreen>) => {
      return serviceUtils.processSuccess<IScreen>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const sendDraft = (auxBody: IScreen) =>
  axios
    .put(`/sendDraft`, auxBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('Saved task :', auxBody);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const submitTask = (requestBody: string): Promise<IScreen> => {
  const bodyFormData: FormData = new FormData();
  bodyFormData.append('screenfilebody', requestBody);
  return axios
    .postForm(`/submitTask`, bodyFormData, axiosConfig)
    .then((res: AxiosResponse<IScreen>) => {
      return serviceUtils.processSuccess<IScreen>(res);
    })
    .catch((error: AxiosError<Array<ITaskError>>) => {
      if (error.response && error.response.status != 500) {
        throw { errors: error.response.data, errorCode: error.response.status };
      }
      throw serviceUtils.processError(error);
    });
};

export const assignTaskToUser = (reqBody: IClaim): Promise<void> =>
  axios
    .patch(`/patchTask`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('The task has been claimed successfully', reqBody);
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error submitting task claim: ', error.toJSON());
      throw error.message;
    });

export const getTab = (taskId: string, tabId: string): Promise<IScreen> => {
  if (getTabAbortController) {
    getTabAbortController.abort();
  }

  getTabAbortController = new AbortController();

  return axios
    .get(
      `/getSpecificTab${API_CONSTANTS.TASK}/${encodeURIComponent(taskId)}/tab/${encodeURIComponent(
        tabId
      )}`,
      { ...axiosConfig, signal: getTabAbortController.signal }
    )
    .then((res: AxiosResponse<IScreen>) => {
      getTabAbortController = undefined;
      return serviceUtils.processSuccess<IScreen>(res);
    })
    .catch((error: AxiosError) => {
      throw error;
    });
};

export const updateTab = (body: IScreen, tabId: string): Promise<IScreen> =>
  axios
    .put(
      `/saveSpecificTab${API_CONSTANTS.TASK}/${encodeURIComponent(
        body.id
      )}/tab/${encodeURIComponent(tabId)}`,
      body,
      axiosConfig
    )
    .then((res: AxiosResponse<IScreen>) => {
      return serviceUtils.processSuccess<IScreen>(res);
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error saving tab: ', error.toJSON());
      throw error;
    });

export const assignTaskToRole = (reqBody: IAssign): Promise<void> =>
  axios
    .patch(`/assignTaskToRole`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('Assigned Task', res);
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error saving tab: ', error.toJSON());
      throw error;
    });

export const assignBulkTasksToUser = (reqBody: IBulkClaim): Promise<void> =>
  axios
    .post(`/getUnassignedTasks`, reqBody, axiosConfig)
    .then((res: AxiosResponse) => {
      console.debug('Bulk Assignment successfully done :', reqBody);
    })
    .catch(function (error: AxiosError<any>) {
      console.error('Error During Bulk Assignment: ', error.toJSON());
      throw error.message;
    });

export const addCorrelationId = (taskId: string, correlationId: string): Promise<void> =>
  axios
    .post(`/addCorrelationId/${taskId}/${correlationId}`, null, axiosConfig)
    .then((res: AxiosResponse) => {
      return;
    })
    .catch(function (error: AxiosError<any>) {
      throw error.message;
    });

export const taskRefresh = (id: string | undefined) =>
  `${window.__RUNTIME_CONFIG__?.REACT_APP_BACKEND_URL_ROOT}/taskManagerService${API_CONSTANTS.SCREEN_REFRESH}/${id}`;
