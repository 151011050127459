import React, { useState } from 'react';
import { Divider, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import INotification from '../../../../../utils/entities/header/notification-center/INotification';
import { dismissNotifications } from '../../../../../service/notification_manager.service';
import { INotificationContainer } from '../../../../../utils/entities/header/notification-center/INotificationContainer';
import './NotificationContainer.scss';
import DateText from '../../../../generic/timer/DateText';
import IconButtonComponent from '../../../../generic/button/IconButtonComponent';
import TextButton from '../../../../generic/button/text/TextButton';
import NotificationText from '../text/NotificationText';

const NotificationContainer = ({
  notification,
  index,
  notifications,
  setNotifications,
  handleMenuClose,
}: INotificationContainer) => {
  const { t } = useTranslation();

  const [expandNotification, setExpandNotification] = useState<Array<boolean>>([false]);

  const dismissNote = (index: number) => {
    dismissNotifications(notifications[index].id).then(() =>
      setNotifications(notifications.filter((note: INotification) => note !== notifications[index]))
    );
  };

  const clickNotification = (index: number) => {
    const expandNoteTemp = [...expandNotification];
    expandNoteTemp[index] = !expandNoteTemp[index];
    setExpandNotification(expandNoteTemp);
  };

  const notificationTitle = (notification: INotification): string => {
    switch (notification.action) {
      case 'OPEN_CASE':
        return t('NOTIFICATION_CENTER.NOTIFICATION_TITLE_CASE');
      case 'OPEN_TASK':
        return t('NOTIFICATION_CENTER.NOTIFICATION_TITLE_TASK');
      case 'OPEN_DOCUMENT':
        return t('NOTIFICATION_CENTER.NOTIFICATION_TITLE_DOC');
      case 'NONE':
        return t('NOTIFICATION_CENTER.NOTIFICATION_TITLE');
    }
  };

  return (
    <div key={notification.id} className="notification-container padding-left-right-10">
      <Stack spacing={2} direction="row" justifyContent={'space-between'}>
        <div className="align-self-center bold-font">{notificationTitle(notification)}</div>

        <IconButtonComponent
          icon={'close'}
          handleClick={() => dismissNote(index)}
          classes="align-self-center delete-notification"
          size="small"
        />
      </Stack>

      <NotificationText
        notification={notification}
        handleMenuClose={handleMenuClose}
        expanded={expandNotification[index] ?? false}
      />

      {notification.notificationMessage.length > 110 && (
        <TextButton
          label={expandNotification[index] ? t('SHOW_LESS') : t('SHOW_MORE')}
          handleClick={() => clickNotification(index)}
        />
      )}

      <div className="notification-date">
        <DateText date={notification.creationDate} />
      </div>
      <Divider className="margin-top-10" />
    </div>
  );
};

export default NotificationContainer;
