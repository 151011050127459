import React from 'react';
import TextMetadata from './TextMetadata';
import AutocompleteMetadata from './AutocompleteMetadata';
import SelectMetadata from './SelectMetadata';
import DatePickerMetadata from './DatePickerMetadata';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import './Metadata.scss';
import { IMetadataInputs } from '../../../../utils/entities/meta-data/IMetaDataInput';

const Metadata = (props: IMetadataInputs) => {
  const { attribute, showLabel, valueUpdated } = { ...props };
  const { t } = useTranslation();

  const selectComp = () => {
    switch (attribute?.type?.toUpperCase()) {
      case 'STRING':
      case 'NUMBER':
        return attribute.enum == null || !attribute.enum.length ? (
          attribute.multiple ? (
            <AutocompleteMetadata
              attribute={attribute}
              valueUpdated={valueUpdated}
              showLabel={showLabel}
            />
          ) : (
            <TextMetadata attribute={attribute} valueUpdated={valueUpdated} showLabel={showLabel} />
          )
        ) : (
          <SelectMetadata attribute={attribute} valueUpdated={valueUpdated} showLabel={showLabel} />
        );
      case 'DATE':
        return (
          <DatePickerMetadata
            attribute={attribute}
            valueUpdated={valueUpdated}
            showLabel={showLabel}
          />
        );
      default:
        return <>{t('QUESTION_TYPE_NOT_IMPLEMENTED', { atributeType: attribute.type })}</>;
    }
  };

  return (
    <Grid
      item
      xs={6}
      md={4}
      justifyContent="flex-start"
      key={`metadata-${attribute.name}`}
      className="attribute-container">
      <div>{`${attribute.title}${attribute.required ? ' *' : ''}`}</div>
      <div className={`margin-auto ${attribute.updated ? 'updated' : ''}`}>{selectComp()}</div>
    </Grid>
  );
};

export default Metadata;
