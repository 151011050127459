import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SourceCard from './SourceCard/SourceCard';
import './SourceContainer.scss';
import ISourceContainerProps from '../../../../../utils/entities/id-and-v/ISourceContainer';

const SourceContainer = ({ isIdentification, dataBlocks, maxSources }: ISourceContainerProps) => {
  const { t } = useTranslation();

  return (
    <Box className="source-container">
      <Stack spacing={2} direction="row" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          {isIdentification
            ? t('ID.SOURCE_TITLE')
            : t('V.SOURCE_TITLE', { count: dataBlocks?.length })}
        </Typography>

        {!isIdentification &&
          maxSources &&
          t('V.SOURCE_COUNTER', { size: dataBlocks.length, max: maxSources })}
      </Stack>

      <Box className="source-container-box">
        {dataBlocks.map((block) => (
          <SourceCard
            source={block.source}
            key={`${block.source.type}-${block.source.ingestionDate}`}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SourceContainer;
