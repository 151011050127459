import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { CONSTANTS } from '../../../utils/constants/constants';
import Logo from '../../../assets/icons/logo.png';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getRoleByName, getUser } from '../../../service/role-manager.service';
import jwtDecode from 'jwt-decode';
import { revalidateUserRole } from '../../../service/authentication.service';
import { useTranslation } from 'react-i18next';
import { IToken, IAccessToken } from '../../../utils/entities/authentication';
import { IRole, IUser } from '../../../utils/entities/role';
import { useNavigate } from 'react-router-dom';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import GeneralUtils from '../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../utils/constants/event_constants';
import { addAlert } from '../../../store/actions/alerts.actions';

interface IProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SwitchRoleDialog = (props: IProps) => {
  const { setOpenModal } = { ...props };
  const { accounts } = useMsal();
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [authentication, setAuthentication] = useState('');
  const [roleValue, setRoleValue] = useState<string>('');
  const [loggedUser, setLoggedUser] = useState<string>('');
  const [userInfo, setUserInfo] = useState<IAccessToken | undefined>();
  const [roles, setRoles] = useState<IRole[]>();

  enum Inputs {
    Text,
    Drop,
  }

  useEffect(() => {
    retrieveUser();
  }, [accounts]);

  useEffect(() => {
    setUserInfo(authentication ? jwtDecode(authentication) : undefined);
  }, [authentication]);

  useEffect(() => {
    loggedInfo();
  }, [userInfo]);

  const retrieveUser = () => {
    getUser().then((res: IUser) => {
      setRoleValue(res.activeRole.name);
      setRoles(res.roles);
    });
  };

  // TODO: Check this function usage
  const userInputChange = (e: any, input: Inputs) => {
    if (input == Inputs.Drop) {
      setRoleValue(e.target.value);
    }
  };

  const loggedInfo = () => {
    setLoggedUser(userInfo?.user ?? '');
  };

  const getActiveRole = (activeRoleName: string) => {
    getRoleByName(activeRoleName)
      .then((role) => {
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE,
          GeneralUtils.deepCopy(role)
        );
        GeneralUtils.triggerEvt(EVENTS_CONSTANTS.ACTIVE_ROLE_UPDATED, GeneralUtils.deepCopy(role));
        addAlert({ type: 'success', primaryText: t('ROLE_SUCCESS', { role: roleValue }) });
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ROLE_ERROR', { role: roleValue }) });
      });
  };

  const switchRole = () => {
    revalidateUserRole(roleValue)
      .then((token: IToken) => {
        const userInfo: IAccessToken = jwtDecode(token.accessToken);
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO,
          GeneralUtils.deepCopy(userInfo)
        );
        getActiveRole(userInfo.role.name);
        setAuthentication(token.accessToken);
        navigate(CONSTANTS.PAGES_URL.INBOX);
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ROLE_ERROR', { role: roleValue }) });
      })
      .finally(() => {
        setOpenModal(false);
      });
  };

  useEffect(() => {
    userInfo && LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO, userInfo);
  }, [userInfo]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box style={{ color: 'white', fontSize: 'xx-large' }}>
        <img src={Logo} alt="logo" style={{ height: '100px' }} />
        {t('CASE_MANAGEMENT_TOOL')}
      </Box>
      <Box>
        <Grid container sx={{ width: 'min-content' }}>
          <Grid item xs={12}>
            <InputLabel sx={{ color: 'white' }}>{t('USERNAME')}</InputLabel>
            <TextField
              className="loginInputs"
              size="small"
              variant="outlined"
              value={userInfo ? loggedUser : accounts[0]?.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                userInputChange(e, Inputs.Text);
              }}
              disabled={isAuthenticated}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel sx={{ color: 'white' }}>{t('ROLE')}</InputLabel>
            <Select
              className="loginInputs"
              size="small"
              variant="outlined"
              value={roleValue}
              onChange={(e: SelectChangeEvent<string>) => {
                userInputChange(e, Inputs.Drop);
              }}>
              {roles?.map((role: IRole) => (
                <MenuItem key={role?.name} value={role?.name}>
                  {role?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <Button
              variant="contained"
              onClick={switchRole}
              className={!roleValue ? 'disabled' : ''}>
              {t('SWITCH_ROLE')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SwitchRoleDialog;
