import React from 'react';
import AggregateViewDetail from './aggregate-view/AggregateView';
import Case360 from './case-360/Case360';
import { ICaseDetail } from '../../utils/entities/pages/case-detail/ICaseDetails';

const CaseDetailContent = ({
  isWorkflowHidden,
  setIsWorkflowHidden,
  toggleOpen,
  openStatus,
  noData,
  isCompletedTask,
  taskLoading,
  clickHandler,
  selectedTaskName,
  selectedTab,
  caseId,
}: ICaseDetail) => {
  if (selectedTab?.[0]) {
    switch (selectedTab[0].title) {
      case 'Aggregate View':
        return (
          <AggregateViewDetail
            isWorkflowHidden={isWorkflowHidden}
            setIsWorkflowHidden={setIsWorkflowHidden}
            toggleOpen={toggleOpen}
            openStatus={openStatus}
            noData={noData}
            isCompletedTask={isCompletedTask}
            taskLoading={taskLoading}
            clickHandler={clickHandler}
            selectedTaskName={selectedTaskName}
            caseId={caseId}
          />
        );
      case 'Case 360':
        return <Case360 caseId={caseId} />;
      default:
        return null; // Fallback case if selectedTab doesn't match any known titles
    }
  } else {
    return null; // Handle the case where selectedTab is undefined or empty
  }
};

export default CaseDetailContent;
