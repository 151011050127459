import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import { CONSTANTS } from '../../../../utils/constants/constants';
import LabeledChip from '../../../generic/chip/LabeledChip';
import DrawerBox from './DrawerBox';
import { useTranslation } from 'react-i18next';
import IIdAndVStatusProps from '../../../../utils/entities/id-and-v/IIdAndVStatus';

const IdAndVStatus = ({ question, value, entity }: IIdAndVStatusProps) => {
  const { t } = useTranslation();

  const [openIdDrawer, setOpenIdDrawer] = useState<boolean>(false);
  const [openVDrawer, setOpenVDrawer] = useState<boolean>(false);

  const toggleIdDrawer = () => {
    if (
      question.identificationStatus[entity].identificationStatus ||
      'Not_Required' !== CONSTANTS.IDENTIFICATIONSTATUS.NOT_REQUIRED
    ) {
      setOpenIdDrawer(!openIdDrawer);
    }
  };

  const toggleVDrawer = () => {
    if (
      question.verificationStatus[entity].verificationStatus ||
      'Not_Required' !== CONSTANTS.VERIFICATIONSTATUS.NOT_REQUIRED
    ) {
      setOpenVDrawer(!openVDrawer);
    }
  };

  return (
    <>
      <Drawer className="drawer-layout" anchor="right" open={openIdDrawer} onClose={toggleIdDrawer}>
        <DrawerBox
          isIdentification
          openDrawer={toggleIdDrawer}
          question={question}
          entity={entity}
          value={value}
        />
      </Drawer>

      <Drawer className="drawer-layout" anchor="right" open={openVDrawer} onClose={toggleVDrawer}>
        <DrawerBox
          isIdentification={false}
          openDrawer={toggleVDrawer}
          question={question}
          entity={entity}
          value={value}
        />
      </Drawer>

      <div className="manage-idv">
        {CONSTANTS.IDENTIFICATIONSTATUS.NOT_REQUIRED !=
          question.identificationStatus[entity]?.identificationStatus && (
          <LabeledChip
            label={t('ID.ID')}
            isRequired={
              question?.identificationStatus[entity] &&
              question.identificationStatus[entity].identificationStatus ===
                CONSTANTS.IDENTIFICATIONSTATUS.NOT_IDENTIFIED
            }
            isIdentified={
              CONSTANTS.IDENTIFICATIONSTATUS.IDENTIFIED ===
              question.identificationStatus[entity]?.identificationStatus
            }
            isClickable={
              CONSTANTS.IDENTIFICATIONSTATUS.NOT_REQUIRED !=
              question.identificationStatus[entity]?.identificationStatus
            }
            handleClick={() =>
              CONSTANTS.IDENTIFICATIONSTATUS.NOT_REQUIRED ===
              question.identificationStatus[entity]?.identificationStatus
                ? null
                : toggleIdDrawer()
            }
          />
        )}
        {CONSTANTS.VERIFICATIONSTATUS.NOT_REQUIRED !=
          question.verificationStatus[entity]?.verificationStatus && (
          <LabeledChip
            label={t('V.V')}
            isRequired={
              CONSTANTS.VERIFICATIONSTATUS.NOT_VERIFIED ===
              question.verificationStatus[entity]?.verificationStatus
            }
            isIdentified={
              CONSTANTS.VERIFICATIONSTATUS.VERIFIED ===
              question.verificationStatus[entity]?.verificationStatus
            }
            isPartially={
              CONSTANTS.VERIFICATIONSTATUS.PARTIALLY_VERIFIED ===
              question.verificationStatus[entity]?.verificationStatus
            }
            isClickable={
              CONSTANTS.VERIFICATIONSTATUS.NOT_REQUIRED !=
              question.verificationStatus[entity]?.verificationStatus
            }
            handleClick={() =>
              CONSTANTS.VERIFICATIONSTATUS.NOT_REQUIRED ===
              question.verificationStatus[entity]?.verificationStatus
                ? null
                : toggleVDrawer()
            }
          />
        )}
      </div>
    </>
  );
};

export default IdAndVStatus;
