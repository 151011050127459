import React from 'react';
import { FormControl, FormLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PDFHeaderToggle = (props: {
  viewPDF: boolean;
  setViewPDF: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const { viewPDF, setViewPDF } = props;

  return (
    <FormControl className="pdf-header-wrapper">
      <FormLabel>{t('DD.INTERACTIVE_VIEW')}</FormLabel>
      <Switch
        value={viewPDF}
        onChange={() => {
          setViewPDF(!viewPDF);
        }}
      />
      <FormLabel>{t('PDF_VIEW')}</FormLabel>
    </FormControl>
  );
};

export default PDFHeaderToggle;
