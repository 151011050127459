import React, { ReactNode } from 'react';
import { Tooltip } from '@mui/material';
import './Tooltip.scss';

interface IProps {
  label: string;
  tooltipText?: string;
  bigEllipsis?: boolean;
  children?: ReactNode;
}

const TooltipComp = (props: IProps) => {
  const { label, tooltipText, bigEllipsis, children } = { ...props };

  return (
    <Tooltip
      data-testid="tooltip-component"
      title={<pre>{tooltipText ? tooltipText : label}</pre>}
      placement="bottom"
      className="tooltip-component">
      {children ? (
        <span>{children}</span>
      ) : (
        <span className={bigEllipsis ? 'big-ellipsis' : 'ellipsis'}>{label}</span>
      )}
    </Tooltip>
  );
};

export default TooltipComp;
