import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocalStorageUtils from '../../utils/functions/localStorageUtils';
import DialogComponent from '../generic/modal/DialogComponent';
import { CONSTANTS } from '../../utils/constants/constants';
import { IAccessToken } from '../../utils/entities/authentication';
import ICaseRoles from '../../utils/entities/case/ICaseRoles';
import { createRequestToJoin, getCase } from '../../service/case-manager.service';
import { addAlert } from '../../store/actions/alerts.actions';
import { getCaseRolesByProject } from '../../service/role-manager.service';
import GeneralUtils from '../../utils/functions/generalUtils';
import PlainTextButton from '../generic/button/PlainTextButton';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import MultipleCheckboxComponent from '../generic/inputs/checkbox/MultipleCheckboxComponent';
import { ICheckbox } from '../../utils/entities/genericComponents/ICheckbox';
import { IRequestToJoinProps } from '../../utils/entities/request-to-join/IRequestToJoin';

const RequestToJoin = ({ caseIds }: IRequestToJoinProps) => {
  const { t } = useTranslation();

  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );

  const [isRTJOpen, setIsRTJOpen] = useState<boolean>(false);
  const [isRTJLoading, setIsRTJLoading] = useState<boolean>(false);
  const [checkboxes, setCheckboxes] = useState<Array<ICheckbox>>([]);
  const [selectedCaseRoles, setSelectedCaseRoles] = useState<Array<string>>([]);

  const processIds = async (caseRoles: ICaseRoles[]) => {
    for (const id of caseIds) {
      try {
        const data = await getCase(id);
        if (data) {
          for (const role of data.caseRoles) {
            const caseRole = caseRoles.find((r) => r.name === role.name);
            if (caseRole) {
              caseRole.disabled =
                caseRole.disabled ||
                role.users.length >= caseRole.max ||
                role.users.includes(userInfo.user);
            }
          }

          if (caseRoles.every((role) => role.disabled)) {
            break;
          }
        }
      } catch (error) {
        addAlert({ type: 'error', primaryText: t('ERROR_GETTING_CASE'), secondaryText: id });
      }
    }
    setCheckboxes(
      caseRoles.map((role) => {
        return {
          id: role.name,
          name: role.name,
          checked: false,
          disabled: role.disabled,
        };
      })
    );
    setIsRTJLoading(false);
    setIsRTJOpen(!!caseRoles.length);
  };

  const openRTJ = () => {
    setIsRTJLoading(true);
    getCaseRolesByProject(
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME)
    )
      .then((caseRoles) => {
        caseRoles.forEach(function (item: ICaseRoles, i: number) {
          item.disabled = false;

          if (GeneralUtils.checkIfOwner(item)) {
            caseRoles.splice(i, 1);
            caseRoles.unshift(item);
          }
        });

        processIds(caseRoles);
      })
      .catch(() => {
        addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
        setCheckboxes([]);
      });
  };

  const handleRTJSubmit = () => {
    if (selectedCaseRoles.length) {
      setIsRTJLoading(true);
      createRequestToJoin({
        caseIds,
        caseRoles: selectedCaseRoles,
        projectName: LocalStorageUtils.getSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.PROJECT_NAME
        ),
      })
        .then(() => {
          addAlert({ type: 'success', primaryText: t('RTJ.REQUEST_SUCCESS') });
        })
        .catch(() => {
          addAlert({ type: 'error', primaryText: t('ERROR_MESSAGE') });
        })
        .finally(() => {
          setIsRTJLoading(false);
        });
      closeRTJ();
    } else {
      addAlert({
        type: 'info',
        primaryText: t('CASE_ROLES.SAVE_NO_CHANGES'),
      });
    }
  };

  const closeRTJ = () => {
    setIsRTJOpen(false);
    setCheckboxes([]);
    setSelectedCaseRoles([]);
  };

  return (
    <>
      <PlainTextButton
        handleClick={openRTJ}
        isDisabled={!caseIds.length}
        loading={isRTJLoading}
        classes="text-transform-capitalize bold-font"
        icon={<AssignmentTurnedInOutlinedIcon />}
        label={t('RTJ.REQUEST_TO_JOIN')}
      />

      <DialogComponent
        showConfirmButton
        open={isRTJOpen}
        title={t('RTJ.REQUEST_TO_JOIN')}
        body={
          <div>
            <Typography variant="body1" gutterBottom className="numberAssignedTasks">
              {t('RTJ.YOU_HAVE_SELECTED', { count: caseIds.length })}
            </Typography>
            <MultipleCheckboxComponent
              checkboxes={checkboxes}
              selected={selectedCaseRoles}
              setSelected={setSelectedCaseRoles}
            />
          </div>
        }
        onClose={closeRTJ}
        setConfirm={handleRTJSubmit}
      />
    </>
  );
};

export default RequestToJoin;
