import variables from '../styles/variables/_export.module.scss';
import baseLayoutSizes from '../layouts/base-layout/_exportBaseLayoutScss.module.scss';

export const getStyleColors = () => {
  return variables;
};

export const getBaseLayoutSizes = () => {
  return baseLayoutSizes;
};
