import { SpelExpressionEvaluator, StandardContext } from 'spel2js';
import { IPermission } from '../entities/role';
import IExpressionContext from '../entities/expressions/IExpressionContext';
import ISimpleAttributeQL from 'fso-fincrime-transaction-reviewer-ui/dist/entities/ISimpleAttributeQL';
import StringUtils from './stringUtils';
import dateUtils from './dateUtils';
import LocalStorageUtils from './localStorageUtils';
import { CONSTANTS } from '../constants/constants';
import ITaskTimer from '../entities/screen/ITaskTimer';
import { ISelectedSubmitOption } from '../entities/screen/ISubmitOptions';
import ISpelUtils from '../entities/functions/ISpelUtils';

const SPEL_CONTEXT = StandardContext.create(null, null);

const SpelUtils: ISpelUtils = {
  useCaseData: (caseData: IExpressionContext, attributeName: string): string | undefined => {
    return (caseData as Array<Array<ISimpleAttributeQL>>)
      .flat()
      .find((data: ISimpleAttributeQL) => data.name == attributeName)?.value;
  },
  getValueFromRoot: (root: IExpressionContext, key: string): string => {
    return root[key];
  },
  getValueFromHeader: (
    header: IExpressionContext,
    key: string,
    secondKey: string | null
  ): string => {
    return secondKey ? JSON.parse(header[key])[secondKey] : header[key];
  },
  castToNumber: (text: string): number | null => {
    return Number(text);
  },
  userHasRole: (contextVar: string, role: string): boolean => {
    return StringUtils.compareStrings(contextVar, role);
  },
  userHasAsset: (contextVar: Array<IPermission>, asset: string): boolean => {
    return contextVar.filter((p: IPermission) => p.name === asset).length > 0;
  },
  userHasAssets: (contextVar: Array<IPermission>, assets: Array<string>): boolean => {
    return assets.every((asset: string) => SpelUtils.userHasAsset(contextVar, asset));
  },
  getCurrentEpoch: (): number => {
    return Number(
      dateUtils.dateToEpoch(
        new Date().toString(),
        LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TIME_ZONE)
      )
    );
  },
  convertDateToEpoch: (date: string) => {
    return SpelUtils.convertToEpochWithFormat(`${date}T00:00:00`, 'YYYY-MM-DDTHH:mm:ss');
  },
  convertToEpochWithFormat: (date: string, format: string) => {
    // TODO: this should be a generic date function
    return new Date(dateUtils.dateFormatToGeneric(date, format)).getTime() / 1000;
  },
  getTimerStartEpoch: (timer: ITaskTimer) => {
    return timer?.taskStartDate;
  },
  getTimerEndEpoch: (timer: ITaskTimer) => {
    return timer?.taskFinishDate;
  },
  isTimerPaused: (timer: ITaskTimer) => {
    return timer?.paused;
  },
  isTimerRemoved: (timer: ITaskTimer) => {
    return timer?.removed;
  },
  listContainsValue(values: string, value: string): boolean {
    const valuesArray = values.split(',') ?? [];
    return valuesArray.includes(value);
  },
  getAttributeValueFromSubmitOption: (
    submitOptions?: ISelectedSubmitOption
  ): string | undefined => {
    return submitOptions?.attributeValue;
  },
  getAttributeNameFromSubmitOption: (submitOptions?: ISelectedSubmitOption): string | undefined => {
    return submitOptions?.attributeName;
  },
  evaluateExpression: (expression: string | null, context: IExpressionContext): any => {
    if (!expression) return true;

    const rolePermissions: Array<IPermission> =
      LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.USER.ACTIVE_ROLE)?.permissions ??
      [];

    const selectedSubmitOption: ISelectedSubmitOption | undefined = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.TASK.SUBMIT_OPTION
    );

    const resultContext: IExpressionContext = {
      useCaseData: SpelUtils.useCaseData,
      getValueFromRoot: SpelUtils.getValueFromRoot,
      getValueFromHeader: SpelUtils.getValueFromHeader,
      castToNumber: SpelUtils.castToNumber,
      userHasRole: SpelUtils.userHasRole,
      userHasAsset: SpelUtils.userHasAsset,
      userHasAssets: SpelUtils.userHasAssets,
      getCurrentEpoch: SpelUtils.getCurrentEpoch,
      convertDateToEpoch: SpelUtils.convertDateToEpoch,
      convertToEpochWithFormat: SpelUtils.convertToEpochWithFormat,
      getTimerStartEpoch: SpelUtils.getTimerStartEpoch,
      listContainsValue: SpelUtils.listContainsValue,
      getAttributeValueFromSubmitOption: SpelUtils.getAttributeValueFromSubmitOption,
      getAttributeNameFromSubmitOption: SpelUtils.getAttributeNameFromSubmitOption,
      role: context.userRole?.name,
      assets: rolePermissions,
      rootAttributes: context.contextLocals?.rootEntity,
      headerAttributes: context.contextLocals?.header,
      caseData: context.caseData?.case?.caseData,
      timer: context.timer,
      submitOptions: selectedSubmitOption,
    };

    const expressionCompiled = SpelExpressionEvaluator.compile(expression);
    return expressionCompiled.eval(SPEL_CONTEXT, { ...context, ...resultContext });
  },
  expressionValidation: (
    defaultBoolean: boolean,
    expression: string | null,
    context: IExpressionContext
  ): boolean => {
    if (expression) {
      try {
        return SpelUtils.evaluateExpression(expression, context);
      } catch (error: unknown) {
        console.error('Error evaluating SpEL expression for ', expression, error);
        return false;
      }
    }
    return defaultBoolean ?? true;
  },
  getValueFromExpression: (expression: string | null, context: IExpressionContext): any => {
    if (expression) {
      try {
        return SpelUtils.evaluateExpression(expression, context);
      } catch (error: unknown) {
        console.error('Error evaluating SpEL expression for ', expression, error);
        return null;
      }
    }
    return null;
  },
};

export default SpelUtils;
