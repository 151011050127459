import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Stack,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextFieldProps,
  Grid,
} from '@mui/material';
import QuestionTooltip from '../question-tooltip/QuestionTooltip';
import IdAndVStatus from '../IDAndV/IdAndVStatus';
import SpelUtils from '../../../../utils/functions/spelUtils';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { IAccessToken } from '../../../../utils/entities/authentication';
import IQuestionType from '../../../../utils/entities/questionnaire/IQuestionType';
import DynamicQuestionnaireUtils from '../../../../utils/functions/dynamicQuestionnaireUtils';
import ErrorStack from './Error/ErrorStack';
import IQuestion from '../../../../utils/entities/screen/IQuestion';
import { useIScreen } from '../../../../pages/task/Task';
import IValue from '../../../../utils/entities/screen/IValue';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';

const TypeAheadTextQuestion = (props: IQuestionType) => {
  const {
    question,
    entity,
    locals,
    contextLocals,
    updateLocals,
    updateValue,
    disabled,
    error,
    requestBody,
    setIndex,
    activeTabId,
  } = props;

  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
  );
  const storedValue = LocalStorageUtils.getSavedItem(
    `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`
  );

  const oldValue = requestBody?.dynamicQuestionnaire?.[activeTabId!]?.sets[
    setIndex as number
  ].questions.find((currentQuestion: IQuestion) => currentQuestion.id == question.id) as IQuestion;

  const requiredResult = SpelUtils.expressionValidation(
    question.required,
    question.requiredExpression,
    {
      contextLocals,
      userRole: userInfo.role,
      ...locals,
    }
  );
  const responseOptions = question.responseOptions;

  const [value, setValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
  );

  const [previousValue, setPreviousValue] = useState<string>(
    DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, oldValue?.value, entity)
  );

  const [runtimeValidationError, setRuntimeValidationError] = useState(false);

  useEffect(() => {
    question.wasChanged = { [entity]: false };
    question.oldValue = oldValue?.value as IValue;
  }, []);

  useEffect(() => {
    LocalStorageUtils.setSavedItem(
      `${CONSTANTS.LOCAL_STORAGE_KEYS.DYNAMIC_QUESTIONNAIRE}_${entity}.${question.attributeName}`,
      value?.toString()
    );
    const valueArray = [value?.toString()];
    updateValue(question.attributeName, valueArray);

    locals[question?.attributeName] = value;

    updateLocals(locals);

    if (value) {
      setRuntimeValidationError(
        DynamicQuestionnaireUtils.hasRuntimeError(question.validation, value, {
          ...{
            contextLocals,
            userRole: userInfo.role,
            ...locals,
          },
          [question.attributeName]: value,
        })
      );
    }

    if (previousValue != value) {
      GeneralUtils.triggerEvt(EVENTS_CONSTANTS.IS_DIRTY_EVT, {
        isDirty: true,
      });
    }
  }, [value]);

  useEffect(() => {
    setValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, question.value, entity)
    );
  }, [entity]);

  useEffect(() => {
    setPreviousValue(
      DynamicQuestionnaireUtils.getQuestionValueAsString(storedValue, oldValue?.value, entity)
    );
  }, [requestBody]);

  const onChangeHandler = (newInput: string | null) => {
    if (newInput !== null) {
      setValue(newInput);
    } else {
      setValue('');
    }
  };

  useEffect(() => {
    if (question.refresh) {
      setValue(question.value[entity][0]);
    }
  }, [question.refresh]);

  return (
    <>
      {SpelUtils.expressionValidation(question.visible, question.visibilityExpression, {
        contextLocals,
        userRole: userInfo.role,
        ...locals,
      }) && (
        <Stack sx={{ width: '100%', margin: '0.5rem 0' }}>
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>
                {question.label}
                {requiredResult ? '*' : ''}
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Autocomplete
                disabled={
                  disabled
                    ? disabled
                    : SpelUtils.expressionValidation(
                        question.readOnly,
                        question.readOnlyExpression,
                        {
                          contextLocals,
                          userRole: userInfo.role,
                          ...locals,
                        }
                      )
                }
                id="autocomplete"
                value={value}
                onChange={(event, newInputValue: string | null) => onChangeHandler(newInputValue)}
                inputValue={input}
                onInputChange={(event, newInputValue) => setInput(newInputValue)}
                options={responseOptions.map((options) => options.label)}
                sx={{ width: '90%', margin: 'normal' }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField variant="standard" {...(params as TextFieldProps)} />
                )}
              />
              <Grid className="questionnaire-icons">
                <IdAndVStatus question={question} entity={entity} value={value} />
                <QuestionTooltip question={question} />
              </Grid>
            </Stack>
            <ErrorStack
              value={value}
              entity={entity}
              error={error}
              runtimeValidationError={runtimeValidationError}
              required={requiredResult}
            />
          </>
        </Stack>
      )}
    </>
  );
};

export default TypeAheadTextQuestion;
