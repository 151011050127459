import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Container,
  IconButton,
} from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Metadata from '../metadata/Metadata';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ILine, IMetadata, IMetadataAttr } from '../../../../utils/entities/meta-data';
import FilePreview from '../../../generic/file-preview/FilePreview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PlainTextButton from '../../../generic/button/PlainTextButton';
import { ICaptureMetadata } from '../../../../utils/entities/document-center';
import ISimpleAttribute from '../../../../utils/entities/case/ISimpleAttribute';
import PrimaryButton from '../../../generic/buttons/PrimaryButton';

const CaptureMetadata = (props: ICaptureMetadata) => {
  const { fileUrl, metadata, setMetadata, setIsSaveDisabled, documentConfigurations } = {
    ...props,
  };

  const [expanded, setExpanded] = useState<Array<boolean>>(Array(metadata.length).fill(false));
  const [isExpandAll, setIsExpandAll] = useState<boolean>(true);

  useEffect(() => {
    const metadataCopied = [...metadata];
    for (const data of metadataCopied) {
      for (const line of data.lines) {
        const bks = line?.businessKeys;
        if (bks?.length === 1) {
          const names = bks[0].map((bk: { name: string }) => bk.name);

          const attr = line.line.find((attr: { name: string }) => names.includes(attr.name));
          if (attr) {
            attr.disabled = true;
          }
        }
      }
    }

    setMetadata([...metadataCopied]);
  }, [metadata]);

  const handleExpand = (idx: number) => {
    expanded[idx] = !expanded[idx];
    setExpanded([...expanded]);
  };

  const checkAllRequiredAreFilled = () => {
    setIsSaveDisabled(
      metadata.find(
        (gr) =>
          gr.mandatory &&
          gr.lines
            .flat(1)
            .find(
              (line: { line: IMetadataAttr[] }) =>
                line.line.find((attr) => attr.value == null || attr.value.trim().length === 0) !=
                null
            )
      ) != null
    );
  };

  const valueUpdated = (line: ILine, attribute: IMetadataAttr) => {
    const businessKeys = line.businessKeys;
    const l = line.line;

    const businessKey = businessKeys?.reduce((acc: { [key: string]: ISimpleAttribute }, curr) => {
      acc[curr[0].name] = curr[0];
      return acc;
    }, {})?.[attribute.name];

    if (
      businessKey &&
      (businessKey.value !== attribute.value || businessKey.values !== attribute.values)
    ) {
      for (const attr of l) {
        const attrName = attr.name;
        const bk = businessKeys?.reduce((acc: { [key: string]: ISimpleAttribute }, curr) => {
          acc[curr[0].name] = curr[0];
          return acc;
        }, {});

        if (bk?.[attrName]?.name === attrName && bk?.[attrName]?.name != businessKey.name) {
          attr.disabled = true;
        }
      }
    } else {
      for (const attr of l) {
        attr.disabled = false;
      }
    }

    setMetadata([...metadata]);
    checkAllRequiredAreFilled();
  };

  const addNewLine = (m: any) => {
    m.lines.push(
      m.lines[0]?.map((line: any[][]) =>
        line?.map((attr: any) => {
          const a = { ...attr };
          a.value = '';
          a.values = [];
          return { ...a };
        })
      )
    );
    setMetadata([...metadata]);
  };

  const deleteLine = (groupIdx: number, lineIdx: number) => {
    metadata[groupIdx].lines.splice(lineIdx, 1);
    setMetadata([...metadata]);
  };

  const isOnlyOneGroup = () => {
    return metadata.length === 1;
  };

  const getExpandBtnText = () => {
    return isExpandAll ? 'Expand All' : 'Close All';
  };

  const expandAll = () => {
    setIsExpandAll(!isExpandAll);
    setExpanded([...expanded.fill(isExpandAll)]);
  };

  return (
    <Grid container spacing={1} className="full-height">
      <Grid
        item
        xs={4}
        style={{
          paddingRight: 8,
          paddingLeft: 0,
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
        }}>
        {documentConfigurations.find((config) => config.field === 'documentPreview')?.enabled && (
          <FilePreview data={fileUrl.url} type={fileUrl.type} />
        )}
      </Grid>
      <Grid item xs={8} columns={8} className="questionnaire" style={{ paddingRight: 0 }}>
        <Grid container>
          <Grid item xs={12}>
            <PrimaryButton
              isPositiveButton
              text={getExpandBtnText()}
              onClick={expandAll}
              isDisabled={isOnlyOneGroup()}
            />
            {metadata.map((gr: IMetadata, grIdx: number) => (
              <Accordion
                key={grIdx + '-accordionHeader'}
                expanded={expanded[grIdx] || isOnlyOneGroup()}
                onChange={() => handleExpand(grIdx)}
                className="full-width">
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="bold-font header-title font-size-12">
                    {gr.title?.toUpperCase()} {gr.mandatory ? ' *' : ''}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Container className="document-preview-container scrollTable">
                    {gr.lines.map((line, lineIdx: number) => (
                      <Grid
                        container
                        spacing={5}
                        justifyContent="flex-start"
                        className="document-preview-container-column"
                        key={`grid-${lineIdx}`}>
                        {line.line.map((attr) => (
                          <Metadata
                            attribute={attr}
                            valueUpdated={() => valueUpdated(line, attr)}
                            key={`metadata-${attr.name}`}
                          />
                        ))}
                        {gr.repeatable && (
                          <IconButton
                            className="delete-document-preview-container"
                            disabled={gr.repeatable && gr.lines.length === 1}
                            style={{ padding: 0 }}
                            onClick={() => deleteLine(grIdx, lineIdx)}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </Grid>
                    ))}
                    {gr.repeatable && (
                      <Grid
                        container
                        spacing={5}
                        justifyContent="flex-start"
                        className="add-document-preview-container">
                        <PlainTextButton
                          label={t('ADD_ROW')}
                          handleClick={() => addNewLine(gr)}
                          icon={<AddCircleOutlineIcon />}
                        />
                      </Grid>
                    )}
                  </Container>
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CaptureMetadata;
