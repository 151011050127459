import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import './JoinRequest.scss';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TextFieldComponent from '../../../components/generic/inputs/text-field/TextFieldComponent';
import { IJoinRequest } from '../../../utils/entities/case/CaseCreation/JoinRequest/IJoinRequest';
import { IJoinRequestDetail } from '../../../utils/entities/case/CaseCreation/JoinRequest/IJoinRequestDetail';
import ICaseRoles from '../../../utils/entities/case/ICaseRoles';
import { IOpenRequests } from '../../../utils/entities/case/CaseCreation/JoinRequest/IOpenRequests';

const JoinRequest = (props: {
  caseRoles: Array<ICaseRoles>;
  role: ICaseRoles;
  requests: IJoinRequest | undefined;
  setRequestsToJoin: React.Dispatch<React.SetStateAction<IJoinRequest | undefined>>;
  handleChange: (role: ICaseRoles, values: Array<string>, event?: any) => void;
  assignedCaseRoles: ICaseRoles[];
  acceptedRequests: string[];
  setAcceptedRequests: React.Dispatch<React.SetStateAction<string[]>>;
  deniedRequests: string[];
  setDeniedRequests: React.Dispatch<React.SetStateAction<string[]>>;
  isCaseRolesAdmin: boolean | undefined;
  onChangeAssignedCaseRoles: (assignedCaseRoles: ICaseRoles[]) => void;
  openRequests: IOpenRequests[];
  setOpenRequests: React.Dispatch<React.SetStateAction<IOpenRequests[]>>;
}) => {
  const {
    caseRoles,
    role,
    requests,
    setRequestsToJoin,
    handleChange,
    assignedCaseRoles,
    acceptedRequests,
    setAcceptedRequests,
    deniedRequests,
    setDeniedRequests,
    isCaseRolesAdmin,
    onChangeAssignedCaseRoles,
    openRequests,
    setOpenRequests,
  } = props;
  const { t } = useTranslation();
  const roleRequests = requests?.[role.name] ?? [];
  const hasPendingRequests = roleRequests.filter(
    (request) => !acceptedRequests.includes(request.id) && !deniedRequests.includes(request.id)
  );
  const assignedCaseRole = assignedCaseRoles.find((caseRole) => caseRole.name === role.name);
  const roleUsersEmails = assignedCaseRole?.users.map((user) => user);

  const [allRequests, setAllRequests] = useState<IJoinRequest | undefined>(requests);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const [pendingRequests, setPendingRequests] = useState<Array<IJoinRequestDetail>>(
    roleRequests.filter((request) => !acceptedRequests.includes(request.id))
  );
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    onChangeAssignedCaseRoles(assignedCaseRoles);
    setRequestsToJoin(requests);
    setAcceptedRequests([]);
    setDeniedRequests([]);
  }, []);

  useEffect(() => {
    setPendingRequests(roleRequests.filter((request) => !acceptedRequests.includes(request.id)));
  }, [acceptedRequests, deniedRequests]);

  const handleClick = () => {
    const newRequests = { ...allRequests };
    setAllRequests(newRequests);
    const updatedRequests: Array<IOpenRequests> = openRequests?.map((request: IOpenRequests) => {
      if (request.role === role.name) {
        return { ...request, open: !request.open };
      } else {
        return { ...request, open: false };
      }
    });
    setOpenRequests(updatedRequests);
  };

  useEffect(() => {
    openRequests?.forEach((request: IOpenRequests) => {
      if (request.role === role.name) {
        setOpen(request.open);
      }
    });
  }, [openRequests]);

  const handleSearch = (e: string) => {
    const value = e;

    setSearchTerm(value);
    setPendingRequests(
      roleRequests.filter(
        (request) =>
          (value ? request.userEmail.toLowerCase().includes(value.toLowerCase()) : true) &&
          !request.accepted &&
          !request.denied &&
          !(roleUsersEmails && roleUsersEmails.includes(request.userEmail))
      )
    );
  };

  const requestStatus = (role: ICaseRoles, request: IJoinRequestDetail, status: boolean) => {
    const user = request.userEmail;
    if (status) {
      if (assignedCaseRole) {
        const usersAssigned = assignedCaseRole.users ? [...assignedCaseRole.users] : [];
        if (!usersAssigned.includes(user)) {
          usersAssigned.push(user);
          handleChange(role, usersAssigned);
          setAcceptedRequests((prevAccepted) => [...(prevAccepted as string[]), request.id]);
        }
      } else {
        const usersAssigned = role.users ? [...role.users] : [];
        if (!usersAssigned.includes(user)) {
          usersAssigned.push(user);
          handleChange(role, usersAssigned);
          setAcceptedRequests((prevAccepted) => [...(prevAccepted as string[]), request.id]);
        }
      }
    } else {
      const isAccepted = acceptedRequests.find((accepted) => accepted === request.id);
      if (isAccepted) {
        const removeAcceptedRequest = acceptedRequests.filter((id) => id !== request.id);
        setAcceptedRequests(removeAcceptedRequest);
      }

      setDeniedRequests((prevDenied) => [...(prevDenied as string[]), request.id]);
    }

    const findRequest = pendingRequests.findIndex(
      (pendinRequest) => pendinRequest.id === request.id
    );
    if (findRequest !== -1) {
      pendingRequests[findRequest] = {
        ...pendingRequests[findRequest],
        ...request,
      };
    }
  };

  useEffect(() => {
    const updatedRequests = pendingRequests.map((request) => {
      return request;
    });
    setPendingRequests(updatedRequests);
  }, [assignedCaseRoles]);

  return (
    <>
      {isCaseRolesAdmin &&
        ((assignedCaseRole && assignedCaseRole?.users.length < assignedCaseRole?.max) || role) && (
          <Grid
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            sx={{ flex: '1' }}>
            {hasPendingRequests.length ? (
              <List>
                <ListItemButton onClick={handleClick} className="add-requests">
                  <div className="overlay-front badge circle-ellipse">
                    {hasPendingRequests.length}
                  </div>
                  <PersonAddAlt1Icon />
                </ListItemButton>

                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Collapse in={open} timeout="auto" unmountOnExit className="requests-list">
                    <List component="div">
                      <Grid
                        container
                        xs={12}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        wrap="nowrap">
                        <ListItemText>
                          <TextFieldComponent
                            placeholder={t('SEARCH_3_DOTS')}
                            type="text"
                            value={searchTerm}
                            setData={(e: string) => handleSearch(e)}
                            icon={<SearchIcon />}
                          />
                        </ListItemText>
                      </Grid>
                      {pendingRequests.length ? (
                        pendingRequests.map((request: IJoinRequestDetail, index: number) => {
                          const alreadyAccepted = acceptedRequests.some(
                            (accepted) => accepted === request.id
                          );
                          const alreadyDenied = deniedRequests.some(
                            (denied) => denied === request.id
                          );
                          return (
                            !alreadyAccepted &&
                            !alreadyDenied && (
                              <Grid
                                container
                                xs={12}
                                key={index}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                wrap="nowrap"
                                className="request-item">
                                <Grid item xs={9} zeroMinWidth>
                                  <ListItemText>
                                    <Tooltip title={request.userEmail}>
                                      <Typography variant="body2" noWrap>
                                        {request.userEmail}
                                      </Typography>
                                    </Tooltip>
                                  </ListItemText>
                                </Grid>
                                <Grid container item xs={3}>
                                  <Grid item container xs={6}>
                                    <Tooltip title={t('ACCEPT')}>
                                      <IconButton
                                        disabled={
                                          alreadyAccepted ||
                                          (assignedCaseRole?.users &&
                                            assignedCaseRole?.users?.length >= role.max)
                                        }
                                        className="accept-request-icon"
                                        onClick={() => requestStatus(role, request, true)}>
                                        <CheckIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item container xs={6}>
                                    <Tooltip title={t('DENY')}>
                                      <IconButton
                                        className="declined-request-icon"
                                        onClick={() => requestStatus(role, request, false)}>
                                        <ClearIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          );
                        })
                      ) : (
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          wrap="nowrap">
                          <ListItemText>
                            <Typography variant="body2" noWrap>
                              {t('NO_REQUESTS')}
                            </Typography>
                          </ListItemText>
                        </Grid>
                      )}
                    </List>
                  </Collapse>
                </ClickAwayListener>
              </List>
            ) : undefined}
          </Grid>
        )}
    </>
  );
};

export default JoinRequest;
