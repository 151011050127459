import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AssignCaseContainer from '../../../components/cases/assign-case-container/AssignCaseContainer';
import { CONSTANTS } from '../../../utils/constants/constants';
import ExportCaseData from '../../../components/cases/export/ExportCaseData';
import GeneralUtils from '../../../utils/functions/generalUtils';
import LocalStorageUtils from '../../../utils/functions/localStorageUtils';
import IAggregateViewHeaderProps from '../../../utils/entities/case/case-detail/case-header/IAggregateViewHeader';

const AggregateViewHeader = ({ caseId, caseRoles, casePermissions }: IAggregateViewHeaderProps) => {
  const { t } = useTranslation();
  const tenant = LocalStorageUtils.getSavedItem(
    CONSTANTS.LOCAL_STORAGE_KEYS.PROJECT_SETTINGS.TENANT
  );

  return (
    <Grid
      container
      xs={12}
      className="aggregate-view-header"
      direction="row"
      justifyContent="flex-start"
      alignItems="center">
      <Grid container item xs={4} justifyContent="center">
        <div className="bold-font header-title">{t('ALL_TASKS')}</div>
      </Grid>
      <Grid container item xs={4} justifyContent="flex-end" style={{ textAlign: 'right' }}>
        <AssignCaseContainer caseId={caseId} caseRoles={caseRoles} />

        {CONSTANTS.EXPORT_OPTIONS.filter((option) =>
          GeneralUtils.checkUserPermissions(option.permissionType, casePermissions)
        ).length > 0 && (
          <Grid item xs={4} key="2">
            <ExportCaseData caseId={caseId} tenant={tenant} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AggregateViewHeader;
