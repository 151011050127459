import React, { useEffect, useRef, useState } from 'react';
import { Divider, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import INotificationMenuProps from '../../../../utils/entities/header/notification-center/INotificationMenu';
import INotification from '../../../../utils/entities/header/notification-center/INotification';
import {
  dismissAllUserNotifications,
  getPaginatedNotifications,
} from '../../../../service/notification_manager.service';
import NotificationContainer from './notification-container/NotificationContainer';
import Loader from '../../../loader/Loader';
import TextButton from '../../../generic/button/text/TextButton';
import NoNotification from './no-notification/NoNotification';
import './NotificationMenu.scss';

const NotificationMenu = ({
  anchorEl,
  isOpenMenu,
  onClose,
  setTotalNotifications,
}: INotificationMenuProps) => {
  const { t } = useTranslation();
  const pageSize = 10;

  const [scrollableMenuElement, setScrollableMenuElement] = useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState<Array<INotification>>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number | undefined>(undefined);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState<boolean>(false);

  const pageNumberRef = useRef(pageNumber);
  const canLoadMore = totalPages === undefined || totalPages > pageNumber;

  useEffect(() => {
    pageNumberRef.current = pageNumber;

    if (canLoadMore) {
      loadNotifications(pageNumber, true);
    }
  }, [pageNumber]);

  const loadNotifications = (pageNumber: number, loadMore: boolean) => {
    setIsNotificationsLoading(true);
    getPaginatedNotifications(pageNumber, pageSize)
      .then((paginatedNotifications) => {
        const notificationsTemp: INotification[] =
          paginatedNotifications.content as INotification[];
        setNotifications(loadMore ? [...notifications, ...notificationsTemp] : notificationsTemp);
        setTotalPages(paginatedNotifications.totalPages);
        setTotalNotifications(paginatedNotifications.totalElements);
      })
      .finally(() => setIsNotificationsLoading(false));
  };

  const handleScroll = (element: HTMLElement | null) => {
    if (element) {
      const atBottom = element.scrollHeight - element.scrollTop <= element.clientHeight;

      if (atBottom && totalPages && pageNumberRef.current < totalPages - 1) {
        setPageNumber(pageNumberRef.current + 1);
      }
    }
  };

  const handleMenuEntering = () => {
    loadNotifications(0, false);
  };

  const handleMenuEntered = () => {
    const element = document.getElementById('notification-menu-scrollable');
    element?.addEventListener('scroll', () => handleScroll(element));
    setScrollableMenuElement(element);
  };

  const handleMenuExited = () => {
    scrollableMenuElement?.removeEventListener('scroll', () => handleScroll(scrollableMenuElement));
  };

  const dismissAllNotifications = () => {
    dismissAllUserNotifications().then(() => {
      setNotifications([]);
      setTotalNotifications(0);
      setPageNumber(0);
      setTotalPages(0);
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpenMenu}
      TransitionProps={{
        onEntering: handleMenuEntering,
        onEntered: handleMenuEntered,
        onExited: handleMenuExited,
      }}
      onClose={onClose}
      slotProps={{ paper: { className: 'nexus-shadow notification-menu' } }}>
      <div className="header">
        <h3>{t('NOTIFICATION_CENTER.TITLE')}</h3>
        {notifications.length > 0 && (
          <TextButton
            label={t('NOTIFICATION_CENTER.DISMISS_ALL')}
            handleClick={dismissAllNotifications}
          />
        )}
      </div>
      <Divider className="thicker-divider" />
      <div style={{ width: 500, height: 400 }}>
        {isNotificationsLoading && notifications.length == 0 && <Loader />}
        {!isNotificationsLoading && notifications.length == 0 && <NoNotification />}
        {!isNotificationsLoading && notifications.length == 0 && <NoNotification />}
        {notifications.length > 0 && (
          <div
            id="notification-menu-scrollable"
            className="show-scroll"
            style={{ height: 'inherit', overflowY: 'scroll' }}>
            {notifications.map((notification: INotification, index: number) => (
              <NotificationContainer
                key={notification.id}
                notification={notification}
                index={index}
                handleMenuClose={onClose}
                notifications={notifications}
                setNotifications={setNotifications}
              />
            ))}
            {isNotificationsLoading && (
              <div style={{ height: 20 }}>
                <Loader />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="footer">{`(${pageNumber + 1}/${totalPages})`}</div>
    </Menu>
  );
};

export default NotificationMenu;
