import axios, { AxiosError, AxiosResponse } from 'axios';
import axiosConfig from './axios.config';
import serviceUtils from '../utils/functions/serviceUtils';
import ICreateCase360DataTemplate from '../utils/entities/case-360/ICreateCase360DataTemplate';

export const getCaseDetailCase360 = (
  tenant: string,
  caseId: string
): Promise<ICreateCase360DataTemplate> =>
  axios
    .post(
      `/createCase360DataTemplate/${encodeURIComponent(tenant)}/${encodeURIComponent(caseId)}`,
      undefined,
      axiosConfig
    )
    .then((res: AxiosResponse<ICreateCase360DataTemplate>) => {
      return serviceUtils.processSuccess<ICreateCase360DataTemplate>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });

export const getCase360HeaderByProject = (projName: string, caseId: string): Promise<any> =>
  axios
    .get(
      `/getCase360HeaderByProject/${encodeURIComponent(projName)}/${encodeURIComponent(caseId)}`,
      axiosConfig
    )
    .then((res: AxiosResponse<any>) => {
      return serviceUtils.processSuccess<any>(res);
    })
    .catch((error: AxiosError | string) => {
      throw serviceUtils.processError(error);
    });
