import React, { useCallback, useEffect, useState } from 'react';
import ErrorBoundary from '../../error-boundary/ErrorBoundary';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Paper,
  Typography,
  FormControlLabel,
  SelectChangeEvent,
} from '@mui/material';
import GeneralUtils from '../../../../utils/functions/generalUtils';
import AddIcon from '@mui/icons-material/Add';
import { PermissionTypes } from '../../../../utils/constants/enums';
import { useTranslation } from 'react-i18next';
import Loader from '../../../loader/Loader';
import CheckboxComponent from '../../inputs/checkbox/CheckboxComponent';
import { AgGridReact } from 'ag-grid-react';
import Views from '../views/Views';
import {
  ColDef,
  Column,
  ColumnVO,
  FilterModel,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from 'ag-grid-community';
import SearchIcon from '@mui/icons-material/Search';

import { getColumnConfig } from '../../../../service/config-manager.service';
import { CONSTANTS } from '../../../../utils/constants/constants';
import { addAlert } from '../../../../store/actions/alerts.actions';
import IAgGridTblPayload from '../../../../utils/entities/ag-grid/IAgGridTblPayload';
import { useDebouncedCallback } from 'use-debounce';
import LocalStorageUtils from '../../../../utils/functions/localStorageUtils';
import { IAccessToken } from '../../../../utils/entities/authentication';
import RequestToJoin from '../../../request-to-join/RequestToJoin';
import TextFieldComponent from '../../inputs/text-field/TextFieldComponent';
import ExportComponent from '../export/Export';
import Reset from '../reset/Reset';
import CreateCaseModal from '../../../cases/CreateCaseModal/CreateCaseModal';
import IDataModelColumn from '../../../../utils/entities/ag-grid/IDataModelColumn';
import Pagination from '../pagination/Pagination';
import AssignTask from '../../../task-list/assign-task/AssignTask';
import { EVENTS_CONSTANTS } from '../../../../utils/constants/event_constants';
import { IGridProps } from '../../../../utils/entities/ag-grid/IGridProps';
import ITask from '../../../../utils/entities/screen/ITask';

const AgGridTbl: React.FC<IGridProps> = React.memo(
  ({
    selectedGroup,
    caseCreationLoading,
    getSelectedGroup,
    groups,
    selectAll,
    handleSelectAll,
    payload,
    columnsViews,
    tenant,
    viewType,
    listType,
    fetchData,
    pageSize,
    pageNo,
    setGroupByParam,
    canRTJRequest,
    gridSelectedRows,
    noRows,
    setDefaultView,
    setColumnSorting,
    setColumnGroup,
    setColumnFiltering,
    setSelectAllChecked,
    setResetColumns,
    defaultView,
    columnFiltering,
    columnSorting,
    columnGroup,
    selectAllChecked,
    resetColumns,
    columns,
    getRowClass,
    onRowSelected,
    onFilterChanged,
    onColumnRowGroupChanged,
    onRowGroupOpened,
    columnsMoved,
    getContextMenuItems,
    setGridSelectedRows,
    columnsConfigs,
    setColumnsConfigs,
    history,
    params,
    gridOptions,
    groupKeys,
    totalPages,
    totalElements,
    setPageSize,
    setPageNo,
    openModal,
    setOpenModal,
    setSelectedGroup,
    setTotalPages,
    setGroupKeys,
    gridName,
    selcetAllLabel,
    groupByParams,
    usercanTaskUnassign,
    usercanTaskAssign,
    usercanFullTaskUnassign,
    usercanFullTaskAssign,
    users,
    activeRole,
    setColumnsViews,
    setDataLoading,
    dataLoading,
    gridRef,
    gridNode,
    gridApi,
    setGridApi,
    showCaseCreationModule,
    showAssignTaskModule,
    showExportModule,
    showViewsModule,
    showResetModule,
  }: IGridProps) => {
    const { t } = useTranslation();
    const userInfo: IAccessToken = LocalStorageUtils.getSavedItem(
      CONSTANTS.LOCAL_STORAGE_KEYS.USER_INFO
    );
    const displayedColumns = gridNode?.columnApi?.api?.columnModel?.displayedColumns;
    const [searchField, setSearchField] = useState<string>('');

    const [viewsPayload, setViewsPayload] = useState<IAgGridTblPayload | undefined>(undefined);
    const [isUserCreatedView, setIsUserCreatedView] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [activateFilters, setActivateFilters] = useState<boolean>(false);

    const debouncedFetchData = useDebouncedCallback(
      (params: IServerSideGetRowsParams, workItemPayload: IAgGridTblPayload) => {
        fetchData(params, workItemPayload);
      },
      500
    );

    useEffect(() => {
      if (loading && listType === CONSTANTS.TASK_LIST) {
        setTimeout(() => {
          reloadGrid();
          setLoading(false);
        }, 5000);
      }
    }, [loading]);

    useEffect(() => {
      if (viewsPayload) {
        const gridNode = gridRef.current as any;

        if (viewsPayload.sortModel.length) {
          gridNode?.columnApi.api.applyColumnState({
            state: [viewsPayload.sortModel[0]],
          });
        }
      }
    }, [viewsPayload]);

    useEffect(() => {
      if (activateFilters && viewsPayload && listType === CONSTANTS.TASK_LIST) {
        const gridNode = gridRef.current as any;

        if (viewsPayload.rowGroupCols.length) {
          viewsPayload.rowGroupCols.forEach((group) => {
            gridNode?.columnApi.api.applyColumnState({
              state: [{ colId: group.id, rowGroup: true, hide: true }],
            });
          });
        }

        if (viewsPayload?.filterModel?.assignee?.filter === 'currentUser') {
          gridApi?.api.setFilterModel({
            assignee: {
              filterType: 'text',
              type: 'contains',
              filter: viewsPayload.assignee,
            },
          });
        } else {
          gridApi?.api.setFilterModel(viewsPayload.filterModel);
        }

        if (viewsPayload?.sortModel?.length) {
          gridNode?.columnApi.api.applyColumnState({
            state: [viewsPayload.sortModel[0]],
          });
        }

        if (viewsPayload?.filterModel?.taskId?.type === 'searchAllFields') {
          setSearchField(viewsPayload.filterModel.taskId?.filter ?? '');
        }

        setPageNo(viewsPayload.pageNumber);
        setTotalPages(viewsPayload.pageSize - 1);
        setActivateFilters(false);
      }
    }, [activateFilters]);

    // This will be responsible for clear the sort
    const clearSort = useCallback(() => {
      gridRef.current!.api.applyColumnState({
        defaultState: { sort: null },
      });
    }, []);

    const getRemovedColumns = () => {
      const gridNode = gridRef.current;
      const displayedColumns = gridNode?.columnApi.api.columnModel.displayedColumns;

      if (!gridNode || !displayedColumns) return;

      getColumnConfig(tenant, viewType)
        .then((res: Array<IDataModelColumn>) => {
          const showMoreCol: IDataModelColumn = {
            attributeLabel: '',
            attributeValue: '',
            attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
            attributeType: '',
            columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
            listType: listType,
          };
          const checkboxFirstCol: IDataModelColumn = {
            attributeLabel: '',
            attributeValue: '',
            attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
            attributeType: '',
            columnType: CONSTANTS.COLUMNTYPES.TEXT,
            listType: listType,
          };
          const columnsToAdd: Array<IDataModelColumn> = [];
          columnsToAdd.push(showMoreCol);
          columnsToAdd.push(checkboxFirstCol);
          res.forEach((column: IDataModelColumn) => {
            displayedColumns?.forEach((displayedColumn: any) => {
              if (column.attributeName === displayedColumn?.colId) {
                columnsToAdd.push(column);
              }
            });
          });
          setColumnsViews(columnsToAdd);
          const groupedColumn = gridRef?.current?.api?.getColumns()?.find((column: any) => {
            return column.isRowGroupActive();
          });

          if (groupedColumn) {
            gridRef?.current!.api?.autoSizeColumns([displayedColumns[0]?.colId], false);
          }
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_GETTING_COLUMNS_CONFIGS') }));
    };

    const handleSelectView = (
      viewsPayload: IAgGridTblPayload,
      filteredColumns: Array<IDataModelColumn>,
      userCreated: boolean
    ) => {
      setIsUserCreatedView(userCreated);
      const gridNode = gridRef.current as any;

      if (!gridNode || !gridApi) return;

      setViewsPayload(viewsPayload);
      gridApi?.api.setFilterModel(null);
      gridNode?.columnApi.api?.resetColumnState();
      gridApi?.api.onFilterChanged();
      setSearchField('');
      setPageNo(viewsPayload.pageNumber);
      setPageSize(viewsPayload.pageSize);
      setTotalPages(viewsPayload.pageSize - 1);
      listType === CONSTANTS.CASE_LIST && setGroupKeys && setGroupKeys(viewsPayload.groupKeys);

      if (viewsPayload?.filterModel?.id && viewsPayload.filterModel.id.type === 'searchAllFields') {
        setSearchField(viewsPayload.filterModel.id?.filter ?? '');
      }

      gridApi?.api.setFilterModel(viewsPayload.filterModel);

      const ids = viewsPayload.rowGroupCols.map((r) => r.id);
      const colDefs = gridApi?.api.getColumnDefs() || [];
      colDefs.forEach((c: ColDef) => {
        c['rowGroup'] = ids.includes(c?.colId as string);
      });
      gridApi?.api.setGridOption('columnDefs', colDefs);

      if (viewsPayload.sortModel?.length) {
        const sortModelMap = new Map(
          viewsPayload.sortModel.map((model) => [model.colId, model.sort])
        );
        filteredColumns?.forEach((column) => {
          column.columnStateProperties = {
            ...column.columnStateProperties,
            sort: sortModelMap.get(column?.attributeName) ?? null,
          };
        });
      }

      if (filteredColumns?.length && !viewsPayload?.rowGroupCols?.length) {
        setColumnsConfigs(filteredColumns);
      } else {
        setColumnsConfigs(columnsConfigs);
      }
      if (listType === CONSTANTS.TASK_LIST) {
        setActivateFilters(true);
      }
      clearSort();
    };

    useEffect(() => {
      reloadGrid();
    }, [pageNo, pageSize, searchField, tenant, activeRole, columnsConfigs]);

    const reloadGrid = () => {
      gridApi?.api?.updateGridOptions({ serverSideDatasource: datasource });
    };

    const datasource: IServerSideDatasource = {
      getRows(params: IServerSideGetRowsParams) {
        const { request, success, fail } = params;
        const { filterModel, sortModel, rowGroupCols, groupKeys } = request;
        setGroupByParam && setGroupByParam(rowGroupCols);
        LocalStorageUtils.setSavedItem(
          CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.ROW_GROUP_COLS,
          rowGroupCols
        );

        const filter = filterModel as FilterModel;
        if (filter) {
          for (const filterKey in filter) {
            if (filter[filterKey].filterType === 'date') {
              const matchingColumnConfig = columnsConfigs.find(
                (config: any) => config.attributeName === filterKey
              );
              if (matchingColumnConfig) {
                filter[filterKey].dateType = matchingColumnConfig.columnType;
              }

              const traverseAndConvert = (filterObj: any) => {
                for (const key in filterObj) {
                  if (filterObj[key] === null) {
                    filterObj[key] = '';
                  } else if (typeof filterObj[key] === 'object' && filterObj[key] !== null) {
                    traverseAndConvert(filterObj[key]);
                  }
                }
              };

              if (Array.isArray(filter[filterKey].conditions)) {
                delete filter[filterKey].conditions;
              }
              traverseAndConvert(filter[filterKey]);
            }
          }
        }

        const getDateFormatToGroupBy = (rowGroupCols: Array<ColumnVO>) => {
          let hasDate = false;
          for (const groupCol of rowGroupCols) {
            const isDate =
              gridNode?.columnApi.api?.columnModel.columnDefs?.find(
                (col: ColDef) => col.field === groupCol?.id
              )?.filter === CONSTANTS.AG_GRID.FILTER.DATE;

            if (isDate) {
              hasDate = true;
              break;
            }
          }

          return hasDate
            ? LocalStorageUtils.getSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.DATE_FORMAT)
            : null;
        };

        const saveNewPayload = (workItemPayload: IAgGridTblPayload) => {
          const payload = GeneralUtils.deepCopy(workItemPayload);

          if (listType === CONSTANTS.TASK_LIST) {
            LocalStorageUtils.setSavedItem(CONSTANTS.LOCAL_STORAGE_KEYS.TASK_LIST_PAYLOAD, payload);
            GeneralUtils.triggerEvt(EVENTS_CONSTANTS.TASK_LIST_PAYLOAD_UPDATED, payload);
          } else {
            LocalStorageUtils.setSavedItem(
              CONSTANTS.LOCAL_STORAGE_KEYS.AG_GRID.CASE_LIST.PAYLOAD,
              payload
            );
          }
        };

        const workItemPayload: IAgGridTblPayload = {
          filterModel: filter,
          sortModel: sortModel,
          pageNumber: pageNo,
          pageSize: pageSize,
          rowGroupCols: rowGroupCols,
          groupKeys: groupKeys,
          assignee: userInfo.user,
          tenant: tenant,
          dateFormat: getDateFormatToGroupBy(rowGroupCols),
        };

        if (searchField) {
          filter.id = {
            filterType: typeof searchField,
            type: 'searchAllFields',
            filter: searchField,
          };
        } else if (!searchField && filter?.id?.type === 'searchAllFields') {
          if (filter.id) {
            delete filter.id;
          }
        }
        saveNewPayload(workItemPayload);

        setDataLoading(true);
        debouncedFetchData(params, workItemPayload);
      },
    };

    const onGridReady = (params: GridReadyEvent) => {
      setGridApi(params);
      params.api.setGridOption('domLayout', 'autoHeight');
      params.api.setGridOption('serverSideDatasource', datasource);
    };

    const getTableHeaders = () => {
      gridApi?.api.showLoadingOverlay();
      getColumnConfig(tenant, viewType)
        .then((res: Array<IDataModelColumn>) => {
          const checkboxFirstCol: IDataModelColumn = {
            attributeLabel: '',
            attributeValue: '',
            attributeName: CONSTANTS.AG_GRID.COLUMNS.CHECKBOX,
            attributeType: '',
            columnType: CONSTANTS.COLUMNTYPES.TEXT,
            listType: listType,
          };
          const showMoreCol: IDataModelColumn = {
            attributeLabel: '',
            attributeValue: '',
            attributeName: CONSTANTS.AG_GRID.COLUMNS.SHOW_MORE,
            attributeType: '',
            columnType: CONSTANTS.COLUMNTYPES.SHOW_MORE,
            listType: listType,
          };
          if (res.length) {
            res.unshift(checkboxFirstCol);
            res.unshift(showMoreCol);
          }
          setColumnsConfigs(res);
          gridApi?.api.hideOverlay();
        })
        .catch(() => addAlert({ type: 'error', primaryText: t('ERROR_FETCHING') }));
    };

    useEffect(() => {
      if (tenant) {
        getTableHeaders();
      }
    }, [gridApi, tenant]);

    const onFilterTextBoxChanged = (word: string) => {
      setSearchField(word);
    };

    // /**
    //  * Auto-size all columns once the initial data is rendered.
    //  */
    // const autoSizeAll = useCallback(
    //   (skipHeader: boolean) => {
    //     const allColumnIds: string[] = [];
    //     if (!isUserCreatedView) {
    //       gridRef?.current!.api?.getColumns()!.forEach((column: any) => {
    //         allColumnIds.push(column.getId());
    //       });
    //       gridRef?.current!.api?.autoSizeColumns(allColumnIds, skipHeader);
    //       const params = {
    //         force: true,
    //       };
    //       gridRef?.current!.api?.refreshCells(params);
    //     }
    //   },
    //   [isUserCreatedView]
    // );

    const commaAgGridProps = {
      // processRowPostCreate: () => autoSizeAll(false),
      gridOptions: gridOptions,
      ref: gridRef,
      defaultColDef: CONSTANTS.AG_GRID.DEFAULT_COL_DEF,
      rowGroupPanelShow: 'always',
      rowSelection: 'multiple',
      domLayout: 'autoHeight',
      rowModelType: 'serverSide',
      suppressRowClickSelection: true,
      suppressServerSideInfiniteScroll: false,
      animateRows: true,
      columnDefs: columns,
      onGridReady: onGridReady,
      getRowClass: getRowClass,
      onRowSelected: onRowSelected,
      onColumnMoved: columnsMoved,
      onColumnVisible: getRemovedColumns,
      getContextMenuItems: getContextMenuItems,
      onPaginationChanged: () => {
        if (!selectAll) {
          gridApi?.api.deselectAll();
          setGridSelectedRows([]);
        }
      },
      paginationPageSize: pageSize,
      paginationAutoPageSize: false,
      cacheBlockSize: 0,
    };
    let agGridProps = {};
    if (listType === CONSTANTS.CASE_LIST && groupKeys) {
      agGridProps = {
        ...commaAgGridProps,
        isGroupOpenByDefault: (params: any) => groupKeys.includes(params.key),
        groupSelectsChildren: !!searchField,
        onFilterChanged: onFilterChanged,
        onColumnRowGroupChanged: onColumnRowGroupChanged,
        onRowGroupOpened: onRowGroupOpened,
      };
    } else {
      agGridProps = {
        ...commaAgGridProps,
        groupSelectsChildren: groupByParams && !(searchField && !groupByParams.length),
      };
    }
    return (
      <div className={listType === CONSTANTS.CASE_LIST ? 'custom-container' : ''}>
        {loading && listType === CONSTANTS.TASK_LIST && <Loader isMainLoader />}
        <Grid container spacing={2} className="padding-top-40" justifyContent="space-between">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }} mb={1}>
            {t(gridName)}
          </Typography>

          {showCaseCreationModule &&
            GeneralUtils.checkUserPermissions(PermissionTypes.CREATE_CASE) && (
              <FormControl variant="standard" sx={{ minWidth: 170, mb: 1 }} size="small">
                <InputLabel className="color-black">
                  <div className="create-case-btn">
                    <AddIcon className="color-blue-200" />
                    <div className="btn">{t('CREATE_NEW')}</div>
                  </div>
                </InputLabel>
                <Select
                  label={t('CREATE_NEW')}
                  value={selectedGroup?.itemCode ?? ''}
                  sx={{
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  }}
                  onChange={(e: SelectChangeEvent<string>) =>
                    getSelectedGroup && getSelectedGroup(e.target.value)
                  }>
                  {caseCreationLoading ? (
                    <Loader />
                  ) : (
                    groups?.map((group: any) => {
                      return (
                        <MenuItem
                          key={group.itemCode}
                          className="create-case-select color-black"
                          value={group.itemCode}>
                          {group.description}
                        </MenuItem>
                      );
                    })
                  )}
                </Select>
              </FormControl>
            )}

          <Paper variant="outlined" className="transaction-paper full-width">
            <div className="center-aligned-space-between padding-left-right-10">
              <Stack spacing={2} direction="row" className="center-aligned">
                <FormControlLabel
                  control={
                    <CheckboxComponent
                      isDisabled={noRows}
                      checked={selectAll}
                      onClick={handleSelectAll}
                    />
                  }
                  label={<span className="label bold-font">{selcetAllLabel}</span>}
                />
                {showAssignTaskModule && (
                  <>
                    {(usercanFullTaskUnassign || usercanTaskUnassign) && (
                      <AssignTask
                        isUnAssign
                        gridSelectedRows={gridSelectedRows}
                        bulkAssign={selectAll}
                        reloadGrid={reloadGrid}
                        taskListLoading={loading}
                        setTaskListLoading={setLoading}
                      />
                    )}
                    {(usercanFullTaskAssign || usercanTaskAssign) && (
                      <AssignTask
                        gridSelectedRows={gridSelectedRows}
                        bulkAssign={selectAll}
                        users={users}
                        reloadGrid={reloadGrid}
                        taskListLoading={loading}
                        setTaskListLoading={setLoading}
                      />
                    )}
                  </>
                )}
                {showViewsModule && payload && gridApi && (
                  <Views
                    payload={payload}
                    columnsViews={columnsViews}
                    defaultColumns={columnsConfigs}
                    onSelectView={handleSelectView}
                    tenant={tenant}
                    type={viewType}
                    gridApi={gridApi}
                  />
                )}
              </Stack>
              <Stack spacing={2} direction="row" className="center-aligned">
                {canRTJRequest && (
                  <RequestToJoin caseIds={gridSelectedRows.map((row: ITask) => row.caseId)} />
                )}
                <Box className="label bold-font search-box margin-bottom-10">
                  <TextFieldComponent
                    placeholder={t('SEARCH_3_DOTS')}
                    type="text"
                    value={payload && searchField ? searchField : ''}
                    setData={(value: string) => onFilterTextBoxChanged(value)}
                    icon={<SearchIcon />}
                  />
                </Box>
                {showExportModule && payload && (
                  <ExportComponent
                    noRows={noRows}
                    viewType={viewType}
                    rows={gridSelectedRows}
                    payload={payload}
                    selectedAll={selectAll}
                    currentCols={displayedColumns
                      ?.filter(
                        (col: Column) =>
                          col.getColId() != CONSTANTS.AG_GRID.COLUMNS.CHECKBOX &&
                          col.getColId() != CONSTANTS.AG_GRID.COLUMNS.GROUP_BY
                      )
                      ?.map((col: Column) => {
                        const columnType = columnsConfigs.find(
                          (colConfig) => colConfig.attributeName === col.getColId()
                        )?.columnType;

                        if (columnType) {
                          return {
                            id: col.getColId(),
                            field: col.getColId(),
                            displayName: col.getUserProvidedColDef()?.headerName ?? '',
                            columnType,
                          };
                        }
                      })}
                  />
                )}

                {showResetModule && (
                  <Reset
                    searchField={searchField}
                    setSearchField={setSearchField}
                    setDefaultView={setDefaultView}
                    setColumnSorting={setColumnSorting}
                    setColumnGroup={setColumnGroup}
                    setColumnFiltering={setColumnFiltering}
                    setSelectAllChecked={setSelectAllChecked}
                    setResetColumns={setResetColumns}
                    defaultView={defaultView}
                    columnFiltering={columnFiltering}
                    columnSorting={columnSorting}
                    columnGroup={columnGroup}
                    selectAllChecked={selectAllChecked}
                    resetColumns={resetColumns}
                    grid={gridRef}
                    api={gridApi}
                    columnsConfigs={columnsConfigs}
                    displayedColumns={displayedColumns ?? []}
                  />
                )}
              </Stack>
            </div>
          </Paper>
          <Box width="100%">
            <ErrorBoundary history={history} params={params}>
              <div className="ag-theme-alpine">
                <AgGridReact {...agGridProps} />

                <Pagination
                  pageSize={pageSize}
                  pageNo={pageNo}
                  totalPages={totalPages}
                  totalElements={totalElements}
                  setPageSize={setPageSize}
                  setPageNo={setPageNo}
                  dataLoading={dataLoading}
                />
              </div>
            </ErrorBoundary>
          </Box>
        </Grid>

        {showCaseCreationModule && (
          <ErrorBoundary history={history} params={params}>
            {openModal && (
              <CreateCaseModal
                isOpen={openModal}
                group={selectedGroup}
                tenant={tenant}
                closeDlg={() => {
                  setOpenModal && setOpenModal(false);
                  setSelectedGroup && setSelectedGroup(undefined);
                }}
                reloadGrid={reloadGrid}
              />
            )}
          </ErrorBoundary>
        )}
      </div>
    );
  }
);
// Set the display name for better debugging
AgGridTbl.displayName = 'AgGridTbl';
export default AgGridTbl;
