import React from 'react';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { IChart, IChartData } from '../../utils/entities/chart/IChart';
import WorkloadCards from './cards/WorkloadCards';
import { ChartData, ChartOptions } from 'chart.js';
import { ICardViewDetails } from '../../utils/entities/chart/ICards';

const ChartSelector = (props: IChart) => {
  const { data, chartType } = props;

  const getChartOptions = (): ChartOptions<any> => {
    const chartData: IChartData = { ...(data as IChartData) };

    return {
      elements: {
        bar: {
          borderWidth: 1,
        },
      },
      indexAxis: chartData.indexAxis,
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          labels: {
            usePointStyle: true,
            boxHeight: 8,
            boxWidth: 8,
          },
          display: true,
        },
        title: {
          display: true,
          text: chartData.title,
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: chartData.stacked,
          grid: {
            display: chartData.indexAxis === 'y',
          },
        },
        y: {
          stacked: chartData.stacked,
          grid: {
            display: chartData.indexAxis === 'x',
          },
        },
      },
    };
  };

  const getChartData = (): ChartData<any> => {
    const chartData: IChartData = { ...(data as IChartData) };

    return {
      labels: chartData.labels,
      datasets: chartData.datasets,
    };
  };

  const getChart = () => {
    switch (chartType?.toUpperCase()) {
      case 'BAR':
        return <Bar options={getChartOptions()} data={getChartData()} />;
      case 'LINE':
        return <Line options={getChartOptions()} data={getChartData()} />;
      case 'DOUGHNUT':
        return <Doughnut options={getChartOptions()} data={getChartData()} />;
      case 'CARDS':
        return <WorkloadCards data={data as ICardViewDetails} />;
    }
  };

  return <>{getChart()}</>;
};

export default ChartSelector;
